import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, DeleteSchoolSubscriptionAction} from "mindsets-js-sdk";

const DeleteButton = function ({item}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <button
                className='button red'
                onClick={event => {
                    event.preventDefault();
                    if (confirm('Are you sure you want to delete this School Subscription?')) {
                        dispatch(DeleteSchoolSubscriptionAction(item.school_id, item.id));
                    }
                }}
            >
                <i className='fas fa-trash'/>
            </button>
        );
    }

    return null;
}

DeleteButton.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        school_id: PropTypes.number.isRequired
    }).isRequired
}

export default DeleteButton;
