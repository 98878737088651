import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Checkbox} from "mindsets-js-sdk";

import Batch from "./Batch";

function BatchesTable({form_shown, setBatchId, action, selected_batches, setSelectedBatches}) {
    const project_batches = useSelector(state => state.project_batches);

    if (form_shown) {
        return null;
    }

    if (project_batches.length > 0) {
        const batches = JSON.parse(JSON.stringify(project_batches));
        const selected_batches_sorted = JSON.parse(JSON.stringify(selected_batches)).sort();
        let number_students = 0;
        let number_students_to_grade = 0;
        let number_students_graded = 0;
        let number_responses = 0;
        let number_responses_gradable = 0;
        let number_responses_to_grade = 0;
        let number_responses_graded = 0;
        let number_responses_auto_graded = 0;
        batches.map(item => {
            if (item.number_students > 0) {
                number_students += item.number_students;
            }
            if (item.number_students_to_grade > 0) {
                number_students_to_grade += item.number_students_to_grade;
            }
            if (item.number_students_graded > 0) {
                number_students_graded += item.number_students_graded;
            }
            if (item.number_responses > 0) {
                number_responses += item.number_responses;
            }
            if (item.number_responses_to_grade > 0) {
                number_responses_to_grade += item.number_responses_to_grade;
            }
            if (item.number_responses_graded > 0) {
                number_responses_graded += item.number_responses_graded;
            }
            if (item.number_responses_gradable > 0) {
                number_responses_gradable += item.number_responses_gradable;
            }
            if (item.number_responses_auto_graded > 0) {
                number_responses_auto_graded += item.number_responses_auto_graded;
            }
        });

        return (
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr className="text-center">
                        <th scope="col">
                            <Checkbox
                                checked={selected_batches_sorted.join(',') === batches.map(batch => batch.id).sort().join(',')}
                                toggle={() => {
                                    let array = [];
                                    if (selected_batches_sorted.join(',') !== batches.map(batch => batch.id).sort().join(',')) {
                                        array = batches.filter(batch => batch.status === 'draft').map(batch => batch.id);
                                    }
                                    setSelectedBatches(array);
                                }}
                            />
                        </th>
                        <th scope="col">No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Grading Provider</th>
                        <th scope="col">Practice</th>
                        <th scope="col">Completion</th>
                        <th scope="col"># Classes</th>
                        <th scope="col">Status</th>
                        <th scope="col"># students graded/requested/total</th>
                        <th scope="col">
                            <div># responses</div>
                            <div>
                                <span>graded</span>
                                <span>/</span>
                                <span className='text-info'>requested</span>
                                <span>/</span>
                                <span className='text-warning'>auto</span>
                                <span>/</span>
                                <span>gradable</span>
                                <span>/</span>
                                <span className='text-success'>total</span>
                            </div>
                        </th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {batches.map((item, index) => (
                        <Batch
                            key={item.id}
                            item={item}
                            row_number={index + 1}
                            selected_batches={selected_batches_sorted}
                            setSelectedBatches={setSelectedBatches}
                            setBatchId={setBatchId}
                            action={action}
                        />
                    ))}
                    <tr>
                        <td colSpan={8}><strong>Total</strong></td>
                        <td className='text-center'>
                            <strong>
                            <span
                                className={number_students_graded !== number_students_to_grade ? 'text-danger' : ''}
                            >{number_students_graded}</span>
                                <span>/</span>
                                <span>{number_students_to_grade}</span>
                                <span>/</span>
                                <span>{number_students}</span>
                            </strong>
                        </td>
                        <td className='text-center'>
                            <strong>
                            <span
                                className={number_responses_graded !== number_responses_to_grade ? 'text-danger' : ''}
                            >{number_responses_graded}</span>
                                <span>/</span>
                                <span className='text-info'>{number_responses_to_grade}</span>
                                <span>/</span>
                                <span className='text-warning'>{number_responses_auto_graded}</span>
                                <span>/</span>
                                <span>{number_responses_gradable}</span>
                                <span>/</span>
                                <span className='text-success'>{number_responses}</span>
                            </strong>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <p>No batches found</p>
        );
    }
}

BatchesTable.propTypes = {
    form_shown: PropTypes.bool.isRequired,
    setBatchId: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
    selected_batches: PropTypes.array.isRequired,
    setSelectedBatches: PropTypes.func.isRequired
}

export default BatchesTable;
