import React from 'react';

import BaseAnswer from '../BaseAnswer';
import AnswerGroup from './AnswerGroup';
import AnswerLayout from './AnswerLayout';


class DragAndDrop extends BaseAnswer {
    renderAnswerGroups() {
        const answer_groups = _.get(this.props.component_data, 'data.answer_groups')
        const render_groups = answer_groups.map((answer_group) => {
            return (
                <div key={answer_group.dnd_type} className="">
                    <AnswerGroup
                        answer_group={answer_group}
                        answer_layout={_.get(this.props.component_data, 'data.answer_layout')}
                    />
                </div>
            );
        })

        return (
            <div className="d-flex flex-box-10-10 flex-wrap">
                {render_groups}
            </div>
        )
    }

    renderAnswerLayout() {
        const answer_layout = _.get(this.props.component_data, 'data.answer_layout')
        const answer_groups = _.get(this.props.component_data, 'data.answer_groups')

        return (
            <AnswerLayout
                answer_layout={answer_layout}
                answer_groups={answer_groups}
            />
        );
    }

    render() {
        return (
            <div className="challenge-component-dnd">
                {this.renderAnswerGroups()}

                <div className="section">
                </div>

                <div>
                    {this.renderAnswerLayout()}
                </div>
            </div>
        );
    }
}

export default DragAndDrop
