import React, {useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {ErrorMessageAction, LoadingAction, CreateProjectBatchAction} from "mindsets-js-sdk";

const CreateBatchForm = function ({setShown, action}) {
    const dispatch = useDispatch();
    const project = useSelector(state => state.project);
    const practices = useSelector(state => state.project_practices);
    const grading_providers = useSelector(state => state.grading_providers);
    const [name, setName] = useState('');
    const [practice_id, setPracticeId] = useState(null);
    const [grading_provider_id, setGradingProviderId] = useState(null);
    const [completion, setCompletion] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [selected_lesson_codes_string, setSelectedLessonCodesString] = useState('');

    function validate() {
        let errors = 0;

        if (!name || '' === name) {
            dispatch(ErrorMessageAction('Error! Please specify the name of the Project Batch.'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <form onSubmit={event => {
            event.preventDefault();
            if (validate) {
                dispatch(CreateProjectBatchAction(project.id, {
                    name,
                    practice_id,
                    grading_provider_id,
                    completion,
                    class_codes: selected_lesson_codes_string.split(',').map(item => item.trim()).filter(item => item !== '').join(',')
                })).then(() => {
                    setName('');
                    setPracticeId(null);
                    setGradingProviderId(null);
                    setCompletion(null);
                    setSelectedLessonCodesString('');
                    setShown(false);
                }).then(action).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error, true));
                    console.error(error);
                });
            }
        }} autoComplete="off" className='mb-3'>
            <div>
                <label htmlFor="name" className="form-label">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={event => {
                        setName(event.target.value);
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}/>
            </div>
            <div>
                <label className='form-label' htmlFor='practice'>Practice/Curriculum</label>
                <div>
                    <select
                        id="practice"
                        value={practice_id ? practice_id : ''}
                        onChange={event => {
                            setPracticeId(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    >
                        <option value=''>Please select</option>
                        {practices.map(practice => {
                            return (
                                <option value={practice.id} key={practice.id}>{practice.name}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div>
                <label className='form-label' htmlFor='grading_provider'>Grading Provider</label>
                <div>
                    <select
                        id="grading_provider"
                        value={grading_provider_id ? grading_provider_id : ''}
                        onChange={event => {
                            setGradingProviderId(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    >
                        <option value=''>Please select</option>
                        {grading_providers.map(grading_provider => {
                            return (
                                <option value={grading_provider.id}
                                        key={grading_provider.id}>{grading_provider.name}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div>
                <label className='form-label' htmlFor='completion'>Completion</label>
                <div>
                    <select
                        id="completion"
                        value={completion ? completion : ''}
                        onChange={event => {
                            setCompletion(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    >
                        <option value=''>Please select</option>
                        <option value='stuck'>Stuck</option>
                        <option value='underway'>Underway</option>
                        <option value='nearly'>Nearly</option>
                        <option value='completed'>Completed</option>
                        <option value='completed_bonus'>Completed bonus</option>
                    </select>
                </div>
            </div>
            <div>
                <label htmlFor='lesson-codes' className='d-block'>Class codes</label>
                <textarea
                    id='lesson-codes'
                    value={selected_lesson_codes_string}
                    onChange={event => setSelectedLessonCodesString(event.target.value)}/>
            </div>

            <div className='d-flex flex-gap-5'>
                <button type="submit" className="button">Submit</button>
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    setName('');
                    setPracticeId(null);
                    setGradingProviderId(null);
                    setCompletion(null);
                    setSelectedLessonCodesString('');
                    setShown(false);
                }}>Cancel
                </button>
            </div>
        </form>
    );
}

CreateBatchForm.propTypes = {
    setShown: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
}

export default CreateBatchForm;
