import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: false,
            attributes: {
                text: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                bubble_background: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                }
            }
        };
    }
}
