import {LOADED_ANNOUNCEMENT_ROLES} from "../actions/announcement_actions";

const announcement_roles = (payload = [], action) => {
    switch (action.type) {
        case LOADED_ANNOUNCEMENT_ROLES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    announcement_roles
}
