import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ApproveContractAction} from "mindsets-js-sdk";

function ApproveButton({id, status, callback}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MASTER_CONTRACTS]) && status === 'review') {
        return (
            <button className="button" onClick={event => {
                event.preventDefault();
                dispatch(ApproveContractAction(id)).then(callback);
            }}>Approve</button>
        );
    }

    return null;
}

ApproveButton.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
};

export default ApproveButton;
