import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ReviewContractAction} from "mindsets-js-sdk";


const ReviewButton = function ({id, status, callback}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS]) && status === 'draft') {
        return (
            <button className="button" onClick={event => {
                event.preventDefault();
                dispatch(ReviewContractAction(id)).then(callback);
            }}>Submit for review</button>
        );
    }

    return null;
};

ReviewButton.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
};

export default ReviewButton;
