import React, {Component} from 'react';
import PropTypes from 'prop-types';

import CardRow from './CardRow';


class CardContent extends Component {
    renderRows() {
        if (this.props.card_content && this.props.card_content.rows) {
            return this.props.card_content.rows.map((row_data, i) => {
                return (
                    <CardRow
                        key={i}
                        row_data={row_data}
                        row_index={i}
                        selectRow={this.props.selectRow}
                        selectComponent={this.props.selectComponent}
                        updateRow={row => {
                            let card_content = this.props.card_content
                            let rows = card_content.rows;
                            rows[i] = row;
                            card_content.rows = rows;
                            this.props.updateCardContent(card_content)
                        }}
                    />
                );
            })
        }

        return null;
    }

    render() {
        return (
            <div className="challenge-card-content">
                {this.renderRows()}
            </div>
        )
    }
}

CardContent.propTypes = {
    card_content: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.shape({
            components: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                data: PropTypes.object
            }))
        }))
    }),
    selectRow: PropTypes.func.isRequired,
    selectComponent: PropTypes.func.isRequired,
    updateCardContent: PropTypes.func.isRequired
}

export default CardContent
