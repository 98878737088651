import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {ResponseContent} from "mindsets-js-sdk";

function Row({item, row_number}) {
    const question = useSelector(state => state.question);

    if (question) {
        return (
            <tr>
                <td>{row_number}</td>
                <td>{item.id}</td>
                <td style={{wordBreak: 'break-word'}}>
                    <ResponseContent
                        question={question}
                        response={item.data.answer}/>
                </td>
                <td>{item.points}</td>
                <td>{item.ai_points}</td>
                <td>{item.reasoning}</td>
            </tr>
        );
    }

    return null;
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        points: PropTypes.number,
        ai_points: PropTypes.number,
        data: PropTypes.shape({
            answer: PropTypes.any.isRequired
        }).isRequired,
        reasoning: PropTypes.string
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
