import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    authUtils,
    accessConstants,
    LoadGradingProvidersAction,
    LoadProjectBatchesAction,
    LoadProjectAction,
    SetProjectAction,
    filterConstants
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadPracticesAction} from "../../actions/project_actions";
import Batches from "./components/Batches";
import UpdateProjectForm from "./components/UpdateProjectForm";
import Details from "./components/Details";

export default function () {
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const params = useParams();
    const project = useSelector(state => state.project);
    const [update, setUpdate] = useState(false);

    function action() {
        dispatch(LoadProjectAction(params.project_id, {
            'selectors[0]': 'district',
            'selectors[1]': 'school'
        }));
        dispatch(LoadProjectBatchesAction({
            'selectors[0]': 'lessons',
            'selectors[1]': 'number_students',
            'selectors[2]': 'number_students_to_grade',
            'selectors[3]': 'number_students_graded',
            'selectors[4]': 'number_responses',
            'selectors[5]': 'number_responses_to_grade',
            'selectors[6]': 'number_responses_graded',
            'selectors[7]': 'number_responses_gradable',
            'selectors[8]': 'number_responses_auto_graded',
            'filters[project_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.project_id,
            limit: 0
        }));
    }

    useEffect(() => {
        dispatch(LoadPracticesAction());
        dispatch(LoadGradingProvidersAction());
        action();

        return () => {
            dispatch(SetProjectAction({}));
        };
    }, []);

    if (!me || !project) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <div className='container section'>
                <div className='d-flex justify-content-between'>
                    <button className='button' onClick={() => navigate(-1)}>
                        <i className={'fas fa-backward'}/>
                    </button>
                    <h1>Grading Project {project.name}</h1>
                    <button className='button' onClick={() => setUpdate(!update)}>
                        <i className={'fas ' + (update ? 'fa-ban' : 'fa-edit')}/>
                    </button>
                </div>
                <Details/>
                <UpdateProjectForm update={update} setUpdate={setUpdate} action={action}/>
            </div>
            <div className='section'>
                <Batches action={action} update={update}/>
            </div>
        </>
    );
}
