import Validator from './Validator'

export default class JsonValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: true,
            attributes: {
                challenge: {
                    mandatory: true,
                    type: "string",
                    empty: false,
                },
                entities: {
                    mandatory: true,
                    type: "array",
                    empty: false,
                }
            }
        };
    }
}
