import React from 'react';
import {useSelector} from "react-redux";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.dashboards);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Project</th>
                    <th scope="col">Type</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item=> {
                    return (
                        <Row
                            key={item.id}
                            item={item}
                        />
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};
