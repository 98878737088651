import {
    LOADED_IMPACT_CHALLENGE_VERSION,
    LOADED_IMPACT_PRACTICES,
    LOADED_IMPACT_STREAMS,
    LOADED_IMPACT_STUDY
} from "../actions/impact_actions";

const impact_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_IMPACT_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};
const impact_challenge_version = (payload = null, action) => {
    switch (action.type) {
        case LOADED_IMPACT_CHALLENGE_VERSION:
            return action.payload;
        default:
            return payload;
    }
};
const impact_streams = (payload = [], action) => {
    switch (action.type) {
        case LOADED_IMPACT_STREAMS:
            return action.payload;
        default:
            return payload;
    }
};
const impact_study = (payload = null, action) => {
    switch (action.type) {
        case LOADED_IMPACT_STUDY:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    impact_practices,
    impact_challenge_version,
    impact_streams,
    impact_study
}
