import React from 'react';
import PropTypes from "prop-types";

const Row = function ({item}) {
    return (
        <tr>
            <td>{item.user.fname + ' ' + (item.user.lname ? item.user.lname : '')}</td>
            <td>{item.progress}</td>
            <td>{item.completed}</td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        user: PropTypes.shape({
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string
        }).isRequired,
        completed: PropTypes.number.isRequired,
        progress: PropTypes.number.isRequired
    }).isRequired
};

export default Row;
