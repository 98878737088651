import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'object',
      empty: false,
      mandatory: true,
      attributes: {
        rows: {
          type: 'array',
          empty: false,
          mandatory: true
        }
      }
    };
  }
}
