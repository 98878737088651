import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const Question = function ({question_code, practice_id}) {
    const questions = useSelector(state => state.questions);
    const practices = useSelector(state => state.responses_practices);
    const question = questions.find(question => question.code === question_code);

    function renderPoll() {
        if (question.answer_type === 'poll.inline') {
            return (
                <div>
                    {question.poll.poll_options.map((poll_option, index) => {
                        return (
                            <div key={poll_option.id}>{index + 1}. {poll_option.title}</div>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    function renderCorrectAnswer() {
        function getCorrectAnswer(qps) {
            if (question.answer_type === 'poll.inline') {
                return (
                    <div>
                        {qps.correct_answer.map(correct_answer_option => {
                            const poll_option = question.poll.poll_options.find(poll_option => poll_option.id === correct_answer_option.poll_option_id);
                            return (
                                <div key={correct_answer_option.poll_option_id}>{correct_answer_option.points} points - {poll_option.title}</div>
                            );
                        })}
                    </div>
                );
            } else {
                return qps.correct_answer;
            }
        }

        if (Array.isArray(question.question_practice_skills) && question.question_practice_skills.length > 0) {
            let question_practice_skills = question.question_practice_skills.filter(qps => Array.isArray(qps.correct_answer) && qps.correct_answer.length);
            if (!!practice_id) {
                question_practice_skills = question_practice_skills.filter(qps => qps.practice_skill.practice_id === practice_id);
            }

            return (
                <div>
                    {question_practice_skills.map(qps => {
                        const practice = practices.find(practice => practice.id === qps.practice_skill.practice_id);

                        return (
                            <div key={qps.id}>Correct Answer for {practice.name} is <b>{getCorrectAnswer(qps)}</b>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    if (question) {
        return (
            <div className='section'>
                <div dangerouslySetInnerHTML={{__html: 'Q' + question.number + ' ' + question.title}}/>
                <div>{question.answer_type}</div>
                {renderPoll()}
                {renderCorrectAnswer()}
            </div>
        );
    }

    return null;
}

Question.propTypes = {
    question_code: PropTypes.string,
    practice_id: PropTypes.number
};

export default Question;
