import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SpreadsheetHeader from './SpreadsheetHeader'
import SpreadsheetRow from './SpreadsheetRow'


class Spreadsheet extends Component {
    renderTableHeader() {
        let header_data = _.get(this.props.component_data, 'data.headers')
        if (!_.isEmpty(header_data)) {
            return (
                <SpreadsheetHeader
                    data={header_data}
                    updateRows={rows => {
                        let component_data = this.props.component_data;
                        component_data.data.headers = rows;
                        this.props.updateComponent(component_data);
                    }}
                />
            )
        }
    }

    renderTableRows() {
        const getPlaceholder = (row_key) => (col_key) => () => _.get(this.props.component_data, ['data', 'data', row_key, col_key])
        return this.props.component_data.data.data.map((row, row_key) => {
            return (
                <SpreadsheetRow
                    key={row_key}
                    data={row}
                    getPlaceholder={getPlaceholder(row_key)}
                    updateRow={row => {
                        let component_data = this.props.component_data;
                        component_data.data.data[row_key] = row;
                        this.props.updateComponent(component_data);
                    }}
                />
            )
        })
    }

    render() {
        const class_name = _.get(this.props.component_data, 'data.class_name');

        return (
            <div className="challenge-component-spreadsheet">
                <div className="horizontal-scroll-container">
                    <table className={class_name}>
                        {this.renderTableHeader()}

                        <tbody>
                        {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

Spreadsheet.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default Spreadsheet
