import React from 'react';
import {useSelector} from "react-redux";

import DistrictSubscriptionsRow from "./DistrictSubscriptionsRow";

export default function () {
    const district = useSelector(state => state.district);
    if (district.subscriptions && district.subscriptions.length > 0) {
        const subscriptions = JSON.parse(JSON.stringify(district.subscriptions)).reverse();

        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr className="text-center">
                            <th scope="col">No</th>
                            <th scope="col">Period</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Is Pilot</th>
                            <th scope="col">Created by</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {subscriptions.map((item, index) => (
                            <DistrictSubscriptionsRow
                                key={item.id}
                                item={item}
                                row_number={index + 1}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        return (
            <p>No subscriptions found</p>
        );
    }
};
