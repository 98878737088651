import React from 'react';
import {useSelector} from "react-redux";

import BaseComponentProperty from "../index";

class Selector extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    hasConfigs() {
        return true;
    }

    onChange(index, key, value) {
        let data = JSON.parse(JSON.stringify(this.props.component.data));
        data.items[index][key] = value;
        if (Object.keys(value).length <= 0) {
            delete data.items[index][key];
        }
        this.props.onChange('data', data);
    }

    renderItemProperties(item, index) {
        return (
            <>
                <div>
                    <label className="col-form-label">Title</label>
                    <input
                        type='text'
                        className='form-control'
                        value={item.title ? item.title : ''}
                        onChange={event => {
                            this.onChange(index, 'title', event.target.value);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Value</label>
                    <input
                        type='text'
                        className='form-control'
                        value={item.value ? item.value : ''}
                        onChange={event => {
                            this.onChange(index, 'value', event.target.value);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Image url</label>
                    <input
                        type='text'
                        className='form-control'
                        value={item.image_url ? item.image_url : ''}
                        onChange={event => {
                            this.onChange(index, 'image_url', event.target.value);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Image alt</label>
                    <input
                        type='text'
                        className='form-control'
                        value={item.image_alt ? item.image_alt : ''}
                        onChange={event => {
                            this.onChange(index, 'image_alt', event.target.value);
                        }}
                    />
                </div>
            </>
        );
    }

    renderOwnProperties() {
        return (
            <>
                <div>
                    <label className="col-form-label">Title 1</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.title_1 ? this.props.component.data.title_1 : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.title_1 = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Description 1</label>
                    <textarea
                        className='form-control'
                        value={this.props.component.data.description_1 ? this.props.component.data.description_1 : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.description_1 = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Title 2</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.title_2 ? this.props.component.data.title_2 : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.title_2 = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Description 2</label>
                    <textarea
                        className='form-control'
                        value={this.props.component.data.description_2 ? this.props.component.data.description_2 : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.description_2 = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Unit prefix</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.unit_prefix ? this.props.component.data.unit_prefix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.unit_prefix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Unit suffix</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.unit_suffix ? this.props.component.data.unit_suffix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.unit_suffix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Value prefix</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.value_prefix ? this.props.component.data.value_prefix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.value_prefix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Value suffix</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.value_suffix ? this.props.component.data.value_suffix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.value_suffix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Amount maximum</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.amount_maximum ? this.props.component.data.amount_maximum : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.amount_maximum = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Error amount too big</label>
                    <input
                        type='text'
                        className='form-control'
                        value={this.props.component.data.error_amount_too_big ? this.props.component.data.error_amount_too_big : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.error_amount_too_big = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label className="col-form-label">Items</label>
                    <button className='button mb-2' onClick={() => {
                        let data = JSON.parse(JSON.stringify(this.props.component.data));
                        data.items.push({
                            id: data.items.length + 1,
                            title: "",
                            value: "",
                            image_url: "",
                            image_alt: ""
                        });
                        this.props.onChange('data', data);
                    }}>+ Item
                    </button>
                    <div>
                        {this.props.component.data.items.map((item, index) => {
                            return (
                                <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                                    <i
                                        className='fas fa-trash text-danger clickable'
                                        style={{position: 'absolute', right: '0', top: '5px'}}
                                        onClick={() => {
                                            let data = JSON.parse(JSON.stringify(this.props.component.data));
                                            data.items.splice(index, 1);
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                    <div>
                                        {this.renderItemProperties(item, index)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default function (props) {
    return <Selector {...props}/>;
}
