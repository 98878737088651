import React from 'react';
import {accessConstants, BasicLayout, FullLayout} from 'mindsets-js-sdk';

import AIMatchingRate from "./pages/AIMatchingRate";
import Announcements from "./pages/Announcements";
import AnswerReview from "./pages/AnswerReview";
import AnnouncementCreate from "./pages/AnnouncementCreate";
import AnnouncementUpdate from "./pages/AnnouncementUpdate";
import Challenges from "./pages/Challenges";
import ChallengeBuilder from "./pages/ChallengeBuilder";
import ChallengeValidator from "./pages/ChallengeValidator";
import ChallengeVersions from "./pages/ChallengeVersions";
import Contracts from "./pages/Contracts";
import ContractCreate from "./pages/ContractCreate";
import ContractPreview from "./pages/ContractPreview";
import ContractUpdate from "./pages/ContractUpdate";
import Curricula from "./pages/Curricula";
import CurriculumCreate from "./pages/CurriculumCreate";
import CurriculumUpdate from "./pages/CurriculumUpdate";
import Dashboard from "./pages/Dashboard";
import Dashboards from "./pages/Dashboards";
import DashboardCreate from "./pages/DashboardCreate";
import DashboardUpdate from "./pages/DashboardUpdate";
import Districts from "./pages/Districts";
import DistrictCreate from "./pages/DistrictCreate";
import DistrictUpdate from "./pages/DistrictUpdate";
import Forbidden from "./pages/Forbidden";
import GraderReview from "./pages/GraderReview";
import GraderCorrectnessReview from "./pages/GraderCorrectnessReview";
import GradingProviders from "./pages/GradingProviders";
import GradingProviderCreate from "./pages/GradingProviderCreate";
import GradingProviderUpdate from "./pages/GradingProviderUpdate";
import GradingScoresReview from "./pages/GradingScoresReview";
import Impact from "./pages/Impact";
import ImpactStudy from "./pages/ImpactStudy";
import Lessons from "./pages/Lessons";
import LessonUpdate from "./pages/LessonUpdate";
import LessonSchedules from "./pages/LessonSchedules";
import LessonScheduleCreate from "./pages/LessonScheduleCreate";
import LessonFiltersAssign from "./pages/LessonFiltersAssign";
import LessonGradeAssign from "./pages/LessonGradeAssign";
import Login from "./pages/Login";
import Longitudinal from "./pages/Longitudinal";
import LongitudinalStudentView from "./pages/LongitudinalStudentView";
import NotFound from "./pages/NotFound";
import Permissions from "./pages/Permissions";
import Projects from "./pages/Projects";
import ProjectCreate from "./pages/ProjectCreate";
import ProjectUpdate from "./pages/ProjectUpdate";
import QuestionPracticeSkillGroups from "./pages/QuestionPracticeSkillGroups";
import QA from "./pages/QA";
import Questions from "./pages/Questions";
import Responses from "./pages/Responses";
import RoleCreate from "./pages/RoleCreate";
import Roles from "./pages/Roles";
import RubricManager from "./pages/RubricManager";
import RubricPreview from "./pages/RubricPreview";
import Scoring from "./pages/Scoring";
import Schools from "./pages/Schools";
import SchoolCreate from "./pages/SchoolCreate";
import SchoolUpdate from "./pages/SchoolUpdate";
import Settings from "./pages/Settings";
import SettingUpdate from "./pages/SettingUpdate";
import Streams from "./pages/Streams";
import StreamCreate from "./pages/StreamCreate";
import StudentCompletion from "./pages/StudentCompletion";
import Standards from "./pages/Standards";
import StandardUpdate from "./pages/StandardUpdate";
import TimeSpent from "./pages/TimeSpent";
import Users from "./pages/Users";
import UsersCreate from "./pages/UsersCreate";
import UserUpdate from "./pages/UserUpdate";
import Error from "./pages/Error";
import UserMenu from "./layout/UserMenu";
import MainMenu from "./layout/MainMenu";

export default [
    {
        path: '/',
        element: <FullLayout user_menu={<UserMenu/>} main_menu={<MainMenu/>} with_footer={false} can_login_to_permission={accessConstants.PERMISSION_CAN_LOGIN_TO_ADMIN}/>,
        errorElement: <Error/>,
        children: [
            {
                path: '/announcements',
                element: <Announcements/>
            },
            {
                path: '/announcements/new',
                element: <AnnouncementCreate/>
            },
            {
                path: '/announcements/:announcement_id',
                element: <AnnouncementUpdate/>
            },
            {
                path: '/answers',
                element: <AnswerReview/>
            },
            {
                path: '/challenges',
                element: <Challenges/>
            },
            {
                path: '/challenges/:challenge_code',
                element: <ChallengeVersions/>
            },
            {
                path: '/builder',
                element: <ChallengeBuilder/>
            },
            {
                path: '/validator',
                element: <ChallengeValidator/>
            },
            {
                path: '/contracts',
                element: <Contracts/>
            },
            {
                path: '/contracts/new',
                element: <ContractCreate/>
            },
            {
                path: '/contracts/:contract_id',
                element: <ContractPreview/>
            },
            {
                path: '/contracts/:contract_id/edit',
                element: <ContractUpdate/>
            },
            {
                path: '/curricula',
                element: <Curricula/>
            },
            {
                path: '/curricula/new',
                element: <CurriculumCreate/>
            },
            {
                path: '/curricula/:curriculum_id',
                element: <CurriculumUpdate/>
            },
            {
                path: '/',
                element: <Dashboard/>
            },
            {
                path: '/dashboards',
                element: <Dashboards/>
            },
            {
                path: '/dashboards/new',
                element: <DashboardCreate/>
            },
            {
                path: '/dashboards/:dashboard_id',
                element: <DashboardUpdate/>
            },
            {
                path: '/districts',
                element: <Districts/>
            },
            {
                path: '/districts/new',
                element: <DistrictCreate/>
            },
            {
                path: '/districts/:district_id',
                element: <DistrictUpdate/>
            },
            {
                path: '/403',
                element: <Forbidden/>
            },
            {
                path: '/grader_review',
                element: <GraderReview/>
            },
            {
                path: '/grader_correctness',
                element: <GraderCorrectnessReview/>
            },
            {
                path: '/ai_matching_rates',
                element: <AIMatchingRate/>
            },
            {
                path: '/grading_providers',
                element: <GradingProviders/>
            },
            {
                path: '/grading_providers/new',
                element: <GradingProviderCreate/>
            },
            {
                path: '/grading_providers/:grading_provider_id',
                element: <GradingProviderUpdate/>
            },
            {
                path: '/grading_scores/:question_code/:practice_id',
                element: <GradingScoresReview/>
            },
            {
                path: '/impact',
                element: <Impact/>
            },
            {
                path: '/impact_study',
                element: <ImpactStudy/>
            },
            {
                path: '/lessons',
                element: <Lessons/>
            },
            {
                path: '/lessons/:lesson_id',
                element: <LessonUpdate/>
            },
            {
                path: '/lessons/:lesson_id/streams',
                element: <Streams/>,
            },
            {
                path: '/lessons/:lesson_id/streams/new',
                element: <StreamCreate/>,
            },
            {
                path: '/lesson_schedules',
                element: <LessonSchedules/>
            },
            {
                path: '/lesson_schedules/new',
                element: <LessonScheduleCreate/>
            },
            {
                path: '/lesson_grade',
                element: <LessonGradeAssign/>
            },
            {
                path: '/lesson_filter',
                element: <LessonFiltersAssign/>
            },
            {
                path: '/longitudinal',
                element: <Longitudinal/>
            },
            {
                path: '/longitudinal/:school_id/student/:user_id',
                element: <LongitudinalStudentView/>
            },
            {
                path: '/permissions',
                element: <Permissions/>
            },
            {
                path: '/projects',
                element: <Projects/>
            },
            {
                path: '/projects/new',
                element: <ProjectCreate/>
            },
            {
                path: '/projects/:project_id',
                element: <ProjectUpdate/>
            },
            {
                path: '/qa/:schedule_id',
                element: <QA/>
            },
            {
                path: '/questions',
                element: <Questions/>
            },
            {
                path: '/responses',
                element: <Responses/>
            },
            {
                path: '/roles',
                element: <Roles/>
            },
            {
                path: '/roles/new',
                element: <RoleCreate/>
            },
            {
                path: '/rubrics/:challenge_code',
                element: <RubricManager/>
            },
            {
                path: '/rubrics',
                element: <QuestionPracticeSkillGroups/>
            },
            {
                path: '/challenge/:challenge_code/practice/:practice_code/preview',
                element: <RubricPreview/>
            },
            {
                path: '/schedule/:schedule_id',
                element: <Scoring/>
            },
            {
                path: '/schools',
                element: <Schools/>
            },
            {
                path: '/schools/new',
                element: <SchoolCreate/>
            },
            {
                path: '/schools/:school_id',
                element: <SchoolUpdate/>
            },
            {
                path: '/settings',
                element: <Settings/>
            },
            {
                path: '/settings/:key',
                element: <SettingUpdate/>
            },
            {
                path: '/standards',
                element: <Standards/>
            },
            {
                path: '/standards/:standard_id',
                element: <StandardUpdate/>
            },
            {
                path: '/completion',
                element: <StudentCompletion/>
            },
            {
                path: '/timespent',
                element: <TimeSpent/>
            },
            {
                path: '/users',
                element: <Users/>
            },
            {
                path: '/users/new',
                element: <UsersCreate/>
            },
            {
                path: '/users/:user_id',
                element: <UserUpdate/>
            }
        ]
    },
    {
        path: '/login',
        element: <BasicLayout/>,
        errorElement: <Error/>,
        children: [
            {
                path: '/login',
                element: <Login/>
            }
        ]
    },
    {
        path: '*',
        element: <NotFound/>
    }
];
