import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigDirectionValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'string',
            empty: false,
            mandatory: false
        };
    }
}
