import React from 'react';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

import FractionInput from './FractionInput';


export default (props) => {

    const renderInput = (fieldKey) => {
        const fieldData = _.get(props.itemData, ['inputs', fieldKey]);
        return (
            <FractionInput
                component_data={props.component_data}
                is_preview={props.is_preview}
                answer={props.answer}
                existing_answer={props.existing_answer}
                onChange={props.onChange(fieldKey)}
                fieldData={fieldData}
                fieldKey={fieldKey}
                fractionKey={props.fractionKey}
            />
        );
    }

    const renderText = (fieldKey) => {
        const fieldData = _.get(props.itemData, ['texts', fieldKey]);
        if (fieldData) {
            return (
                <div
                    className="cc-fraction-text"
                    style={fieldData.style}
                >
                    <HTMLContent html_str={contentUtils.processStringContent(fieldData.content.html)}/>
                </div>
            )
        }
    }

    const fractionStyle = _.get(props.itemData, 'style');
    const dividerStyle = _.get(props.itemData, 'divider.style');

    return (
        <div className="cc-fraction-fractionpart" style={fractionStyle}>
            <div className="d-flex align-items-center">
                {renderInput('front')}
                {renderText('front')}
                <div className="d-flex flex-column align-items-center">
                    {renderInput('top')}
                    {renderText('top')}
                    <div className="cc-fraction-divider" style={dividerStyle}></div>
                    {renderInput('bottom')}
                    {renderText('bottom')}
                </div>
            </div>
        </div>
    )
}
