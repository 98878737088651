import React from 'react';

import SliderSingle from "./SliderSingle";

export default class SliderMultiple extends SliderSingle {
    renderValue() {
        return [
            <div key='minValue'>
                <label htmlFor="minValue">Min value</label>
                <input
                    type='text'
                    id="minValue"
                    className='form-control'
                    value={this.props.component.data.valueMin ? this.props.component.data.valueMin : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.valueMin = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>,
            <div key='maxValue'>
                <label htmlFor="maxValue">Max value</label>
                <input
                    type='text'
                    id="maxValue"
                    className='form-control'
                    value={this.props.component.data.valueMax ? this.props.component.data.valueMax : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.valueMax = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        ];
    }
}
