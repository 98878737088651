import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReadOnly from './ReadOnly'

class SpreadsheetCell extends Component {
    renderImage() {
        if (this.props.data.image) {
            return (
                <div>
                    <img className="spreadsheet-image" src={this.props.data.image} alt=''/>
                </div>
            );
        }
    }

    renderText() {
        if (!this.props.data.editable && !this.props.data.image) {
            return (
                <input
                    type='text'
                    className='clear'
                    placeholder='Put text here'
                    value={this.props.data.title ? this.props.data.title : ''}
                    onChange={event => {
                        let cell = this.props.data;
                        cell.title = event.target.value;
                        this.props.updateCell(cell);
                    }}/>
            );
        }
    }

    renderReadOnly() {
        if (!!this.props.data.editable) {
            return (
                <ReadOnly data={this.props.data}/>
            );
        }
    }

    render() {
        const class_name = "spreadsheet-cell " + this.props.data.class_name + (this.props.data.editable ? ' spreadsheet-cell-editable' : '');

        return (
            <td className={class_name} style={this.props.data.style}>
                <div className="">
                    {this.renderImage()}
                    {this.renderText()}
                    {this.renderReadOnly()}
                </div>
            </td>
        )
    }
}

SpreadsheetCell.propTypes = {
    data: PropTypes.object.isRequired,
    updateCell: PropTypes.func.isRequired
}

export default SpreadsheetCell
