import {LOADED_GRADING_CONSISTENCY, LOADED_GRADING_CORRECTNESS} from "../actions/statistics";

const grading_consistency = (payload = {}, action) => {
    switch (action.type) {
        case LOADED_GRADING_CONSISTENCY:
            return action.payload;
        default:
            return payload;
    }
};
const grading_correctness = (payload = {}, action) => {
    switch (action.type) {
        case LOADED_GRADING_CORRECTNESS:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    grading_consistency,
    grading_correctness
}
