import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    ErrorMessageAction,
    authUtils,
    accessConstants,
    CreateRoleAction,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
        return (
            <NoAccess/>
        );
    }
    const validate = function () {
        let errors = 0;

        if (!id) {
            dispatch(ErrorMessageAction('ID field must not be empty'));
            errors++;
        }
        if (!name) {
            dispatch(ErrorMessageAction('Name field must not be empty'));
            errors++;
        }
        if (/[^a-z0-9_-]+/g.test(id)) {
            dispatch(ErrorMessageAction('ID field may have lower case letters, numbers and underscores only'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container roles-page section'>
            <h1>Create new Role</h1>
            <div className='section'>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (validate()) {
                        dispatch(CreateRoleAction({id, name})).then(() => navigate(-1)).catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                    }
                }} className='form text-center'>
                    <div>
                        <label htmlFor="id">ID</label>
                        <input
                            type='text'
                            name='id'
                            placeholder='ID'
                            onChange={event => setId(event.target.value)}
                            value={id}
                        />
                    </div>
                    <div>
                        <label htmlFor="name">Name</label>
                        <input
                            type='text'
                            name='name'
                            placeholder='Name'
                            onChange={event => setName(event.target.value)}
                            value={name}
                        />
                    </div>
                    <button type="submit" className="button">Create</button>
                </form>
            </div>
        </div>
    );
}
