import {LOADED_SCHOOLS_CURRICULA} from "../actions/school_actions";

const schools_curricula = (payload = [], action) => {
    switch (action.type) {
        case LOADED_SCHOOLS_CURRICULA:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    schools_curricula
}
