import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

function Row({item}) {
    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.challenge_code.code}</td>
            <td>{item.version}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <Link
                        to={'/questions?column~0=challenge_version_id&expression~0=eq&value~0=' + item.id}
                        className='button'
                    >Questions</Link>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired
    })
};

export default Row;
