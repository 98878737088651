import Validator from "./Validator";

export default class JsonEntitiesEntityReferenceValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'string',
      empty: false,
      mandatory: true
    };
  }
}
