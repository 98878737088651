import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {isBonus} from '../utils';

class CardContainer extends Component {
    renderBg() {
        if (isBonus(this.props.page)) {
            return 'card-container-bg-bonus';
        } else {
            return 'card-container-bg-grey';
        }
    }

    renderHero() {
        if (this.props.hero_image) {
            return (
                <div className="card-container-hero" style={{backgroundImage: `url(${this.props.hero_image})`}}>
                    <div className="card-container-slope" style={{backgroundColor: this.props.challenge.color}}/>
                </div>
            );
        }
    }

    render() {
        const extra_class = this.props.use_challenge_color ? ' use-challenge-color ' : ''
        return (
            <div
                className={`position-relative challenge-card-container card-container-bg ${extra_class} ` + this.renderBg()}
                style={{backgroundColor: this.props.use_challenge_color ? this.props.challenge.color : undefined}}
            >
                {this.renderHero()}

                <div className="container">
                    <div
                        className={"challenge-card-min-height align-items-center " + (this.props.use_challenge_color ? '' : 'challenge-card-background-color')}
                        style={{minHeight: this.props.min_height}}>
                        <div className="flex-grow-1">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CardContainer.propsTypes = {
    challenge: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    }).isRequired,
    page: PropTypes.object.isRequired,
    use_challenge_color: PropTypes.bool,
    hero_image: PropTypes.string,
    min_height: PropTypes.string,
}

export default CardContainer
