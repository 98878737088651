import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: ['string', 'object'],
            empty: false,
            mandatory: false,
            attributes: {
                src: {
                    type: 'string',
                    empty: false,
                    mandatory: true
                }
            }
        };
    }
}
