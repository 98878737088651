import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_SCHOOLS_CURRICULA = '[schools] Loaded Curricula';

export const LoadCurriculaAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('curriculums', {limit: 0}))
        .then(response => dispatch(LoadedCurriculaAction(response.items)));
};
export const LoadedCurriculaAction = payload => ({
    type: LOADED_SCHOOLS_CURRICULA,
    payload
});
