import React from 'react';
import {configureStore} from '@reduxjs/toolkit';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {crashReporterMiddleware, loggerMiddleware} from "mindsets-js-sdk/src/react/layout/middlewares";
import MindsetsJsSDK from "mindsets-js-sdk";

import reducers from "./reducers";
import routes from "./routes";

import "../css/style.scss";

pusher = new Pusher(process.env.PUBLIC_PUSHER_APP_KEY, {
    cluster: process.env.PUBLIC_PUSHER_APP_CLUSTER,
    channelAuthorization: {
        endpoint: `${process.env.PUBLIC_API_URL}/pusher/auth`,
        headers: {
            "X-Source": process.env.PUBLIC_APP_SOURCE,
            "Access-Control-Allow-Origin": "*",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: 'Bearer ' + MindsetsJsSDK().Auth.getAccessToken()
        }
    }
});

const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .prepend(
            crashReporterMiddleware,
            // loggerMiddleware
        ),
});
const router = createBrowserRouter(routes);
const root = createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>
    // </React.StrictMode>
);
module.hot ? module.hot.accept() : null;
