import {useSelector} from "react-redux";
import React, {useState} from "react";
import ChallengeDropdownItem from "mindsets-js-sdk";

export default function () {
    const contract = useSelector(state => state.contract);
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className='d-flex align-items-end flex-gap-5'>
                <h5>
                    <span>Challenges locked in the scope of the contract</span>
                    <span>{contract.challenge_codes && contract.challenge_codes.length ? '(' + contract.challenge_codes.length + ' selected)' : ''}</span>
                </h5>
                {contract.challenge_codes && contract.challenge_codes.length ? (
                    <button onClick={event => {
                        event.preventDefault();
                        setOpen(!open);
                    }}>{open ? 'Hide' : 'Open'}
                    </button>
                ) : null}
            </div>
            {!contract.challenge_codes || !contract.challenge_codes.length ? (
                <p>All the Challenges are available for teachers</p>
            ) : null}
            {open ? (
                <div className='d-flex flex-wrap mx-n1'>
                    {contract.challenge_codes && contract.challenge_codes.length ? contract.challenge_codes.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }).map(challenge_code => {
                        return (
                            <div key={challenge_code.id} className='bordered m-1 d-flex'>
                                <ChallengeDropdownItem item={{
                                    name: challenge_code.name,
                                    measure: challenge_code.measure
                                }}/>
                            </div>
                        );
                    }) : null}
                </div>
            ) : null}
        </>
    );
}
