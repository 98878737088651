import React from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {MoveAnnouncementDownAction, MoveAnnouncementUpAction, ReloadListAction, datesConstants} from "mindsets-js-sdk";

import Audience from "./Audience";
import Target from "./Target";
import Delete from "./Delete";
import Edit from "./Edit";

const Row = function ({item, first, last}) {
    const dispatch = useDispatch();

    return (
        <tr className='announcements-table-row'>
            <td>{item.id}</td>
            <td>{item.title ? item.title : 'None'}</td>
            <td>{item.body}</td>
            <td>{item.status}</td>
            <td className='no-wrap'>{new Date(item.starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='no-wrap'>{new Date(item.ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='announcements-order-cell'>
                <div className='d-flex flex-row justify-content-center align-items-center flex-gap-5'>
                    <div>{item.order}</div>
                    <div>
                        <div className='announcement-move'>
                            <i
                                className={'fas fa-angle-up ' + (first ? 'disabled' : 'clickable')}
                                onClick={() => first ? null : dispatch(MoveAnnouncementUpAction(item.id)).then(() => dispatch(ReloadListAction(true, 'announcements')))}
                            />
                        </div>
                        <div className='announcement-move'>
                            <i
                                className={'fas fa-angle-down ' + (last ? 'disabled' : 'clickable')}
                                onClick={() => last ? null : dispatch(MoveAnnouncementDownAction(item.id)).then(() => dispatch(ReloadListAction(true, 'announcements')))}
                            />
                        </div>
                    </div>
                </div>
            </td>
            <td>{item.dismissable ? 'True' : 'False'}</td>
            <td>{item.dismissable_after ? item.dismissable_after : 'None'}</td>
            <td><Audience audience={item.audience}/></td>
            <td><Target target={item.target}/></td>
            <td>
                <Edit item_id={item.id}/>
                <Delete item_id={item.id}/>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        order: PropTypes.number.isRequired,
        body: PropTypes.string.isRequired,
        title: PropTypes.string,
        status: PropTypes.string.isRequired,
        starts_at: PropTypes.number.isRequired,
        ends_at: PropTypes.number.isRequired,
        dismissable: PropTypes.bool.isRequired,
        dismissable_after: PropTypes.number,
        audience: PropTypes.shape({
            persona: PropTypes.arrayOf(PropTypes.string)
        }),
        target: PropTypes.shape({
            page: PropTypes.arrayOf(PropTypes.string)
        }),
        created_at: PropTypes.number.isRequired,
        updated_at: PropTypes.number.isRequired
    }).isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
};

export default Row;
