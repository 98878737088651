import React from 'react';
import {Checkbox} from "mindsets-js-sdk";

import BaseComponentProperty from "../index";

export default class Step extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        className='form-control'
                        value={this.props.component.data.description ? this.props.component.data.description : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.description = event.target.value;
                            this.props.onChange('data', data)
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="image_url">Image url</label>
                    <textarea
                        id="image_url"
                        className='form-control'
                        value={this.props.component.data.image_url ? this.props.component.data.image_url : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.image_url = event.target.value;
                            this.props.onChange('data', data)
                        }}
                    />
                </div>
            </div>
        );
    }

    renderConfigs() {
        return (
            <div>
                <div className='d-flex'>
                    <Checkbox
                        label='Show tick?'
                        toggle={() => {
                            let config = this.props.component.config ? this.props.component.config : {};
                            config.show_tick = !(this.props.component.config && this.props.component.config.show_tick === true);
                            this.props.onChange('config', config);
                        }}
                        checked={this.props.component.config && this.props.component.config.show_tick === true}
                    />
                    <Checkbox
                        label='Show next?'
                        toggle={() => {
                            let config = this.props.component.config ? this.props.component.config : {};
                            config.show_next = !(this.props.component.config && this.props.component.config.show_next === true);
                            this.props.onChange('config', config);
                        }}
                        checked={this.props.component.config && this.props.component.config.show_next === true}
                    />
                    <Checkbox
                        label='Is bonus?'
                        toggle={() => {
                            let config = this.props.component.config ? this.props.component.config : {};
                            config.is_bonus = !(this.props.component.config && this.props.component.config.is_bonus === true);
                            this.props.onChange('config', config);
                        }}
                        checked={this.props.component.config && this.props.component.config.is_bonus === true}
                    />
                </div>
                <div>
                    <label htmlFor="config">Width</label>
                    <input
                        type='text'
                        id="width"
                        className='form-control'
                        value={this.props.component.config && this.props.component.config.width ? this.props.component.config.width : ''}
                        onChange={event => {
                            let config = this.props.component.config;
                            config.width = event.target.value;
                            this.props.onChange('config', config);
                        }}
                    />
                </div>
            </div>
        );
    }
}
