import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
    LoadingAction,
    countriesConstants,
    DistrictDropdown,
    statesConstants,
    ErrorMessageAction,
    SetContractAction,
    CreateDistrictAction,
    StateField
} from "mindsets-js-sdk";

const DistrictSelector = function ({district_selector_id}) {
    const dispatch = useDispatch();
    const contract_state = useSelector(state => state.contract);
    let contract = JSON.parse(JSON.stringify(contract_state));
    const [district_state, setDistrict] = useState({});
    const district = JSON.parse(JSON.stringify(district_state));
    const renderDisabledMessage = function () {
        if (!contract.country) {
            return (
                <span className='text-danger'>Select a Country first to set a School District</span>
            );
        } else if (!countriesConstants.COUNTRIES_WITH_DISTRICTS.includes(contract.country)) {
            return (
                <span className='text-danger'>School Districts are not supported for this country</span>
            );
        }
    }
    let setAutocompleteValue = () => {
    }

    useEffect(() => {
        district.country = contract.country;
        setDistrict(district);
    }, [contract.country]);
    useEffect(() => {
        district.state = contract.state;
        setDistrict(district);
    }, [contract.state]);

    return (
        <>
            <label>School District</label>
            <div className='d-flex flex-box-0-10 align-items-center'>
                <DistrictDropdown
                    id={district_selector_id}
                    onSelect={district => {
                        if (district) {
                            contract.district_id = district.id;
                        } else {
                            contract.district_id = null;
                        }
                        dispatch(SetContractAction(contract));

                        return district ? district.name : '';
                    }}
                    country={contract.country}
                    renderAdd={setValue => {
                        setAutocompleteValue = setValue;

                        return (
                            <div
                                data-bs-toggle="modal"
                                data-bs-target='#create-district-modal'
                            >CREATE
                            </div>
                        );
                    }}
                    show_add_always={true}
                    input_class='form-control'
                    placeholder='Start typing name'
                    state={contract.state ? contract.state : null}
                    with_reset={true}
                    onReset={() => {
                        if (contract.district_id) {
                            delete contract.district_id;
                            dispatch(SetContractAction(contract));
                        }
                    }}
                    disabled={!contract.country || !countriesConstants.COUNTRIES_WITH_DISTRICTS.includes(contract.country)}
                    default_value={contract.district ? contract.district.name : null}
                    show_counts={true}
                    debounce={useCallback(_.debounce(a => a(), 600), [])}
                />
                {renderDisabledMessage()}
            </div>
            <div
                className="modal fade"
                id='create-district-modal'
                tabIndex="-1"
                role="dialog"
                aria-labelledby="createDistrictModal"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createDistrictModal">Create a new School District</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label htmlFor="name">Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Name"
                                    value={district.name ? district.name : ''}
                                    onChange={event => {
                                        district.name = event.target.value;
                                        setDistrict(district);
                                    }}/>
                            </div>
                            <div>
                                <label htmlFor="country">Country*</label>
                                <select
                                    className="form-control"
                                    id="country"
                                    value={district.country ? district.country : ''}
                                    onChange={() => {
                                    }}
                                    disabled={true}
                                >
                                    <option value='' disabled={true}>SELECT COUNTRY</option>
                                    {Object.keys(countriesConstants.COUNTRIES).map(country_code => {
                                        return (
                                            <option
                                                key={country_code}
                                                value={country_code}
                                            >{countriesConstants.COUNTRIES[country_code]}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <StateField
                                country={district.country}
                                value={district.state}
                                onChange={value => {
                                    district.state = value;
                                    setDistrict(district);
                                    contract.state = value;
                                    dispatch(SetContractAction(contract));
                                }}
                                disabled={!!contract.state}
                                label='State*'
                            />
                            <div>
                                <label htmlFor="name">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    placeholder="City"
                                    value={district.city ? district.city : ''}
                                    onChange={event => {
                                        district.city = event.target.value;
                                        setDistrict(district);
                                    }}/>
                            </div>
                            <div>
                                <label htmlFor="name">Notes</label>
                                <textarea
                                    className="form-control"
                                    id="notes"
                                    placeholder="Notes"
                                    value={district.notes ? district.notes : ''}
                                    onChange={event => {
                                        district.notes = event.target.value;
                                        setDistrict(district);
                                    }}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="button" onClick={event => {
                                event.preventDefault();
                                const validate = function () {
                                    let errors = 0;

                                    if (!district.name || district.name === '') {
                                        dispatch(ErrorMessageAction('Error! Please specify the name of the School District.'));
                                        errors++;
                                    }

                                    if (!district.country || district.country === '') {
                                        dispatch(ErrorMessageAction('Error! Please select country.'));
                                        errors++;
                                    }

                                    if (district.country && '' !== district.country && statesConstants.STATES.hasOwnProperty(district.country)) {
                                        if (!district.state || '' === district.state) {
                                            dispatch(ErrorMessageAction('Error! Please specify the state of the School District.'));
                                            errors++;
                                        }
                                    }

                                    if (!district.city || district.city === '') {
                                        dispatch(ErrorMessageAction('Error! Please specify the city of the School District.'));
                                        errors++;
                                    }

                                    return errors === 0;
                                }
                                if (validate()) {
                                    dispatch(CreateDistrictAction(district))
                                        .then(district_id => {
                                            setAutocompleteValue(district.name);
                                            district.id = district_id;
                                            contract.district_id = district_id;
                                            contract.district = district;
                                            dispatch(SetContractAction(contract));
                                            setDistrict({});
                                            bootstrap.Modal.getInstance(document.getElementById("create-district-modal")).hide();
                                        })
                                        .catch(error => {
                                            dispatch(LoadingAction(false));
                                            dispatch(ErrorMessageAction(error));
                                            console.error(error);
                                        });
                                }
                            }}>Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

DistrictSelector.propTypes = {
    district_selector_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default DistrictSelector;
