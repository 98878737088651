import MindsetsJsSDK, {SuccessMessageAction} from "mindsets-js-sdk";

export const LOADED_LESSONS_CURRICULA = '[lessons] Loaded curricula';
export const LOADED_LESSONS_PRACTICES = '[lessons] Loaded practices';
export const LOADED_SCHEDULES_GRADING_PROVIDERS = '[schedules] Loaded Grading Providers';

export const LoadCurriculaAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('curriculums', {limit: 0}))
        .then(response => dispatch(LoadedCurriculaAction(response.items)));
};
export const LoadedCurriculaAction = payload => ({
    type: LOADED_LESSONS_CURRICULA,
    payload
});

export const LoadPracticesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('practices', {order_by: 'name'}))
        .then(response => dispatch(LoadedPracticesAction(response.items)));
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_LESSONS_PRACTICES,
    payload
});

export const LoadGradingProvidersAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('grading_providers', {order_by: 'name'}))
        .then(response => dispatch(LoadedGradingProvidersAction(response.items)));
};
export const LoadedGradingProvidersAction = payload => ({
    type: LOADED_SCHEDULES_GRADING_PROVIDERS,
    payload
});

export const AssignFiltersAction = (data, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.post(`lessons/assign_filters`, data, options))
        .then(() => dispatch(SuccessMessageAction('Filter(s) successfully assigned to selected Classes')));
};

export const ChangeOwnerAction = (data, options) => dispatch => {
    return MindsetsJsSDK().API.post(`lessons/transfer`, data, options);
};

export const ScheduleLessonResponsesForGradingAction = (id, options) => dispatch => {
    return MindsetsJsSDK().API.post(`lessons/${id}/responses/schedule`, {}, options);
};
