export default class Validator {
    constructor() {
        this.validationRules = {};
    }

    getValidationRules() {
        return this.validationRules;
    }

    validate(value) {
        let messages = [];
        let isValid = true;

        if (this.validationRules.mandatory && (undefined === value || null === value)) {
            isValid = false;
            messages.push(`The Field is mandatory`);
        } else if (!this.validationRules.empty && (undefined === value || null === value || '' === value)) {
            isValid = false;
            messages.push(`The Field must not be empty`);
        } else {
            if (Array.isArray(this.validationRules.type)) {
                const results = this.validationRules.type.map(type => this.validateType(type, value));
                for (let i = 0; i <= results.length - 1; i++) {
                    if (results[i].isValid === true) {
                        return {isValid, messages};
                    }
                }

                return {isValid: false, messages: [].concat(results.map(result => result.messages))};
            } else {
                return this.validateType(this.validationRules.type, value);
            }
        }

        return {isValid, messages};
    }

    validateType(type, value) {
        switch (type) {
            case 'string':
                return this.validateString(value);
            case 'boolean':
                return this.validateBoolean(value);
            case 'number':
                return this.validateNumber(value);
            case 'array':
                return this.validateArray(value);
            case 'object':
                return this.validateObject(value);
            default:
                throw 'Unrecognized attribute type: "' + type + '"';
        }
    }

    validateString(value) {
        let messages = [];
        let isValid = true;
        if (typeof value !== 'string') {
            isValid = false;
            messages.push(`Must be of type "string"`);
        } else {
            if (this.validationRules.hasOwnProperty('in') && this.validationRules.in.indexOf(value) < 0) {
                isValid = false;
                messages.push(`Must be one of: ${this.validationRules.in.join(', ')}`);
            }
        }

        return {isValid, messages};
    }

    validateBoolean(value) {
        let messages = [];
        let isValid = true;
        if (typeof value !== 'boolean') {
            isValid = false;
            messages.push(`Must be of type "boolean"`);
        }

        return {isValid, messages};
    }

    validateNumber(value) {
        let messages = [];
        let isValid = true;
        if (typeof value !== 'number') {
            isValid = false;
            messages.push(`Must be of type "number"`);
        }

        return {isValid, messages};
    }

    validateArray(value) {
        let messages = [];
        let isValid = true;
        if (Array.isArray(value)) {
            if (!this.validationRules.empty && value.length <= 0) {
                isValid = false;
                messages.push(`Must not be empty`);
            }
        } else {
            isValid = false;
            messages.push(`Must be of type "array"`);
        }

        return {isValid, messages};
    }

    validateObject(value) {
        let messages = [];
        let isValid = true;
        if (value !== null && typeof value === 'object') {
            if (!this.validationRules.empty && Object.keys(value).length <= 0) {
                isValid = false;
                messages.push(`Must not be empty`);
            }
            if (this.validationRules.hasOwnProperty('attributes')) {
                Object.keys(this.validationRules.attributes).map(key => {
                    const attribute = this.validationRules.attributes[key];

                    if (attribute.mandatory && !value.hasOwnProperty(key)) {
                        isValid = false;
                        messages.push(`Must have "${key}" field`);
                    } else if (!attribute.empty && value.hasOwnProperty(key) && (undefined === value[key] && null === value[key] || '' === value[key])) {
                        isValid = false;
                        messages.push(`Field "${key}" must not be empty`);
                    }

                    if (undefined !== value[key] && null !== value[key]) {
                        switch (attribute.type) {
                            case 'string':
                                if (typeof value[key] !== 'string') {
                                    isValid = false;
                                    messages.push(`Field "${key}" must be of type "string"`);
                                }
                                break;
                            case 'boolean':
                                if (typeof value[key] !== 'boolean') {
                                    isValid = false;
                                    messages.push(`Field "${key}" must be of type "boolean"`);
                                }
                                break;
                            case 'number':
                                if (typeof value[key] !== 'number') {
                                    isValid = false;
                                    messages.push(`Field "${key}" must be of type "number"`);
                                }
                                break;
                            case 'array':
                                if (!Array.isArray(value[key])) {
                                    isValid = false;
                                    messages.push(`Field "${key}" must be of type "array"`);
                                }
                                break;
                            case 'object':
                                if (!(value[key] !== null && typeof value[key] === 'object')) {
                                    isValid = false;
                                    messages.push(`Field "${key}" must be of type "object"`);
                                }
                                break;
                        }
                    }
                });
                Object.keys(value).map(key => {
                    if (!this.validationRules.attributes.hasOwnProperty(key)) {
                        isValid = false;
                        messages.push(`Field "${key}" is not allowed here`);
                    }
                });
            }
        } else {
            isValid = false;
            messages.push(`Must be of type "object"`);
        }

        return {isValid, messages};
    }
}
