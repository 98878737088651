import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    ErrorMessageAction,
    LoadingAction,
    LoadStandardAction,
    SetStandardAction,
    UpdateStandardAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const standard = useSelector(state => state.standard);
    useEffect(() => {
        dispatch(LoadStandardAction(params.standard_id));

        return () => {
            dispatch(SetStandardAction({}));
            setUpdated(false);
        };
    }, []);
    if (!me || !standard) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CURRICULUMS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!standard.description || standard.description === '') {
            dispatch(ErrorMessageAction('Error! Please specify the description.'));
            errors++;
        }

        if (!standard.subject || standard.subject === '') {
            dispatch(ErrorMessageAction('Error! Please specify the subject.'));
            errors++;
        }

        if (!standard.domain || standard.domain === '') {
            dispatch(ErrorMessageAction('Error! Please specify the domain.'));
            errors++;
        }

        if (!standard.topic || standard.topic === '') {
            dispatch(ErrorMessageAction('Error! Please specify the topic.'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container section'>
            <h1>Update Standard {standard.code ? standard.code : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(UpdateStandardAction(standard.id, standard)).then(() => navigate(-1)).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }} autoComplete="off">
                <div className="row">
                    <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            placeholder="Description"
                            value={standard.description ? standard.description : ''}
                            onChange={event => {
                                let standard_new = JSON.parse(JSON.stringify(standard));
                                standard_new.description = event.target.value;
                                dispatch(SetStandardAction(standard_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="subject" className="col-sm-2 col-form-label">Subject</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="subject"
                            placeholder="Subject"
                            value={standard.subject ? standard.subject : ''}
                            onChange={event => {
                                let standard_new = JSON.parse(JSON.stringify(standard));
                                standard_new.subject = event.target.value;
                                dispatch(SetStandardAction(standard_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="domain" className="col-sm-2 col-form-label">Domain</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="domain"
                            placeholder="Domain"
                            value={standard.domain}
                            onChange={event => {
                                let standard_new = JSON.parse(JSON.stringify(standard));
                                standard_new.domain = event.target.value;
                                dispatch(SetStandardAction(standard_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="topic" className="col-sm-2 col-form-label">Topic</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="Topic"
                            placeholder="topic"
                            value={standard.topic}
                            onChange={event => {
                                let standard_new = JSON.parse(JSON.stringify(standard));
                                standard_new.topic = event.target.value;
                                dispatch(SetStandardAction(standard_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="grade" className="col-sm-2 col-form-label">Grade</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="grade"
                            placeholder="Grade"
                            value={standard.grade}
                            onChange={event => {
                                let standard_new = JSON.parse(JSON.stringify(standard));
                                standard_new.grade = event.target.value;
                                dispatch(SetStandardAction(standard_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
