import React from 'react';
import PropTypes from "prop-types";
import {countriesConstants} from "mindsets-js-sdk";

import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import DistrictSubscriptionPeriod from "./DistrictSubscriptionPeriod";
import SchoolsCount from "./SchoolsCount";

const Row = function ({item, row_number}) {
    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.state}</td>
            <td>{countriesConstants.COUNTRIES[item.country]}</td>
            <td className="text-center"><SchoolsCount district={item}/></td>
            <td className="text-center">{item.teachers_count}</td>
            <td className="text-center">{item.students_count}</td>
            <td><DistrictSubscriptionPeriod district={item}/></td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <EditButton id={item.id}/>
                    <DeleteButton id={item.id}/>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        state: PropTypes.string,
        country: PropTypes.string.isRequired,
        schools_count: PropTypes.number.isRequired,
        teachers_count: PropTypes.number.isRequired,
        students_count: PropTypes.number.isRequired,
        subscriptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            starts_at: PropTypes.number.isRequired,
            ends_at: PropTypes.number.isRequired
        }))
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
