import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    Checkbox,
    countriesConstants,
    ErrorMessageAction,
    SuccessMessageAction,
    LoadCurriculumAction,
    SetCurriculumAction,
    UpdateCurriculumAction,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const curriculum = useSelector(state => state.curriculum);
    useEffect(() => {
        dispatch(LoadCurriculumAction(params.curriculum_id));

        return () => dispatch(SetCurriculumAction({}));
    }, []);
    if (!me || !curriculum) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CURRICULUMS])) {
        return (
            <NoAccess/>
        );
    }
    const validate = function () {
        let errors = 0;

        if (!curriculum.name || curriculum.name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name.'));
            errors++;
        }

        if (!curriculum.code || curriculum.code === '') {
            dispatch(ErrorMessageAction('Error! Please specify the code.'));
            errors++;
        }

        if (!curriculum.country_code || curriculum.country_code === '') {
            dispatch(ErrorMessageAction('Error! Please specify the country code.'));
            errors++;
        }

        if (!curriculum.grade_term || curriculum.grade_term === '') {
            dispatch(ErrorMessageAction('Error! Please specify the grade term.'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container section'>
            <h1>Update Curriculum {curriculum.name}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(UpdateCurriculumAction(params.curriculum_id, curriculum)).then(() => {
                        dispatch(SuccessMessageAction('The Curriculum has been updated.'));
                        navigate(-1);
                    }).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }} autoComplete="off" id="curriculum-form">
                <div className="row">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={curriculum.name ? curriculum.name : ''}
                            onChange={event => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.name = event.target.value;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="code" className="col-sm-2 col-form-label">Code</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="code"
                            placeholder="Code"
                            value={curriculum.code ? curriculum.code : ''}
                            disabled={true}
                            onChange={event => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.code = event.target.value;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="country_code" className="col-sm-2 col-form-label">Country</label>
                    <div className="col-sm-3">
                        <select
                            className="form-control"
                            id="country_code"
                            value={curriculum.country_code ? curriculum.country_code : ''}
                            onChange={event => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.country_code = event.target.value;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}
                        >
                            <option value={''} disabled={true}>PLEASE SELECT</option>
                            {Object.keys(countriesConstants.COUNTRIES).map(country_code => (
                                <option key={country_code}
                                        value={country_code}>{countriesConstants.COUNTRIES[country_code]}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <Checkbox
                            checked={curriculum.is_enabled}
                            label='Is Enabled'
                            toggle={() => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.is_enabled = !curriculum_new.is_enabled;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}
                        />
                    </div>

                </div>

                <div className="row">
                    <label htmlFor="state_code" className="col-sm-2 col-form-label">State Code</label>
                    <div className="col-sm-3">
                        <input
                            type="text"
                            className="form-control"
                            id="state_code"
                            placeholder="State Code"
                            value={curriculum.state_code ? curriculum.state_code : ''}
                            onChange={event => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.state_code = event.target.value;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}/>
                    </div>
                    <div className="col-sm-2">
                        <Checkbox
                            checked={curriculum.is_code_shown}
                            label='Is Code Shown'
                            toggle={() => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.is_code_shown = !curriculum_new.is_code_shown;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="grade_term" className="col-sm-2 col-form-label">Grade Term</label>
                    <div className="col-sm-3">
                        <input
                            type="text"
                            className="form-control"
                            id="grade_term"
                            placeholder="Grade term"
                            value={curriculum.grade_term ? curriculum.grade_term : ''}
                            onChange={event => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.grade_term = event.target.value;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}/>
                    </div>
                    <div className="col-sm-2">
                        <Checkbox
                            checked={curriculum.is_default}
                            label='Is Default'
                            toggle={() => {
                                let curriculum_new = JSON.parse(JSON.stringify(curriculum));
                                curriculum_new.is_default = !curriculum_new.is_default;
                                dispatch(SetCurriculumAction(curriculum_new));
                            }}
                        />
                    </div>
                </div>

                <div className='d-flex flex-gap-5'>
                    <button type="submit" className="button">Submit</button>
                    <button className="button default" onClick={event => {
                        event.preventDefault();
                        navigate(-1);
                    }}>Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
