import React from 'react';
import {Checkbox} from "mindsets-js-sdk";

import BaseComponentProperty from "../index";

export default class Speech extends BaseComponentProperty {
    renderLeft() {
        if (!!this.props.component.data.left) {
            return (
                <div>
                    <div>
                        <label htmlFor="bubble_background_left">Bubble color</label>
                        <input
                            type='text'
                            id="bubble_background_left"
                            className='form-control'
                            value={this.props.component.data.left && this.props.component.data.left.bubble_background ? this.props.component.data.left.bubble_background : ''}
                            onChange={event => {
                                let data = this.props.component.data;
                                data.left.bubble_background = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="text_left">Html</label>
                        <textarea
                            id="text_left"
                            className='form-control'
                            value={this.props.component.data.left && this.props.component.data.left.text ? this.props.component.data.left.text : ''}
                            onChange={event => {
                                let data = this.props.component.data;
                                data.left.text = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    renderRight() {
        if (!!this.props.component.data.right) {
            return (
                <div>
                    <div>
                        <label htmlFor="bubble_background_right">Bubble color</label>
                        <input
                            type='text'
                            id="bubble_background_right"
                            className='form-control'
                            value={this.props.component.data.right && this.props.component.data.right.bubble_background ? this.props.component.data.right.bubble_background : ''}
                            onChange={event => {
                                let data = this.props.component.data;
                                data.right.bubble_background = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="html_right">Html</label>
                        <textarea
                            id="html_right"
                            className='form-control'
                            value={this.props.component.data.right && this.props.component.data.right.text ? this.props.component.data.right.text : ''}
                            onChange={event => {
                                let data = this.props.component.data;
                                data.right.text = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="src">Image</label>
                    <textarea
                        id="image"
                        className='form-control'
                        value={this.props.component.data.image && this.props.component.data.image.src ? this.props.component.data.image.src : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            if (!data.image) {
                                data.image = {};
                            }
                            data.image.src = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="direction">Direction</label>
                    <select
                        id="direction"
                        className='form-control'
                        value={this.props.component.config && this.props.component.config.direction ? this.props.component.config.direction : ''}
                        onChange={event => {
                            let config = this.props.component.config;
                            if (!config) {
                                config = {};
                            }
                            config.direction = event.target.value;
                            this.props.onChange('config', config);
                        }}
                    >
                        <option value=''>Select Direction</option>
                        <option value='horizontal'>Horizontal</option>
                        <option value='vertical'>Vertical</option>
                    </select>
                </div>
                <div>
                    <div className='bordered'>
                        <Checkbox
                            label='Left'
                            toggle={() => {
                                let data = this.props.component.data;
                                if (data.left) {
                                    delete data.left;
                                } else {
                                    data.left = {
                                        text: ''
                                    }
                                }
                                this.props.onChange('data', data);
                            }}
                            checked={!!this.props.component.data.left}
                        />
                        {this.renderLeft()}
                    </div>
                    <div className='bordered'>
                        <Checkbox
                            label='Right'
                            toggle={() => {
                                let data = this.props.component.data;
                                if (data.right) {
                                    delete data.right;
                                } else {
                                    data.right = {
                                        text: ''
                                    }
                                }
                                this.props.onChange('data', data);
                            }}
                            checked={!!this.props.component.data.right}
                        />
                        {this.renderRight()}
                    </div>
                </div>
            </div>
        );
    }
}
