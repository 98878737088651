import React from 'react';
import {useDispatch} from "react-redux";
import {
    ListPage,
    filterConstants,
    accessConstants,
    LoadSettingsAction,
    SyncSettingsAction,
    ReloadListAction
} from "mindsets-js-sdk";

import Table from "./components/Table";

export default function () {
    const dispatch = useDispatch();

    return (
        <>
            <h1>Settings</h1>
            <ListPage
                action={LoadSettingsAction}
                filter_columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'key',
                        name: 'Key',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'type',
                        name: 'Type',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'key',
                        name: 'Key'
                    },
                    {
                        key: 'type',
                        name: 'Type'
                    }
                ]}
                entity={'settings'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <button className='button' onClick={event => {
                            event.preventDefault();
                            dispatch(SyncSettingsAction()).dispatch(ReloadListAction(true, 'settings'));
                        }}>Sync
                        </button>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_MANAGE_SETTINGS]}
                show_paginator={false}
                default_order_by='key'
            >
                <Table/>
            </ListPage>
        </>
    );
}
