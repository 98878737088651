import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ErrorMessageAction, LoadingAction, ReloadListAction} from "mindsets-js-sdk";

import {InvalidateSessionAction} from "../../../actions/user_actions";

function InvalidateSession({user}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);

    if (user.is_authenticated && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <div>
                <button
                    className='button bg-warning mt-2 no-wrap'
                    onClick={event => {
                        event.preventDefault();
                        dispatch(InvalidateSessionAction(user.id))
                            .then(() => dispatch(ReloadListAction(true, 'users')))
                            .catch(error => {
                                dispatch(LoadingAction(false));
                                dispatch(ErrorMessageAction(error));
                                console.error(error);
                            });
                    }}
                >Invalidate session
                </button>
            </div>
        );
    }

    return null;
}

InvalidateSession.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        is_authenticated: PropTypes.bool.isRequired,
    }).isRequired
};

export default InvalidateSession;
