import MindsetsJsSDK, {setCurrentAction, setLimitAction, SuccessMessageAction} from "mindsets-js-sdk";

export const LOADED_STATS = '[Dashboard] Loaded Stats';
export const LOADED_DASHBOARDS = '[dashboards] Loaded dashboards';
export const LOADED_DASHBOARDS_PAGES = '[dashboards] Loaded pages';
export const LOADED_DASHBOARDS_TOTAL = '[dashboards] Loaded total';
export const SET_DASHBOARD = '[dashboard] Set Dashboard';

export const LoadDashboardStatsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/app', options))
        .then(response => dispatch(LoadedDashboardStatsAction(response)));
};
export const LoadedDashboardStatsAction = response => ({
    type: LOADED_STATS,
    payload: response
});

export const LoadDashboardsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('dashboards', options))
        .then(response => {
            dispatch(LoadedDashboardsAction(response.items));
            dispatch(setCurrentAction('dashboards', response.pages >= response.current ? response.current : 1));
            dispatch(setLimitAction('dashboards', response.limit));
            dispatch(LoadedDashboardsPagesAction(response.pages));
            dispatch(LoadedDashboardsTotalAction(response.total));

            return response.items;
        });
};
export const LoadedDashboardsAction = payload => ({
    type: LOADED_DASHBOARDS,
    payload
});
export const LoadedDashboardsPagesAction = payload => ({
    type: LOADED_DASHBOARDS_PAGES,
    payload
});
export const LoadedDashboardsTotalAction = payload => ({
    type: LOADED_DASHBOARDS_TOTAL,
    payload
});

export const DeleteDashboardAction = (dashboard_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.delete(`dashboards/${dashboard_id}`, options))
        .then(() => dispatch(SuccessMessageAction('Dashboard has been successfully deleted')));
};
export const LoadDashboardAction = (dashboard_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('dashboards/' + dashboard_id, options))
        .then(response => {
            dispatch(SetDashboardAction(response.dashboard));

            return response.dashboard;
        });
};
export const SetDashboardAction = payload => ({
    type: SET_DASHBOARD,
    payload
});
export const CreateDashboardAction = (data, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.post('dashboards', data, options))
        .then(response => {
            dispatch(SuccessMessageAction('A new Dashboard has been created.'));

            return response.dashboard_id;
        });
};
export const UpdateDashboardAction = (id, data, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.put(`dashboards/${id}`, data, options))
        .then(() => dispatch(SuccessMessageAction('Dashboard has been updated.')));
};
export const InvalidateDashboardCacheAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.post(`dashboards/${id}/invalidate`, null, options))
        .then(() => dispatch(SuccessMessageAction('Dashboard cache has been invalidated.')));
};
