import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ActivateChallengeAction, ReloadListAction} from "mindsets-js-sdk";


const ActivateButton = function ({item}) {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CHALLENGES]) && 'active' !== item.challenge_code.status) {
        return (
            <button
                className='button'
                onClick={event => {
                    event.preventDefault();
                    dispatch(ActivateChallengeAction(item.challenge_code.code)).then(() => dispatch(ReloadListAction(true, 'challenges')));
                }}
            >Activate</button>
        );
    }

    return null;
}

ActivateButton.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired
        })
    })
};

export default ActivateButton;
