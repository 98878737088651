import React from 'react'
import PropTypes from "prop-types";

import AbstractType from './AbstractType'

class TypeBoolean extends AbstractType {
    content() {
        return this.props.value.toString();
    }
}

TypeBoolean.propTypes = {
    value: PropTypes.bool.isRequired
};

export default TypeBoolean;
