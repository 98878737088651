import React, {Component} from 'react'
import PropTypes from "prop-types";

class AbstractType extends Component {
    renderErrorMessages() {
        if (this.props.validationResult && this.props.validationResult.messages && !this.props.validationResult.isValid) {
            return (
                <span key={'Error-Messages'} className="bg-warning">{this.props.validationResult.messages.join(', ')}</span>
            );
        }

        return '';
    }

    content() {
        throw 'Define content method';
    }

    render() {
        return [
            this.content(),
            this.renderErrorMessages()
        ];
    }
}

AbstractType.propTypes = {
    validationResult: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    }).isRequired
};

export default AbstractType;
