import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_IMPACT_PRACTICES = '[impact] Loaded Practices';
export const LOADED_IMPACT_CHALLENGE_VERSION = '[impact] Loaded Challenge version';
export const LOADED_IMPACT_STREAMS = '[impact] Loaded Streams';
export const LOADED_IMPACT_STUDY = '[impact] Loaded Impact Study';

export const LoadPracticesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('practices', {order_by: 'name'}))
        .then(response => dispatch(LoadedPracticesAction(response.items)));
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_IMPACT_PRACTICES,
    payload
});
export const LoadImpactDataAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/impact', options))
        .then(response => {
            dispatch(LoadedChallengeVersionAction(response.challenge_version));
            dispatch(LoadedStreamsAction(response.streams));
        });
};
export const LoadedChallengeVersionAction = payload => ({
    type: LOADED_IMPACT_CHALLENGE_VERSION,
    payload
});
export const LoadedStreamsAction = payload => ({
    type: LOADED_IMPACT_STREAMS,
    payload
});

export const LoadImpactStudyAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/impact/report/new', options))
        .then(response => {
            const data = {
                mps: response.mps,
                cassp: response.cassp
            }
            dispatch(LoadedImpactStudyAction(data));

            return data;
        });
};
export const LoadedImpactStudyAction = payload => ({
    type: LOADED_IMPACT_STUDY,
    payload
});
