import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {countriesConstants, statesConstants} from "mindsets-js-sdk";

import TeachersCount from "./TeachersCount";
import SchoolSubscriptionPeriod from "./SchoolSubscriptionPeriod";
import DistrictSubscriptionPeriod from "./DistrictSubscriptionPeriod";
import EditSchoolButton from "./EditSchoolButton";
import DeleteButton from "./DeleteButton";

const Row = function ({item, row_number, curriculum}) {
    let location = [];
    if (item.location) {
        location.push(item.location);
    }
    if (item.city) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push(item.city);
    }
    if (statesConstants.STATES.hasOwnProperty(item.country)) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push(statesConstants.STATES[item.country][item.state]);
    }
    if (item.district && item.district.name) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push(<Link
            key={item.district.id}
            to={'/districts/' + item.district.id}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit district"
        >{item.district.name}</Link>);
    }
    if (item.country && countriesConstants.COUNTRIES.hasOwnProperty(item.country)) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push(countriesConstants.COUNTRIES[item.country]);
    }
    if (item.zip) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push(item.zip);
    }
    if (item.phone) {
        if (location.length > 0) {
            location.push(', ');
        }
        location.push('ph. ' + item.phone);
    }

    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{location}</td>
            <td>{curriculum ? curriculum.name : 'Not selected'}</td>
            <td className="text-center"><TeachersCount item={item}/></td>
            <td className="text-center">{item.students_count}</td>
            <td className="text-center">{item.lessons_count}</td>
            <td><SchoolSubscriptionPeriod school={item}/></td>
            <td><DistrictSubscriptionPeriod school={item}/></td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <EditSchoolButton id={item.id}/>
                    <DeleteButton id={item.id}/>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        teachers_count: PropTypes.number.isRequired,
        students_count: PropTypes.number.isRequired,
        lessons_count: PropTypes.number.isRequired,
        country: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        zip: PropTypes.string,
        phone: PropTypes.string,
        curriculum_id: PropTypes.number
    }).isRequired,
    curriculum: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    row_number: PropTypes.number.isRequired,
};

export default Row;
