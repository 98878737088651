import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete, CustomDropdown} from "mindsets-js-sdk";

import {SetQuestionPracticeSkillsUpdatedAction} from "../../actions/question_actions";

function SkillSelector({question_practice_skill}) {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.practices);
    const selected_practice_id = useSelector(state => state.selected_practice_id);
    const practice = practices.find(practice => practice.id === selected_practice_id);
    const question_practice_skills_updated = useSelector(state => state.question_practice_skills_updated);
    let practice_skills = [];
    if (practice) {
        practice_skills = practice.practice_skills;
    }

    let name = 'Please select';
    if (question_practice_skill.practice_skill_id) {
        const practice_skill = practice_skills.find(practice_skill => practice_skill.id === question_practice_skill.practice_skill_id);
        if (practice_skill) {
            name = practice_skill.code + ' - ' + practice_skill.name;
        }
    }
    if (practice_skills.length >= 10) {
        return (
            <Autocomplete
                fetchItems={q => Promise.resolve().then(() => practice_skills.filter(practice_skill => {
                    return practice_skill.name.toLowerCase().includes(q.toLowerCase()) || practice_skill.code.toLowerCase().includes(q.toLowerCase()) || practice_skill.description.toLowerCase().includes(q.toLowerCase())
                }))}
                onSelect={practice_skill => {
                    const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                    if (practice_skill) {
                        question_practice_skills_updated_x[question_practice_skills_updated.findIndex(r => r.question_code === question_practice_skill.question_code && r.practice_id === selected_practice_id)].practice_skill_id = practice_skill.id;
                    } else {
                        question_practice_skills_updated_x[question_practice_skills_updated.findIndex(r => r.question_code === question_practice_skill.question_code && r.practice_id === selected_practice_id)].practice_skill_id = null;
                    }
                    dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));

                    return practice_skill ? practice_skill.code + ' - ' + practice_skill.name : '';
                }}
                renderItem={practice_skill => (
                    <div>
                        <div className="autocomplete-dropdown-menu-item-title">{practice_skill.code}</div>
                        <div className="autocomplete-dropdown-menu-item-second">{practice_skill.name}</div>
                        <div className="autocomplete-dropdown-menu-item-second">{practice_skill.description}</div>
                    </div>
                )}
                persist_value={true}
                default_value={name}
                placeholder='Start typing a category name'
                id='question_practice_skill-category'
                show_counts={true}
            />
        );
    } else {
        let items = {
            0: (
                <a href={'!#'} onClick={event => {
                    event.preventDefault();
                    const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                    question_practice_skills_updated_x[question_practice_skills_updated.findIndex(r => r.question_code === question_practice_skill.question_code && r.practice_id === selected_practice_id)].practice_skill_id = '';
                    dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                }}>
                    <div className="question_practice_skill-dropdown-item-title">Please select</div>
                </a>
            )
        };
        practice_skills.map(practice_skill => {
            items[practice_skill.id] = (
                <a href={'!#'} onClick={event => {
                    event.preventDefault();
                    const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                    question_practice_skills_updated_x[question_practice_skills_updated.findIndex(r => r.question_code === question_practice_skill.question_code && r.practice_id === selected_practice_id)].practice_skill_id = practice_skill.id;
                    dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                }}>
                    <div className="rubric-dropdown-item-title">{practice_skill.code} {practice_skill.name}</div>
                    <div className="rubric-dropdown-item-second">{practice_skill.description}</div>
                </a>
            );
        });

        return (
            <CustomDropdown
                trigger={(
                    <div className="bordered little">{name}</div>
                )}
                no_wrap={true}
                item_class='members-list-member-item'
                items={items}
            />
        );
    }
}

SkillSelector.propTypes = {
    question_practice_skill: PropTypes.shape({
        practice_skill_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
}

export default SkillSelector;
