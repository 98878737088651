import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MatchingItem from './MatchingItem';
import MatchingLine from './MatchingLine';

import './matching.scss';

const MATCH_COLORS = [
    undefined, '#19A8FF', '#74C043', '#D6DF28', '#F79768', '#FF69B4'
];


class Matching extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: this.initAnswer()
        }
    }

    initAnswer() {
        return !Array.isArray(this.props.correct_answer) ? this.props.correct_answer : this.defaultAnswer();
    }

    defaultAnswer() {
        return {
            text: '',
            answer_selections: []
        };
    }

    isValid() {
        return !_.isEmpty(this.state.answer.answer_selections);
    }

    getAnswerText = (answer_selections) => {
        if (_.isEmpty(answer_selections)) {
            return '';
        }

        const {items_left, items_right} = this.props.component_data.data;

        const answer_text_array = answer_selections.map((a_s) => {
            const [id_left, id_right] = a_s
            const item_left = _.find(items_left.items, {id: id_left})
            const item_right = _.find(items_right.items, {id: id_right})
            return `${item_left.text} --- ${item_right.text}`
        });

        return answer_text_array.join('\n');
    }

    getMatchColor = (group) => (id) => {
        const match_item = _.find(this.state.answer.answer_selections, (a_s) => {
            const [id_left, id_right] = a_s;
            if (group === 'left') {
                return id_left === id;
            } else if (group === 'right') {
                return id_right === id;
            } else {
                return false;
            }
        })
        const key = _.get(match_item, 0);
        if (!key) {
            return undefined;
        } else {
            return _.get(MATCH_COLORS, key);
        }
    }

    onClickItem = (group) => (id) => () => {
        const state_name = `selection_${group}`;
        if (this.state[state_name] === id) {
            this.setState({
                [state_name]: undefined
            });
        } else {
            this.setState({
                [state_name]: id
            }, () => setTimeout(this.linkItems, 300));
        }
    }

    linkItems = () => {
        const {selection_left, selection_right} = this.state;
        if (!selection_left || !selection_right) {
            return false;
        }

        // Clone existing answer
        const answer_clone = _.cloneDeep(this.state.answer);
        // Remove exisitng if overlap
        _.remove(answer_clone.answer_selections, ((a_s) => {
            const [id_left, id_right] = a_s
            return (id_left === selection_left) || (id_right === selection_right)
        }));
        // Add new selection pair
        answer_clone.answer_selections.push([selection_left, selection_right]);
        // Sort
        answer_clone.answer_selections = _.orderBy(answer_clone.answer_selections, (a_s) => a_s[0]);
        // Compute answer text
        answer_clone.text = this.getAnswerText(answer_clone.answer_selections);
        this.props.onCorrectAnswerChange(answer_clone);

        this.setState({
            answer: answer_clone
        }, () => {
            // Clear tmp selection pair
            this.setState({
                selection_left: undefined,
                selection_right: undefined
            });
        });
    }

    renderInstruction() {
        const header_name_l = _.get(this.props.component_data, 'data.items_left.header_name');
        const header_name_r = _.get(this.props.component_data, 'data.items_right.header_name');

        return (
            <div className="cc-matching-instruction">
                <div>
                    {`Click an item under ${header_name_l} and then click the matching item under ${header_name_r}.
                        To change a match, click the item and then its new matching item.`}
                </div>
                <div>
                    {`Click Clear Selections to clear all matches.`}
                </div>
            </div>
        );
    }

    renderLeft() {
        const items_left = this.props.component_data.data.items_left;
        const header_name = items_left.header_name;
        const all_matched_left = this.state.answer.answer_selections.map((a_s) => a_s[0]);
        const render_items_left = items_left.items.map((item_data) => {
            return (
                <MatchingItem
                    key={item_data.id}
                    item_data={item_data}
                    item_group="left"
                    is_active={this.state.selection_left === item_data.id}
                    is_matched={all_matched_left.includes(item_data.id)}
                    match_color={this.getMatchColor('left')(item_data.id)}
                    onClick={this.onClickItem('left')(item_data.id)}
                />
            )
        });

        return (
            <div className="cc-matching-group cc-matching-group-left d-flex flex-column align-items-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="d-flex flex-fill flex-column justify-content-evenly">
                    {render_items_left}
                </div>
            </div>
        );
    }

    renderRight() {
        const items_right = this.props.component_data.data.items_right;
        const header_name = items_right.header_name;
        const all_matched_right = this.state.answer.answer_selections.map((a_s) => a_s[1]);
        const render_items_right = items_right.items.map((item_data) => {
            return (
                <MatchingItem
                    key={item_data.id}
                    item_data={item_data}
                    item_group="right"
                    is_active={this.state.selection_right === item_data.id}
                    is_matched={all_matched_right.includes(item_data.id)}
                    match_color={this.getMatchColor('right')(item_data.id)}
                    onClick={this.onClickItem('right')(item_data.id)}
                />
            )
        });

        return (
            <div className="cc-matching-group cc-matching-group-right d-flex flex-column align-items-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="d-flex flex-fill flex-column justify-content-evenly">
                    {render_items_right}
                </div>
            </div>
        );
    }

    renderLines() {
        const render_lines = this.state.answer.answer_selections.map((pair) => {
            const [id_left, id_right] = pair;
            return (
                <MatchingLine
                    key={id_left}
                    start_id={`matching-left-${id_left}`}
                    end_id={`matching-right-${id_right}`}
                    match_color={this.getMatchColor('left')(id_left)}
                />
            );
        })

        return (
            <div className="cc-matching-lines">
                {render_lines}
            </div>
        );
    }

    renderClear() {
        const onClick = event => {
            event.preventDefault();
            this.setState({
                answer: this.defaultAnswer(),
                selection_left: undefined,
                selection_right: undefined
            }, () => this.props.onCorrectAnswerChange([]));
        }

        return (
            <div className="cc-matching-clear d-flex justify-content-center">
                <button className="btn btn-on-white" onClick={onClick}>
                    {`Clear selections`}
                </button>
            </div>
        );
    }

    render() {
        return (
            <div className="challenge-component-matching">
                {this.renderInstruction()}

                {this.renderClear()}

                <div className="d-flex justify-content-between">
                    {this.renderLeft()}

                    {this.renderRight()}
                </div>

                {this.renderLines()}
            </div>
        );
    }
}

Matching.defaultProps = {
    correct_answer: {
        text: '',
        answer_selections: []
    }
}

Matching.propTypes = {
    component_data: PropTypes.object.isRequired,
    correct_answer: PropTypes.oneOfType([
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            answer_selections: PropTypes.array.isRequired
        }),
        PropTypes.array
    ]).isRequired,
    onCorrectAnswerChange: PropTypes.func.isRequired
}

export default Matching
