import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const CorrectAnswer = function ({question, question_practice_skill}) {
    const polls = useSelector(state => state.polls);
    if (question_practice_skill.correct_answer) {
        if (question.answer_type === 'poll.inline' && Array.isArray(question_practice_skill.correct_answer) && question_practice_skill.correct_answer.length > 0) {
            const poll = polls.find(poll => poll.question_id === question.id);
            if (!!poll) {
                return (
                    <div>
                        {poll.poll_options.map(poll_option => {
                            const correct_answer_option_index = question_practice_skill.correct_answer.findIndex(o => o.poll_option_id === poll_option.id);
                            const correct_answer_option = question_practice_skill.correct_answer[correct_answer_option_index];

                            return (
                                <div key={poll_option.id} className='d-flex flex-gap-5 align-items-center'>
                                    <div dangerouslySetInnerHTML={{__html: poll_option.option}}/>
                                    <div>- {correct_answer_option.points} points</div>
                                </div>
                            );
                        })}
                    </div>
                );
            }

            return null;
        } else if ((question.answer_type === 'answer.choice_single' || question.answer_type === 'answer.choice_multiple') && Array.isArray(question_practice_skill.correct_answer) && question_practice_skill.correct_answer.length > 0) {
            return (
                <div>{question_practice_skill.correct_answer.map(i => 'Option ' + i).sort().join(', ')}</div>
            );
        } else if (question.answer_type === 'answer.slider' || question.answer_type === 'answer.range' || question.answer_type === 'answer.number_line') {
            if (typeof question_practice_skill.correct_answer === 'object' && question_practice_skill.correct_answer.hasOwnProperty('min') && question_practice_skill.correct_answer.min && question_practice_skill.correct_answer.hasOwnProperty('max') && question_practice_skill.correct_answer.max) {
                return (
                    <div>From {question_practice_skill.correct_answer.min} to {question_practice_skill.correct_answer.max}</div>
                );
            }
        } else if (question.answer_type === 'answer.dnd') {
            if (typeof question_practice_skill.correct_answer === 'object' && question_practice_skill.correct_answer.hasOwnProperty('text') && question_practice_skill.correct_answer.hasOwnProperty('text') && question_practice_skill.correct_answer.text) {
                return (
                    <div>{question_practice_skill.correct_answer.text}</div>
                );
            }
        } else if (['answer.selector', 'answer.matching', 'answer.fraction'].includes(question.answer_type) && !!question_practice_skill.correct_answer && !!question_practice_skill.correct_answer.text) {
            return (
                <div>{question_practice_skill.correct_answer.text}</div>
            );
        }
    }

    return (
        <div>Not set</div>
    );
}

CorrectAnswer.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        answer_type: PropTypes.string.isRequired
    }).isRequired,
    question_practice_skill: PropTypes.shape({
        correct_answer: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.array,
            PropTypes.object
        ])
    })
}

export default CorrectAnswer;
