import React from "react";
import PropTypes from "prop-types";

function VerifyButtons({response_point, updateScore}) {
    if (response_point.points !== null) {
        return (
            <div className='no-wrap'>
                <div
                    className={'button-border ' + (response_point.is_correct === true ? 'active' : 'inactive')}>
                    <button
                        className={'button inactive'}
                        onClick={() => updateScore(true)}
                        title='Correct'
                    >+
                    </button>
                </div>
                <div
                    className={'button-border ' + (response_point.is_correct === false ? 'active' : 'inactive')}>
                    <button
                        className={'button inactive'}
                        onClick={() => updateScore(false)}
                        title='Incorect'
                    >-
                    </button>
                </div>
            </div>
        );
    }

    return null;
}

VerifyButtons.propTypes = {
    response_point: PropTypes.shape({
        is_correct: PropTypes.bool,
        points: PropTypes.number
    }),
    updateScore: PropTypes.func.isRequired
}

export default VerifyButtons;
