import React from 'react';
import {Checkbox} from "mindsets-js-sdk";

import BaseAnswer from './BaseAnswer';

export default class ChoiceSingle extends BaseAnswer {
    renderOptionText(value, updateOption) {
        return (
            <input
                type='text'
                className='clear'
                placeholder='Put option here'
                value={value}
                onChange={event => updateOption(event.target.value)}/>
        );
    }

    renderOptions() {
        return _.map(this.props.component_data.data.options, (v, l) => {
            return (
                <div key={l} className='component-choice-option'>
                    <Checkbox
                        label={this.renderOptionText(v, option => {
                            let component_data = this.props.component_data;
                            component_data.data.options[l] = option;
                            this.props.updateComponent(component_data);
                        })}
                        toggle={() => {
                        }}
                        checked={false}
                    />
                </div>
            );
        });
    }

    render() {
        return (
            <div className="challenge-component-choice">
                <div className="d-flex justify-content-start">
                    <div className="d-flex flex-column flex-box-5-10">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        );
    }
}
