import React from 'react';
import PropTypes from "prop-types";

const Row = function ({item}) {
    return (
        <tr>
            <td>{item.card_no}</td>
            <td>{item.challenge_code}</td>
            <td>{item.challenge_version_id}</td>
            <td>{item.lesson_code}</td>
            <td>{item.lesson_id}</td>
            <td>{item.stream_entity_id}</td>
            <td>{item.stream_id}</td>
            <td>{item.user_id}</td>
            <td>{item.timestamp}</td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        card_no: PropTypes.number.isRequired,
        challenge_code: PropTypes.string.isRequired,
        challenge_version_id: PropTypes.number.isRequired,
        lesson_code: PropTypes.string.isRequired,
        lesson_id: PropTypes.number.isRequired,
        stream_entity_id: PropTypes.number.isRequired,
        stream_id: PropTypes.number.isRequired,
        user_id: PropTypes.number.isRequired,
        timestamp: PropTypes.number.isRequired
    }).isRequired
};

export default Row;
