import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadPracticesAction} from "../../actions/response_actions";
import Filters from "./components/Filters";
import {LoadAIGradingMatchingRatesAction} from "../../actions/statistics";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [challenge_code, setChallengeCode] = useState(null);
    const [practice_id, setPracticeId] = useState('');
    const [lesson_code, setLessonCode] = useState('');
    const [from, setFrom] = useState(null);
    const [till, setTill] = useState(null);
    const [data, setData] = useState([]);
    const action = function () {
        let options = {};
        if (challenge_code) {
            options.challenge_code_id = challenge_code.id
        }
        if (lesson_code) {
            options.lesson_codes = lesson_code
        }
        if (practice_id) {
            options.practice_id = practice_id
        }
        if (from) {
            const dateFrom = new Date(from * 1000);
            dateFrom.setUTCHours(0,0,0,0);
            options.from = dateFrom.toISOString();
        }
        if (till) {
            const dateTill = new Date(till * 1000);
            dateTill.setUTCHours(23,59,59,999);
            options.till = dateTill.toISOString();
        }
        dispatch(LoadingAction(true)).then(() => dispatch(LoadAIGradingMatchingRatesAction(options)).then(setData)).then(() => dispatch(LoadingAction(false)));
    }

    useEffect(() => {
        dispatch(LoadPracticesAction());
    }, []);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }

    let grand_total = 0;
    let grand_match = 0;
    Object.keys(data).map(challenge => {
        let total = 0;
        let match = 0
        data[challenge].map(question => {
            total += question.total;
            match += question.match;
        });
        grand_total += total;
        grand_match += match;
    });

    return (
        <>
            <div className='container'>
                <h1>AI-grading Matching Rate</h1>
                <p>Percentage of times AI-grading matches manual grading</p>
                <Filters
                    action={action}
                    practice_id={practice_id}
                    setPracticeId={setPracticeId}
                    setChallengeCode={setChallengeCode}
                    lesson_code={lesson_code}
                    setLessonCode={setLessonCode}
                    from={from}
                    till={till}
                    setFrom={setFrom}
                    setTill={setTill}

                />
            </div>
            <div className='section'>
                <h2>Total Matching Rate: {Math.round(grand_match / grand_total * 100)}%</h2>
            </div>
            <div className='section'>
                {Object.keys(data).map((challenge, index) => {
                    let total = 0;
                    let match = 0
                    data[challenge].map(question => {
                        total += question.total;
                        match += question.match;
                    });

                    return (
                        <div key={index} className='section'>
                            <h4>{challenge}</h4>
                            <h5>Matching rate: {Math.round(match / total * 100)}%</h5>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Question Code</th>
                                    <th>Question Number</th>
                                    <th>Question Type</th>
                                    <th>Question Title</th>
                                    <th># Match</th>
                                    <th># Total</th>
                                    <th>Matching rate</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data[challenge].map(question => {
                                    return (
                                        <tr key={question.code}>
                                            <td>{question.code}</td>
                                            <td>{question.number}</td>
                                            <td>{question.answer_type}</td>
                                            <td>{question.title}</td>
                                            <td>{question.match}</td>
                                            <td>{question.total}</td>
                                            <td>{Math.round(question.match / question.total * 100)}%</td>
                                            <td>
                                                <Link
                                                    to={`/grading_scores/${question.code}/${practice_id}`}
                                                    className='button'>Investigate</Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
