import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_RESPONSES_PRACTICES = '[responses] Loaded Practices';

export const LoadPracticesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('practices', Object.assign({}, {order_by: 'name'}, options)))
        .then(response => dispatch(LoadedPracticesAction(response.items)));
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_RESPONSES_PRACTICES,
    payload
});
