import React from 'react';

import QuestionCard from './QuestionCard';

class InstructionCard extends QuestionCard {
    getCardType() {
        return 'instruction';
    }
}

export default InstructionCard
