import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils, filterConstants} from "mindsets-js-sdk";

const SchoolsCount = function ({district}) {
    const me = useSelector(state => state.me);
    if (district.schools_count > 0 && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOLS])) {
        return (
            <Link
                to={`/schools?column~0=district_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${district.id}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Show all the schools in the district">{district.schools_count}</Link>
        );
    } else {
        return district.schools_count;
    }
}

SchoolsCount.propTypes = {
    district: PropTypes.shape({
        id: PropTypes.number.isRequired,
        schools_count: PropTypes.number.isRequired
    }).isRequired
}

export default SchoolsCount;
