import {SET_QUESTION_PRACTICE_SKILL_UPDATED} from "../actions/question_actions";

const question_practice_skills_updated = (payload = [], action) => {
    switch (action.type) {
        case SET_QUESTION_PRACTICE_SKILL_UPDATED:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    question_practice_skills_updated
}
