import React from 'react';
import PropTypes from 'prop-types';

function Item({item, component_data, onClickItem}) {
    const unit_prefix = _.get(component_data, 'data.unit_prefix', '');
    const unit_suffix = _.get(component_data, 'data.unit_suffix', '');
    const value_prefix = _.get(component_data, 'data.value_prefix', '');
    const value_suffix = _.get(component_data, 'data.value_suffix', '');
    const value_text = `${value_prefix}${unit_prefix}${item.value}${unit_suffix}${value_suffix}`;

    return (
        <div className="cc-selector-items-item" onClick={() => onClickItem(item.id, 1)}>
            <div className="center-align">
                <img
                    src={item.image_url}
                    alt={item.image_alt}
                />
            </div>
            <div className="center-align">
                {item.title}
            </div>
            <div className="center-align">
                {value_text}
            </div>
        </div>
    );
}

Item.propTypes = {
    component_data: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Item
