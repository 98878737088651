import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const contract = useSelector(state => state.contract);
    if (contract.district && contract.district.name) {
        return (
            <div>School District: <strong><u>{contract.district.name}</u></strong></div>
        );
    }

    return (
        <div>School District: N/A</div>
    );
};
