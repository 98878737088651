import React from 'react';
import {contentUtils} from "mindsets-js-sdk";

import BaseAnswer from '../BaseAnswer';
import ReadOnly from './ReadOnly';


export default class TextArea extends BaseAnswer {
    getPlaceholder() {
        const placeholder = _.get(this.props.component_data, 'data.placeholder')
        if (placeholder) {
            return contentUtils.processStringContent(placeholder, {
                convert_html: false,
                convert_math: false
            })
        } else {
            return "Write an answer here..."
        }
    }

    render() {
        return (
            <div className="challenge-component-textarea max-width-850">
                <ReadOnly placeholder={this.getPlaceholder()}/>
            </div>
        )
    }
}
