import React, {forwardRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";

export default forwardRef(function ({row_index, ...props}, ref) {
    const dispatch = useDispatch();
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);
    const mouse_over_row = useSelector(state => state.challenge_builder_mouse_over_row);
    const page_index = useSelector(state => state.challenge_builder_index);

    function renderDeleteButton(row_index) {
        if (row_index === selected_row_index && null === selected_component_index) {
            return (
                <i
                    className='clickable fas fa-trash text-danger'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        const pages_new = JSON.parse(JSON.stringify(pages));

                        pages_new[page_index].data.card_content.rows.splice(row_index, 1);
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                    }}
                />
            );
        }

        return null;
    }

    function renderDuplicateButton(row_index) {
        if (row_index === selected_row_index && null === selected_component_index) {
            return (
                <i
                    className='clickable fas fa-clone text-info'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        const pages_new = JSON.parse(JSON.stringify(pages));
                        pages_new[page_index].data.card_content.rows.splice(row_index, 0, _.cloneDeep(pages_new[page_index].data.card_content.rows[row_index]));
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                    }}
                />
            );
        }

        return null;
    }

    return (
        <div
            ref={ref}
            {...props}
            className={'clickable ' + (row_index === selected_row_index && null === selected_component_index || mouse_over_row === row_index ? 'selected' : '')}
        >Row #{row_index + 1} {renderDuplicateButton(row_index)} {renderDeleteButton(row_index)}</div>
    );
});
