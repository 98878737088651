import React from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, SyncStandardsAction, SoftUpdateAllChallengesAction} from "mindsets-js-sdk";

import Import from "./Import";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CHALLENGES])) {
        return (
            <div className='d-flex justify-content-end'>
                <Import/>
                <button
                    className='button'
                    onClick={event => {
                        event.preventDefault();
                        dispatch(SyncStandardsAction());
                    }}
                >Sync standards</button>
                <button
                    className='button'
                    onClick={event => {
                        event.preventDefault();
                        dispatch(SoftUpdateAllChallengesAction());
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Updates basic data. This will also synchronize standards."
                >Soft Update all
                </button>
                <Link to="/validator" className="button">Open Validator</Link>
                <Link to="/builder" className="button">Open Builder</Link>
            </div>
        );
    }

    return null;
}
