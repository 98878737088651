import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ggbUtils} from "mindsets-js-sdk";

class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'answer-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100);
    }

    componentDidMount() {
        document.getElementById('graphing-modal-' + this.props.question_id).addEventListener('shown.bs.modal', () => this.ggbInit());
    }

    ggbInit() {
        const ggb_config = {
            ...ggbUtils.graphingAnswerConfig(this.uniq_id),
            appletOnLoad: (graphing_instance) => {
                this.graphing_instance = graphing_instance;
            }
        };

        const base64 = _.get(this.props.correct_answer, 'base64');
        if (base64) {
            ggb_config.ggbBase64 = base64;
        }

        this.ggbApp = new GGBApplet(ggb_config, true);
        this.ggbApp.inject(this.uniq_id);
    }

    render() {
        return (
            <div id={`component-${this.uniq_id}`}>
                <div>
                    <button className="button red" onClick={event => {
                        event.preventDefault();
                        this.props.onCorrectAnswerChange({
                            latex: ggbUtils.getLatex(this.graphing_instance),
                            base64: this.graphing_instance.getBase64()
                        });
                        bootstrap.Modal.getInstance(document.getElementById('graphing-modal-' + this.props.question_id)).hide();
                    }}>Finish
                    </button>
                </div>
                <div id={this.uniq_id}></div>
            </div>
        )
    }
}

Graphing.propTypes = {
    correct_answer: PropTypes.oneOfType([
        PropTypes.shape({
            base64: PropTypes.string.isRequired
        }),
        PropTypes.array
    ]),
    question_id: PropTypes.number.isRequired,
    onCorrectAnswerChange: PropTypes.func.isRequired
}

export default Graphing
