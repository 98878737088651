import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    filterConstants,
    authUtils,
    LoadQuestionsAction,
    LoadedQuestionsAction,
    LoadGradingProvidersAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {LoadPracticesAction} from "../../actions/response_actions";
import Filters from "./components/Filters";
import Question from "./components/Question";
import {LoadedGradingCorrectnessAction, LoadGradingCorrectnessAction} from "../../actions/statistics";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const data = useSelector(state => state.grading_correctness);
    const [challenge_version, setChallengeVersion] = useState({id: null});
    const [question_code, setQuestionCode] = useState('');
    const [practice_id, setPracticeId] = useState(1);
    const [lesson_code, setLessonCode] = useState('');
    const [grading_provider_id, setGradingProviderId] = useState(1);
    const action = function () {
        if (!question_code) {
            dispatch(ErrorMessageAction('Please select a Question'));
            return;
        }
        if (!practice_id) {
            dispatch(ErrorMessageAction('Please select a Practice'));
            return;
        }
        let options = {question_code, practice_id};
        if (lesson_code) {
            options.lesson_codes = lesson_code;
        }
        if (grading_provider_id) {
            options.grading_provider_id = grading_provider_id;
        }
        dispatch(LoadGradingCorrectnessAction(options));
    }

    useEffect(() => {
        if (challenge_version.id) {
            setQuestionCode('');
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadQuestionsAction({
                order_by: 'number',
                order_direction: 'asc',
                'filters[challenge_version_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + challenge_version.id,
                'filters[correct_answer_set][0]': filterConstants.EXPRESSION_TRUE,
                'selectors[0]': 'practice_skills',
                'selectors[1]': 'poll_poll_options'
            }));
        }
    }, [challenge_version.id]);
    useEffect(() => {
        if (question_code) {
            dispatch(LoadedGradingCorrectnessAction({}));
        }
    }, [question_code]);
    useEffect(() => {
        dispatch(LoadPracticesAction());
        dispatch(LoadGradingProvidersAction());
    }, []);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }

    function getQuestionCorrectnessRate() {
        let total = data['valid'] + data['invalid'];

        return Math.round(((data['valid'] / total * 100) + Number.EPSILON) * 100) / 100;
    }

    return (
        <>
            <h1>Grading Correctness Review</h1>
            <Filters
                action={action}
                question_code={question_code}
                setQuestionCode={setQuestionCode}
                practice_id={practice_id}
                setPracticeId={setPracticeId}
                setChallengeVersion={setChallengeVersion}
                lesson_code={lesson_code}
                setLessonCode={setLessonCode}
                grading_provider_id={grading_provider_id}
                setGradingProviderId={setGradingProviderId}

            />
            <div className='section'>
                <Question
                    question_code={question_code}
                    practice_id={practice_id ? parseInt(practice_id) : null}/>
                <h4>Correctness rate per question: {getQuestionCorrectnessRate(data[question_code])}%</h4>
                <div>Graded correctly: {data['valid']} times</div>
                <div>Graded incorrectly: {data['invalid']} times</div>
            </div>
        </>
    );
}
