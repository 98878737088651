import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

import AnswerOption from './AnswerOption';

class AnswerLayout extends Component {
    getDroppableStyle(answer_group, part) {
        const color_light = _.get(answer_group, 'color_light')
        const color_dark = _.get(answer_group, 'color_dark')
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "10px",
            background: color_light,
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: color_dark,
            borderRadius: "4px",
            position: "relative",
            ..._.get(part, 'droppable_style')
        }
    }

    renderPlaceholder(part) {
        if (_.isEmpty(part.content)) {
            return (
                <div className="dnd-answer-placeholder d-flex align-items-center justify-content-center">
                    {contentUtils.processStringContent(part.placeholder, {convert_spelling: false})}
                </div>
            )
        } else {
            return null
        }
    }

    renderSelected(part, answer_group) {
        if (_.isEmpty(part.content)) {
            return null;
        }

        const extra_style = {}

        return (
            <AnswerOption
                key={part.content.dnd_id}
                answer_group={answer_group}
                option={part.content}
                option_index={0}
                extra_style={extra_style}
            />
        );
    }

    renderDnD(part) {
        const answer_group = _.find(this.props.answer_groups, {dnd_type: part.dnd_type});

        return (
            <div
                style={this.getDroppableStyle(answer_group, part)}
            >
                {this.renderPlaceholder(part)}

                {this.renderSelected(part, answer_group)}
            </div>
        );
    }

    renderLayout() {
        const render_layout = this.props.answer_layout.map((part, i) => {
            switch (part.type) {
                case 'hidden':
                    return null
                case 'static':
                    return (
                        <div key={i} className="d-flex align-items-center justify-content-center">
                            <HTMLContent html_str={contentUtils.processStringContent(part.content.html)}/>
                        </div>
                    )
                case 'dnd':
                    return (
                        <div key={i} className="">
                            {this.renderDnD(part)}
                        </div>
                    )
                default:
                    throw 'Unknown type'
            }
        })

        return (
            <div className="d-flex flex-box-10-10 flex-wrap">
                {render_layout}
            </div>
        )
    }

    render() {
        return (
            <div className="component-dnd-answer">
                {this.renderLayout()}
            </div>
        )
    }
}

AnswerLayout.propTypes = {
    answer_layout: PropTypes.array.isRequired,
    answer_groups: PropTypes.array.isRequired
}

export default AnswerLayout
