import React, {useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, DatePicker, ErrorMessageAction, CreateSubscriptionAction, LoadingAction} from "mindsets-js-sdk";

const CreateDistrictSubscriptionForm = function ({setShown}) {
    const dispatch = useDispatch();
    const district = useSelector(state => state.district);
    const [starts_at, setStartsAt] = useState(null);
    const [ends_at, setEndsAt] = useState(null);
    const [notes, setNotes] = useState('');
    const [is_trial, setIsTrial] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!starts_at || '' === starts_at) {
            dispatch(ErrorMessageAction('Error! Please specify the start date of the plan.'));
            errors++;
        }
        if (!ends_at || '' === ends_at) {
            dispatch(ErrorMessageAction('Error! Please specify the end date of the plan.'));
            errors++;
        }
        if (starts_at >= ends_at) {
            dispatch(ErrorMessageAction('Error! Start date must not be greater than end date'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <form onSubmit={event => {
            event.preventDefault();
            if (validate) {
                dispatch(CreateSubscriptionAction(district.id, {
                    starts_at,
                    ends_at,
                    notes,
                    is_trial
                })).then(() => {
                    setStartsAt(null);
                    setEndsAt(null);
                    setNotes('');
                    setIsTrial(false);
                    setShown(false);
                }).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error));
                    console.error(error);
                });
            }
        }} autoComplete="off" className='mb-3'>
            <div className="row">
                <div className="col-sm-6">
                    <label htmlFor="datepicker-field" className="form-label">Start Date</label>
                    <DatePicker
                        date={starts_at}
                        onChange={setStartsAt}
                    />
                </div>
                <div className="col-sm-6">
                    <label htmlFor="datepicker-field" className="form-label">End Date</label>
                    <DatePicker
                        date={ends_at}
                        onChange={setEndsAt}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <label className="form-label"/>
                    <Checkbox
                        checked={is_trial}
                        label='Is Pilot?'
                        toggle={() => setIsTrial(!is_trial)}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="plan-notes" className="">Notes/Comments</label>
                <textarea
                    className="form-control"
                    id="plan-notes"
                    placeholder="Notes"
                    value={notes}
                    onChange={event => setNotes(event.target.value)}/>
            </div>

            <div className='d-flex flex-gap-5'>
                <button type="submit" className="button">Submit</button>
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    setStartsAt(null);
                    setEndsAt(null);
                    setNotes('');
                    setIsTrial(false);
                    setShown(false);
                }}>Cancel
                </button>
            </div>
        </form>
    );
}

CreateDistrictSubscriptionForm.propTypes = {
    setShown: PropTypes.func.isRequired
}

export default CreateDistrictSubscriptionForm;
