import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_GRADING_CONSISTENCY = '[grading_consistency] Loaded grading consistency';
export const LOADED_GRADING_CORRECTNESS = '[grading_correctness] Loaded grading correctness';

export const LoadGradingConsistencyAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/grading_consistency', options))
        .then(response => {
            dispatch(LoadedGradingConsistencyAction(response.data));

            return response.data;
        });
};
export const LoadedGradingConsistencyAction = payload => ({
    type: LOADED_GRADING_CONSISTENCY,
    payload
});

export const LoadAIGradingMatchingRatesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/ai_matching_rates', options))
        .then(response => response.data);
};

export const LoadGradingCorrectnessAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/grading_correctness', options))
        .then(response => {
            dispatch(LoadedGradingCorrectnessAction(response.data));

            return response.data;
        });
};
export const LoadedGradingCorrectnessAction = payload => ({
    type: LOADED_GRADING_CORRECTNESS,
    payload
});
