import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_ANNOUNCEMENT_ROLES = '[announcement] Loaded roles';

export const LoadAnnouncementRolesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('roles', Object.assign({}, options, {limit: 0})))
        .then(response => dispatch(LoadedAnnouncementRolesAction(response.items)));
};
export const LoadedAnnouncementRolesAction = payload => ({
    type: LOADED_ANNOUNCEMENT_ROLES,
    payload
});
