import React from 'react';

import BaseComponentProperty from "../index";

export default class Expression extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderPrefill() {
        return (
            <div>
                <label htmlFor="prefill">Prefill</label>
                <input
                    type='text'
                    id="prefill"
                    className='form-control'
                    value={this.props.component.data.prefill ? this.props.component.data.prefill : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.prefill = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        );
    }

    renderOwnProperties() {
        return (
            <div className='d-flex flex-wrap flex-box-0-5'>
                <div>
                    <label htmlFor="button_sets">Button sets</label>
                    <select
                        id="button_sets"
                        className='form-control'
                        value={Array.isArray(this.props.component.data.button_sets) && this.props.component.data.button_sets.length > 0 ? this.props.component.data.button_sets[0] : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            if (!data.button_sets || !Array.isArray(data.button_sets)) {
                                data.button_sets = [];
                            }
                            if (data.button_sets.length < 1) {
                                data.button_sets[0] = ''
                            }
                            data.button_sets[0] = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    >
                        <option value=''>Select button set</option>
                        <option value='algebra'>Algebra</option>
                        <option value='binary'>Binary</option>
                        <option value='equal'>Equal</option>
                        <option value='exponent'>Exponent</option>
                        <option value='fraction'>Fraction</option>
                        <option value='not_equal'>Not equal</option>
                        <option value='rational'>Rational</option>
                        <option value='root'>Root</option>
                        <option value='square'>Square</option>
                        <option value='subscript'>Subscript</option>
                    </select>
                </div>
                {this.renderPrefill()}
            </div>
        );
    }
}
