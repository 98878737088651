import React from 'react';
import {useSelector} from "react-redux";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.announcements);

    return (
        <div className="table-responsive announcements-table">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Body</th>
                    <th scope="col">Status</th>
                    <th scope="col">Starts at</th>
                    <th scope="col">Ends at</th>
                    <th scope="col">Order</th>
                    <th scope="col">Dismissable?</th>
                    <th scope="col">Dismissable after (clicks)</th>
                    <th scope="col">Audience</th>
                    <th scope="col">Targets</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return <Row
                        key={item.id}
                        item={item}
                        first={index === 0}
                        last={index === items.length - 1}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
}
