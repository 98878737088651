import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    authUtils,
    accessConstants,
    ErrorMessageAction,
    settingsConstants,
    LoadSettingAction,
    SetSettingAction,
    UpdateSettingAction,
    LoadingAction,
    ReloadListAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";
import FormFields from "./components/FormFields";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        dispatch(LoadSettingAction(params.key));

        return () => dispatch(SetSettingAction(null));
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_SETTINGS])) {
        return (
            <NoAccess/>
        );
    }
    const setting = useSelector(state => state.setting);
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (undefined === setting.value || null === setting.value || setting.value === '') {
            dispatch(ErrorMessageAction('Error! Please set the value.'));
            errors++;
        }

        if (setting.type === settingsConstants.SETTING_INT && !Number.isInteger(Number(setting.value))) {
            dispatch(ErrorMessageAction('Error! The value must be an integer'));
            errors++;
        }

        if (setting.type === settingsConstants.SETTING_DECIMAL && typeof Number(setting.value) !== 'number') {
            dispatch(ErrorMessageAction('Error! The value must be numeric'));
            errors++;
        }

        if (setting.type === settingsConstants.SETTING_ARRAY && !Array.isArray(setting.value)) {
            dispatch(ErrorMessageAction('Error! The value must be an array'));
            errors++;
        }

        if (setting.type === settingsConstants.SETTING_OBJECT && !_.isPlainObject(setting.value)) {
            dispatch(ErrorMessageAction('Error! The value must be an object'));
            errors++;
        }

        if (setting.type === settingsConstants.SETTING_BOOL && true !== setting.value && false !== setting.value) {
            dispatch(ErrorMessageAction('Error! The value must be of type boolean'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container'>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    let value;
                    switch (setting.type) {
                        case settingsConstants.SETTING_INT:
                        case settingsConstants.SETTING_DECIMAL:
                        case settingsConstants.SETTING_STRING:
                            value = "" + setting.value;
                            break;
                        case settingsConstants.SETTING_ARRAY:
                        case settingsConstants.SETTING_OBJECT:
                            value = JSON.stringify(setting.value);
                            break;
                        case settingsConstants.SETTING_BOOL:
                            value = setting.value + "";
                            break;
                        default:
                            throw 'Invalid Setting Type ' + setting.type;
                    }
                    dispatch((UpdateSettingAction(setting.key, value)))
                        .then(() => navigate(-1))
                        .then(() => dispatch(ReloadListAction(true, 'settings')))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="setting-form">
                <h1>Update setting {!!setting && setting.name ? setting.name : ''}</h1>
                <FormFields updated={updated} setUpdated={setUpdated}/>
                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
};
