import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PollHeader extends Component {
    renderIcon() {
        return (
            <div className="poll-header-icon d-flex justify-content-center">
                <i className="fas fa-poll-h fa-3x"/>
            </div>
        );
    }

    renderTitle() {
        return (
            <div className="poll-header-title d-flex justify-content-center">
                <h4 className="header-new text-center">
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put title here'
                        value={this.props.component_data.data.title ? this.props.component_data.data.title : ''}
                        onChange={event => {
                            let component_data = this.props.component_data;
                            component_data.data.title = event.target.value;
                            this.props.updateComponent(component_data);
                        }}/>
                </h4>
            </div>
        );
    }

    renderDescription() {
        return (
            <div className="section">
                <div className="poll-header-description d-flex justify-content-center text-center">
                    <textarea
                        className='clear'
                        placeholder='Put description here'
                        value={this.props.component_data.data.description ? this.props.component_data.data.description : ''}
                        onChange={event => {
                            let component_data = this.props.component_data;
                            component_data.data.description = event.target.value;
                            this.props.updateComponent(component_data);
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="poll-header">
                {this.renderIcon()}
                {this.renderTitle()}
                {this.renderDescription()}
            </div>
        );
    }
}

PollHeader.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default PollHeader
