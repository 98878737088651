import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    accessConstants,
    filterConstants,
    ListPage,
    LoadChallengeCodeAction,
    LoadChallengeVersionsAction
} from "mindsets-js-sdk";

import Table from "./Table";
import {LoadPracticesAction} from "../../actions/challenge_actions";

export default function () {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(LoadChallengeCodeAction(params.challenge_code));
    }, []);
    const challenge = useSelector(state => state.challenge_code);
    const practices = useSelector(state => state.challenge_versions_practices);
    useEffect(() => {
        if (challenge && !practices.length) {
            dispatch(LoadPracticesAction(challenge.subject));
        }
    }, [challenge]);

    if (!challenge) {
        return null;
    }

    return (
        <>
            <h1>Challenge Versions</h1>
            <ListPage
                action={options => LoadChallengeVersionsAction(Object.assign({}, options, {
                    'filters[unversioned][0]': filterConstants.EXPRESSION_TRUE,
                    'filters[data_version][0]': filterConstants.EXPRESSION_EQUAL + '=1',
                }))}
                filter_columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: 'Active',
                                value: 'active'
                            },
                            {
                                label: 'Disabled',
                                value: 'disabled'
                            }
                        ]
                    },
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'unversioned',
                        name: 'Unversioned?',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'data_version',
                        name: 'Data version',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: '0',
                                value: 0
                            },
                            {
                                label: '1',
                                value: 1
                            }
                        ]
                    }
                ]}
                entity={'challenge_versions'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CHALLENGES]}
                show_quick_search={false}
                custom_export={<div/>}
                extra={{
                    'filters[code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code
                }}
                default_order_by='version'
                default_order_direction='desc'
            >
                <Table/>
            </ListPage>
        </>
    );
}
