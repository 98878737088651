import React from 'react';

import BaseComponentProperty from "../index";

export default class ClickToShow extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="html">Html</label>
                    <textarea
                        id="html"
                        className='form-control'
                        value={this.props.component.data.html ? this.props.component.data.html : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.html = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="button_label">Button label</label>
                    <textarea
                        id="button_label"
                        className='form-control'
                        value={this.props.component.data.button_label ? this.props.component.data.button_label : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.button_label = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }
}
