import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {HTMLContent, contentUtils, authUtils, accessConstants, ResponseContent} from "mindsets-js-sdk";

import ScoreInput from "./ScoreInput";
import NavigationButtons from "./NavigationButtons";

function ByStudent({mode, getResponsePoints}) {
    const [search_params] = useSearchParams();
    const me = useSelector(state => state.me);
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    const questions = useSelector(state => state.questions);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const practice_skills = useSelector(state => state.practice_skills);
    const response_points = useSelector(state => state.response_points);
    const responses = useSelector(state => state.responses);
    const [index, setIndex] = useState(search_params.has('si') ? parseInt(search_params.get('si')) : 0);
    const stream_ids = [];
    response_points.map(response_point => {
        if (!stream_ids.includes(response_point.stream_id)) {
            stream_ids.push(response_point.stream_id)
        }
    });
    if (!schedule) {
        return null;
    }

    if ((schedule.status !== 'finished' || authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MASTER_RUBRICS])) && mode === 'student') {
        const stream_id = stream_ids[index];
        if (!stream_id) {
            return null;
        }

        return (
            <div>
                <div className='container'>
                    <NavigationButtons
                        setIndex={setIndex}
                        index={index}
                        length={stream_ids.length}
                        index_key='si'/>
                </div>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div>Student #{index + 1}{search_params.has('show_stream_id') ? (' ID' + stream_id) : ''}</div>
                        <div className='d-flex justify-content-end flex-gap-5'>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-warning'>NR</div>
                                <div>No response from student</div>
                            </div>
                            <div className='d-flex'>
                                <div className='badge bg-grey'>Responses automatically graded by our system</div>
                            </div>
                            <div className='d-flex'>
                                <div className='badge bg-blue'>Responses automatically graded by AI</div>
                            </div>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-danger'>Score exceeds max points
                                </div>
                            </div>
                            <div className='d-flex flex-gap-10'>
                                <div className='badge bg-info'>NA</div>
                                <div>No response or non-gradable</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="table-responsive mb-4">
                            <table className='table table-bordered table-sm'>
                                <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Score</th>
                                </tr>
                                </thead>
                                <tbody>
                                {questions.map(question => {
                                    const response_point_index = response_points.findIndex(response_point => response_point.stream_id === stream_id && response_point.question_id === question.id);
                                    const response_point = response_points[response_point_index];
                                    const question_practice_skill = question_practice_skills.find(qps => qps.question_code === question.code);
                                    let practice_skill = undefined
                                    let dok = undefined
                                    if (question_practice_skill) {
                                        practice_skill = practice_skills.find(practice_skill => practice_skill.id === question_practice_skill.practice_skill_id);
                                        dok = question_practice_skill.dok;
                                    }

                                    let response = null;
                                    if (response_point && response_point.response) {
                                        response = response_point.response;
                                    } else {
                                        if (!question_practice_skill) {
                                            const stream_responses = JSON.parse(JSON.stringify(responses)).filter(response => response.stream_id === stream_id && response.question_id === question.id).reverse();
                                            if (stream_responses.length > 0) {
                                                response = stream_responses[0];
                                            }
                                        }
                                    }

                                    let color = '';
                                    if (response_point && response_point.use_auto === 'using_ai') {
                                        color = 'bg-blue';
                                    } else if (response_point && response_point.use_auto === 'using') {
                                        color = 'bg-grey';
                                    }

                                    const max_points = question_practice_skill && question_practice_skill.points ? question_practice_skill.points.length - 1 : null;
                                    if (response_point && response_point.points > max_points) {
                                        color = 'bg-danger';
                                    }

                                    return (
                                        <tr key={question.id}
                                            className={color}>
                                            <td>
                                                <HTMLContent
                                                    html_str={contentUtils.processStringContent((question.number ? 'Q' + question.number : '') + (question.is_bonus ? '(BONUS)' : '') + ' ' + question.title, {convert_spelling: false})}/>
                                                <div>Max
                                                    points: {question_practice_skill ? question_practice_skill.points.length - 1 : 'Not defined'}</div>
                                                <div>Practice
                                                    Category: {practice_skill ? (practice_skill.code + ' ' + practice_skill.description) : 'Not defined'}</div>
                                                <HTMLContent
                                                    html_str={contentUtils.processStringContent('Example solution: ' + (question_practice_skill ? question_practice_skill.example_solution : 'Not defined'), {convert_spelling: false})}/>
                                                <div>DOK: {dok ? dok : 'Not defined'}</div>
                                            </td>
                                            <td>
                                                <ResponseContent
                                                    question={question}
                                                    response={response && response.data ? response.data.answer: null}/>
                                            </td>
                                            <td>
                                                <ScoreInput
                                                    question_practice_skill={question_practice_skill}
                                                    getResponsePoints={getResponsePoints}
                                                    response_point_index={response_point_index}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

ByStudent.propTypes = {
    mode: PropTypes.string.isRequired,
    getResponsePoints: PropTypes.func.isRequired
};

export default ByStudent;
