import React, {createRef, useEffect} from "react";
import PropTypes from "prop-types";
import noUiSlider from "nouislider";

const Slider = function (
    {
        start,
        step,
        min,
        max,
        onChange,
        orientation = 'horizontal',
        tooltips = false,
        padding
    }
) {
    const slider = createRef();

    useEffect(() => {
        noUiSlider.create(slider.current, {
            start,
            step,
            orientation,
            connect: true,
            range: {
                min,
                max
            },
            tooltips,
            padding
        });
        slider.current.noUiSlider.on('update', val => onChange(parseInt(val[0])));
    }, []);

    return (
        <div ref={slider}/>
    );
}

Slider.propTypes = {
    start: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default Slider;
