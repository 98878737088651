import React, {Component} from 'react';
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import {timeUtils} from "mindsets-js-sdk";

class StudentSummary extends Component {
    prepareSeriesCompletionChart() {
        return [
            {
                name: 'Got Stuck',
                y: this.props.noStreamsStuck ? Math.round(this.props.noStreamsStuck / this.props.noStreams * 100) : 0
            },
            {
                name: 'Underway',
                y: this.props.noStreamsUnderway ? Math.round(this.props.noStreamsUnderway / this.props.noStreams * 100) : 0
            },
            {
                name: 'Completed',
                y: this.props.noStreamsCompleted ? Math.round(this.props.noStreamsCompleted / this.props.noStreams * 100) : 0
            },
            {
                name: 'Completed Bonus',
                y: this.props.noStreamsCompletedBonus ? Math.round(this.props.noStreamsCompletedBonus / this.props.noStreams * 100) : 0
            }
        ];
    }

    prepareSeriesCumulativeTimeSpentChart() {
        let times = this.props.streams.map(stream => {
            if (this.props.timeGroups.hasOwnProperty(stream.id)) {
                return this.props.timeGroups[stream.id].totalTimeSpent;
            } else {
                return 0;
            }
        });

        times.map((time, index) => {
            if (times[index - 1]) {
                times[index] = time + times[index - 1];
            }
        });

        return times;
    }

    renderCompletionChart() {
        if ('completion' === this.props.mode && this.props.noStreams > 0 && (this.props.noStreamsStuck || this.props.noStreamsUnderway || this.props.noStreamsCompleted || this.props.noStreamsCompletedBonus)) {
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie'
                        },
                        title: {
                            text: null
                        },
                        tooltip: {
                            headerFormat: null,
                            pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
                        },
                        accessibility: {
                            enabled: false,
                            point: {
                                valueSuffix: '%'
                            }
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: false
                                },
                                showInLegend: true
                            }
                        },
                        series: [{
                            name: 'Challenges',
                            colorByPoint: true,
                            data: this.prepareSeriesCompletionChart()
                        }],
                        credits: {
                            enabled: false
                        }
                    }}
                />
            );
        }

        return null;
    }

    renderCumulativeTimeSpentChart() {
        if ('time' === this.props.mode && this.props.streams.length > 0) {
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        title: {
                            text: null
                        },
                        tooltip: {
                            pointFormatter: function () {
                                return this.series.name + ': ' + timeUtils.secondsToTimeAsString(this.y);
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        yAxis: {
                            title: {
                                text: 'Cumulative time spent using mindsets'
                            }
                        },
                        xAxis: {
                            title: {
                                text: null
                            },
                            categories: this.props.streams.map(stream => stream.challenge_name)
                        },
                        legend: {
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle'
                        },
                        plotOptions: {
                            series: {
                                label: {
                                    connectorAllowed: false
                                }
                            }
                        },
                        series: [{
                            name: 'Time spent',
                            data: this.prepareSeriesCumulativeTimeSpentChart(),
                            showInLegend: false
                        }],
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        },
                        credits: {
                            enabled: false
                        },
                        accessibility: {
                            enabled: false
                        }
                    }}
                />
            );
        }

        return null;
    }

    renderSchoolChart() {
        if (this.props.noStreams > 0) {
            const that = this;
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            type: 'area'
                        },
                        title: {
                            text: null
                        },
                        xAxis: {
                            title: {
                                text: 'time' === this.props.mode ? 'Total time spent' : 'Avg completion'
                            },
                            allowDecimals: false,
                            labels: {
                                formatter: function () {
                                    return 'time' === that.props.mode ? timeUtils.secondsToTimeAsString(this.value * that.props.getMultiplier()) : (this.value + '%');
                                }
                            },
                            accessibility: {
                                rangeDescription: '%'
                            },
                            plotLines: [{
                                color: '#FF0000',
                                width: 2,
                                value: 'time' === this.props.mode ? (this.props.totalTimeSpent / 60) : this.props.averageCompletion
                            }]
                        },
                        yAxis: {
                            title: {
                                text: null
                            }
                        },
                        tooltip: {
                            headerFormat: null,
                            pointFormatter: function () {
                                if ('time' === that.props.mode) {
                                    return this.y + ' students with total time spent ' + timeUtils.secondsToTimeAsString(this.x * that.props.getMultiplier());
                                }

                                return this.y + ' students with avg completion ' + this.x + '%';
                            }
                        },
                        plotOptions: {
                            area: {
                                pointStart: 0,
                                marker: {
                                    enabled: false,
                                    symbol: 'circle',
                                    radius: 2,
                                    states: {
                                        hover: {
                                            enabled: true
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            name: 'Number students',
                            data: 'time' === this.props.mode ? this.props.schoolStudentsTotalTimeSpent : this.props.schoolStudentsAvgCompletion
                        }],
                        credits: {
                            enabled: false
                        },
                        accessibility: {
                            enabled: false
                        }
                    }}
                />
            );
        }

        return null;
    }

    renderNoChallengesJoined() {
        if ('completion' === this.props.mode) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'># Challenges Joined</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{this.props.noStreams}</div>
                </div>
            );
        }

        return null;
    }

    renderNoChallengesUnderway() {
        if ('completion' === this.props.mode) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'># Challenges Underway</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{this.props.noStreamsUnderway}</div>
                </div>
            );
        }

        return null;
    }

    renderNoChallengesCompleted() {
        if ('completion' === this.props.mode) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'># Challenges Completed</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{this.props.noStreamsCompleted}</div>
                </div>
            );
        }

        return null;
    }

    renderNoChallengesCompletedBonuses() {
        if ('completion' === this.props.mode) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'># Challenge Bonuses Completed</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{this.props.noStreamsCompletedBonus}</div>
                </div>
            );
        }

        return null;
    }

    renderTimeSpentTotal() {
        if ('time' === this.props.mode && this.props.totalTimeSpent) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Total Time spent using Mindsets</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.totalTimeSpent)}</div>
                </div>
            );
        }

        return null;
    }

    renderTimeSpentChallenges() {
        if ('time' === this.props.mode && this.props.challengeTimeSpent) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Time spent in challenges</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.challengeTimeSpent)}</div>
                </div>
            );
        }

        return null;
    }

    renderTimeSpentBonuses() {
        if ('time' === this.props.mode && this.props.bonusTimeSpent) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Time spent in bonuses</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.bonusTimeSpent)}</div>
                </div>
            );
        }

        return null;
    }

    renderAvgTimeSpentTotal() {
        if ('time' === this.props.mode && this.props.totalTimeSpentAverage) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Avg Time spent using mindsets</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.totalTimeSpentAverage)}</div>
                </div>
            );
        }

        return null;
    }

    renderAvgTimeSpentChallenges() {
        if ('time' === this.props.mode && this.props.challengeTimeSpentAverage) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Avg time spent in challenges</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.challengeTimeSpentAverage)}</div>
                </div>
            );
        }

        return null;
    }

    renderAvgTimeSpentBonuses() {
        if ('time' === this.props.mode && this.props.bonusTimeSpentAverage) {
            return (
                <div className='d-flex bordered mx-2 align-items-center'>
                    <div className='mx-1'>Avg time spent in bonuses</div>
                    <div
                        className='mx-1 bg-success text-white px-1 rounded'>{timeUtils.secondsToTimeAsString(this.props.bonusTimeSpentAverage)}</div>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div>
                <div className='d-flex flex-wrap mx-n2'>
                    {this.renderNoChallengesJoined()}
                    {this.renderNoChallengesUnderway()}
                    {this.renderNoChallengesCompleted()}
                    {this.renderNoChallengesCompletedBonuses()}
                    {this.renderTimeSpentChallenges()}
                    {this.renderTimeSpentBonuses()}
                    {this.renderTimeSpentTotal()}
                    {this.renderAvgTimeSpentChallenges()}
                    {this.renderAvgTimeSpentBonuses()}
                    {this.renderAvgTimeSpentTotal()}
                </div>
                <div className='d-flex'>
                    <div>
                        {this.renderSchoolChart()}
                    </div>
                    <div>
                        {this.renderCompletionChart()}
                        {this.renderCumulativeTimeSpentChart()}
                    </div>
                </div>
            </div>
        );
    }
}

StudentSummary.propTypes = {
    streams: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        lesson_id: PropTypes.number.isRequired,
        challenge_version_id: PropTypes.number.isRequired,
        challenge_name: PropTypes.string.isRequired,
        teacher_name: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        no_cards: PropTypes.number.isRequired,
        no_bonus_cards: PropTypes.number.isRequired,
        created_at: PropTypes.number.isRequired,
        updated_at: PropTypes.number.isRequired
    })).isRequired,
    timeGroups: PropTypes.objectOf(PropTypes.shape({
        challengeTimeSpent: PropTypes.number,
        bonusTimeSpent: PropTypes.number,
        totalTimeSpent: PropTypes.number,
    })).isRequired,
    mode: PropTypes.string.isRequired,
    averageCompletion: PropTypes.number.isRequired,
    noStreams: PropTypes.number.isRequired,
    noStreamsStuck: PropTypes.number.isRequired,
    noStreamsUnderway: PropTypes.number.isRequired,
    noStreamsCompleted: PropTypes.number.isRequired,
    noStreamsCompletedBonus: PropTypes.number.isRequired,
    schoolStudentsAvgCompletion: PropTypes.array.isRequired,
    schoolStudentsTotalTimeSpent: PropTypes.array.isRequired,
    totalTimeSpent: PropTypes.number,
    totalTimeSpentAverage: PropTypes.number,
    challengeTimeSpent: PropTypes.number,
    challengeTimeSpentAverage: PropTypes.number,
    bonusTimeSpent: PropTypes.number,
    bonusTimeSpentAverage: PropTypes.number,
    getMultiplier: PropTypes.func.isRequired
};

export default StudentSummary;
