import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AnswerKeyButton extends Component {
    getIsChallengePreview() {
        return true;
    }

    getTooltipProps() {
        if (this.getIsChallengePreview()) {
            return {
                'data-for': 'answer-key-button-tooltip',
                'data-tip': 'You can choose to have the Answer Key on or off'
            }
        } else {
            return {}
        }
    }

    getButtonLabel() {
        if (this.props.is_bonus) {
            return `Download Answer Key - Bonus`
        } else {
            return `Download Answer Key`
        }
    }

    render() {
        return (
            <div className="answer-key-button">
                <a
                    className='button large white'
                    href={'!#'}
                    target="_blank"
                    onClick={event => event.preventDefault()}
                    {...this.getTooltipProps()}
                >
                    <i className="fas fa-download"/>
                    <span className='no-wrap'>
                        {this.getButtonLabel()}
                    </span>
                </a>
            </div>
        )
    }
}

AnswerKeyButton.propTypes = {
    is_bonus: PropTypes.bool.isRequired
}

export default AnswerKeyButton
