import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    authUtils,
    accessConstants,
    AddClassCodesToSelectionAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import {AssignFiltersAction} from "../../actions/lesson_actions";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const [filters, setFilters] = useState([{key: 'impact_study', val: ''}]);
    const [selected_lesson_codes_string, setSelectedLessonCodesString] = useState(selected_lesson_codes.join(', '));
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <div className='section'>
                <button onClick={event => {
                    event.preventDefault();
                    const filters_new = JSON.parse(JSON.stringify(filters));
                    filters_new.push({key: '', val: ''});
                    setFilters(filters_new);
                }}>Add filter
                </button>
                {filters.map((filter, index) => {
                    return (
                        <div key={index} className='d-flex'>
                            <input value={filter.key ? filter.key : ''} onChange={event => {
                                const filters_new = JSON.parse(JSON.stringify(filters));
                                filters_new[index].key = event.target.value;
                                setFilters(filters_new);
                            }}/>
                            <input value={filter.val ? filter.val : ''} onChange={event => {
                                const filters_new = JSON.parse(JSON.stringify(filters));
                                filters_new[index].val = event.target.value;
                                setFilters(filters_new);
                            }}/>
                            <button onClick={event => {
                                event.preventDefault();
                                const filters_new = JSON.parse(JSON.stringify(filters));
                                filters_new.splice(index, 1);
                                setFilters(filters_new);
                            }}>
                                <i className='fas fa-trash text-danger'/>
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className='section'>
                <div>
                    <label htmlFor='lesson-codes' className='d-block'>Class codes *</label>
                    <textarea
                        id='lesson-codes'
                        value={selected_lesson_codes_string}
                        onChange={event => {
                            const value = event.target.value;
                            setSelectedLessonCodesString(value);
                            const array = value.split(',').map(item => item.trim()).filter(item => item !== '');
                            dispatch(AddClassCodesToSelectionAction(array));
                        }}/>
                </div>
            </div>
            <div className='section'>
                <h3>Selected {selected_lesson_codes.length} classes</h3>
            </div>
            <div className='section'>
                <button onClick={event => {
                    const f = {};
                    filters.map(filter => f[filter.key] = filter.val);
                    event.preventDefault();
                    dispatch(AssignFiltersAction({
                        lesson_codes: selected_lesson_codes,
                        filters: f
                    }));
                    setFilters([{key: 'impact_study', val: ''}]);
                    setSelectedLessonCodesString('');
                    dispatch(AddClassCodesToSelectionAction([]));
                }}>Assign/Remove filters for all selected classes
                </button>
            </div>
        </>
    );
}
