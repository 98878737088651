import React from "react";
import {DownloadCSV} from "mindsets-js-sdk";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const DownloadButton = function ({practice_id}) {
    const challenge_version = useSelector(state => state.impact_challenge_version);
    const streams = useSelector(state => state.impact_streams);
    if (challenge_version && practice_id && streams.length > 0) {
        const getRows = function () {
            let firstRow = ['student_fullname', 'student_no'];
            challenge_version.questions.map(question => firstRow.push(question.number));
            firstRow.push('t_fullname');
            firstRow.push('t_email');
            firstRow.push('challenge_name');
            firstRow.push('total_points');
            firstRow.push('class_code');
            firstRow.push('s_id');
            firstRow.push('progress_cat');
            firstRow.push('school_name');
            firstRow.push('class_grade_levels');
            let rows = [
                firstRow
            ];
            streams.map(stream => {
                let row = [];
                row.push(stream.user.fname + ' ' + stream.user.lname);
                row.push(stream.id);
                challenge_version.questions.map(question => {
                    let response_points = [];
                    if (Array.isArray(stream.responses) && stream.responses.length > 0) {
                        stream.responses.filter(response => response.question_id === question.id).map(response => {
                            if (Array.isArray(response.response_points) && response.response_points.length > 0) {
                                const response_points_x = response.response_points.filter(response_point => parseInt(response_point.practice_id) === parseInt(practice_id));
                                response_points = response_points.concat(response_points_x);
                            }
                        });
                    }
                    if (response_points.length === 1) {
                        row.push(response_points[0].points);
                    } else {
                        row.push('NA')
                    }
                });
                row.push(stream.lesson.user.fname + ' ' + stream.lesson.user.lname);
                row.push(stream.lesson.user.email);
                row.push(challenge_version.name);
                row.push(challenge_version.total_points);
                row.push(stream.lesson.code);
                row.push(stream.user.id);
                row.push(stream.completion);
                row.push(stream.lesson.user.school && stream.lesson.user.school.name ? stream.lesson.user.school.name : 'NA');
                row.push(stream.lesson.grade_level ? stream.lesson.grade_level.join(',') : 'NA');

                rows.push(row);
            });

            return rows;
        }

        return (
            <button
                onClick={event => {
                    event.preventDefault();
                    new DownloadCSV().execute(`Impact data for ${challenge_version.name}`, getRows())
                }}
                className="button"
            >Download csv</button>
        );
    }

    return null;
}

DownloadButton.propTypes = {
    practice_id: PropTypes.number
}

export default DownloadButton;
