import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import Stream from './Stream';

const Streams = function ({mode, toggleMode}) {
    const streams = useSelector(state => state.longitudinal_stream_stats);
    const timeGroups = useSelector(state => state.longitudinal_time_spent_stats);
    const challenge_versions = useSelector(state => state.longitudinal_challenge_stats);
    const avgTimes = useSelector(state => state.longitudinal_lesson_avg_time_spent_stats);
    const renderProgressLegend = function () {
        if ('completion' === mode) {
            return (
                <div className='d-flex justify-content-end my-2 flex-gap-5'>
                    <div className='d-flex flex-row'>
                        <div className='bg-secondary mx-1'>&nbsp;</div>
                        <div>Page not seen</div>
                    </div>
                    <div className='d-flex flex-row'>
                        <div className='bg-primary mx-1'>&nbsp;</div>
                        <div>Completed page</div>
                    </div>
                    <div className='d-flex flex-row'>
                        <div className='bg-warning mx-1'>&nbsp;</div>
                        <div>The page the student is currently on</div>
                    </div>
                    <div className='d-flex flex-row'>
                        <div className='bg-danger mx-1'>&nbsp;</div>
                        <div>Completed bonus page</div>
                    </div>
                </div>
            );
        }

        return null;
    }

    if (streams.length <= 0 || challenge_versions.length <= 0) {
        return null;
    }

    return (
        <div className="table-responsive">
            <div className='d-flex justify-content-end my-2 flex-gap-5'>
                <button
                    className={'button ' + ('completion' === mode ? 'inactive' : '')}
                    disabled={'completion' === mode}
                    onClick={toggleMode}
                >Completion Mode
                </button>
                <button
                    className={'button ' + ('time' === mode ? 'inactive' : '')}
                    disabled={'time' === mode}
                    onClick={toggleMode}
                >Time Spent Mode
                </button>
            </div>
            {renderProgressLegend()}
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">Challenge</th>
                    <th scope="col">Teacher</th>
                    <th scope="col">Date</th>
                    {'completion' === mode ? <th scope="col">Completion rate</th> : null}
                    {'completion' === mode ? <th scope="col">Progress</th> : null}
                    {'time' === mode ? <th scope="col">Challenge Time Spent</th> : null}
                    {'time' === mode ? <th scope="col">Bonus Time Spent</th> : null}
                    {'time' === mode ? <th scope="col">Total Time Spent</th> : null}
                    {'time' === mode ? <th scope="col">Average Time Spent by class mates</th> : null}
                </tr>
                </thead>
                <tbody>
                {streams.map((stream, index) => {
                    return <Stream
                        key={index}
                        stream={stream}
                        mode={mode}
                        times={timeGroups.hasOwnProperty(stream.id) ? timeGroups[stream.id] : {}}
                        challenge_version={challenge_versions.find(challenge_version => challenge_version.id === stream.challenge_version_id)}
                        avgTimeSpent={avgTimes.hasOwnProperty(stream.lesson_id) ? avgTimes[stream.lesson_id] : null}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
}

Streams.propTypes = {
    mode: PropTypes.string.isRequired,
    toggleMode: PropTypes.func.isRequired
};

export default Streams;
