import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'array',
            empty: false,
            mandatory: true,
        };
    }
}
