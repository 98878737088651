import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {accessConstants, authUtils} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
        return (
            <Link to='/permissions' className="button">Manage Permissions</Link>
        );
    }

    return null;
}
