import React from 'react';
import PropTypes from "prop-types";
import {datesConstants} from "mindsets-js-sdk";

import DeleteSubscriptionButton from "./DeleteSubscriptionButton";

const DistrictSubscriptionsRow = function ({item, row_number}) {
    return (
        <tr>
            <td>{row_number}</td>
            <td>
                <div
                    className='no-wrap'>From: {new Date(item.starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                <div
                    className='no-wrap'>Until: {new Date(item.ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
            </td>
            <td>{item.notes}</td>
            <td>{item.is_trial ? <i className="fas fa-check"/> : null}</td>
            <td>
                <div className='no-wrap'>{item.user.fname + ' ' + item.user.lname}</div>
                <div className='no-wrap'>({item.user.email})</div>
                <div
                    className='no-wrap'>On {new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
            </td>
            <td>
                <DeleteSubscriptionButton subscription_id={item.id}/>
            </td>
        </tr>
    );
}

DistrictSubscriptionsRow.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        district_id: PropTypes.number.isRequired,
        starts_at: PropTypes.number.isRequired,
        ends_at: PropTypes.number.isRequired,
        created_at: PropTypes.number.isRequired,
        notes: PropTypes.string,
        is_trial: PropTypes.bool.isRequired,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default DistrictSubscriptionsRow;
