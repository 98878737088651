import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

class Text extends Component {
    getHTML() {
        return this.props.component_data.data.html
    }

    getContent() {
        return (
            <HTMLContent html_str={contentUtils.processStringContent(this.getHTML())}/>
        );
    }

    render() {
        return (
            <div className="challenge-component-text">
                <div className="horizontal-scroll-container">
                    {this.getContent()}
                </div>
            </div>
        )
    }
}

Text.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Text
