import React, {Component} from 'react';
import PropTypes from "prop-types";

import {parseStyleString} from "../utils";

class BaseComponentProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            container_style: '',
            config: this.props.component.config ? JSON.stringify(this.props.component.config) : '',
            is_config_valid: true
        }
    }

    getState() {
        return this.state;
    }

    hasContainerStyle() {
        return false;
    }

    hasConfigs() {
        return false;
    }

    hasQuestionNumber() {
        return false;
    }

    renderQuestionNumber() {
        if (this.hasQuestionNumber()) {
            return (
                <div>
                    <label htmlFor="question_number">Question number</label>
                    <input
                        type='text'
                        id="question_number"
                        className='form-control'
                        value={this.props.component.data.question_number ? this.props.component.data.question_number : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.question_number = event.target.value.toString();
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            );
        }
    }

    renderConfigs() {
        if (this.hasConfigs()) {
            return (
                <div>
                    <label htmlFor="config">Configs(JSON)</label>
                    <textarea
                        id="config"
                        className={'form-control ' + (this.state.is_config_valid ? '' : 'is-invalid')}
                        value={this.state.config}
                        onChange={event => {
                            const config = event.target.value;
                            let is_config_valid = true;
                            let result;
                            try {
                                result = JSON.parse(config);
                            } catch (error) {
                                is_config_valid = false
                            }
                            this.setState({config, is_config_valid}, () => {
                                if (is_config_valid) {
                                    this.props.onChange('config', result);
                                }
                            });
                        }}
                    />
                </div>
            );
        }

        return null;
    }

    renderContainerStyle() {
        if (this.hasContainerStyle()) {
            return (
                <div>
                    <label htmlFor="container_style">Container Style</label>
                    <textarea
                        id="container_style"
                        className='form-control'
                        value={this.state.container_style}
                        onChange={event => {
                            const container_style = event.target.value;
                            this.setState({container_style}, () => this.props.onChange('container_style', parseStyleString(container_style), false));
                        }}
                    />
                </div>
            );
        }

        return null;
    }

    renderOwnProperties() {
        if (!this.hasConfigs() && !this.renderContainerStyle() && !this.hasQuestionNumber()) {
            return <div>This component does not use any</div>;
        }

        return null;
    }

    render() {
        return (
            <div>
                {this.renderQuestionNumber()}
                {this.renderContainerStyle()}
                {this.renderConfigs()}
                {this.renderOwnProperties()}
            </div>
        );
    }
}

BaseComponentProperty.propTypes = {
    component: PropTypes.shape({
        type: PropTypes.string,
        data: PropTypes.shape({
            html: PropTypes.string
        }),
        config: PropTypes.object,
        container_style: PropTypes.object
    }),
    onChange: PropTypes.func.isRequired
};

export default BaseComponentProperty;
