import React, {Component} from 'react';
import PropTypes from 'prop-types';

import CardContainer from './CardContainer';
import CardContent from '../CardContent';


class BaseCard extends Component {
    getCardType() {
        return 'base';
    }

    useChallengeColor() {
        return false;
    }

    getHeroImage() {
        return undefined;
    }

    getMinHeight() {
        return undefined;
    }

    cardHasComponents() {
        return true;
    }

    renderTitle() {
        return undefined;
    }

    renderOtherContent() {
        return undefined;
    }

    renderCardContent() {
        if (this.cardHasComponents()) {
            return (
                <CardContent
                    card_content={this.props.page.data.card_content}
                    selectRow={this.props.selectRow}
                    selectComponent={this.props.selectComponent}
                    updateCardContent={card_content => {
                        let page = JSON.parse(JSON.stringify(this.props.page));
                        let data = page.data;
                        data.card_content = card_content;
                        page.data = data;
                        this.props.updatePage(page)
                    }}
                />
            );
        }
    }

    renderAfterContent() {
        return null
    }

    render() {
        return (
            <CardContainer
                challenge={this.props.challenge}
                page={this.props.page}
                use_challenge_color={this.useChallengeColor()}
                hero_image={this.getHeroImage()}
                min_height={this.getMinHeight()}
            >
                <div className={`challenge-${this.getCardType()}-card`}>
                    {this.renderTitle()}

                    <div className="challenge-card-other-content">
                        {this.renderOtherContent()}
                    </div>

                    {this.renderCardContent()}

                    <div className="challenge-card-after-content">
                        {this.renderAfterContent()}
                    </div>
                </div>
            </CardContainer>
        )
    }
}

BaseCard.propTypes = {
    challenge: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    }).isRequired,
    page: PropTypes.shape({
        type: PropTypes.string.isRequired
    }).isRequired,
    updatePage: PropTypes.func.isRequired,
    selectRow: PropTypes.func.isRequired,
    selectComponent: PropTypes.func.isRequired
}

export default BaseCard
