import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

export default function ({}){
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_CREATE_SCHOOLS]) && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <div className='d-flex justify-content-end'>
                <Link to="/schools/new" className="button">+ New School</Link>
            </div>
        );
    }

    return null;
}
