import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {ChallengeDropdown} from "mindsets-js-sdk";

const Filters = function (
    {
        action,
        question_code,
        setQuestionCode,
        practice_id,
        setPracticeId,
        setChallengeVersion,
        lesson_code,
        setLessonCode,
        grading_provider_id,
        setGradingProviderId
    }
) {
    const questions = useSelector(state => state.questions);
    const practices = useSelector(state => state.responses_practices);
    const grading_providers = useSelector(state => state.grading_providers);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    return (
        <div className='section'>
            <div className='d-flex flex-column mb-2'>
                <label>Challenge</label>
                <ChallengeDropdown
                    onSelect={challenge_version => {
                        setChallengeVersion(challenge_version);

                        return challenge_version ? challenge_version.name : '';
                    }}
                    unversioned={true}
                    id='correctness-challenge'
                    debounce={debounce}
                />
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Question*</label>
                <select
                    onChange={event => setQuestionCode(event.target.value)}
                    value={question_code ? question_code : ''}
                >
                    <option value='' disabled={true}>Select one</option>
                    {questions.map(question => {
                        return (
                            <option
                                key={question.code}
                                value={question.code}
                            >Q{question.number} {question.title}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Practice*</label>
                <select
                    value={practice_id}
                    onChange={event => setPracticeId(parseInt(event.target.value))}
                >
                    <option value='' disabled={true}>Select one</option>
                    {practices.map(practice => {
                        return (
                            <option key={practice.id} value={practice.id}>{practice.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Grading Providers</label>
                <select
                    onChange={event => setGradingProviderId(parseInt(event.target.value))}
                    value={grading_provider_id}
                >
                    <option value=''>All graders</option>
                    {grading_providers.map(grading_provider => {
                        return (
                            <option
                                key={grading_provider.id}
                                value={grading_provider.id}
                            >{grading_provider.name}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Class codes(comma separated)</label>
                <input value={lesson_code} onChange={event => setLessonCode(event.target.value)}/>
            </div>
            <button
                className='large'
                onClick={event => {
                    event.preventDefault();
                    action();
                }}
            >GET
            </button>
        </div>
    );
}

Filters.propTypes = {
    action: PropTypes.func.isRequired,
    question_code: PropTypes.string,
    setQuestionCode: PropTypes.func.isRequired,
    setChallengeVersion: PropTypes.func.isRequired,
    lesson_code: PropTypes.string,
    setLessonCode: PropTypes.func.isRequired,
    practice_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setPracticeId: PropTypes.func.isRequired
}

export default Filters;
