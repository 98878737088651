import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, DeleteStreamAction, ReloadListAction} from "mindsets-js-sdk";

const DeleteButton = function ({id}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_STREAMS])) {
        return (
            <button className='button' onClick={event => {
                event.preventDefault();
                if (confirm('You are about to remove student from the Class. All the data will be lost permanently. Are you sure?')) {
                    dispatch(DeleteStreamAction(id)).then(() => dispatch(ReloadListAction(true, 'streams')));
                }
            }}>
                <i className='fas fa-trash'/>
            </button>
        );
    }

    return null;
}

DeleteButton.propTypes = {
    id: PropTypes.number.isRequired
};

export default DeleteButton;
