import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import {useSearchParams} from "react-router-dom";

import './navigation_buttons.scss';

function NavigationButtons({index, setIndex, length, index_key}) {
    const [search_params, setSearchParams] = useSearchParams();
    const container_ref = useRef();

    useEffect(() => {
        const container_rect = container_ref.current.getBoundingClientRect();
        const element = document.getElementById('navigation-buttons-item-' + index);
        const item_rect = element.getBoundingClientRect();
        if (item_rect.right > container_rect.right) {
            container_ref.current.scrollBy({left: container_rect.width / 2 + (item_rect.right - container_rect.right)});
        } else if (item_rect.left < container_rect.left) {
            container_ref.current.scrollBy({left: -(container_rect.width / 2 + (container_rect.left - item_rect.left))});
        }
    }, [index]);

    return (
        <div className='d-flex justify-content-between my-2 navigation-buttons'>
            <button
                className='button'
                disabled={index <= 0}
                onClick={event => {
                    event.preventDefault();
                    setIndex(index - 1);
                    search_params.set(index_key, index - 1);
                    setSearchParams(search_params);
                }}
            >{'<<'} Prev
            </button>
            <div className='w-100'>
                <div className='navigation-buttons-items mx-1 d-flex' ref={container_ref}>
                    {[...Array(length).keys()].map(i => {
                        return (
                            <button
                                key={i}
                                id={'navigation-buttons-item-' + i}
                                className='button mx-1 d-block navigation-buttons-item'
                                disabled={index === i}
                                onClick={event => {
                                    event.preventDefault();
                                    setIndex(i);
                                    search_params.set(index_key, i);
                                    setSearchParams(search_params);
                                }}
                            >{i + 1}</button>
                        );
                    })}
                </div>
            </div>
            <button
                className='button'
                disabled={index >= length - 1}
                onClick={event => {
                    event.preventDefault();
                    setIndex(index + 1);
                    search_params.set(index_key, index + 1);
                    setSearchParams(search_params);
                }}
            >Next {'>>'}
            </button>
        </div>
    );
}

NavigationButtons.propTypes = {
    length: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    setIndex: PropTypes.func.isRequired
}

export default NavigationButtons;
