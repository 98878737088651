import React from 'react';
import PropTypes from "prop-types";

import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import InvalidateButton from "./InvalidateButton";

const Row = function ({item}) {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.title}</td>
            <td>{item.project.name}</td>
            <td>{item.type}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <EditButton id={item.id}/>
                    <DeleteButton id={item.id}/>
                    <InvalidateButton id={item.id}/>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        project: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    })
};

export default Row;
