import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {settingsConstants} from "mindsets-js-sdk";

const Row = function ({item}) {
    const parseValue = function () {
        switch (item.type) {
            case settingsConstants.SETTING_INT:
                return parseInt(item.value);
            case settingsConstants.SETTING_DECIMAL:
                return parseFloat(item.value);
            case settingsConstants.SETTING_STRING:
                return item.value;
            case settingsConstants.SETTING_ARRAY:
                return item.value.map((item, index) => {
                    return (
                        <div key={index}>{item}</div>
                    );
                });
            case settingsConstants.SETTING_OBJECT:
                return Object.keys(item.value).map(key => {
                    return (
                        <div key={key}>{key} : {item.value[key]}</div>
                    );
                });
            case settingsConstants.SETTING_BOOL:
                return item.value === true ? 'TRUE' : 'FALSE';
            default:
                throw 'Invalid Setting Type ' + item.type;
        }
    }

    return (
        <tr key={item.key} className='settings-table-row'>
            <td>{item.name}</td>
            <td>{item.type}</td>
            <td>{parseValue()}</td>
            <td>
                <Link to={'/settings/' + item.key} className="button">Edit</Link>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
            PropTypes.bool
        ]).isRequired,
    }).isRequired
};

export default Row;
