import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import MindsetsJsSDK, {SuccessMessageAction, ResponseContent} from "mindsets-js-sdk";

function Row({item, row_number, practices_present}) {
    const dispatch = useDispatch();

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

        return () => {
            tooltipList.map(tooltipTriggerEl => tooltipTriggerEl.dispose());
        }
    });

    function renderScores(practice) {
        const scores = [];
        if (Array.isArray(item.response_points) && item.response_points.length) {
            item.response_points.map(rp => {
                if (rp.practice_id === practice.id && rp.points !== null) {
                    scores.push(
                        <div key={rp.id + '-n-' + practice.id}>
                            <span className='badge text-bg-primary'>{rp.points}</span>
                        </div>
                    );
                }
            });
        }
        if (Array.isArray(item.response_auto_points) && item.response_auto_points.length) {
            item.response_auto_points.map(rap => {
                if (rap.practice_id === practice.id && rap.ai_points !== null) {
                    if (rap.reasoning) {
                        scores.push(
                            <div key={rap.id + '-a-' + practice.id}>
                                <span
                                    className='badge text-bg-info'
                                    data-bs-toggle="tooltip"
                                    data-bs-title={rap.reasoning}
                                >{rap.ai_points}</span>
                            </div>
                        );
                    } else {
                        scores.push(
                            <div key={rap.id + '-a-' + practice.id}>
                                <span className='badge text-bg-info'>{rap.ai_points}</span>
                            </div>
                        );
                    }
                }
                if (rap.practice_id === practice.id && rap.ra_points !== null) {
                    scores.push(
                        <div key={rap.id + '-r-' + practice.id}>
                            <span className='badge text-bg-secondary'>{rap.ra_points}</span>
                        </div>
                    );
                }
            });
        }

        return <td key={practice.id}>{scores}</td>;
    }

    const date = new Date(item.created_at * 1000);
    date.setUTCHours(0, 0, 0, 0);

    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td style={{whiteSpace: 'nowrap'}}>
                <div>{item.id}</div>
                <div>{date.toISOString().substring(0, 10)}</div>
            </td>
            <td style={{whiteSpace: 'nowrap'}}>
                <div>{item.stream.user.fname + ' ' + item.stream.user.lname}</div>
                <div>{item.stream.user.email}</div>
                <div>ID {item.stream.user.id}</div>
            </td>
            <td>
                <div className='badge text-bg-secondary'>{item.question.answer_type}</div>
                <div dangerouslySetInnerHTML={{__html: 'Q' + item.question.number + ' ' + item.question.title}}/>
                <div>
                    <span className='badge text-bg-secondary'>{item.lesson.challenge_version.challenge_code.code}</span>
                    <span>&nbsp;</span>
                    <span>{item.lesson.challenge_version.name}(v{item.lesson.challenge_version.version})</span>
                    <span>&nbsp;</span>
                    <span className='badge text-bg-secondary'>{item.lesson.code.toUpperCase()}</span>
                </div>
            </td>
            <td style={{wordBreak: 'break-word', color: !item.is_active ? 'lightgray' : ''}}>
                <ResponseContent
                    question={item.question}
                    response={item.data.answer}/>
            </td>
            {practices_present.map(renderScores)}
            <td>
                <button onClick={() => {
                    MindsetsJsSDK().API.post(`responses/${item.id}/schedule`)
                        .then(() => dispatch(SuccessMessageAction('Response has been scheduled for auto-grading')));
                }}>Grade
                </button>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        stream: PropTypes.shape({
            user: PropTypes.shape({
                id: PropTypes.number.isRequired,
                email: PropTypes.string.isRequired,
                fname: PropTypes.string.isRequired,
                lname: PropTypes.string,
            }).isRequired,
        }).isRequired,
        question: PropTypes.shape({
            number: PropTypes.string.isRequired,
            answer_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }).isRequired,
        response_points: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            practice_id: PropTypes.number.isRequired,
            points: PropTypes.number,
        })),
        response_auto_points: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            practice_id: PropTypes.number.isRequired,
            ai_points: PropTypes.number,
            ra_points: PropTypes.number,
            reasoning: PropTypes.string,
        })),
        created_at: PropTypes.number.isRequired,
        is_active: PropTypes.bool,
        lesson: PropTypes.shape({
            code: PropTypes.string.isRequired,
            challenge_version: PropTypes.shape({
                version: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                challenge_code: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    practices_present: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    row_number: PropTypes.number.isRequired,
};

export default Row;
