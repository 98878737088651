export default class UnrecognizedValidator {
    validate(value, hasAttributes) {
        if (hasAttributes) {
            return {isValid: false, messages: ['Unrecognized content: No validator defined for the attribute']};
        }

        return {isValid: true, messages: []};
    }

    getValidationRules() {
        return {};
    }
}
