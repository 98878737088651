import React, {useCallback} from 'react';
import {
    UserDropdown,
    ListPage,
    filterConstants,
    accessConstants
} from "mindsets-js-sdk";

import Table from './components/Table';
import {LoadTimespentAction} from "../../actions/timespent_actions";

export default function () {
    const debounce = useCallback(_.debounce(a => a(), 600), []);

    return (
        <>
            <h1>Time Spent</h1>
            <ListPage
                action={LoadTimespentAction}
                filter_columns={[
                    {
                        key: 'challenge_code',
                        name: 'Challenge Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'lesson_id',
                        name: 'Class ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'lesson_code',
                        name: 'Class code',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'user_id',
                        name: 'User',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setFilterValue(user.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    id='timespent-user-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'timestamp',
                        name: 'Time Stamp'
                    }
                ]}
                entity={'timespent'}
                permissions={[accessConstants.PERMISSION_CAN_REQUEST_STATISTICS]}
                quick_search_placeholder='Type Challenge Code'
                show_quick_search={false}
            >
                <Table/>
            </ListPage>
        </>
    );
}
