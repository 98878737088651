import React, {Component} from 'react';


export default class Divider extends Component {
    render() {
        return (
            <div className="challenge-component-divider">
                <div className="divider-container">
                    <div className="divider-border"/>
                </div>
            </div>
        )
    }
}
