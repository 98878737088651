import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";
import {InvalidateDashboardCacheAction} from "../../actions/dashboard_actions";


function InvalidateButton({id}) {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_PROJECTS])) {
        return (
            <button
                className='button warning'
                onClick={() => {
                    dispatch(InvalidateDashboardCacheAction(id));
                }}
            >
                Invalidate cache
            </button>
        );
    }

    return null;
}

InvalidateButton.propTypes = {
    id: PropTypes.number.isRequired
}

export default InvalidateButton;
