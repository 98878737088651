import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    UserDropdown,
    AssignUsersToLessonAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";

export default function () {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [assigned_users, setAssignedUsers] = useState([]);
    const [selected_user, setSelectedUser] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        return () => setAssignedUsers([]);
    }, []);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container roles-page section'>
            <h1>Assign co-teachers to the class</h1>
            <div className='section'>
                <form onSubmit={event => {
                    event.preventDefault();
                    dispatch(AssignUsersToLessonAction(params.lesson_id, {
                        ids: assigned_users.map(user => user.id)
                    }));
                    navigate(-1);
                }} autoComplete="off" id="assign-users-form">
                    <div className="row">
                        <label htmlFor="title" className="col-sm-2 col-form-label">Search by email, first OR last
                            name</label>
                        <div className="col-sm-3">
                            <UserDropdown
                                onSelect={user => {
                                    setSelectedUser(user);

                                    return user ? user.fname + ' ' + user.lname : '';
                                }}
                                persona='teacher'
                                show_counts={true}
                                debounce={debounce}
                            />
                        </div>
                        <div className="col-sm-2">
                            {selected_user ? <button className="button" onClick={event => {
                                event.preventDefault();
                                let assigned_users_new = JSON.parse(JSON.stringify(assigned_users));
                                if (!assigned_users_new.map(user => user.id).includes(selected_user.id)) {
                                    assigned_users_new.push(selected_user);
                                    setAssignedUsers(assigned_users_new);
                                    setSelectedUser(null);
                                } else {
                                    dispatch(WarningMessageAction('This user has already been added'));
                                }
                            }}>Add</button> : null}
                        </div>
                    </div>

                    {assigned_users.map(user => {
                        return (
                            <div key={user.id} className='my-1 d-flex flex-gap-5'>
                                <span>{user.email}</span>
                                <span>
                                    <i className="fas fa-trash text-danger clickable" onClick={event => {
                                        event.preventDefault();
                                        let assigned_users_new = JSON.parse(JSON.stringify(assigned_users));
                                        const index = assigned_users_new.map(user => user.id).indexOf(user.id);
                                        if (index >= 0) {
                                            assigned_users_new.splice(index, 1);
                                            setAssignedUsers(assigned_users_new);
                                        }
                                    }}/>
                                </span>
                            </div>
                        );
                    })}

                    <div className='d-flex flex-gap-5'>
                        <button type="submit" className="button">Submit</button>
                        <button className="button default" onClick={event => {
                            event.preventDefault();
                            navigate(-1);
                        }}>Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
