import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.responses);
    const current = useSelector(state => state.responses_current);
    const limit = useSelector(state => state.responses_limit);
    const practices = useSelector(state => state.practices);
    const practice_ids = [];
    items.map(item => {
        if (Array.isArray(item.response_points) && item.response_points.length) {
            item.response_points.map(rp => {
                if (!practice_ids.includes(rp.practice_id)) {
                    practice_ids.push(rp.practice_id);
                }
            });
        }
        if (Array.isArray(item.response_auto_points) && item.response_auto_points.length) {
            item.response_auto_points.map(rap => {
                if (!practice_ids.includes(rap.practice_id)) {
                    practice_ids.push(rap.practice_id);
                }
            });
        }
    });
    const practices_present = practices.filter(practice => practice_ids.includes(practice.id));

    return (
        <div>
            <div>
                <span className='badge text-bg-primary'>GradeBook score</span>
                <span>&nbsp;</span>
                <span className='badge text-bg-info'>AI score</span>
                <span>&nbsp;</span>
                <span className='badge text-bg-secondary'>Rubric score</span>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr className="text-center">
                        <th>No</th>
                        <th>Response ID</th>
                        <th>Student</th>
                        <th>Question</th>
                        <th>Response</th>
                        {practices_present.map(practice => {
                            return (
                                <th key={practice.id}>{practice.name}</th>
                            );
                        })}
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) => {
                        return (
                            <Row
                                key={item.id}
                                item={item}
                                row_number={getRowNumber(index, current, limit)}
                                practices_present={practices_present}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
