import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

function Items({component_data, onClickItem}) {
    const items = _.get(component_data, 'data.items', []);
    const render_items = items.map((item) => {
        return (
            <div key={item.id}>
                <Item
                    component_data={component_data}
                    item={item}
                    onClickItem={onClickItem}
                />
            </div>
        );
    });

    return (
        <div className="challenge-component-selector-items">
            <div className="cc-selector-items-top">
                <div>
                    <strong>
                        {_.get(component_data, 'data.title_1')}
                    </strong>
                </div>
                <div className="cc-selector-items-top-description">
                    {_.get(component_data, 'data.description_1')}
                </div>
            </div>
            <div className="challenge-component-selector-items-wrapper">
                {render_items}
            </div>
        </div>
    );
}

Items.propTypes = {
    component_data: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Items
