import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const EditSchoolButton = function ({id}) {
    const me = useSelector(state => state.me);

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <Link to={'/schools/' + id} className="button">Edit</Link>
        );
    }

    return null;
}

EditSchoolButton.propTypes = {
    id: PropTypes.number.isRequired
}

export default EditSchoolButton;
