import React from 'react';
import {useSelector} from "react-redux";
import {statesConstants} from "mindsets-js-sdk";

export default function () {
    const contract = useSelector(state => state.contract);
    if (contract.country && contract.state && statesConstants.STATES[contract.country]) {
        return (
            <div>State: <u>{statesConstants.STATES[contract.country][contract.state]}</u></div>
        );
    }

    return null;
};
