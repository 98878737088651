import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';

const TeacherSignups = function ({days}) {
    const dashboard_stats = useSelector(state => state.dashboard_stats);
    const teachers = dashboard_stats ? dashboard_stats.teachers : [];
    const compileData = function () {
        let data = [];
        if (teachers.length) {
            data.push({
                name: '#teachers',
                data: days.map(day => {
                    const teacher = teachers.find(teacher => teacher.created_at === day.toISOString().substring(0, 10));

                    return [
                        day.getTime(),
                        teacher ? teacher.count : 0
                    ];
                })
            });
        }

        return data;
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'spline'
                    },
                    title: {
                        text: 'Teacher signups'
                    },
                    xAxis: {
                        title: {
                            text: 'Days'
                        },
                        type: 'datetime'
                    },
                    yAxis: {
                        title: {
                            text: '# teachers'
                        }
                    },
                    plotOptions: {
                        area: {
                            pointStart: 0,
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    },
                    series: compileData(),
                    credits: {
                        enabled: false
                    },
                    accessibility: {
                        enabled: false
                    }
                }}
            />
        </div>
    );
}

TeacherSignups.propTypes = {
    days: PropTypes.array.isRequired
};

export default TeacherSignups;
