import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const EditButton = function ({id, status}) {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS]) && status !== 'approved') {
        return (
            <Link to={'/contracts/' + id + '/edit'} className="button">Edit</Link>
        );
    }

    return null;
};

EditButton.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
};

export default EditButton;
