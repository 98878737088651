import React from 'react';

import BaseAnswer from './BaseAnswer';
import NumberLineBase from '../../NumberLine';


export default class NumberLine extends BaseAnswer {
    render() {
        const {data} = this.props.component_data;
        return (
            <div className="challenge-component-numberline">
                <NumberLineBase
                    data={data}
                    value={data.initial_pos}
                />
            </div>
        );
    }
}
