import React, {Component, memo} from 'react';
import PropTypes from 'prop-types';
import {contentUtils} from "mindsets-js-sdk";

class Image extends Component {
    renderImage() {
        return (
            <div>
                <img src={this.props.component_data.data.src}/>
            </div>
        )
    }

    renderCaption() {
        if (this.props.component_data.data.caption) {
            return (
                <div>
                    {contentUtils.processStringContent(this.props.component_data.data.caption)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-image">
                <div className="d-flex justify-content-start">
                    <div className="d-flex flex-column align-items-center flex-box-2-5 text-center">
                        {this.renderImage()}

                        {this.renderCaption()}
                    </div>
                </div>
            </div>
        )
    }
}

Image.propTypes = {
    component_data: PropTypes.object.isRequired
}

function arePropsEqual(oldProps, newProps) {
    return (
        oldProps.component_data.data.src === newProps.component_data.data.src && oldProps.component_data.data.caption === newProps.component_data.data.caption
    );
}

export default memo(function (props) {
    return <Image {...props}/>
}, arePropsEqual);
