import React, {Component} from 'react'
import PropTypes from 'prop-types'


class Item extends Component {
    onClick() {
        return () => {
            this.props.onClickItem(this.props.item.id, 1)
        }
    }

    renderImage() {
        return (
            <div className="center-align">
                <img
                    src={this.props.item.image_url}
                    alt={this.props.item.image_alt}
                />
            </div>
        )
    }

    renderTitle() {
        return (
            <div className="center-align">
                {this.props.item.title}
            </div>
        )
    }

    renderValue() {
        const unit_prefix = _.get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = _.get(this.props.component_data, 'data.unit_suffix', '')
        const value_prefix = _.get(this.props.component_data, 'data.value_prefix', '')
        const value_suffix = _.get(this.props.component_data, 'data.value_suffix', '')
        const value = this.props.item.value
        const value_text = `${value_prefix}${unit_prefix}${value}${unit_suffix}${value_suffix}`
        return (
            <div className="center-align">
                {value_text}
            </div>
        )
    }

    render() {
        return (
            <div className="cc-selector-items-item" onClick={this.onClick()}>
                {this.renderImage()}
                {this.renderTitle()}
                {this.renderValue()}
            </div>
        )
    }
}

Item.propTypes = {
    component_data: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Item