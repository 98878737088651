import React from 'react';
import {
    ListPage,
    filterConstants,
    accessConstants,
} from "mindsets-js-sdk";

import Table from "./components/Table";
import TopButtons from "./components/TopButtons";
import UpdatesModal from "./components/UpdatesModal";
import {LoadChallengesAction} from "../../actions/challenge_actions";

export default function () {
    return (
        <>
            <h1>Challenges</h1>
            <UpdatesModal/>
            <ListPage
                action={LoadChallengesAction}
                filter_columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: 'Active',
                                value: 'active'
                            },
                            {
                                label: 'Disabled',
                                value: 'disabled'
                            }
                        ]
                    },
                    {
                        key: 'is_free',
                        name: 'Is Free',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'is_paid_only',
                        name: 'Is Paid Only',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'version',
                        name: 'Version'
                    }
                ]}
                entity={'challenges'}
                create_button={<TopButtons/>}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CHALLENGES]}
                default_order_by='name'
            >
                <Table/>
            </ListPage>
        </>
    );
}
