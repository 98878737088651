import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const StreamsMenuItem = function ({id}) {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_ALL_STREAMS])) {
        return (
            <Link to={'/lessons/' + id + '/streams'} className="button mx-2 my-1">Members</Link>
        );
    }

    return null;
}

StreamsMenuItem.propTypes = {
    id: PropTypes.number.isRequired
}

export default StreamsMenuItem;
