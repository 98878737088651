import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils} from 'mindsets-js-sdk';


class ReadOnly extends Component {
    getPlaceholder() {
        const placeholder = contentUtils.processStringContent(this.props.data.placeholder, {
            convert_html: false,
            convert_math: false
        })
        return (
            <div className="spreadsheet-cell-readonly-placeholder">
                {placeholder}
            </div>
        )
    }

    getContent() {
        if (!this.props.data.title) {
            return this.getPlaceholder()
        } else {
            return this.props.data.title
        }
    }

    render() {
        return (
            <div className="spreadsheet-cell-readonly">
                {this.getContent()}
            </div>
        )
    }
}

ReadOnly.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ReadOnly
