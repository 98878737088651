import React from "react";
import {useDispatch} from "react-redux";
import {CustomDropdown} from "mindsets-js-sdk";

import {
    SetChallengeBuilderChallengeCodeAction,
    SetChallengeBuilderChallengeColorAction,
    SetChallengeBuilderChallengeNameAction,
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderIndexAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../actions/challenge_actions";
import {CHALLENGE_PAGES} from "../index";

export const CHALLENGE_TEMPLATES = {
    default: {
        name: 'Default',
        pages: [
            'intro',
            'welcome',
            'instruction',
            'question',
            'complete',
            'unlock',
            'question_bonus',
            'final'
        ]
    },
    pd: {
        name: 'PD',
        pages: [
            'intro',
            'question',
            'question',
            'question',
            'question',
            'question',
            'unlock',
            'question_bonus',
            'final'
        ]
    },
    full: {
        name: 'Full',
        pages: [
            'intro',
            'welcome',
            'instruction',
            'poll',
            'winner',
            'question',
            'complete',
            'unlock',
            'question_bonus',
            'final'
        ]
    }
}

export default function () {
    const dispatch = useDispatch();

    function renderTemplate(key) {
        return (
            <a onClick={() => {
                if (confirm('This will permanently delete ALL the data you have for the challenge. Do you wish to proceed?')) {
                    dispatch(SetChallengeBuilderIndexAction(0));
                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                    dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                    dispatch(SetChallengeBuilderChallengeNameAction(''));
                    dispatch(SetChallengeBuilderChallengeCodeAction(''));
                    dispatch(SetChallengeBuilderChallengeColorAction('#ffc110'));
                    dispatch(SetChallengeBuilderChallengePagesAction(CHALLENGE_TEMPLATES[key].pages.map(page => CHALLENGE_PAGES[page])));
                }
            }}>{CHALLENGE_TEMPLATES[key].name}</a>
        );
    }

    function getTemplates() {
        const items = {}

        Object.keys(CHALLENGE_TEMPLATES).map(key => {
            items[key] = renderTemplate(key);
        });

        return items;
    }

    return (
        <CustomDropdown
            trigger={(
                <button>Templates</button>
            )}
            no_wrap={true}
            open_on_mouse_over={true}
            close_on_mouse_leave={true}
            items={getTemplates()}
        />
    );
}
