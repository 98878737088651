import React from 'react';
import PropTypes from 'prop-types';

import Selection from './Selection';

function Selections({component_data, answer, onClickItem}) {
    function exceededMaxValue() {
        const value_maximum = _.get(component_data, 'data.value_maximum');
        if (value_maximum !== undefined) {
            if (answer.total_value > value_maximum) {
                return true;
            }
        }

        return false;
    }

    function exceededMaxAmount() {
        const total_amount = _.sumBy(answer.answer_selections, 'amount');
        const amount_maximum = _.get(component_data, 'data.amount_maximum');
        if (amount_maximum !== undefined) {
            if (total_amount > amount_maximum) {
                return true;
            }
        }

        return false;
    }

    function renderSelections() {
        let total = 0;
        const items = _.get(component_data, 'data.items', []);
        const render_selections = answer.answer_selections.map((selection_data) => {
            const item = _.find(items, {id: selection_data.id})
            total += item.value * selection_data.amount
            return (
                <div key={selection_data.id}>
                    <Selection
                        component_data={component_data}
                        item={item}
                        selection_data={selection_data}
                        onClickItem={onClickItem}
                        answer={answer}
                    />
                </div>
            );
        });

        return (
            <div>
                {render_selections}
            </div>
        );
    }

    function renderBar() {
        const total = answer.total_value;
        const goal = _.get(component_data, 'data.value_goal', 0);
        let remaining = goal - total;
        if (remaining < 0) {
            remaining = 0;
        }

        return (
            <div className="cc-selector-selections-bar">
                <div
                    className={'cc-selector-selections-bar-selected ' + (exceededMaxValue() || exceededMaxAmount() ? 'cc-selector-selections-bar-error' : '')}
                    style={{flex: total}}>
                </div>
                <div className="cc-selector-selections-bar-remaining" style={{flex: remaining}}>
                </div>
            </div>
        );
    }

    function renderTotal() {
        const total = answer.total_value;
        const unit_prefix = _.get(component_data, 'data.unit_prefix', '');
        const unit_suffix = _.get(component_data, 'data.unit_suffix', '');
        const total_text = `${unit_prefix}${total}${unit_suffix}`;

        return (
            <div className="cc-selector-selections-total">
                <div className="cc-selector-selections-total-wrapper">
                    <div className="cc-selector-selections-total-title">
                        {`Total:`}
                    </div>
                    <div className="cc-selector-selections-total-subtotal">
                        {total_text}
                    </div>
                </div>
            </div>
        );
    }

    function renderError() {
        let error_message = '';
        if (exceededMaxValue()) {
            error_message = _.get(component_data, 'data.error_value_too_big');
        } else if (exceededMaxAmount()) {
            error_message = _.get(component_data, 'data.error_amount_too_big');
        }

        if (error_message) {
            return (
                <div className="cc-selector-selections-error">
                    {error_message}
                </div>
            );
        }
    }

    return (
        <div className="challenge-component-selector-selections">
            <div className="cc-selector-selections-top">
                <div>
                    <strong>
                        {_.get(component_data, 'data.title_2', '')}
                    </strong>
                </div>
                <div className="cc-selector-selections-top-description">
                    {_.get(component_data, 'data.description_2', '')}
                </div>
            </div>
            {renderSelections()}
            {renderBar()}
            {renderTotal()}
            {renderError()}
        </div>
    );
}

Selections.propTypes = {
    component_data: PropTypes.object.isRequired,
    answer: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired
}

export default Selections
