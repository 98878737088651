import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    authUtils,
    ReloadListAction,
    UserDropdown,
    ErrorMessageAction,
    AddClassCodesToSelectionAction
} from "mindsets-js-sdk";

import {ChangeOwnerAction} from "../../../actions/lesson_actions";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const class_codes = useSelector(state => state.selected_lesson_codes);
    const [user, setUser] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    function renderTransferOwnership() {
        return (
            <>
                <div
                    className="modal fade"
                    id={'transfer-lessons-ownership-modal'}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="transfer-lesson-ownership" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="transfer-lessons-ownership">Changing the owner of the
                                    Class</h5>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setUser(user);
                                        } else {
                                            setUser(null);
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    label='Select Teacher'
                                    with_school={true}
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-dismiss="modal">Close</button>
                                <button type="button" className="button" onClick={event => {
                                    event.preventDefault();
                                    if (!!user) {
                                        dispatch(ChangeOwnerAction({
                                            class_codes,
                                            user_id: user.id
                                        }))
                                            .then(() => bootstrap.Modal.getInstance(document.getElementById("transfer-lessons-ownership-modal")).hide())
                                            .then(() => dispatch(AddClassCodesToSelectionAction([])))
                                            .then(() => dispatch(ReloadListAction(true, 'streams')));
                                    } else {
                                        dispatch(ErrorMessageAction('Please select a teacher'));
                                    }
                                }}>Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className='button red'
                    data-toggle="modal"
                    data-target={'#transfer-lessons-ownership-modal'}
                >
                    Change Owner
                </button>
            </>
        );
    }

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS]) && class_codes.length) {
        return renderTransferOwnership();
    }

    return null;
}
