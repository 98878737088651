import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReadOnly from '../../AnswerComponent/Expression/ReadOnly';


class ExpressionSingle extends Component {
    render() {
        return (
            <div className="challenge-component-expression">
                <div className="challenge-component-expression-width">
                    <ReadOnly
                        is_readonly={true}
                        default_answer={this.props.answer}
                    />
                </div>
            </div>
        );
    }
}

ExpressionSingle.propTypes = {
    answer: PropTypes.string,
    button_sets: PropTypes.array.isRequired,
}

export default ExpressionSingle
