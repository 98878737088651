import React from 'react';

import BaseCard from './BaseCard';


export default class QuestionCard extends BaseCard {
    getCardType() {
        return 'question';
    }

    renderTitle() {
        return (
            <div className="challenge-card-title">
                <h3 className="header-new">
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put page title here'
                        value={this.props.page.data.title}
                        onChange={event => {
                            let page = JSON.parse(JSON.stringify(this.props.page));
                            page.data.title = event.target.value;
                            this.props.updatePage(page);
                        }}/>
                </h3>
            </div>
        );
    }
}
