import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {LoadingAction, ErrorMessageAction, UpdateScheduleAction, SuccessMessageAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    const response_points = useSelector(state => state.response_points);

    return (
        <button
            className="button red"
            onClick={() => {
                if (confirm('Are you sure you want to send the class back for grading?')) {
                    dispatch(UpdateScheduleAction(schedule.id, {status: 'started'}))
                        .then(() => dispatch(SuccessMessageAction('The class has been sent back for grading')))
                        .then(() => navigate('/lesson_schedules'))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }}
            disabled={!(!!schedule && schedule.status === 'finished' && response_points.filter(response_point => response_point.is_correct === false).length > 0)}
        >Send back
        </button>
    );
}
