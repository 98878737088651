import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, SetContractAction, ChallengeDropdownItem} from "mindsets-js-sdk";

import {LoadChallengesAction} from "../../../actions/contract_actions";

export default function () {
    const dispatch = useDispatch();
    const contract_state = useSelector(state => state.contract);
    const challenges = useSelector(state => state.contract_challenges);
    let contract = JSON.parse(JSON.stringify(contract_state));
    let contract_challenges = JSON.parse(JSON.stringify(challenges));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(LoadChallengesAction({limit: 0}));
    }, []);

    if (open) {
        return (
            <>
                <button onClick={event => {
                    event.preventDefault();
                    setOpen(false);
                }}>Hide
                </button>
                <div>
                    <Checkbox
                        checked={!!contract.challenge_codes && contract.challenge_codes.length > 0 && contract_challenges.length > 0 && contract.challenge_codes.length === contract_challenges.length}
                        toggle={() => {
                            if (!contract.challenge_codes) {
                                contract.challenge_codes = contract_challenges.map(challenge_version => challenge_version.challenge_code)
                            } else {
                                delete contract.challenge_codes;
                            }
                            dispatch(SetContractAction(contract));
                        }}
                        label='Select all Challenges'
                    />
                </div>
                <div className='d-flex flex-wrap mx-n1'>
                    {contract_challenges.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }).map(challenge_version => {
                        return (
                            <div key={challenge_version.id} className='bordered m-1'>
                                <Checkbox
                                    checked={!!contract.challenge_codes && contract.challenge_codes.some(challenge_code => challenge_code.id === challenge_version.challenge_code_id)}
                                    toggle={() => {
                                        if (contract.challenge_codes && contract.challenge_codes.some(challenge_code => challenge_code.id === challenge_version.challenge_code_id)) {
                                            const index = contract.challenge_codes.findIndex(challenge_code => challenge_code.id === challenge_version.challenge_code_id);
                                            contract.challenge_codes.splice(index, 1);
                                            if (contract.challenge_codes.length === 0) {
                                                delete contract.challenge_codes;
                                            }
                                        } else if (contract.challenge_codes) {
                                            contract.challenge_codes.push(challenge_version.challenge_code);
                                        } else {
                                            contract.challenge_codes = [challenge_version.challenge_code]
                                        }
                                        dispatch(SetContractAction(contract));
                                    }}
                                    label={<ChallengeDropdownItem item={{
                                        name: challenge_version.name,
                                        measure: challenge_version.challenge_code.measure,
                                        version: challenge_version.version,
                                    }}/>}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    } else {
        return (
            <div>
                <button onClick={event => {
                    event.preventDefault();
                    setOpen(true);
                }}>show
                </button>
            </div>
        );
    }
};
