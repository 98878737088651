import React, {useState} from 'react';
import PropTypes from "prop-types";

import Row from './Row';
import Expander from "./Expander";

const Table = function ({number_questions, number_stream_entities, items}) {
    const [sort, setSort] = useState('student');
    const [direction, setDirection] = useState('asc');
    const [reduced, setReduced] = useState(true);
    const sortItems = (a, b) => {
        if ('student' === sort) {
            let aName = a.user.fname.toUpperCase() + ' ' + (a.user.lname ? a.user.lname.toUpperCase() : '');
            let bName = b.user.fname.toUpperCase() + ' ' + (b.user.lname ? b.user.lname.toUpperCase() : '');
            if ('asc' === direction) {
                if (aName < bName) {
                    return -1;
                }
                if (aName > bName) {
                    return 1;
                }

                return 0;
            } else {
                if (aName > bName) {
                    return -1;
                }
                if (aName < bName) {
                    return 1;
                }

                return 0;
            }
        } else {
            if ('asc' === direction) {
                if (a.completed < b.completed) {
                    return -1;
                }
                if (a.completed > b.completed) {
                    return 1;
                }

                return 0;
            } else {
                if (a.completed > b.completed) {
                    return -1;
                }
                if (a.completed < b.completed) {
                    return 1;
                }

                return 0;
            }
        }
    }
    let new_items = JSON.parse(JSON.stringify(items));
    if (reduced && new_items.length > 5) {
        new_items.length = 5;
    }
    new_items = new_items.sort(sortItems);
    const renderSortArrow = function (new_sort) {
        if (new_sort === sort) {
            if ('asc' === direction) {
                return (
                    <i className="fas fa-angle-down"/>
                );
            } else {
                return (
                    <i className="fas fa-angle-up"/>
                );
            }
        }

        return null;
    }

    return (
        <div className="table-responsive class-report-table">
            <div className='d-flex flex-row justify-content-end my-2 flex-gap-5'>
                <button
                    onClick={event => {
                        event.preventDefault();
                        setSort('student');
                        setDirection('asc' === direction ? 'desc' : 'asc');
                    }}
                    className="button"
                >
                    {renderSortArrow('student')}
                    <span>SORT BY NAME</span>
                </button>
                <button
                    onClick={event => {
                        event.preventDefault();
                        setSort('progress');
                        setDirection('asc' === direction ? 'desc' : 'asc');
                    }}
                    className="button"
                >
                    {renderSortArrow('progress')}
                    <span>SORT BY PROGRESS</span>
                </button>
            </div>
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col" className='heading'>
                        <div>Students</div>
                        <div
                            className='normal'>Total: {new_items.filter(stream => stream.persona === 'student').length}</div>
                    </th>
                    <th scope="col" className='heading'>
                        <div>Student progress</div>
                        <div className='normal'>Cards in this challenge: {number_stream_entities}</div>
                    </th>
                    <th scope="col" className='heading'>
                        <div>Questions completed by student</div>
                        <div className='normal'>Questions in this challenge: {number_questions}</div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {new_items.map((item, index) => <Row key={index} item={item}/>)}
                <Expander length={items.length} reduced={reduced} setReduced={setReduced}/>
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = {
    number_questions: PropTypes.number.isRequired,
    number_stream_entities: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.shape({
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string
        }).isRequired,
        completed: PropTypes.number.isRequired,
        progress: PropTypes.number.isRequired,
        persona: PropTypes.string.isRequired
    })).isRequired
};

export default Table;
