import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    ErrorMessageAction,
    LoadingAction,
    DatePicker,
    DistrictDropdown,
    CreateProjectAction, SchoolDropdown, SchoolDropdownExtended
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import SubmitButtons from "./components/SubmitButtons";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [updated, setUpdated] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [starts_at, setStartsAt] = useState(null);
    const [ends_at, setEndsAt] = useState(null);
    const [district_id, setDistrictId] = useState(null);
    const [school_id, setSchoolId] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        return () => {
            setName('');
            setType('');
            setStartsAt(null);
            setEndsAt(null);
            setDistrictId(null);
            setSchoolId(null);
        };
    }, []);
    useEffect(() => {
        setDistrictId(null);
        setSchoolId(null);
    }, [type]);

    const validate = function () {
        let errors = 0;

        if (!name || name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name of the project.'));
            errors++;
        }
        if (!type || type === '') {
            dispatch(ErrorMessageAction('Error! Please choose the implementation type of the project.'));
            errors++;
        }
        if (type === 'district' && !district_id) {
            dispatch(ErrorMessageAction('Error! Please choose a School District'));
            errors++;
        }
        if (type === 'school' && !school_id) {
            dispatch(ErrorMessageAction('Error! Please choose a School'));
            errors++;
        }

        return errors === 0;
    }

    function renderDistrictField() {
        if (type === 'district') {
            return (
                <div>
                    <label htmlFor="create-project-district">School District</label>
                    <DistrictDropdown
                        id='create-project-district'
                        onSelect={district => {
                            if (district) {
                                setDistrictId(district.id);
                            } else {
                                setDistrictId(null);
                            }

                            return district ? district.name : '';
                        }}
                        input_class='form-control'
                        placeholder='Start typing name'
                        with_reset={true}
                        onReset={() => setDistrictId(null)}
                        show_counts={true}
                        debounce={debounce}
                    />
                </div>
            );
        }

        return null;
    }

    function renderSchoolField() {
        if (type === 'school') {
            return (
                <div>
                    <label htmlFor="create-project-school">School</label>
                    <SchoolDropdownExtended
                        id='create-project-school'
                        onSelect={school => {
                            if (school) {
                                setSchoolId(school.id);
                            } else {
                                setSchoolId(null);
                            }

                            return school ? school.name : '';
                        }}
                        input_class='form-control'
                        placeholder='Start typing name'
                        with_reset={true}
                        onReset={() => setSchoolId(null)}
                        show_counts={true}
                        debounce={debounce}
                    />
                </div>
            );
        }

        return null;
    }

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container section'>
            <h1>Create new Grading Project {name}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateProjectAction({name, type, district_id, school_id, starts_at, ends_at}))
                        .then(() => navigate(-1))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="project-form">
                <div>
                    <label htmlFor="name" className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}/>
                </div>
                <div>
                    <label htmlFor="type" className="form-label">Implementation Type</label>
                    <select
                        className="form-control"
                        id="type"
                        value={type}
                        onChange={event => {
                            setType(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}>
                        <option value='' disabled={true}>Please select implementation type</option>
                        <option value='district'>District</option>
                        <option value='school'>School</option>
                    </select>
                </div>
                {renderDistrictField()}
                {renderSchoolField()}
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="datepicker-field" className='d-block'>Project start day</label>
                        <DatePicker
                            date={starts_at}
                            onChange={timestamp => {
                                setStartsAt(timestamp);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="datepicker-field" className='d-block'>Project end day</label>
                        <DatePicker
                            date={ends_at}
                            onChange={timestamp => {
                                setEndsAt(timestamp);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                </div>
                <SubmitButtons updated={updated}/>
            </form>
        </div>
    );
}
