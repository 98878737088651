import ChoiceSingle from "./ChoiceSingle";
import React from "react";
import {MultipleSelector} from "mindsets-js-sdk";

export default class ChoiceMultiple extends ChoiceSingle {
    updateAuto(config, value) {
        if (value === 'false') {
            config.answer = [];
        }
    }

    renderCorrectAnswer() {
        if (this.props.component.config.auto_grading) {
            return (
                <div>
                    <label>Correct Answer</label>
                    <MultipleSelector
                        item_name='option'
                        trigger={<div
                            className='bordered little'>{this.props.component.config.answer.length > 0 ? this.props.component.config.answer.map(i => 'Option ' + i).sort().join(', ') : 'Please select'}</div>}
                        items={Object.keys(this.props.component.data.options).map(option_key => ({
                            id: option_key,
                            label: <span dangerouslySetInnerHTML={{__html: this.props.component.data.options[option_key]}}/>
                        }))}
                        selected={this.props.component.config.answer}
                        callback={correct_answer => {
                            let config = this.props.component.config;
                            config.answer = correct_answer;
                            this.props.onChange('config', config);
                        }}
                        show_all_option={true}
                        match_dropdown_width={false}
                    />
                </div>
            );
        }
    }
}
