import Validator from "./Validator";

export default class JsonEntitiesEntityValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'object',
      empty: false,
      mandatory: true,
      attributes: {
        type: {
          type: 'string',
          empty: false,
          mandatory: true,
          in: [
            'intro',
            'welcome',
            'poll',
            'winner',
            'instruction',
            'question',
            'instruction',
            'complete',
            'unlock',
            'final'
          ]
        },
        reference: {
          type: 'string',
          empty: false,
          mandatory: true
        },
        data: {
          type: 'object',
          empty: false,
          mandatory: true
        }
      }
    };
  }
}
