import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CustomDropdown} from "mindsets-js-sdk";

import {SetChallengeBuilderModeAction} from "../../../actions/challenge_actions";

export const MODES = {
    default: {
        name: 'Default mode',
        pages: [
            'intro',
            'welcome',
            'instruction',
            'question',
            'complete',
            'unlock',
            'question_bonus',
            'final'
        ]
    },
    pd: {
        name: 'PD mode',
        pages: [
            'intro',
            'question',
            'question',
            'question',
            'question',
            'question',
            'unlock',
            'question_bonus',
            'final'
        ]
    },
    dev: {
        name: 'Dev mode',
        pages: [
            'intro',
            'welcome',
            'instruction',
            'poll',
            'winner',
            'question',
            'complete',
            'unlock',
            'question_bonus',
            'final'
        ]
    }
}

export default function () {
    const dispatch = useDispatch();
    const mode = useSelector(state => state.challenge_builder_mode);

    function renderModes(key) {
        return (
            <a onClick={() => {
                dispatch(SetChallengeBuilderModeAction(key));
            }}>{MODES[key].name} {mode === key ? <i className='fas fa-check green'/> : ''}</a>
        );
    }

    function getModes() {
        const items = {}

        Object.keys(MODES).map(key => {
            items[key] = renderModes(key);
        });

        return items;
    }

    return (
        <CustomDropdown
            trigger={(
                <button className='last'>Modes</button>
            )}
            no_wrap={true}
            open_on_mouse_over={true}
            close_on_mouse_leave={true}
            items={getModes()}
        />
    );
}
