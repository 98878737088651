import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {LoadQuestionAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const question = useSelector(state => state.question);

    useEffect(() => {
        dispatch(LoadQuestionAction(params.question_code, {
            'selectors[0]': 'poll_poll_options',
            'selectors[1]': 'spreadsheet',
        }));
    }, []);

    if (question) {
        return (
            <div className='section'>
                <div>Q{question.number} {question.title}</div>
                <div>{question.answer_type}</div>
            </div>
        );
    }

    return null;
}
