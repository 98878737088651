import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {filterConstants} from "mindsets-js-sdk";

const Row = function ({user, row_number, school_id}) {
    return (
        <tr key={user.id}>
            <td>{row_number}</td>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.fname ? user.fname : ''} {user.lname ? user.lname : ''}</td>
            <td scope="col">{user.schools && user.schools.length ? user.schools.map((school, index) => <div key={index}>{school.name}</div>) : 'n/a'}</td>
            <td className="text-center">{user.streams_count > 0 ?
                <Link
                    to={`/lessons?column~0=student_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${user.id}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Show all the Classes the user is a member of"
                >{user.streams_count}</Link> : user.streams_count}</td>
            <td>
                <Link to={`/longitudinal/${school_id}/student/${user.id}`} className="button">Select</Link>
            </td>
        </tr>
    );
}

Row.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        fname: PropTypes.string,
        lname: PropTypes.string,
        persona: PropTypes.string.isRequired,
        schools: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        streams_count: PropTypes.number.isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired,
    school_id: PropTypes.number.isRequired
};

export default Row;
