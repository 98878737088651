import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils, filterConstants} from "mindsets-js-sdk";

const NumberLessonsCreated = function ({user}) {
    const me = useSelector(state => state.me);

    if (user.number_lessons > 0 && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_ALL_LESSONS])) {
        return (
            <Link
                to={`/lessons?column~0=user_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${user.id}`}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Show all the Classes created by the user"
            >{user.number_lessons}</Link>
        );
    } else {
        return user.number_lessons;
    }
}

NumberLessonsCreated.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        number_lessons: PropTypes.number.isRequired
    }).isRequired
};

export default NumberLessonsCreated;
