import {LOADED_PROJECT_PRACTICES} from "../actions/project_actions";

const project_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_PROJECT_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    project_practices
}
