import React from 'react';

import BaseComponentProperty from "../index";

export default class Table extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <label htmlFor="html">Html</label>
                <textarea
                    id="html"
                    className='form-control'
                    value={this.props.component.data.html ? this.props.component.data.html : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.html = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        );
    }
}
