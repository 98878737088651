import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

const UserRole = function ({user_role, persona}) {
    const roles = useSelector(state => state.users_roles);

    let suffix = [];
    if (user_role.initial) {
        suffix.push('i');
    }
    if (user_role.id === persona) {
        suffix.push('c');
    }

    const content = roles.find(role => role.id === user_role.id).name + (suffix.length ? ('(' + suffix.join(', ') + ')') : '');

    if (user_role.id === persona) {
        return (
            <div className='no-wrap'><strong>{content}</strong></div>
        );
    } else {
        return (
            <div className='no-wrap'>{content}</div>
        );
    }
}

UserRole.propTypes = {
    user_role: PropTypes.shape({
        id: PropTypes.string.isRequired,
        initial: PropTypes.bool.isRequired
    }).isRequired,
    persona: PropTypes.string.isRequired
};

export default UserRole;
