import React, {useState} from 'react';

import SchoolSubscriptionsTable from "./SchoolSubscriptionsTable";
import CreateSchoolSubscriptionForm from "./CreateSchoolSubscriptionForm";
import CreateButton from "./CreateButton";

export default function () {
    const [shown, setShown] = useState(false);

    return (
        <div>
            <h3>School subscriptions</h3>
            <CreateButton shown={shown} setShown={setShown}/>
            <CreateSchoolSubscriptionForm shown={shown} setShown={setShown}/>
            <SchoolSubscriptionsTable/>
        </div>
    );
};
