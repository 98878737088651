import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PollHeader from './PollHeader';
import PollOptions from './PollOptions';


class Poll extends Component {
    renderHeader() {
        if (this.props.component_data.type === 'poll.block') {
            return (
                <PollHeader component_data={this.props.component_data} updateComponent={this.props.updateComponent}/>
            );
        }
    }

    renderOptions() {
        return (
            <PollOptions component_data={this.props.component_data} updateComponent={this.props.updateComponent}/>
        );
    }

    render() {
        return (
            <div className="challenge-component-poll">
                <div className='poll-new'>
                    {this.renderHeader()}

                    <div className="section">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        );
    }
}

Poll.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default Poll
