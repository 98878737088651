import React from 'react';
import PropTypes from "prop-types";

const GradeLevels = function ({grade_levels}) {
    if (grade_levels && Array.isArray(grade_levels)) {
        return grade_levels.map(grade_level => {
            return (
                <div key={grade_level} className='no-wrap'>Grade {grade_level}</div>
            );
        });
    }

    return null;
}

GradeLevels.propTypes = {
    grade_levels: PropTypes.array
}

export default GradeLevels;
