import React, {Component} from 'react';
import PropTypes from 'prop-types';


class CardComponentContainer extends Component {
    getContainerStyle() {
        return _.get(this.props.component_data, 'container_style')
    }

    getPaddingClassName() {
        switch (this.props.component_data.type) {
            case 'question':
                return 'challenge-card-component-padding-top'
            default:
                return 'challenge-card-component-padding'
        }
    }

    render() {
        return (
            <div className="challenge-card-component-container" style={this.getContainerStyle()}>
                <div className={this.getPaddingClassName()}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

CardComponentContainer.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default CardComponentContainer
