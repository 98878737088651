import React from 'react';

import BaseComponentProperty from "../index";

class Matching extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderItem(item, category, index) {
        return (
            <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                <i
                    className='fas fa-trash text-danger clickable'
                    style={{position: 'absolute', right: '0', top: '5px'}}
                    onClick={() => {
                        let data = JSON.parse(JSON.stringify(this.props.component.data));
                        data[category].items.splice(index, 1);
                        this.props.onChange('data', data);
                    }}
                />
                <div>
                    <div>
                        <label className="col-form-label">HTML content 1</label>
                        <textarea
                            className='form-control'
                            value={item.html_content_1 ? item.html_content_1 : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                data[category].items[index].html_content_1 = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label className="col-form-label">Image url</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.image_url ? item.image_url : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                data[category].items[index].image_url = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label className="col-form-label">Image alt</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.image_alt ? item.image_alt : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                data[category].items[index].image_alt = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label className="col-form-label">HTML content 2</label>
                        <textarea
                            className='form-control'
                            value={item.html_content_2 ? item.html_content_2 : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                data[category].items[index].html_content_2 = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label className="col-form-label">Text</label>
                        <textarea
                            className='form-control'
                            value={item.text ? item.text : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                data[category].items[index].text = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderOwnProperties() {
        return (
            <>
                <h5>Left Items</h5>
                <div>
                    <div>
                        <label className="col-form-label">Header name</label>
                        <input
                            type='text'
                            className='form-control'
                            value={this.props.component.data.items_left && this.props.component.data.items_left.header_name ? this.props.component.data.items_left.header_name : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                if (!data.items_left) {
                                    data.items_left = {};
                                }
                                data.items_left.header_name = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <label
                        htmlFor={"textarea-placeholder"}
                        className="col-form-label">Items</label>
                    <div>
                        <button className='button mb-2' onClick={() => {
                            let data = JSON.parse(JSON.stringify(this.props.component.data));
                            if (!data.items_left) {
                                data.items_left = {};
                            }
                            if (!data.items_left.items) {
                                data.items_left.items = [];
                            }
                            data.items_left.items.push({
                                id: data.items_left.items.length + 1,
                                html_content_1: "",
                                image_url: "",
                                image_alt: "",
                                html_content_2: "",
                                text: ""
                            });
                            this.props.onChange('data', data);
                        }}>+ Item
                        </button>
                        <div>
                            {this.props.component.data.items_left.items.map((item, index) => {
                                return this.renderItem(item, 'items_left', index);
                            })}
                        </div>
                    </div>
                </div>

                <h5>Left Right</h5>
                <div>
                    <div>
                        <label className="col-form-label">Header name</label>
                        <input
                            type='text'
                            className='form-control'
                            value={this.props.component.data.items_right && this.props.component.data.items_right.header_name ? this.props.component.data.items_right.header_name : ''}
                            onChange={event => {
                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                if (!data.items_right) {
                                    data.items_right = {};
                                }
                                data.items_right.header_name = event.target.value;
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <label
                        htmlFor={"textarea-placeholder"}
                        className="col-form-label">Items</label>
                    <div>
                        <button className='button mb-2' onClick={() => {
                            let data = JSON.parse(JSON.stringify(this.props.component.data));
                            if (!data.items_right) {
                                data.items_right = {};
                            }
                            if (!data.items_right.items) {
                                data.items_right.items = [];
                            }
                            data.items_right.items.push({
                                id: data.items_right.items.length + 1,
                                html_content_1: "",
                                image_url: "",
                                image_alt: "",
                                html_content_2: "",
                                text: ""
                            });
                            this.props.onChange('data', data);
                        }}>+ Item
                        </button>
                        <div>
                            {this.props.component.data.items_right.items.map((item, index) => {
                                return this.renderItem(item, 'items_right', index);
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default function (props) {

    return <Matching {...props}/>;
}
