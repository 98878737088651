import React from 'react';

import SliderSingle from './SliderSingle';


export default class SliderMultiple extends SliderSingle {
    getStartValue() {
        return [this.props.component_data.data.valueMin, this.props.component_data.data.valueMax]
    }
}
