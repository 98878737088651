import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, DeleteDistrictAction, ReloadListAction} from "mindsets-js-sdk";


const DeleteButton = function ({id}) {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_DISTRICTS])) {
        return (
            <button
                className='button red'
                onClick={() => {
                    if (confirm('Are you sure you want to delete this District?')) {
                        dispatch(DeleteDistrictAction(id)).then(() => dispatch(ReloadListAction(true, 'districts')));
                    }
                }}
            >
                <i className='fas fa-trash'/>
            </button>
        );
    }

    return null;
}

DeleteButton.propTypes = {
    id: PropTypes.number.isRequired
}

export default DeleteButton;
