import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {statesConstants, SetUserAction} from "mindsets-js-sdk";

const States = function ({user, updated, setUpdated}) {
    const dispatch = useDispatch();
    if (user.country && statesConstants.STATES.hasOwnProperty(user.country)) {
        return (
            <div className="row">
                <label htmlFor="country" className="col-sm-3 col-form-label">State</label>
                <div className="col-sm-9">
                    <select
                        className="form-control"
                        id="state"
                        value={user.state ? user.state : ''}
                        onChange={event => {
                            let user_new = JSON.parse(JSON.stringify(user));
                            user_new.state = event.target.value;
                            dispatch(SetUserAction(user_new));
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    >
                        <option value='' disabled>SELECT STATE</option>
                        {Object.keys(statesConstants.STATES[user.country]).map(key => {
                            return (
                                <option key={key} value={key}>{statesConstants.STATES[user.country][key]}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    }

    return null;
}

States.propTypes = {
    user: PropTypes.shape({
        state: PropTypes.string,
        country: PropTypes.string,
    }).isRequired,
    updated: PropTypes.bool.isRequired,
    setUpdated: PropTypes.func.isRequired
};

export default States;
