import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";

import CardComponent from '../CardComponent';
import {
    SetChallengeBuilderMouseOverComponentAction,
    SetChallengeBuilderMouseOverRowAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../../actions/challenge_actions";


class CardRow extends Component {
    getContainerStyle() {
        const {container_style} = this.props.row_data
        return container_style
    }

    getConfig() {
        const {config = {}} = this.props.row_data
        return config
    }

    getFlex(i) {
        const {flex = true, flex_custom = []} = this.getConfig()
        if (flex) {
            return _.get(flex_custom, i, 1)
        } else {
            return 0
        }
    }

    getHAlign() {
        const {h_align} = this.getConfig()
        return h_align ? `justify-content-${h_align}` : ''
    }

    getVAlign() {
        const {v_align} = this.getConfig()
        return v_align ? `align-items-${v_align}` : ''
    }

    getFlexWrap() {
        const {wrap = true} = this.getConfig()
        return wrap ? `flex-wrap` : ''
    }

    renderComponents() {
        if (Array.isArray(this.props.row_data.components) && this.props.row_data.components.length) {
            return this.props.row_data.components.map((component_data, i) => {
                return (
                    <div className={`flex-${this.getFlex(i)}`} key={i}>
                        <CardComponent
                            component_data={component_data}
                            selectComponent={() => {
                                this.props.selectComponent(
                                    i === this.props.selected_component_index && this.props.selected_row_index === this.props.row_index ? null : this.props.row_index,
                                    i === this.props.selected_component_index && this.props.selected_row_index === this.props.row_index ? null : i
                                );
                            }}
                            selected={(this.props.selected_component_index === i && this.props.selected_row_index === this.props.row_index) || (!!this.props.mouse_over_component && this.props.mouse_over_component.row_index === this.props.row_index && this.props.mouse_over_component.component_index === i)}
                            // selected={true}
                            updateComponent={component => {
                                let row = this.props.row_data;
                                row.components[i] = component;
                                this.props.updateRow(row);
                            }}
                            setMouseOverComponent={set => this.props.setMouseOverComponent(set ? {
                                row_index: this.props.row_index,
                                component_index: i
                            } : null)}
                        />
                    </div>
                )
            });
        } else {
            return (
                <div className='badge bg-info'>Drag and Drop your first component here from the Component Library at the top.</div>
            );
        }
    }

    render() {
        const flex_class = `d-sm-flex flex-box-0-10 ${this.getHAlign()} ${this.getVAlign()} ${this.getFlexWrap()}`;
        return (
            <div
                className={"challenge-card-row-droppable " + (this.props.row_index === this.props.selected_row_index && null === this.props.selected_component_index || this.props.mouse_over_row === this.props.row_index ? 'selected' : '')}
                onClick={event => {
                    event.stopPropagation();
                    this.props.selectRow(this.props.row_index === this.props.selected_row_index && this.props.selected_component_index === null ? null : this.props.row_index);
                }}
                onMouseOver={() => this.props.dispatch(SetChallengeBuilderMouseOverRowAction(this.props.row_index))}
                onMouseLeave={() => this.props.dispatch(SetChallengeBuilderMouseOverRowAction(null))}
            >
                <div
                    className={"challenge-card-row"}
                    style={this.getContainerStyle()}
                >
                    <div className={flex_class}>
                        {this.renderComponents()}
                    </div>
                </div>
            </div>
        );
    }
}

CardRow.propTypes = {
    row_index: PropTypes.number.isRequired,
    row_data: PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            data: PropTypes.object
        }))
    }).isRequired,
    selectRow: PropTypes.func.isRequired,
    selectComponent: PropTypes.func.isRequired,
    updateRow: PropTypes.func.isRequired,
    selected_row_index: PropTypes.number,
    selected_component_index: PropTypes.number,
    mouse_over_row: PropTypes.number,
    mouse_over_component: PropTypes.shape({
        row_index: PropTypes.number,
        component_index: PropTypes.number
    }),
    addComponent: PropTypes.func.isRequired,
    setMouseOverComponent: PropTypes.func.isRequired
}

export default function (props) {
    const dispatch = useDispatch();
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);
    const mouse_over_row = useSelector(state => state.challenge_builder_mouse_over_row);
    const mouse_over_component = useSelector(state => state.challenge_builder_mouse_over_component);

    function addComponent(component) {
        let row = JSON.parse(JSON.stringify(props.row_data));
        if (!row.hasOwnProperty('components')) {
            row.components = [];
        }
        row.components.push(_.cloneDeep(component.structure));
        props.updateRow(row);
        dispatch(SetChallengeBuilderSelectedRowIndexAction(props.row_index));
        dispatch(SetChallengeBuilderSelectedComponentIndexAction(row.components.length - 1));
    }

    function setMouseOverComponent(val) {
        return dispatch(SetChallengeBuilderMouseOverComponentAction(val));
    }

    return <CardRow
        {...props}
        dispatch={dispatch}
        selected_row_index={selected_row_index}
        selected_component_index={selected_component_index}
        mouse_over_row={mouse_over_row}
        mouse_over_component={mouse_over_component}
        setMouseOverComponent={setMouseOverComponent}
        addComponent={addComponent}/>
}
