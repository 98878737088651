import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

import TextBubble from '../../TextBubble.js';

class Speech extends Component {
    renderLeft() {
        const {left} = this.props.component_data.data
        if (left) {
            return (
                <TextBubble
                    bubble_background={left.bubble_background}
                    bubble_direction='right'
                >
                    <HTMLContent html_str={contentUtils.processStringContent(this.props.component_data.data.left && this.props.component_data.data.left.text ? this.props.component_data.data.left.text : '')}/>
                </TextBubble>
            )
        }
    }

    renderImage() {
        const {image} = this.props.component_data.data
        if (image) {
            return (
                <div className="component-speech-image">
                    <img src={image.src} alt=''/>
                </div>
            )
        }
    }

    renderRight() {
        const {right} = this.props.component_data.data
        if (right) {
            return (
                <TextBubble
                    bubble_background={right.bubble_background}
                    bubble_direction='left'
                >
                    <HTMLContent html_str={contentUtils.processStringContent(this.props.component_data.data.right && this.props.component_data.data.right.text ? this.props.component_data.data.right.text : '')}/>
                </TextBubble>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-speech max-width-850">
                <div className="d-flex justify-content-start align-items-center flex-box-5-10">
                    {this.renderLeft()}
                    {this.renderImage()}
                    {this.renderRight()}
                </div>
            </div>
        )
    }
}

Speech.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Speech
