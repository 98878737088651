import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const EditSchool = function ({school}) {
    const me = useSelector(state => state.me);

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <Link
                to={'/schools/' + school.id}
                data-toggle="tooltip"
                data-placement="top"
                title="Edit School"
            >{school.name}</Link>
        );
    }

    return school.name;
}

EditSchool.propTypes = {
    school: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
};

export default EditSchool;
