import Validator from "./Validator";

export default class JsonEntitiesEntityTypeValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'string',
      empty: false,
      mandatory: true,
      in: [
        'intro',
        'welcome',
        'poll',
        'winner',
        'instruction',
        'question',
        'instruction',
        'complete',
        'unlock',
        'final'
      ]
    };
  }
}
