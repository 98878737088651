import React from 'react';
import {useSelector} from "react-redux";

import Row from './Row';

export default function () {
    const items = useSelector(state => Array.isArray(state.challenge_versions) ? state.challenge_versions : []);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Version</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item => <Row key={item.id} item={item}/>)}
                </tbody>
            </table>
        </div>
    );
};
