import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, LoadRolesAction} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import Table from "./components/Table";
import AddNewRoleButton from "./components/AddNewRoleButton";
import SyncAllRolesButton from "./components/SyncAllRolesButton";
import PermissionsButton from "./components/PermissionsButton";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    useEffect(() => {
        dispatch(LoadRolesAction({
            limit: 0,
            'selectors[0]': 'permissions'
        }));
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <div className='container roles-page section'>
                <h1>Roles Management System</h1>
                <div className='section'>
                    <div className='d-flex justify-content-end my-2 flex-gap-5'>
                        <AddNewRoleButton/>
                        <SyncAllRolesButton/>
                        <PermissionsButton/>
                    </div>
                    <Table/>
                </div>
            </div>
        </>
    );
}
