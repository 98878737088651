import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils, CustomDropdown} from "mindsets-js-sdk";

import './main_menu.scss';

export default function () {
    const me = useSelector(state => state.me);

    function renderUsers() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Users</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        users: <Link to="/users">Users</Link>,
                        grading_providers: <Link to="/grading_providers">Grading Providers</Link>,
                        'users/new': <Link to="/users/new">Create User/s</Link>
                    }}
                />
            );
        } else if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_USERS])) {
            return (
                <Link to="/users" className="main-menu-item-link">Users</Link>
            );
        }

        return null;
    }

    function renderLessons() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Classes</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        lessons: <Link to="/lessons">Classes</Link>,
                        lesson_grade: <Link to="/lesson_grade">Assign Grade Level</Link>,
                        lesson_filter: <Link to="/lesson_filter">Assign Filters</Link>,
                        responses: <Link to="/responses">Student Responses</Link>
                    }}
                />
            );
        } else if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_ALL_LESSONS])) {
            return (
                <Link to="/lessons" className="main-menu-item-link">Classes</Link>
            );
        }

        return null;
    }

    function renderSettings() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SETTINGS])) {
            return (
                <Link to="/settings" className="main-menu-item-link">Settings</Link>
            );
        }

        return null;
    }

    function renderRoles() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
            return (
                <Link to="/roles" className="main-menu-item-link">RMS</Link>
            );
        }

        return null;
    }

    function renderCurriculums() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_CURRICULUMS])) {
            return (
                <Link to="/curricula" className="main-menu-item-link">Curricula</Link>
            );
        }

        return null;
    }

    function renderContracts() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Contracts</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        contracts: <Link to="/contracts">Contracts</Link>,
                        'contracts/new': <Link to="/contracts/new">New Contract</Link>,
                    }}
                />
            );
        } else if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_CONTRACTS])) {
            return (
                <Link to="/contracts" className="main-menu-item-link">Contracts</Link>
            );
        }

        return null;
    }

    function renderOrganizations() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOLS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Organizations</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        schools: <Link to="/schools">Schools</Link>,
                        districts: <Link to="/districts">Districts</Link>,
                    }}
                />
            );
        }

        return null;
    }

    function renderChallenges() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_CHALLENGES])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Challenges</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        challenges: <Link to="/challenges">All Challenges</Link>,
                        questions: <Link to="/questions">All Questions</Link>,
                        validator: <Link to="/validator">Challenge script validator</Link>,
                        builder: <Link to="/builder">Challenge Builder</Link>
                    }}
                />
            );
        }

        return null;
    }

    function renderAnnouncements() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_ANNOUNCEMENTS])) {
            return (
                <Link to="/announcements" className="main-menu-item-link">Announcements</Link>
            );
        }

        return null;
    }

    function renderStats() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Statistics</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        timespent: <Link to="/timespent">Time Spent</Link>,
                        completion: <Link to="/completion">Student Completion</Link>,
                        longitudinal: <Link to="/longitudinal">Student Longitudinal View</Link>,
                        impact: <Link to="/impact">Impact Study data</Link>,
                        answers: <Link to="/answers">Answer Review</Link>,
                        grader_review: <Link to="/grader_review">Grader Review</Link>,
                        grader_correctness: <Link to="/grader_correctness">Grader Correctness</Link>,
                        impact_study: <Link to="/impact_study">Impact Study</Link>,
                        ai_matching_rates: <Link to="/ai_matching_rates">AI-grading Matching Rates</Link>
                    }}
                />
            );
        }

        return null;
    }

    function renderAssessment() {
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_RUBRICS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <a
                            href={'!#'}
                            className="main-menu-item-link"
                            onClick={event => event.preventDefault()}
                        >Assessment</a>
                    )}
                    no_wrap={true}
                    open_on_mouse_over={true}
                    close_on_mouse_leave={true}
                    items={{
                        rubrics: <Link to="/rubrics">Rubrics</Link>,
                        projects: <Link to="/projects">Grading Projects</Link>,
                        dashboards: <Link to="/dashboards">Insights Dashboards</Link>,
                        lesson_schedules: <Link to="/lesson_schedules">Existing schedules</Link>,
                        'lesson_schedules/new': <Link to="/lesson_schedules/new">Send classes for QA</Link>
                    }}
                />
            );
        }

        return null;
    }

    return (
        <>
            <div className="d-none d-xl-block h-100">
                <ul className="main-menu">
                    <li className="main-menu-item">{renderUsers()}</li>
                    <li className="main-menu-item">{renderOrganizations()}</li>
                    <li className="main-menu-item">{renderLessons()}</li>
                    <li className="main-menu-item">{renderAssessment()}</li>
                    <li className="main-menu-item">{renderChallenges()}</li>
                    <li className="main-menu-item">{renderCurriculums()}</li>
                    <li className="main-menu-item">{renderContracts()}</li>
                    <li className="main-menu-item">{renderAnnouncements()}</li>
                    <li className="main-menu-item">{renderStats()}</li>
                    <li className="main-menu-item">{renderSettings()}</li>
                    <li className="main-menu-item">{renderRoles()}</li>
                </ul>
            </div>
            <div className='d-xl-none d-flex align-items-center'>
                <CustomDropdown
                    trigger={(
                        <i className='fas fa-bars text-white fa-2x'/>
                    )}
                    no_wrap={true}
                    close_on_mouse_leave={true}
                    items={{
                        users: renderUsers(),
                        organizations: renderOrganizations(),
                        lessons: renderLessons(),
                        assessment: renderAssessment(),
                        challenges: renderChallenges(),
                        curricula: renderCurriculums(),
                        contracts: renderContracts(),
                        announcements: renderAnnouncements(),
                        stats: renderStats(),
                        settings: renderSettings(),
                        roles: renderRoles()
                    }}
                    use_arrow={false}
                />
            </div>
        </>
    );
}
