import React from "react";
import PropTypes from "prop-types";

const BodyCounter = function ({body}) {
    if (140 <= body.length) {
        return (
            <small id="bodyHelp"
                   className="form-text text-muted">{'The length (' + body.length + ') is more than recommended 140 characters.'}</small>
        );
    }

    return null;
}

BodyCounter.propTypes = {
    body: PropTypes.string.isRequired
}

export default BodyCounter;
