import React, {Component} from 'react';
import PropTypes from 'prop-types';

class BaseAnswer extends Component {
    render() {
        return null
    }
}

BaseAnswer.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default BaseAnswer
