import MindsetsJsSDK, {
    filterConstants,
    setLimitAction,
    setCurrentAction
} from "mindsets-js-sdk";

export const LOADED_LONGITUDINAL_STATS = '[longitudinal_stats] Loaded Longitudinal Stats';
export const LOADED_LONGITUDINAL_STATS_PAGES = '[longitudinal_stats] Loaded pages';
export const LOADED_LONGITUDINAL_STATS_TOTAL = '[longitudinal_stats] Loaded total';
export const SET_LONGITUDINAL_USER = '[longitudinal_user] Set longitudinal user';
export const SET_LONGITUDINAL_USER_STATS = '[longitudinal_user] Set longitudinal user stats';
export const SET_LONGITUDINAL_STREAM_STATS = '[longitudinal_user] Set longitudinal stream stats';
export const SET_LONGITUDINAL_CHALLENGE_STATS = '[longitudinal_user] Set longitudinal challenge stats';
export const SET_LONGITUDINAL_TIME_SPENT_STATS = '[longitudinal_user] Set longitudinal time spent stats';
export const SET_LONGITUDINAL_LESSON_AVG_TIME_SPENT_STATS = '[longitudinal_user] Set longitudinal lesson avg time spent stats';

export const LoadLongitudinalStatsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => {
            options['filters[persona][0]'] = filterConstants.EXPRESSION_EQUAL + '=student';
            options['selectors[0]'] = 'schools';
            options['selectors[1]'] = 'streams_count';
            return MindsetsJsSDK().API.get('users', options);
        })
        .then(response => {
            dispatch(LoadedLongitudinalStatsAction(response.items));
            dispatch(setCurrentAction('longitudinal_stats', response.pages >= response.current ? response.current : 1));
            dispatch(setLimitAction('longitudinal_stats', response.limit));
            dispatch(LoadedLongitudinalStatsPagesAction(response.pages));
            dispatch(LoadedLongitudinalStatsTotalAction(response.total));

            return response.items;
        });
};
export const LoadedLongitudinalStatsAction = payload => ({
    type: LOADED_LONGITUDINAL_STATS,
    payload
});
export const LoadedLongitudinalStatsPagesAction = payload => ({
    type: LOADED_LONGITUDINAL_STATS_PAGES,
    payload
});
export const LoadedLongitudinalStatsTotalAction = payload => ({
    type: LOADED_LONGITUDINAL_STATS_TOTAL,
    payload
});

export const LoadLongitudinalUserAction = (user_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('users/' + user_id, options))
        .then(response => dispatch(SetLongitudinalUserAction(response.user)));
};
export const SetLongitudinalUserAction = payload => ({
    type: SET_LONGITUDINAL_USER,
    payload
});
export const LoadLongitudinalUserStatsAction = (school_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`statistics/schools/${school_id}/students`, options))
        .then(response => dispatch(SetLongitudinalUserStatsAction(response.items)));
};
export const SetLongitudinalUserStatsAction = payload => ({
    type: SET_LONGITUDINAL_USER_STATS,
    payload
});
export const LoadLongitudinalStreamStatsAction = (school_id, user_id) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`streams/schools/${school_id}/users/${user_id}/stats`))
        .then(response => {
            dispatch(SetLongitudinalStreamStatsAction(response.items));

            return response.items;
        });
};
export const SetLongitudinalStreamStatsAction = payload => ({
    type: SET_LONGITUDINAL_STREAM_STATS,
    payload
});
export const LoadLongitudinalChallengeStatsAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`statistics/challenge_versions`, {
            'filters[unversioned][0]': filterConstants.EXPRESSION_TRUE,
            'limit': 500
        }))
        .then(response => {
            dispatch(SetLongitudinalChallengeStatsAction(response.challenge_versions))

            return response.challenge_versions;
        });
};
export const SetLongitudinalChallengeStatsAction = payload => ({
    type: SET_LONGITUDINAL_CHALLENGE_STATS,
    payload
});
export const LoadLongitudinalTimeSpentStatsAction = (user_id, streams, challengeStats) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/streams/times', {
            limit: 0,
            'filters[user_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + user_id
        }))
        .then(response => {
            const timeGroups = _.groupBy(response.items, 'stream_id');
            let streamTimes = {};

            streams.filter(stream => Object.keys(timeGroups).includes(stream.id.toString())).map(stream => {
                const challenge_version = challengeStats.find(challenge_version => challenge_version.id === stream.challenge_version_id);
                const times = timeGroups[stream.id].sort((a, b) => a.card_no - b.card_no);

                let challengeTimeSpent = _.first(times).timestamp - stream.created_at;
                let bonusTimeSpent = 0;
                let totalTimeSpent = _.first(times).timestamp - stream.created_at;

                times.map((time, index) => {
                    if (time.card_no > challenge_version.index_bonus_first && times[index - 1]) {
                        bonusTimeSpent += time.timestamp - times[index - 1].timestamp;
                    }
                    if (times[index - 1]) {
                        totalTimeSpent += time.timestamp - times[index - 1].timestamp;
                    }
                });
                times
                    .filter(time => time.card_no <= challenge_version.index_bonus_first)
                    .map((time, index) => {
                        if (times[index - 1]) {
                            challengeTimeSpent += time.timestamp - times[index - 1].timestamp;
                        }
                    });
                streamTimes[stream.id] = {challengeTimeSpent, bonusTimeSpent, totalTimeSpent};
            });

            return streamTimes;
        })
        .then(timeGroups => dispatch(SetLongitudinalTimeSpentStatsAction(timeGroups)));
};
export const SetLongitudinalTimeSpentStatsAction = payload => ({
    type: SET_LONGITUDINAL_TIME_SPENT_STATS,
    payload
});
export const LoadLongitudinalLessonAvgTimeSpentStatsAction = (user_id, streams) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`statistics/lessons/times/${user_id}`, Object.assign({}, ...streams.map((stream, index) => {
            return {['lesson_id[' + index + ']']: stream.lesson_id};
        }))))
        .then(response => dispatch(SetLongitudinalLessonAvgTimeSpentStatsAction(response.items)));
};
export const SetLongitudinalLessonAvgTimeSpentStatsAction = payload => ({
    type: SET_LONGITUDINAL_LESSON_AVG_TIME_SPENT_STATS,
    payload
});
