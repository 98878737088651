import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    SchoolDropdown,
    countriesConstants,
    statesConstants,
    ErrorMessageAction,
    CreateUsersAction,
    LoadingAction,
    StateField
} from "mindsets-js-sdk";

import RemoveButton from "./components/RemoveButton";
import SubmitButtons from "./components/SubmitButtons";

export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [users, setUsers] = useState([{fname: '', lname: '', email: ''}]);
    const [updated, setUpdated] = useState(false);
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [school, setSchool] = useState(null);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        return () => {
            setUsers([{fname: '', lname: '', email: ''}]);
            setCountry('');
            setCountry('');
            setState('');
            setSchool('');
        };
    }, []);

    const validate = function () {
        let errors = 0;

        if (users.length <= 0) {
            dispatch(ErrorMessageAction('Error! Please add at least one user'));
            errors++;
        }
        if (!country || '' === country) {
            dispatch(ErrorMessageAction('Error! Please select country'));
            errors++;
        }
        if (!!country && statesConstants.STATES.hasOwnProperty(country) && (!state || '' === state)) {
            dispatch(ErrorMessageAction('Error! Please select state'));
            errors++;
        }
        if (!school) {
            dispatch(ErrorMessageAction('Error! Please select a school'));
            errors++;
        }

        users.map((user, index) => {
            if (!user.fname || user.fname === '') {
                dispatch(ErrorMessageAction('Error! Please specify the first name of a User in row #' + index));
                errors++;
            }
            if (!user.lname || user.lname === '') {
                dispatch(ErrorMessageAction('Error! Please specify the last name of a User in row #' + index));
                errors++;
            }
            if (!user.email || user.email === '') {
                dispatch(ErrorMessageAction('Error! Please specify the email of a User in row #' + index));
                errors++;
            }
        });

        return errors === 0;
    }

    return (
        <>
            <h1>Add New User/s</h1>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateUsersAction({
                        users,
                        school_id: school.id,
                        country,
                        state
                    })).then(() => navigate(-1)).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }} autoComplete="off" id="schools-form">
                {users.map((user, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex flex-row justify-content-between align-items-center align-self-center flex-wrap"
                        >
                            <div className="p-1">{index}.</div>
                            <div className="p-2 flex-fill">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fname"
                                        placeholder="First Name"
                                        value={user.fname}
                                        onChange={event => {
                                            let users_new = JSON.parse(JSON.stringify(users));
                                            users_new[index].fname = event.target.value;
                                            setUsers(users_new);
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }}/>
                                </div>
                            </div>
                            <div className="p-2 flex-fill">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lname"
                                        placeholder="Last Name"
                                        value={user.lname}
                                        onChange={event => {
                                            let users_new = JSON.parse(JSON.stringify(users));
                                            users_new[index].lname = event.target.value;
                                            setUsers(users_new);
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }}/>
                                </div>
                            </div>
                            <div className="p-2 flex-fill">
                                <div>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        value={user.email}
                                        onChange={event => {
                                            let users_new = JSON.parse(JSON.stringify(users));
                                            users_new[index].email = event.target.value;
                                            setUsers(users_new);
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }}/>
                                </div>
                            </div>
                            <div className="p-1">
                                <RemoveButton
                                    index={index}
                                    setUpdated={setUpdated}
                                    updated={updated}
                                    users={users}
                                    setUsers={setUsers}
                                />
                            </div>
                        </div>
                    );
                })}
                <div>
                    <a href="#" onClick={event => {
                        event.preventDefault();
                        let users_new = JSON.parse(JSON.stringify(users));
                        users_new.push({fname: '', lname: '', email: ''});
                        setUsers(users_new);
                        if (!updated) {
                            setUpdated(true);
                        }
                    }} className='p-2'>
                        <i className="fas fa-plus-circle fa-2x"/>
                    </a>
                </div>

                <hr/>

                <div className="row">
                    <label htmlFor="country" className="col-2 col-form-label">Country</label>
                    <div className="col-4">
                        <select
                            className="form-control"
                            id="country"
                            value={country}
                            onChange={event => {
                                setCountry(event.target.value);
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        >
                            <option value='' disabled={true}>SELECT COUNTRY</option>
                            {Object.keys(countriesConstants.COUNTRIES).map(country_code => (
                                <option
                                    key={country_code}
                                    value={country_code}
                                >{countriesConstants.COUNTRIES[country_code]}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <StateField
                    country={country}
                    value={state}
                    onChange={value => {
                        setState(value);
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}
                    outer_class_name='row'
                    inner_class_name='col-4'
                    label_class_name='col-sm-2 col-form-label'
                />
                <div className="row">
                    <label htmlFor="autoload-field" className="col-2 col-form-label">School</label>
                    <div className="col-4">
                        <SchoolDropdown
                            onSelect={school => {
                                setSchool(school);
                                if (!updated) {
                                    setUpdated(true);
                                }

                                return school ? school.name : '';
                            }}
                            placeholder='School Name'
                            input_class='form-control'
                            with_district={true}
                            country={country ? country : null}
                            id='users-create-school'
                            show_counts={true}
                            debounce={debounce}
                        />
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="district-name" className="col-2 col-form-label">District Name</label>
                    <div className="col-4">
                        <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="district-name"
                            value={school && school.district ? school.district.name : ''}
                            onChange={() => {
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <label htmlFor="location" className="col-2 col-form-label">Location</label>
                    <div className="col-4">
                        <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="location"
                            value={school && school.location ? school.location : ''}
                            onChange={() => {
                            }}/>
                    </div>
                </div>

                <hr/>

                <SubmitButtons updated={updated}/>
            </form>
        </>
    );
};
