import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {LoadingAction, ErrorMessageAction, UpdateScheduleAction} from "mindsets-js-sdk";

function ActionButton ({schedule}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (schedule.status === 'finished' || schedule.status === 'QA passed') {
        return (
            <Link
                to={`/schedule/${schedule.id}`}
                className="button d-inline-block">View results</Link>
        );

    } else if (schedule.status === 'started') {
        return (
            <Link
                to={`/schedule/${schedule.id}`}
                className="button d-inline-block">Continue assessment</Link>
        );
    } else if (schedule.status === 'requested') {
        return (
            <button
                className="button d-inline-block"
                onClick={() => dispatch(UpdateScheduleAction(schedule.id, {status: 'started'}))
                    .then(() => navigate(`/schedule/${schedule.id}`))
                    .catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    })}
            >Start assessment</button>
        );
    } else {
        return null;
    }
}

ActionButton.propTypes = {
    schedule: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
}

export default ActionButton;
