import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    announcementTargetsConstants,
    ErrorMessageAction,
    SuccessMessageAction,
    authUtils,
    accessConstants,
    DatePicker,
    Checkbox,
    SetAnnouncementAction,
    CreateAnnouncementAction,
    LoadingAction
} from "mindsets-js-sdk";

import {LoadAnnouncementRolesAction} from "../../actions/announcement_actions";
import NoAccess from "../../common/NoAccess";
import BodyCounter from "./components/BodyCounter";

export default function () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roles = useSelector(state => state.announcement_roles);
    const me = useSelector(state => state.me);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [status, setStatus] = useState('draft');
    const [dismissable, setDismissable] = useState(true);
    const [dismissable_after, setDismissableAfter] = useState(3);
    const [starts_at, setStartsAt] = useState(null);
    const [ends_at, setEndsAt] = useState(null);
    const [audience, setAudience] = useState(null);
    const [target, setTarget] = useState(null);
    const validate = function () {
        let errors = 0;

        if (!body || body === '') {
            dispatch(ErrorMessageAction('Error! Please specify the body.'));
            errors++;
        }

        if (!status || status === '') {
            dispatch(ErrorMessageAction('Error! Please specify the status.'));
            errors++;
        }

        if (!starts_at || starts_at === '') {
            dispatch(ErrorMessageAction('Error! Please select the date when the announcement should start.'));
            errors++;
        }

        if (!ends_at || ends_at === '') {
            dispatch(ErrorMessageAction('Error! Please select the date when the announcement should end.'));
            errors++;
        }

        if (starts_at >= ends_at) {
            dispatch(ErrorMessageAction('Error! Start date is grater or equal to End date.'));
            errors++;
        }

        if (dismissable_after && !Number.isInteger(Number(dismissable_after))) {
            dispatch(ErrorMessageAction('Error! The "Dismissable After" field must be an integer.'));
            errors++;
        }

        if (dismissable && dismissable_after <= 0) {
            dispatch(ErrorMessageAction('Error! The "Dismissable After" field must be more than zero'));
            errors++;
        }

        return errors === 0;
    }
    useEffect(() => {
        dispatch(LoadAnnouncementRolesAction());

        return () => dispatch(SetAnnouncementAction({}));
    }, []);

    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ANNOUNCEMENTS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateAnnouncementAction({
                        title,
                        body,
                        status,
                        dismissable,
                        dismissable_after,
                        starts_at,
                        ends_at,
                        audience,
                        target
                    })).then(() => {
                        dispatch(SuccessMessageAction('New Announcement has been created.'));
                        navigate(-1);
                    }).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }}
            autoComplete="off"
            id="announcement-form"
        >
            <h1>New Announcement</h1>

            <div className="row">
                <label htmlFor="title" className="col-sm-2 col-form-label">Title(optional)</label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <label htmlFor="body" className="col-sm-2 col-form-label">Body</label>
                <div className="col-sm-10">
                        <textarea
                            className="form-control"
                            id="body"
                            placeholder="Body"
                            value={body}
                            onChange={event => setBody(event.target.value)}
                        />
                    <BodyCounter body={body}/>
                </div>
            </div>

            <div className="row">
                <label htmlFor="status" className="col-sm-2 col-form-label">Status</label>
                <div className="col-sm-2">
                    <select
                        className="form-control"
                        id="status"
                        value={status}
                        onChange={event => setStatus(event.target.value)}
                    >
                        <option value='draft'>Draft</option>
                        <option value='active'>Active</option>
                    </select>
                </div>
                <label htmlFor="dismissable" className="col-sm-2 col-form-label">Dismissable?</label>
                <div className="col-sm-2">
                    <select
                        className="form-control"
                        id="dismissable"
                        value={dismissable.toString()}
                        onChange={event => setDismissable(event.target.value === 'true')}
                    >
                        <option value={'true'}>True</option>
                        <option value={'false'}>False</option>
                    </select>
                </div>
                <label htmlFor="dismissable_after" className="col-sm-2 col-form-label">After</label>
                <div className="col-sm-2">
                    <input
                        type="text"
                        className="form-control"
                        id="dismissable_after"
                        placeholder="Clicks"
                        value={dismissable_after.toString()}
                        onChange={event => setDismissableAfter(!isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : '')}
                        disabled={dismissable === false}
                    />
                </div>
            </div>

            <div className="row">
                <label className="col-sm-2 col-form-label">Active between</label>
                <div className="col-sm-4">
                    <DatePicker
                        range={true}
                        date={function () {
                            if (starts_at && ends_at) {
                                return [starts_at, ends_at];
                            }

                            return null;
                        }()}
                        onChange={timestamps => {
                            if (timestamps.from && timestamps.till) {
                                setStartsAt(timestamps.from);
                                setEndsAt(timestamps.till);
                            }
                        }}
                    />
                </div>
            </div>

            <h5>Audience</h5>
            <div>
                <p>Persona</p>
                <div className='d-flex'>
                    {roles.map(role => {
                        return (
                            <Checkbox
                                key={role.id}
                                checked={audience && audience.hasOwnProperty('persona') ? audience['persona'].includes(role.id) : false}
                                label={role.name}
                                toggle={() => {
                                    let audience_X = JSON.parse(JSON.stringify(audience));
                                    if (!audience_X) {
                                        audience_X = {}
                                    }
                                    if (audience_X.hasOwnProperty('persona') && audience_X['persona'].includes(role.id)) {
                                        let group = audience_X['persona'];
                                        group.splice(group.indexOf(role.id), 1);
                                    } else if (audience_X.hasOwnProperty('persona')) {
                                        let group = audience_X['persona'];
                                        group.push(role.id);
                                    } else {
                                        audience_X['persona'] = [role.id];
                                    }
                                    Object.keys(audience_X).map(audience_group => {
                                        if (audience_X[audience_group].length <= 0) {
                                            delete audience_X[audience_group];
                                        }
                                    });
                                    if (Object.keys(audience_X).length <= 0) {
                                        audience_X = null;
                                    }
                                    setAudience(audience_X);
                                }}
                            />
                        );
                    })}
                </div>
            </div>

            <h5>Targets</h5>
            <div>
                {Object.keys(announcementTargetsConstants.TARGETS).map(target_group_key => {
                    return (
                        <div key={target_group_key}>
                            <div>{_.capitalize(target_group_key)}</div>
                            <div className='d-flex'>
                                {Object.keys(announcementTargetsConstants.TARGETS[target_group_key]).map(target_item_key => {
                                    return (
                                        <Checkbox
                                            key={target_item_key}
                                            checked={!!target && target.hasOwnProperty(target_group_key) && target[target_group_key].includes(target_item_key)}
                                            label={announcementTargetsConstants.TARGETS[target_group_key][target_item_key]}
                                            toggle={() => {
                                                let target_X = JSON.parse(JSON.stringify(target));
                                                if (!target_X) {
                                                    target_X = {}
                                                }
                                                if (target_X.hasOwnProperty(target_group_key) && target_X[target_group_key].includes(target_item_key)) {
                                                    let group = target_X[target_group_key];
                                                    group.splice(group.indexOf(target_item_key), 1);
                                                } else if (target_X.hasOwnProperty(target_group_key)) {
                                                    let group = target_X[target_group_key];
                                                    group.push(target_item_key);
                                                } else {
                                                    target_X[target_group_key] = [target_item_key];
                                                }
                                                Object.keys(target_X).map(target_group_key => {
                                                    if (target_X[target_group_key].length <= 0) {
                                                        delete target_X[target_group_key];
                                                    }
                                                });
                                                if (Object.keys(target_X).length <= 0) {
                                                    target_X = null;
                                                }
                                                setTarget(target_X);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className='d-flex flex-gap-5'>
                <button type="submit" className="button">Submit</button>
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>Cancel
                </button>
            </div>
        </form>
    );
}
