import React, {useState} from 'react';

import DistrictSubscriptionsTable from "./DistrictSubscriptionsTable";
import CreateDistrictSubscriptionForm from "./CreateDistrictSubscriptionForm";

export default function () {
    const [shown, setShown] = useState(false);

    return (
        <div>
            <h3>District subscriptions</h3>
            {shown === false ? <button className="button" onClick={() => setShown(true)}>Create</button> : null}
            {shown === true ? <CreateDistrictSubscriptionForm setShown={setShown}/> : null}
            <DistrictSubscriptionsTable/>
        </div>
    );
};
