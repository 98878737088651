import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PollInlineOption from './PollInlineOption';


class PollInlineOptions extends Component {
    renderOptions() {
        return this.props.poll.poll_options.map((option, i) => {
            return (
                <div key={i}>
                    <PollInlineOption
                        poll_option={option}
                        is_selected={false}
                        show_graph={false}
                        option_votes={0}
                        total_votes={0}
                        updateOption={option => {
                            let data = this.props.poll;
                            data.poll_options[i] = option;
                            this.props.updateComponentData(data);
                        }}
                    />
                </div>
            );
        })
    }

    render() {
        return (
            <div className="poll-inline-options">
                <div className="d-flex justify-content-start">
                    <div className="d-flex flex-column flex-box-5-10">
                        {this.renderOptions()}
                    </div>
                </div>
            </div>
        );
    }
}

PollInlineOptions.propTypes = {
    poll: PropTypes.object.isRequired,
    updateComponentData: PropTypes.func.isRequired
}

export default PollInlineOptions
