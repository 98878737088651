import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

function UpdateBatchButton({batch, setBatchId}) {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <button
                className='button'
                onClick={() => setBatchId(batch.id)}
                disabled={batch.status !== 'draft'}
            >
                <i className='fas fa-edit'/>
            </button>
        );
    }

    return null;
}

UpdateBatchButton.propTypes = {
    batch: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
}

export default UpdateBatchButton;
