import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    LoadingAction,
    authUtils,
    CustomDropdown,
    ErrorMessageAction,
    SuccessMessageAction,
    ScheduleProjectBatchAction
} from "mindsets-js-sdk";

function BulkActionsButton({disabled, selected_batches, setSelectedBatches, action}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const project_batches = useSelector(state => state.project_batches);
    if (project_batches.length > 0) {
        const batches = JSON.parse(JSON.stringify(project_batches)).filter(batch => selected_batches.includes(batch.id));
        if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
            return (
                <CustomDropdown
                    trigger={(
                        <button className="button">Bulk Actions</button>
                    )}
                    no_wrap={true}
                    content_class='dropdown-to-left'
                    items={{
                        schedule: <a href={'!#'} onClick={event => {
                            event.preventDefault();
                            Promise.resolve().then(() => {
                                batches.map(item => {
                                    if (!item.grading_provider_id) {
                                        dispatch(ErrorMessageAction('Grading provider is not selected for Batch ' + item.name));
                                        return;
                                    }
                                    if (!item.practice_id) {
                                        dispatch(ErrorMessageAction('Practice is not selected for Batch ' + item.name));
                                        return;
                                    }
                                    if (item.status !== 'draft') {
                                        dispatch(ErrorMessageAction('Can not schedule Batch ' + item.name));
                                        return;
                                    }
                                    if (!item.lessons || item.lessons.lessons <= 0) {
                                        dispatch(ErrorMessageAction('No Classes added to the Batch ' + item.name));
                                        return;
                                    }
                                    dispatch(ScheduleProjectBatchAction(item.id)).then(() => {
                                        dispatch(SuccessMessageAction('Classes in Batch ' + item.name + ' have been scheduled for Grading'));
                                    }).catch(error => {
                                        dispatch(LoadingAction(false));
                                        dispatch(ErrorMessageAction(error));
                                        console.error(error);
                                    });
                                });
                            }).then(() => {
                                setSelectedBatches([]);
                                setTimeout(action, 500);
                            });
                        }}>Schedule All</a>
                    }}
                    disabled={disabled}
                />
            );
        }
    }

    return null;
}

BulkActionsButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
    selected_batches: PropTypes.array.isRequired,
    setSelectedBatches: PropTypes.func.isRequired
}

export default BulkActionsButton;
