import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {CONTENT_COMPONENTS, ANSWER_COMPONENTS} from "../index";
import {SetChallengeBuilderChallengePagesAction} from "../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();

    const mode = useSelector(state => state.challenge_builder_mode);
    const index = useSelector(state => state.challenge_builder_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);

    let pages = JSON.parse(JSON.stringify(challenge_pages));
    let page = challenge_pages[index];

    function renderComponent(component) {
        return (
            <div className='library-component clickable' key={component.id} onClick={() => {
                pages[index].data.card_content.rows[selected_row_index].components.push(component.structure);
                dispatch(SetChallengeBuilderChallengePagesAction(pages));
            }}>
                <div className='library-component-inner'>
                    <div className='navigation-component-icon'>
                        <i className={'fas fa-2x fa-' + component.icon}/>
                    </div>
                    <div className='navigation-component-name'>{component.name}</div>
                </div>
            </div>
        );
    }

    function renderContent() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return (
                <h3 className='text-center text-warning'>This page type is static and does not support customization</h3>
            );
        } else if (selected_row_index === null && selected_component_index === null) {
            return (
                <h3 className='text-center text-warning'>Please select a row you would like to add a component to</h3>
            );
        } else if (mode === 'pd') {
            return (
                <div className='library-components'>
                    {CONTENT_COMPONENTS.filter(component => ['image', 'question', 'text'].includes(component.id)).map(renderComponent)}
                    {ANSWER_COMPONENTS.filter(component => ['answer.choice_multiple', 'answer.choice_single', 'poll.inline', 'answer.textarea'].includes(component.id)).map(renderComponent)}
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        <h5>Content Components</h5>
                        <div className='library-components'>
                            {CONTENT_COMPONENTS.map(renderComponent)}
                        </div>
                    </div>
                    <div>
                        <h5>Answer Components</h5>
                        <div className='library-components'>
                            {ANSWER_COMPONENTS.map(renderComponent)}
                        </div>
                    </div>
                </div>
            );
        }
    }

    if (selected_row_index !== null && selected_component_index !== null) {
        return null;
    }

    return (
        <div className='bordered flex-grow-1'>
            <h4>Component Library</h4>
            {renderContent()}
        </div>
    );
}
