import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointNumberLinePointValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'number',
            empty: false,
            mandatory: false
        };
    }
}
