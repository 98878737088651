import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

import TextBubble from '../../TextBubble.js';

class Comparison extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gif: false
        }
    }

    renderImages() {
        const {
            imageURL, image_name, animated_gif_url, animated_git_name
        } = this.props.component_data.data
        const {gif} = this.state

        let images = []
        images.push(
            imageURL &&
            <img
                className={gif && animated_gif_url ? 'hide' : ''} key="image"
                onClick={() => this.setState({gif: !gif})}
                src={imageURL} title={image_name} alt={image_name}
            />
        )
        images.push(
            animated_gif_url &&
            <img
                className={gif ? '' : 'hide'} key="gif"
                onClick={() => this.setState({gif: !gif})}
                src={animated_gif_url} title={animated_git_name} alt={animated_git_name}
            />
        )

        return images
    }

    renderText() {
        const {
            bubble_background, bubble_direction, text
        } = this.props.component_data.data

        return (
            <TextBubble
                bubble_background={bubble_background}
                bubble_direction={bubble_direction}
            >
                <HTMLContent html_str={contentUtils.processStringContent(text)}/>
            </TextBubble>
        );
    }

    render() {
        const {bubble_direction} = this.props.component_data.data
        const extra_class = ` bubble-direction-${bubble_direction} flex-box-10-10 `
        return (
            <div className={"challenge-component-comparison " + extra_class}>
                <div className="challenge-component-comparison-image">
                    {this.renderImages()}
                </div>

                <div className="challenge-component-comparison-text">
                    {this.renderText()}
                </div>
            </div>
        )
    }
}

Comparison.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Comparison
