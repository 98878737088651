import React from 'react';

import BaseCard from './BaseCard';


export default class PollCard extends BaseCard {
    getCardType() {
        return 'poll';
    }
}
