import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import RowItem from "./RowItem";
import {
    SetChallengeBuilderMouseOverRowAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";

export default function ({index, row_index}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: index + 1});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const dispatch = useDispatch();
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);

    return (
        <RowItem
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            row_index={row_index}
            onClick={event => {
                event.stopPropagation();
                dispatch(SetChallengeBuilderSelectedRowIndexAction(row_index === selected_row_index && selected_component_index === null ? null : row_index));
                dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
            }}
            onMouseOver={() => dispatch(SetChallengeBuilderMouseOverRowAction(row_index))}
            onMouseLeave={() => dispatch(SetChallengeBuilderMouseOverRowAction(null))}
        />
    );
}
