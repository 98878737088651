import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils} from "mindsets-js-sdk";

class Hint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    renderTrigger() {
        if (this.props.hint && !this.state.show) {
            const onClick = (e) => {
                e.preventDefault()
                this.setState({show: true})
            }

            return (
                <div className="d-flex">
                    <a className="link-new" href="#" onClick={onClick}>
                        {`Hint`}
                        <i className="far fa-compass"/>
                    </a>
                </div>
            )
        }
    }

    renderHint() {
        if (this.props.hint && this.state.show) {
            const onClick = () => {
                this.setState({show: false})
            }

            return (
                <div className="question-hint">
                    <div className="question-hint-header">
                        {`Hint`}
                    </div>

                    <div>
                        {contentUtils.processStringContent(this.props.hint)}
                    </div>

                    <div className="question-hint-close">
                        <i className="fas fa-times" onClick={onClick}/>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-question-hint">
                {this.renderTrigger()}

                {this.renderHint()}
            </div>
        )
    }
}

Hint.propTypes = {
    hint: PropTypes.string.isRequired
}

export default Hint
