import React, {Component} from 'react'
import PropTypes from 'prop-types'

import TextArea from './TextArea'
import NumberLine from './NumberLine'
import ChoiceSingle from './ChoiceSingle'
import ChoiceMultiple from './ChoiceMultiple'
import SliderSingle from './SliderSingle'
import SliderMultiple from './SliderMultiple'
import Graphing from './Graphing'
import Expression from './Expression'
import ExpressionMulti from './ExpressionMulti'
import DragAndDrop from './DragAndDrop'
import Spreadsheet from './Spreadsheet'
import InlinePoll from './InlinePoll'
import Fraction from './Fraction'
import Matching from './Matching'
import NumberOnly from './NumberOnly'
import Selector from './Selector'


class AnswerComponent extends Component {
    getComponentClass() {
        const type = this.props.component_data.type.toLowerCase()
        switch (type) {
            case 'answer.textarea':
                return TextArea
            case 'answer.number_line':
                return NumberLine
            case 'answer.choice_single':
                return ChoiceSingle
            case 'answer.choice_multiple':
                return ChoiceMultiple
            case 'answer.slider':
                return SliderSingle
            case 'answer.range':
                return SliderMultiple
            case 'answer.graphing':
                return Graphing
            case 'answer.expression':
                return Expression
            case 'answer.expression_multi':
                return ExpressionMulti
            case 'answer.dnd':
                return DragAndDrop
            case 'spreadsheet':
                return Spreadsheet
            case 'poll.inline':
                return InlinePoll
            case 'answer.fraction':
                return Fraction
            case 'answer.matching':
                return Matching
            case 'answer.number_only':
                return NumberOnly
            case 'answer.selector':
                return Selector
            default:
                throw 'Unknown component type "' + type + '"'
        }
    }

    render() {
        const ComponentClass = this.getComponentClass()

        return (
            <div className="challenge-answer-component">
                <ComponentClass
                    component_data={this.props.component_data}
                    updateComponent={this.props.updateComponent}/>
            </div>
        )
    }
}

AnswerComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default AnswerComponent
