import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

class NumberLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answer: '',
            startMousePos: 0,
            lineWidth: 0,
            increment_count: 0,
            snap_division_width: 0,
            points: [],
            readablePosition: null,
            objRef: null,
            topLabels: [],
            bottomLabels: [],
            submitting: false
        };

        this.handleDown = this.handleDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.updateWidth = this.updateWidth.bind(this);
        this.setupLedgends = this.setupLedgends.bind(this);
    }

    setInitialNumber() {
        // if(this.state.readablePosition===null) {
        // this.state.readablePosition = Number(this.props.value);
        this.setState({readablePosition: this.props.value});
        // }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }

    setupLedgends() {
        let propsData = this.props.data;
        let firstNum = propsData.first_num;
        let topLabels = [];
        let bottomLabels = [];

        if (propsData.numberPoints) {
            for (let l = 0; l < propsData.numberPoints.length; l++) {
                let thisPoint = propsData.numberPoints[l];
                let formula = thisPoint.formula;

                let leftPos = (this.state.lineWidth * (thisPoint.numberLinePoint - firstNum)) + "px";
                topLabels.push(
                    <div style={{left: leftPos}} key={"topL" + l}>
                        <HTMLContent html_str={contentUtils.processStringContent(formula)}/>
                    </div>
                );
            }
        }

        for (let l = 1; l < this.state.points.length; l++) {
            let leftPos = ((this.state.lineWidth / (this.props.data.last_num - firstNum)) * this.state.points[l]) + 'px';
            bottomLabels.push(<div style={{left: leftPos}} className="numberLineMarker" key={"bottomL" + l}>|</div>);
        }

        if (!isNaN(firstNum)) {
            bottomLabels.push(<div style={{left: '0 px'}} key={"bottomLFirst"}>
                <div>{firstNum}</div>
            </div>);
        }

        if (!isNaN(this.props.data.last_num)) {
            bottomLabels.push(<div style={{left: this.state.lineWidth + 'px'}} key={"bottomLLast"}>
                <div>{this.props.data.last_num}</div>
            </div>);
        }

        this.setState({topLabels, bottomLabels});
    }

    componentDidMount() {
        this.setState({increment_count: Math.floor((this.props.data.last_num - this.props.data.first_num) / this.props.data.increment_interval)}, function () {
            this.doInterpolation();
            this.setupLedgends();
        });

        this.state.objRef = this.refs;
        window.addEventListener("resize", this.updateWidth);
        setTimeout(() => {
            this.setInitialNumber();
            this.updateWidth();
        }, 300);
        // this.updateWidth();
    }

    componentDidUpdate(prevProps) {
        if (this.refs.lineBG.offsetWidth !== this.state.lineWidth) {
            this.updateWidth()
        }

        if (this.props.readonly && (this.props.value !== prevProps.value || this.props.data.first_num !== prevProps.data.first_num || this.props.data.last_num !== prevProps.data.last_num || this.props.data.initial_pos !== prevProps.data.initial_pos || this.props.data.increment_interval !== prevProps.data.increment_interval || this.props.data.snap_divisions_per_interval !== prevProps.data.snap_divisions_per_interval)) {
            this.setInitialNumber()
            this.updateWidth()
        }
    }

    doInterpolation() {
        for (let l = 0; l < this.state.increment_count; l++) {
            this.state.points.push(l * this.props.data.increment_interval);
        }
    }

    getAnswerValue(position) {
        return ((position / this.state.lineWidth) * (this.props.data.last_num - this.props.data.first_num));
    }

    setHandlePos(mousePos) {
        let position = Math.min(Math.max(mousePos, 0), this.state.lineWidth);
        let incLevel = (1 / this.props.data.increment_interval) * this.props.data.snap_divisions_per_interval * this.state.increment_count;

        incLevel *= 100;

        position = this.transformA(position);
        this.refs.handle.style.left = position + 'px';

        let rawPosition = Number.parseFloat(((this.getAnswerValue(position) * incLevel) / incLevel) + this.props.data.first_num);
        rawPosition = rawPosition.toFixed(1 + Math.ceil(Math.log10(rawPosition)));
        if (!isNaN(Number(rawPosition))) {
            this.setState({readablePosition: Number(rawPosition)});
        }
    }

    onMouseMove(e) {
        e.preventDefault();
        let line = this.refs.lineBG;
        if (!line || !window.onmousemove) {
            return;
        }
        let xPos = e.pageX || e.changedTouches[0].pageX;
        this.state.startMousePos = -line.getBoundingClientRect().x;
        let mousePos = xPos + (this.state.startMousePos - (this.refs.handle.offsetWidth / 2));
        this.setHandlePos(mousePos);
    }

    transformA(inVal) {
        if (this.state.snap_division_width === 0) {
            inVal = 0;
        } else {
            inVal = Math.round(inVal / this.state.snap_division_width);
            inVal = Math.round(inVal * this.state.snap_division_width);
        }
        return inVal;
    }

    handleDown(e) {
        e.preventDefault();

        if (window.onmousemove) {
            return;
        }
        window.onmousemove = this.onMouseMove;
        window.onmouseup = this.onMouseUp;
        if ('ontouchstart' in window) {
            window.ontouchmove = this.onMouseMove;
            window.touchend = this.onMouseUp;
        }
    }

    onMouseUp(e) {
        e.preventDefault();

        window.onmousemove = null;
        window.onmouseup = null;
        if ('ontouchstart' in window) {
            window.touchmove = null;
            window.touchend = null;
        }

        this.setState({answer: this.state.readablePosition}, function () {
            if (this.props.updateAnswer) {
                this.props.updateAnswer(this.state.readablePosition);
            }
        });
    }

    updateWidth() {
        if (!this.state.objRef) {
            return;
        }
        this.setState({lineWidth: this.refs.lineBG.offsetWidth}, function () {
            this.setState({snap_division_width: this.state.lineWidth / ((this.props.data.last_num - this.props.data.first_num) * this.props.data.snap_divisions_per_interval)}, function () {
                var divSze = (this.state.lineWidth / (this.props.data.last_num - this.props.data.first_num));

                if (this.state.readablePosition !== null) {
                    this.setHandlePos(divSze * (this.state.readablePosition - this.props.data.first_num));
                }
                this.setupLedgends();
            });
        });
    }

    getEventHandlers() {
        if (this.props.readonly) {
            return {}
        }

        return {
            onMouseDown: this.handleDown,
            onTouchStart: (('ontouchstart' in window) ? this.handleDown : null),
            onTouchEnd: this.onMouseUp
        }
    }

    render() {
        return (
            <div>
                <div id="numberLineOuter" className="numberLineOuter">
                    <div>
                        <div id="topLabels">
                            {this.state.topLabels}
                        </div>
                        <div className="lineBG" id="lineBG" ref="lineBG">
                            <div className="leftArrow"/>
                            <div className="rightArrow"/>
                        </div>
                        <div className="handle" id="handle" ref="handle" {...this.getEventHandlers()}>
                            <div className="handle-inner">
                                <div className="handle-arrow"/>
                            </div>
                            <div className="handle-value">
                                {this.state.readablePosition}
                            </div>
                        </div>
                        <div id="bottomLabels">
                            {this.state.bottomLabels}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

NumberLine.defaultProps = {
    readonly: true
}

NumberLine.propType = {
    value: PropTypes.number.isRequired,
    data: PropTypes.shape({
        first_num: PropTypes.number,
        last_num: PropTypes.number,
        question_number: PropTypes.string,
        numberPoints: PropTypes.array,
        initial_pos: PropTypes.number,
        increment_interval: PropTypes.number,
        snap_divisions_per_interval: PropTypes.number
    }).isRequired,
    updateAnswer: PropTypes.func,
    readonly: PropTypes.bool
};

export default NumberLine;
