import React from 'react';

import BaseComponentProperty from "../index";

export default class Image extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="src">Link</label>
                    <textarea
                        id="src"
                        className='form-control'
                        value={this.props.component.data.src ? this.props.component.data.src : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.src = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="caption">Caption</label>
                    <textarea
                        id="caption"
                        className='form-control'
                        value={this.props.component.data.caption ? this.props.component.data.caption : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.caption = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }
}
