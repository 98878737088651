import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.standards);
    const current = useSelector(state => state.standards_current);
    const limit = useSelector(state => state.standards_limit);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">ID</th>
                    <th scope="col">Curriculum</th>
                    <th scope="col">Code</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Topic</th>
                    <th scope="col">Domain</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return <Row
                        key={item.id}
                        item={item}
                        row_number={getRowNumber(index, current, limit)}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
};
