import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

function ModeSelector({mode, setMode}) {
    const me = useSelector(state => state.me);
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    if (!!schedule && (schedule.status !== 'finished' || authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MASTER_RUBRICS]))) {
        return (
            <div className='container my-2'>
                <div className='d-flex justify-content-between'>
                    <button
                        className={'button ' + (mode === 'question' ? '' : 'inactive')}
                        onClick={event => {
                            event.preventDefault();
                            setMode('question');
                        }}
                    >By Question
                    </button>
                    <button
                        className={'button mx-2 ' + (mode === 'student' ? '' : 'inactive')}
                        onClick={event => {
                            event.preventDefault();
                            setMode('student');
                        }}
                    >By Student
                    </button>
                    <button
                        className={'button ' + (mode === 'preview' ? '' : 'inactive')}
                        onClick={event => {
                            event.preventDefault();
                            setMode('preview');
                        }}
                    >Preview
                    </button>
                </div>
            </div>
        );
    }

    return null;

}

ModeSelector.propTypes = {
    mode: PropTypes.string.isRequired,
    setMode: PropTypes.func.isRequired
};

export default ModeSelector;
