import Validator from "./Validator";

export default class JsonEntitiesEntityDataTitleValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'string',
      empty: false,
      mandatory: false
    };
  }
}
