import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, SyncRolesAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
        return (
            <button
                className='button'
                onClick={() => dispatch(SyncRolesAction())}
                data-toggle="tooltip"
                data-placement="top"
                title="Updates roles and all their permissions"
            >Sync all roles
            </button>
        );
    }

    return null;
}
