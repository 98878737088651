import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {contentUtils, HTMLContent} from "mindsets-js-sdk";

class DataTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: 0
        }
    }

    onSelect(selected) {
        return (e) => {
            e.preventDefault()
            this.setState({selected})
        }
    }

    getSelected() {
        return this.props.component_data.data.items[this.state.selected]
    }

    renderTabs() {
        const tabs = this.props.component_data.data.items.map((tab, i) => {
            return (
                <div
                    className={'datatabs-tabs-item ' + (i === this.state.selected ? 'datatabs-tabs-item-active' : '')}
                    key={i} onClick={this.onSelect(i)}>
                    {tab.title}
                </div>
            )
        })
        return (
            <div className="datatabs-tabs d-flex flex-wrap align-items-end">
                {tabs}
            </div>
        )
    }

    renderImage() {
        const tab = this.getSelected()
        const image_url = tab.image_url
        const image_alt = tab.image_alt

        if (!image_url) {
            return null
        }

        return (
            <div className="datatabs-content-image">
                <img src={image_url} alt={image_alt}/>
            </div>
        )
    }

    renderContent() {
        const tab = this.getSelected()
        return (
            <div className="datatabs-content">
                <HTMLContent html_str={contentUtils.processStringContent(tab.html_content_1)}/>
                {this.renderImage()}
                <HTMLContent html_str={contentUtils.processStringContent(tab.html_content_2)}/>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-datatabs">
                {this.renderTabs()}

                {this.renderContent()}
            </div>
        )
    }
}

DataTabs.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default DataTabs
