import React, {useState} from 'react';
import PropTypes from "prop-types";
import {contentUtils} from "mindsets-js-sdk";

import FractionPart from './FractionPart';

import './fraction.scss';


function Fraction(
    {
        component_data,
        correct_answer = {
            text: '',
            answer_selections: []
        },
        onCorrectAnswerChange
    }
) {
    const [answer, setAnswer] = useState(!Array.isArray(correct_answer) ? correct_answer : defaultAnswer());

    function defaultAnswer() {
        const items = _.get(component_data, 'data.items');
        const fractions = _.filter(items, {type: 'fraction'});
        const answer_selections = fractions.map((fractionData) => {
            return _.mapValues(fractionData.inputs, () => '');
        });
        return {
            text: '',
            answer_selections
        }
    }

    function onChange(fractionKey) {
        return (fieldKey) => e => {
            e.preventDefault();
            const regex = /^[0-9]*$/;
            const v = e.target.value;
            if (v.match(regex)) {
                const newAnswerSelections = _.cloneDeep(answer.answer_selections);
                _.set(newAnswerSelections, [fractionKey, fieldKey], v);
                const newText = getAnswerText(newAnswerSelections);
                const newAnswer = {
                    text: newText,
                    answer_selections: newAnswerSelections
                }
                onCorrectAnswerChange(newAnswer);
                setAnswer(newAnswer);
            }
        }
    }

    function fractionPart(fractionData, fractionKey, inputKey, answer_selections) {
        const text = _.get(fractionData, ['texts', inputKey, 'content', 'text']);
        const answer = _.get(answer_selections, [fractionKey, inputKey], '');
        return text ?? answer;
    }

    function getAnswerText(answer_selections) {
        const isNotEmpty = !!_.find(answer_selections, (fraction) => {
            const front = _.get(fraction, 'front');
            const top = _.get(fraction, 'top');
            const bottom = _.get(fraction, 'bottom');
            return !!front || !!top || !!bottom;
        });
        if (!isNotEmpty) {
            return '';
        }

        const items = _.get(component_data, 'data.items', []);
        let fractionKey = -1;
        const textArray = items.map((item) => {
            if (item.type === 'fraction') {
                fractionKey++;
                // e.g. 5 frac{1}{2}
                const front = fractionPart(item, fractionKey, 'front', answer_selections);
                const top = fractionPart(item, fractionKey, 'top', answer_selections);
                const bottom = fractionPart(item, fractionKey, 'bottom', answer_selections);
                return `${front} frac{${top}}{${bottom}}`;
            } else {
                return _.get(item, 'content.text');
            }
        })
        return textArray.join('');
    }

    function renderItem(itemData, fractionKey) {
        switch (itemData.type) {
            case 'hidden':
                return null;
            case 'static':
                const content = _.get(itemData, 'content.html');
                return (
                    <div className="cc-numberonly-item-static" style={itemData.style}>
                        {contentUtils.processStringContent(content)}
                    </div>
                );
            case 'fraction':
                return (
                    <FractionPart
                        component_data={component_data}
                        answer={answer}
                        existing_answer={correct_answer}
                        onChange={onChange(fractionKey)}
                        itemData={itemData}
                        fractionKey={fractionKey}
                    />
                );
            default:
                return null;
        }
    }

    function renderAllItems() {
        const items = _.get(component_data, 'data.items', []);
        let fractionKey = -1;
        return items.map((itemData, itemKey) => {
            if (itemData.type === 'fraction') {
                fractionKey++;
            }
            return (
                <div key={itemKey}>
                    {renderItem(itemData, fractionKey)}
                </div>
            );
        });
    }

    return (
        <div className="challenge-component-fraction">
            <div className="cc-fraction-description">
                {`Enter a number in each box below.`}
            </div>

            <div className="d-flex flex-wrap align-items-center">
                {renderAllItems()}
            </div>
        </div>
    );
}

Fraction.propTypes = {
    component_data: PropTypes.object.isRequired,
    correct_answer: PropTypes.oneOfType([
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            answer_selections: PropTypes.array.isRequired
        }),
        PropTypes.array
    ]).isRequired,
    onCorrectAnswerChange: PropTypes.func.isRequired
}

export default Fraction
