import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {MultipleSelector, NumericValueField} from "mindsets-js-sdk";

import Graphing from "./Graphing";
import Matching from "./Matching";
import Fraction from "./Fraction";
import {SetQuestionPracticeSkillsUpdatedAction} from "../../actions/question_actions";
import Selector from "../../common/Selector";

//TODO implement
// answer.number_only
// answer.dnd_puzzle
function CorrectAnswer({question}) {
    const dispatch = useDispatch();
    const polls = useSelector(state => state.polls);
    const selected_practice_id = useSelector(state => state.selected_practice_id);
    const question_practice_skills_updated = useSelector(state => state.question_practice_skills_updated);
    const question_practice_skill = question_practice_skills_updated.find(skill => skill.question_code === question.code && skill.practice_id === selected_practice_id);

    function onCorrectAnswerChange(correct_answer) {
        const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
        if (Array.isArray(correct_answer) && correct_answer.length > 0 || Object.isExtensible(correct_answer) && Object.keys(correct_answer).length > 0) {
            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(skill => skill.question_code === question.code && skill.practice_id === selected_practice_id)].correct_answer = correct_answer;
        } else {
            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(skill => skill.question_code === question.code && skill.practice_id === selected_practice_id)].correct_answer = null;
        }
        dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
    }

    if ((question.answer_type === 'answer.choice_single' || question.answer_type === 'answer.choice_multiple') && question.options) {
        let options;
        if (Array.isArray(question.options)) {
            options = question.options;
        } else {
            options = Object.keys(question.options).map(option_index => question.options[option_index]);
        }
        return (
            <MultipleSelector
                item_name='option'
                trigger={<div
                    className='bordered little'>{question_practice_skill.correct_answer && Array.isArray(question_practice_skill.correct_answer) && question_practice_skill.correct_answer.length > 0 ? question_practice_skill.correct_answer.map(i => 'Option ' + i).sort().join(', ') : 'Please select'}</div>}
                items={options.map((option, index) => ({
                    id: index,
                    label: <span dangerouslySetInnerHTML={{__html: option}}/>
                }))}
                selected={question_practice_skill.correct_answer && Array.isArray(question_practice_skill.correct_answer) ? question_practice_skill.correct_answer : []}
                callback={onCorrectAnswerChange}
                show_all_option={false}
                match_dropdown_width={false}
            />
        );
    } else if (question.answer_type === 'poll.inline') {
        const poll = polls.find(poll => poll.question_id === question.id);
        if (!poll) {
            return null;
        }
        const poll_new = JSON.parse(JSON.stringify(poll));

        return (
            <div className='d-flex flex-column flex-gap-5'>
                {poll_new.poll_options.map(poll_option => {
                    let correct_answer = JSON.parse(JSON.stringify(question_practice_skill.correct_answer));
                    if (!Array.isArray(correct_answer)) {
                        correct_answer = [];
                    }
                    const correct_answer_option_index = correct_answer.findIndex(o => o.poll_option_id === poll_option.id);
                    const correct_answer_option = correct_answer[correct_answer_option_index];

                    return (
                        <div key={poll_option.id} className='d-flex flex-gap-5 align-items-center'>
                            <div dangerouslySetInnerHTML={{__html: poll_option.option}} className='w-100'/>
                            <input
                                className='form-control flex-shrink-1 w-auto'
                                value={correct_answer_option && !isNaN(parseInt(correct_answer_option.points.toString())) ? correct_answer_option.points.toString() : ''}
                                onChange={event => {
                                    const value = event.target.value;
                                    if (!isNaN(parseInt(value))) {
                                        if (correct_answer_option) {
                                            correct_answer[correct_answer_option_index].points = parseInt(value)
                                        } else {
                                            correct_answer.push({
                                                poll_option_id: poll_option.id,
                                                points: parseInt(value)
                                            });
                                        }
                                    } else {
                                        correct_answer.splice(correct_answer_option_index, 1);
                                    }
                                    onCorrectAnswerChange(correct_answer);
                                }}/>
                        </div>
                    );
                })}
            </div>
        );
    } else if (question.answer_type === 'answer.slider' || question.answer_type === 'answer.range' || question.answer_type === 'answer.number_line') {
        let correct_answer = !!question_practice_skill.correct_answer && !Array.isArray(question_practice_skill.correct_answer) && Object.isExtensible(question_practice_skill.correct_answer) ? JSON.parse(JSON.stringify(question_practice_skill.correct_answer)) : {};

        return (
            <div className='d-flex justify-content-between'>
                <div>
                    <label className='form-label' htmlFor='from'>Min</label>
                    <NumericValueField
                        callback={value => {
                            if (value === null) {
                                delete correct_answer.min;
                            } else {
                                correct_answer.min = value;
                            }
                            onCorrectAnswerChange(correct_answer);
                        }}
                        initial={correct_answer ? correct_answer.min : null}
                    />
                </div>
                <div>
                    <label className='form-label' htmlFor='to'>Max</label>
                    <NumericValueField
                        callback={value => {
                            if (value === null) {
                                delete correct_answer.max;
                            } else {
                                correct_answer.max = value;
                            }
                            onCorrectAnswerChange(correct_answer);
                        }}
                        initial={correct_answer ? correct_answer.max : null}
                    />
                </div>
            </div>
        );
    } else if (question.answer_type === 'answer.dnd') {
        return (
            <div>
                <input
                    type="text"
                    className="form-control"
                    value={question_practice_skill.correct_answer && question_practice_skill.correct_answer.text ? question_practice_skill.correct_answer.text : ''}
                    onChange={event => {
                        let correct_answer = null;
                        if (event.target.value) {
                            correct_answer = {
                                text: event.target.value
                            }
                        }
                        onCorrectAnswerChange(correct_answer);
                    }}
                />
            </div>
        );
    } else if (question.answer_type === 'answer.selector') {
        let existing_answer = undefined;
        if (question_practice_skill && !!question_practice_skill.correct_answer && !!question_practice_skill.correct_answer.answer_selections) {
            existing_answer = {
                data: {
                    answer: question_practice_skill.correct_answer
                }
            }
        }

        return (
            <div>
                <Selector
                    component_data={{data: question.answer_component_data}}
                    existing_answer={existing_answer}
                    callback={onCorrectAnswerChange}
                />
            </div>
        );
    } else if (question.answer_type === 'answer.graphing') {
        return (
            <div>
                <div className="modal fade" id={'graphing-modal-' + question.id} tabIndex="-1" role="dialog"
                     aria-labelledby="graphingModal" aria-hidden="true">
                    <div className="modal-dialog" role="document" style={{"maxWidth": "initial"}}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <Graphing
                                    question_id={question.id}
                                    correct_answer={question_practice_skill.correct_answer ? question_practice_skill.correct_answer : null}
                                    onCorrectAnswerChange={onCorrectAnswerChange}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">Dismiss
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className='button'
                    data-bs-toggle="modal"
                    data-bs-target={'#graphing-modal-' + question.id}
                    onClick={event => event.preventDefault()}
                >
                    Open graph builder
                </button>
            </div>
        );
    } else if (question.answer_type === 'answer.matching') {
        return (
            <div>
                <Matching
                    component_data={{data: question.answer_component_data}}
                    correct_answer={question_practice_skill.correct_answer ? question_practice_skill.correct_answer : null}
                    onCorrectAnswerChange={onCorrectAnswerChange}
                />
            </div>
        );
    } else if (question.answer_type === 'answer.fraction') {
        return (
            <div>
                <Fraction
                    component_data={{data: question.answer_component_data}}
                    correct_answer={question_practice_skill.correct_answer ? question_practice_skill.correct_answer : null}
                    onCorrectAnswerChange={onCorrectAnswerChange}
                />
            </div>
        );
    }

    return (
        <div>N/A</div>
    );
}

CorrectAnswer.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        answer_type: PropTypes.string.isRequired,
        options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        answer_component_data: PropTypes.object
    }).isRequired
}

export default CorrectAnswer;
