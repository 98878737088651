import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {filterConstants} from "mindsets-js-sdk";

const TeachersCount = function ({item}) {
    if (item.teachers_count > 0) {
        return (
            <Link
                to={`/users?column~0=school_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${item.id}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Show all the teachers in the school">{item.teachers_count}</Link>
        );
    } else {
        return item.teachers_count;
    }
}

TeachersCount.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        teachers_count: PropTypes.number.isRequired
    }).isRequired
};

export default TeachersCount;
