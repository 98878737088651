import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ErrorMessageAction, LoadingAction, SyncCurriculaAction, ReloadListAction} from "mindsets-js-sdk";


export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CURRICULUMS])) {
        return (
            <button className='button' onClick={event => {
                event.preventDefault();
                dispatch(SyncCurriculaAction()).then(() => dispatch(ReloadListAction(true, 'curricula'))).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error));
                    console.error(error);
                });
            }}>Sync</button>
        );
    }

    return null;
}
