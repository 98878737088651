import React from 'react';
import {contentUtils} from "mindsets-js-sdk";

import BaseCard from './BaseCard';

export default class UnlockCard extends BaseCard {
    getCardType() {
        return 'unlock';
    }

    getMinHeight() {
        return '400px';
    }

    cardHasComponents() {
        return false;
    }

    renderBadge() {
        return (
            <div className="unlock-card-badge text-center">
                <img src={`assets.go.mindsets.com/badges/bbz.png`} alt='badge'/>
            </div>
        );
    }

    renderContent() {
        const {content1} = this.props.page.data
        if (content1) {
            return (
                <div className="section">
                    <div className="unlock-card-content">
                        <div className="d-flex flex-row align-items-center justify-content-center text-center">
                            {contentUtils.processStringContent(content1)}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderOtherContent() {
        return (
            <div className="">
                <div className="section"/>

                <div className="section">
                    <h2 className="header-new text-center">Congratulations!</h2>
                </div>

                {this.renderBadge()}

                <h5 className="header-new text-center">Now, enter your bonus questions...</h5>

                {this.renderContent()}
            </div>
        );
    }
}
