import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Checkbox} from "mindsets-js-sdk";

import {objectToStyleString, parseStyleString} from "../../utils";

class SpreadsheetCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: props.cell.style ? objectToStyleString(props.cell.style) : ''
        }
    }

    renderStyles() {
        if (this.props.is_header) {
            const cell = this.props.cell;

            return (
                <div className="row">
                    <label htmlFor={"style"} className="col-sm-1 col-form-label">Styles</label>
                    <div className="col-sm-11">
                        <textarea
                            className="form-control"
                            id={"style"}
                            value={this.state.style}
                            onChange={event => {
                                const style = event.target.value;
                                this.setState({style}, () => {
                                    cell.style = parseStyleString(style);
                                    this.props.updateCell(cell);
                                });
                            }}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    renderPlaceholder(cell) {
        if (!!cell && cell.hasOwnProperty('editable') && cell.editable === true) {
            return (
                <div>
                    <label htmlFor="spreadsheet-cell-placeholder">Placeholder(optional)</label>
                    <textarea
                        id="spreadsheet-cell-placeholder"
                        className='form-control'
                        value={cell.placeholder ? cell.placeholder : ''}
                        onChange={event => {
                            cell.placeholder = event.target.value;
                            this.props.updateCell(cell);
                        }}
                    />
                </div>
            );
        }

        return null;
    }

    renderOther() {
        if (!this.props.is_header) {
            const cell = this.props.cell;

            return (
                <div>
                    <Checkbox
                        label='Editable?'
                        toggle={() => {
                            cell.editable = cell.hasOwnProperty('editable') ? !cell.editable : true;
                            if (cell.editable !== true) {
                                delete (cell.placeholder);
                            }
                            this.props.updateCell(cell);
                        }}
                        checked={cell.hasOwnProperty('editable') ? cell.editable : false}
                    />
                    <div>
                        <label htmlFor="image">Image URL(optional)</label>
                        <textarea
                            id="image"
                            className='form-control'
                            value={cell.image ? cell.image : ''}
                            onChange={event => {
                                cell.image = event.target.value;
                                this.props.updateCell(cell);
                            }}
                        />
                    </div>
                    {this.renderPlaceholder(cell)}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div style={{position: 'relative'}} className='bordered'>
                <i
                    className='fas fa-trash text-danger clickable'
                    style={{position: 'absolute', right: '5px', top: '5px'}}
                    onClick={this.props.deleteCell}
                />
                {this.renderStyles()}
                {this.renderOther()}
            </div>
        );
    }
}

SpreadsheetCell.propTypes = {
    cell: PropTypes.object.isRequired,
    updateCell: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    is_header: PropTypes.bool.isRequired
};

export default SpreadsheetCell;
