import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    filterConstants,
    SearchResult,
    authUtils,
    Paginator,
    LoadQuestionsAction,
    LoadedQuestionsAction,
    LoadedResponsesAction,
    LoadResponsesAction
} from "mindsets-js-sdk";

import Table from "./components/Table";
import NoAccess from "../../common/NoAccess";
import Filters from "./components/Filters";
import Question from "./components/Question";
import {LoadPracticesAction} from "../../actions/response_actions";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const questions = useSelector(state => state.questions);
    const responses_current = useSelector(state => state['responses_current']);
    const responses_limit = useSelector(state => state['responses_limit']);
    const [challenge_version, setChallengeVersion] = useState({id: null});
    const [question_id, setQuestionId] = useState('');
    const [practice_id, setPracticeId] = useState(1);
    const [graded, setGraded] = useState(false);
    const [with_trashed, setWithTrashed] = useState(false);
    const [with_scores, setWithScores] = useState(true);
    const [lesson_code, setLessonCode] = useState('');
    const action = function (current = null) {
        if (question_id) {
            const question = questions.find(question => question.id === question_id);
            if (question) {
                let options = {
                    'filters[question_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + question.id
                };
                if (with_scores) {
                    options['selectors[0]'] = 'with_score';
                }
                if (lesson_code) {
                    options['filters[lesson_code][0]'] = filterConstants.EXPRESSION_IN + '=' + lesson_code
                }
                if (practice_id) {
                    options['filters[practice_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + practice_id
                }
                if (with_trashed) {
                    options['filters[with_trashed][0]'] = filterConstants.EXPRESSION_TRUE
                }
                if (graded) {
                    options['filters[graded][0]'] = filterConstants.EXPRESSION_TRUE
                }
                options.current = current ? current : responses_current;
                options.limit = responses_limit;
                dispatch(LoadResponsesAction(options));
            }
        }
    }

    useEffect(() => {
        if (challenge_version.id) {
            setQuestionId('');
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadQuestionsAction({
                order_by: 'number',
                order_direction: 'asc',
                'filters[challenge_version_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + challenge_version.id,
                'selectors[0]': 'poll_poll_options',
                'selectors[1]': 'spreadsheet'
            }));
        }
    }, [challenge_version.id]);
    useEffect(() => {
        dispatch(LoadedResponsesAction([]));
    }, [question_id]);
    useEffect(() => {
        dispatch(LoadPracticesAction());
    }, []);
    useEffect(() => {
        action();
    }, [responses_current, responses_limit]);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_SEE_ANSWER_REVIEW])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <h1>Answers Review</h1>
            <Filters
                action={action}
                question_id={question_id}
                setQuestionId={setQuestionId}
                practice_id={practice_id}
                setPracticeId={setPracticeId}
                setChallengeVersion={setChallengeVersion}
                graded={graded}
                setGraded={setGraded}
                with_trashed={with_trashed}
                setWithTrashed={setWithTrashed}
                with_scores={with_scores}
                setWithScores={setWithScores}
                lesson_code={lesson_code}
                setLessonCode={setLessonCode}

            />
            <Question question_id={question_id}/>
            <div className='section'>
                <SearchResult entity='responses'/>
                <Table with_scores={with_scores}/>
                <Paginator entity='responses'/>
            </div>
        </>
    );
}
