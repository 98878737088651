import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: ['string', 'object'],
            empty: false,
            mandatory: false,
            attributes: {
                text: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                align: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                }
            }
        };
    }
}
