import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleAlignValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'string',
            empty: false,
            mandatory: false,
        };
    }
}
