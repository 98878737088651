import React from 'react';

import Expression from "./Expression";

export default class ExpressionMulti extends Expression {
    hasConfigs() {
        return true;
    }

    renderPrefill() {
        return (
            <div className='bordered'>
                <h4>Prefill</h4>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let prefill = data.prefill;
                    prefill.push('');
                    data.items = prefill;
                    this.props.onChange('data', data);
                }}>+
                </button>
                <div className=''>
                    {this.props.component.data.prefill.map((item, index) => {
                        return (
                            <div key={index} style={{position: 'relative'}}>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '-7px', top: '17px'}}
                                    onClick={() => {
                                        let data = this.props.component.data;
                                        data.prefill.splice(index, 1);
                                        this.props.onChange('data', data);
                                    }}
                                />
                                <div>
                                    <label htmlFor={"prefill" + index}>{index + 1}</label>
                                    <input
                                        type='text'
                                        id={"prefill" + index}
                                        className='form-control'
                                        value={item ? item : ''}
                                        onChange={event => {
                                            let data = this.props.component.data;
                                            data.prefill[index] = event.target.value;
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
