import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, DeleteSchoolAction, ReloadListAction} from "mindsets-js-sdk";

const DeleteButton = function ({id}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_SCHOOLS])) {
        return (
            <button
                className='button red'
                onClick={() => {
                    if (confirm('Are you sure you want to delete this School?')) {
                        dispatch(DeleteSchoolAction(id)).then(() => dispatch(ReloadListAction(true, 'schools')));
                    }
                }}
            >
                <i className='fas fa-trash'/>
            </button>
        );
    }

    return null;
}

DeleteButton.propTypes = {
    id: PropTypes.number.isRequired
}

export default DeleteButton;
