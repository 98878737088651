import React from 'react';
import {CustomDropdown} from "mindsets-js-sdk";
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderIndexAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";
import {CHALLENGE_PAGES} from "../../index";
import Sortable from "./Sortable";

export default function () {
    const dispatch = useDispatch();
    const page_index = useSelector(state => state.challenge_builder_index);
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const mode = useSelector(state => state.challenge_builder_mode);

    function getPages() {
        const items = {}

        Object.keys(CHALLENGE_PAGES).filter(key => mode === 'pd' ? (['intro', 'question', 'complete', 'unlock', 'final'].includes(key)) : true).map(key => {
            items[key] = renderPage(key);
        });

        return items;
    }

    function renderPage(key) {
        return (
            <a onClick={() => {
                let challenge_pages = JSON.parse(JSON.stringify(pages));
                challenge_pages.splice(page_index + 1, 0, CHALLENGE_PAGES[key]);
                dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages));
                if (pages.length > 0) {
                    dispatch(SetChallengeBuilderIndexAction(page_index + 1));
                }
                dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
            }}>{_.upperFirst(key)}</a>
        );
    }

    return (
        <div className='d-flex justify-content-between mb-2'>
            <div className='d-flex flex-grow-1' style={{overflowX: 'clip', minWidth: 0}}>
                <Sortable/>
                <div className='navigation-card narrow'>
                    <div className={'card position-relative '}>
                        <CustomDropdown
                            trigger={(
                                <div className='card-body d-flex justify-content-center align-items-center'>
                                    <i className='fas fa-plus'/>
                                </div>
                            )}
                            no_wrap={true}
                            open_on_mouse_over={true}
                            close_on_mouse_leave={true}
                            items={getPages()}
                            use_arrow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
