import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    accessConstants,
    filterConstants,
    authUtils,
    HTMLContent,
    contentUtils,
    GetRubricPDFDataAction,
    LoadChallengeCodeAction,
    LoadQuestionsAction,
    LoadPollsAction,
    LoadedChallengeCodeAction,
    LoadedQuestionsAction,
    LoadedPollsAction,
    LoadQuestionPracticeSkillsAction,
    LoadedPracticeAction,
    LoadPracticeAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import EditButton from "./components/EditButton";
import CorrectAnswer from "./components/CorrectAnswer";
import Summary from "./components/Summary";
import SummaryBonus from "./components/SummaryBonus";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const params = useParams();
    const challenge_code = useSelector(state => state.challenge_code);
    const practice = useSelector(state => state.practice);
    const questions = useSelector(state => state.questions);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    useEffect(() => {
        dispatch(LoadChallengeCodeAction(params.challenge_code, {'selectors[0]': 'latest_challenge_version_name'}));
        dispatch(LoadPracticeAction(params.practice_code, {'selectors[0]': 'practice_skills'})).then(practice => {
            dispatch(LoadQuestionPracticeSkillsAction({
                'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
                'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + practice.id,
                'selectors[0]': 'points',
                'selectors[1]': 'questions',
                limit: 0
            }));
        });
        dispatch(LoadQuestionsAction({
            'selectors[0]': 'by_code',
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            order_by: 'number'
        }));
        dispatch(LoadPollsAction({
            'filters[challenge_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.challenge_code,
            'filters[type][0]': filterConstants.EXPRESSION_EQUAL + '=inline',
            'selectors[0]': 'poll_options',
            'limit': 0
        }));

        return () => {
            dispatch(LoadedPracticeAction(null));
            dispatch(LoadedChallengeCodeAction(null));
            dispatch(LoadedQuestionsAction([]));
            dispatch(LoadedPollsAction([]));
        }
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_SEE_RUBRICS])) {
        return (
            <NoAccess/>
        );
    }
    if (!challenge_code || !practice || !question_practice_skills.length || !questions.length) {
        return null;
    }

    return (
        <div className='container section'>
            <h1>{practice.name} Rubric for the challenge {challenge_code.latest_challenge_version_name}</h1>
            <div className='d-flex justify-content-end flex-gap-5'>
                <EditButton/>
                <button
                    className='button'
                    onClick={event => {
                        event.preventDefault();
                        dispatch(GetRubricPDFDataAction(params.challenge_code, params.practice_code)).then(buffer => {
                            let a = document.createElement('a');
                            const blob = new Blob([buffer], {'type': 'application/pdf'});
                            a.href = window.URL.createObjectURL(blob);
                            a.download = challenge_code.latest_challenge_version_name + ' rubric.pdf';
                            a.click();
                            window.close();
                        });
                    }}>Download PDF
                </button>
            </div>
            <div>
                {questions.map(question => {
                    const question_practice_skill = question_practice_skills.find(qps => qps.question_code === question.code);
                    if (question_practice_skill) {
                        const practice_skill = practice.practice_skills.find(practice_skill => question_practice_skill.practice_skill_id === practice_skill.id);
                        if (practice_skill) {
                            return (
                                <div className="table-responsive mb-4" key={question.id}>
                                    <table className='table table-bordered table-sm'>
                                        <tbody>
                                        <tr>
                                            <td colSpan={question_practice_skill.points.length > 1 ? question_practice_skill.points.length + 1 : 2}>
                                                <HTMLContent
                                                    html_str={contentUtils.processStringContent((question.number ? 'Q' + question.number : '') + (question.is_bonus ? '(BONUS)' : '') + ' ' + question.title, {convert_spelling: false})}/>
                                                <div>{question.answer_type}</div>
                                                <div>Category: <strong>{practice_skill.code + ' ' + practice_skill.description}</strong>
                                                </div>
                                                <div><strong>DOK: {question_practice_skill.dok}</strong>
                                                </div>
                                                <div>Skill: {question_practice_skill.skill}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Correct answer</td>
                                            <td colSpan={question_practice_skill.points.length}>
                                                <CorrectAnswer question={question}
                                                               question_practice_skill={question_practice_skill}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Depends on</td>
                                            <td colSpan={question_practice_skill.points.length}>
                                                {Array.isArray(question_practice_skill.questions) && question_practice_skill.questions.length ? questions.filter(q => question_practice_skill.questions.includes(q.id)).map(q => 'Q' + q.number).join(', ') : 'Is not dependant'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={question_practice_skill.points.length > 1 ? null : 2}>Example
                                                Solution
                                            </td>
                                            {question_practice_skill.points.map(point => {
                                                return (
                                                    <td key={point.id}>
                                                        {point.point} point{point.point === 1 ? '' : 's'}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td colSpan={question_practice_skill.points.length > 1 ? null : 2}>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: question_practice_skill.example_solution}}
                                                    style={{'whiteSpace': 'pre-wrap'}}/>
                                            </td>
                                            {question_practice_skill.points.map(point => {
                                                return (
                                                    <td key={point.id}>{point.description}</td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td colSpan={question_practice_skill.points.length > 1 ? question_practice_skill.points.length + 1 : 2}>
                                                Notes: {question_practice_skill.notes}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            );
                        } else {
                            return 'Data is still loading. Please wait.';
                        }
                    } else {
                        return (
                            <div key={question.id} className={'bordered mb-4'}>No data for Q{question.number}</div>
                        );
                    }
                })}
            </div>
            <Summary/>
            <SummaryBonus/>
        </div>
    );
}
