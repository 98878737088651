import React, {useCallback, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
    UserDropdown,
    ListPage,
    filterConstants,
    accessConstants,
    countriesConstants,
    LoadResponsesAction,
    LoadPracticesAction,
    LessonDropdown,
    ChallengeDropdown,
    SchoolDropdown,
    catchQueryFilters,
    DatePicker,
    LoadedResponsesAction,
    QuestionDropdown,
    DistrictDropdown, StateField
} from "mindsets-js-sdk";

import Table from "./Table";

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoadPracticesAction());

        return () => {
            dispatch(LoadedResponsesAction([]))
        }
    }, []);

    const actionDebounced = useCallback(_.debounce(a => a(), 600), []);

    return (
        <>
            <h1>Student Responses</h1>
            <ListPage
                action={options => LoadResponsesAction(Object.assign({
                    'selectors[0]': 'response_auto_points',
                    'selectors[2]': 'response_points',
                    'selectors[3]': 'user',
                    'selectors[4]': 'question_poll_spreadsheet',
                    'selectors[5]': 'lesson_challenge_code',
                }, options))}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'country',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: Object.keys(countriesConstants.COUNTRIES).map(code => {
                            return {
                                label: countriesConstants.COUNTRIES[code],
                                value: code
                            }
                        })
                    },
                    {
                        key: 'state',
                        name: 'State',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];
                            const country_filter = filters.find(filter => filter.column === 'country');

                            return (
                                <StateField
                                    value={filter.value}
                                    disabled={!country_filter || !country_filter.value}
                                    onChange={state => {
                                        if (state) {
                                            setFilterValue(state);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return state ? state : '';
                                    }}
                                    country={country_filter && country_filter.value ? country_filter.value : null}
                                    label={false}
                                />
                            );
                        },
                    },
                    {
                        key: 'user_id',
                        name: 'Student',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const district_id_filter = filters.find(filter => filter.column === 'district_id');
                            const school_id_filter = filters.find(filter => filter.column === 'school_id');
                            const country_filter = filters.find(filter => filter.column === 'country');
                            const state_filter = filters.find(filter => filter.column === 'state');
                            const lesson_id_filter = filters.find(filter => filter.column === 'lesson_id');

                            return (
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setFilterValue(user.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    persona='student'
                                    district_id={district_id_filter && district_id_filter.value ? parseInt(district_id_filter.value) : null}
                                    school_id={school_id_filter && school_id_filter.value ? parseInt(school_id_filter.value) : null}
                                    country={country_filter && country_filter.value ? country_filter.value : null}
                                    state={state_filter && state_filter.value ? state_filter.value : null}
                                    lesson_id={lesson_id_filter && lesson_id_filter.value ? parseInt(lesson_id_filter.value) : null}
                                    debounce={actionDebounced}
                                    show_counts={true}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'district_id',
                        name: 'School District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const country_filter = filters.find(filter => filter.column === 'country');
                            const state_filter = filters.find(filter => filter.column === 'state');

                            return (
                                <DistrictDropdown
                                    id='responses-district-filter'
                                    onSelect={district => {
                                        if (district) {
                                            setFilterValue(district.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return district ? district.name : '';
                                    }}
                                    country={country_filter && country_filter.value ? country_filter.value : null}
                                    state={state_filter && state_filter.value ? state_filter.value : null}
                                    show_counts={true}
                                    debounce={actionDebounced}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'lesson_id',
                        name: 'Class',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const challenge_code_id_filter = filters.find(filter => filter.column === 'challenge_code_id');
                            const district_id_filter = filters.find(filter => filter.column === 'district_id');
                            const school_id_filter = filters.find(filter => filter.column === 'school_id');
                            const country_filter = filters.find(filter => filter.column === 'country');
                            const state_filter = filters.find(filter => filter.column === 'state');

                            return (
                                <LessonDropdown
                                    onSelect={lesson => {
                                        if (lesson) {
                                            setFilterValue(lesson.id);

                                        } else {
                                            setFilterValue('');
                                        }

                                        return lesson ? lesson.code : '';
                                    }}
                                    id='responses-lesson-filter'
                                    challenge_code_id={challenge_code_id_filter && challenge_code_id_filter.value ? parseInt(challenge_code_id_filter.value) : null}
                                    district_id={district_id_filter && district_id_filter.value ? parseInt(district_id_filter.value) : null}
                                    school_id={school_id_filter && school_id_filter.value ? parseInt(school_id_filter.value) : null}
                                    country={country_filter && country_filter.value ? country_filter.value : null}
                                    state={state_filter && state_filter.value ? state_filter.value : null}
                                    debounce={actionDebounced}
                                    show_counts={true}
                                />
                            );
                        },
                        multiple: false,
                        depends_on: 'challenge_code_id'
                    },
                    {
                        key: 'challenge_code_id',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown
                                    onSelect={challenge_version => {
                                        if (challenge_version) {
                                            setFilterValue(challenge_version.challenge_code_id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return challenge_version ? challenge_version.name : '';
                                    }}
                                    id='responses-challenge-filter'
                                    debounce={actionDebounced}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const district_id_filter = filters.find(filter => filter.column === 'district_id');
                            const country_filter = filters.find(filter => filter.column === 'country');
                            const state_filter = filters.find(filter => filter.column === 'state');

                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='responses-district-filter'
                                    district_id={district_id_filter && district_id_filter.value ? parseInt(district_id_filter.value) : null}
                                    country={country_filter && country_filter.value ? country_filter.value : null}
                                    state={state_filter && state_filter.value ? state_filter.value : null}
                                    debounce={actionDebounced}
                                    show_counts={true}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'question_code',
                        name: 'Question',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            const filters = catchQueryFilters();
                            const challenge_code_id_filter = filters.find(filter => filter.column === 'challenge_code_id');

                            return (
                                <QuestionDropdown
                                    onSelect={question => {
                                        if (question) {
                                            setFilterValue(question.code);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return question ? ('Q' + question.number + ' ' + question.title) : '';
                                    }}
                                    id='responses-question-filter'
                                    challenge_code_id={challenge_code_id_filter && challenge_code_id_filter.value ? parseInt(challenge_code_id_filter.value) : null}
                                    debounce={actionDebounced}
                                    show_counts={true}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'created_at',
                        name: 'Created At',
                        expressions: [filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];
                            return (
                                <DatePicker
                                    date={filter && filter.value ? filter.value : null}
                                    onChange={setFilterValue}/>
                            );
                        },
                    },
                    {
                        key: 'graded',
                        name: 'Graded only',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'with_trashed',
                        name: 'Include deleted',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'user_id',
                        name: 'Student ID'
                    }
                ]}
                entity={'responses'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_ALL_STREAMS]}
                default_order_direction={'desc'}
            >
                <Table/>
            </ListPage>
        </>
    );
}
