import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_STREAMS_LESSON = '[streams] Loaded Lesson';
export const LOADED_STREAMS_ROLES = '[streams] Loaded Roles';

export const LoadRolesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('roles', options))
        .then(response => dispatch(LoadedRolesAction(response.items)));
};
export const LoadedRolesAction = payload => ({
    type: LOADED_STREAMS_ROLES,
    payload
});

export const LoadLessonAction = (lesson_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get(`lessons/${lesson_id}`, options))
        .then(response => dispatch(LoadedLessonAction(response.lesson)));
};
export const LoadedLessonAction = payload => ({
    type: LOADED_STREAMS_LESSON,
    payload
});
