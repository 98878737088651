import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {AddClassCodesToSelectionAction} from "mindsets-js-sdk";

const Results = function ({lessons, setLessons, setSelectedLessonCodesString, assign}) {
    const dispatch = useDispatch();
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const renderAction = function () {
        let is_all_valid = true;
        Object.keys(lessons).map(lesson_code => {
            if (!lessons[lesson_code].is_valid) {
                is_all_valid = false;
            }
        });
        if (is_all_valid) {
            return (
                <button onClick={event => {
                    event.preventDefault();
                    assign();
                }}>Assign grade level for all selected classes</button>
            );
        } else {
            return (
                <button onClick={event => {
                    event.preventDefault();
                    let selected_lesson_codes_new = JSON.parse(JSON.stringify(selected_lesson_codes));
                    Object.keys(lessons).map(lesson_code => {
                        if (lessons[lesson_code].is_valid === false) {
                            selected_lesson_codes_new.splice(
                                selected_lesson_codes_new.findIndex(selected_lesson_code => selected_lesson_code === lesson_code),
                                1
                            );
                        }
                    });
                    setLessons({});
                    setSelectedLessonCodesString(selected_lesson_codes_new.join(', '));
                    dispatch(AddClassCodesToSelectionAction(selected_lesson_codes_new))
                }}>Remove failed classes from selection</button>
            );
        }
    }
    if (Object.keys(lessons).length) {
        return (
            <div>
                <h4>Verification results</h4>
                {Object.keys(lessons).map(lesson_code => {
                    if (lessons[lesson_code].is_valid) {
                        return (
                            <div key={lesson_code} className='d-flex flex-box-0-5'>
                                <i className='fas fa-check green'/>
                                <div>
                                    <div>{lesson_code.toUpperCase()} passed verification</div>
                                    <ul>
                                        <li>Teacher: {lessons[lesson_code].owner_name}</li>
                                        <li>School: {lessons[lesson_code].school_name}</li>
                                        <li>District: {lessons[lesson_code].district_name}</li>
                                    </ul>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={lesson_code} className='d-flex flex-box-0-5'>
                                <i className="fas fa-times red"></i>
                                <div>
                                    <div>{lesson_code.toUpperCase()} verification failed</div>
                                    <ul>
                                        {lessons[lesson_code].messages.map((message, index) => {
                                            return (
                                                <li key={index}>{message}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <button className='button red' onClick={event => {
                                    event.preventDefault();
                                    let selected_lesson_codes_new = JSON.parse(JSON.stringify(selected_lesson_codes));
                                    selected_lesson_codes_new.splice(
                                        selected_lesson_codes_new.findIndex(selected_lesson_code => selected_lesson_code === lesson_code),
                                        1
                                    );
                                    let lessons_new = JSON.parse(JSON.stringify(lessons));
                                    delete lessons_new[lesson_code];
                                    setLessons(lessons_new);
                                    setSelectedLessonCodesString(selected_lesson_codes_new.join(', '));
                                    dispatch(AddClassCodesToSelectionAction(selected_lesson_codes_new))
                                }}>
                                    <i className='fas fa-trash'/>
                                </button>
                            </div>
                        );
                    }
                })}
                {renderAction()}
            </div>
        );
    }

    return null;
}

Results.propTypes = {
    lessons: PropTypes.object.isRequired,
    setLessons: PropTypes.func.isRequired,
    setSelectedLessonCodesString: PropTypes.func.isRequired,
    assign: PropTypes.func.isRequired
}

export default Results;
