import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils, mathUtils} from "mindsets-js-sdk";

class ReadOnly extends Component {
    getContent() {
        if (this.props.default_answer) {
            const katex = mathUtils.convertLatexToKatex(this.props.default_answer);
            return (
                <HTMLContent html_str={contentUtils.processStringContent(katex, {convert_spelling: false})}/>
            );
        } else {
            return null
        }
    }

    render() {
        return (
            <div className="component-expression-readonly d-flex align-items-center">
                {this.getContent()}
            </div>
        )
    }
}

ReadOnly.propTypes = {
    default_answer: PropTypes.string
}

export default ReadOnly
