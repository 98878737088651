import React, {Component} from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {DownloadCSV, ExportToGoogleDrive, LoadingAction, ErrorMessageAction} from "mindsets-js-sdk";

import DataCompiler from "./compilers/DataCompiler";

class Exports extends Component {
    constructor(props) {
        super(props);
        this.downloadCSV = new DownloadCSV();
        this.dataCompiler = new DataCompiler();
        this.exportToGoogleDrive = new ExportToGoogleDrive(props.dispatch);
    }

    onDownloadCsvClick() {
        return () => {
            return this.downloadCSV.execute(this.props.title, this.dataCompiler.compile(this.props.items));
        }
    }

    onExportToGoogleDriveClick() {
        return () => {
            this.props.dispatch(LoadingAction(true));
            return this.exportToGoogleDrive.execute(this.props.title, {
                sheets: [
                    {
                        title: 'Questions Completed',
                        content: this.dataCompiler.compile(this.props.items)
                    }
                ]
            }, process.env.PUBLIC_GOOGLE_CLIENT_ID)
                .then(() => this.props.dispatch(LoadingAction(false)))
                .catch((error) => {
                    this.props.dispatch(LoadingAction(false));
                    this.props.dispatch(ErrorMessageAction(error));
                });
        }
    }

    render() {
        return (
            <div className='d-flex no-wrap'>
                <button
                    onClick={this.onDownloadCsvClick()}
                    disabled={this.props.items.length === 0}
                    className="button d-none d-sm-inline mx-1"
                >
                    <i className="fas fa-download"/>
                    <span>Download CSV</span>
                </button>
                <button
                    onClick={this.onDownloadCsvClick()}
                    disabled={this.props.items.length === 0}
                    className="button d-inline d-sm-none mx-1"
                >
                    <i className="fas fa-download"/>
                    <span>Download</span>
                </button>
                <button
                    onClick={this.onExportToGoogleDriveClick()}
                    disabled={this.props.items.length === 0}
                    className="button d-none d-sm-inline mx-1"
                >
                    <i className="fab fa-google-drive"/>
                    <span>Export to Google Drive</span>
                </button>
                <button
                    onClick={this.onExportToGoogleDriveClick()}
                    disabled={this.props.items.length === 0}
                    className="button d-inline d-sm-none mx-1"
                >
                    <i className="fab fa-google-drive"/>
                    <span>Export GD</span>
                </button>
            </div>
        );
    }
}

Exports.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
        number_stream_entities: PropTypes.number.isRequired,
        challenge_version: PropTypes.shape({
            name: PropTypes.string.isRequired,
            number_questions: PropTypes.number.isRequired,
            challenge_code: PropTypes.shape({
                grades: PropTypes.object.isRequired,
                standards: PropTypes.arrayOf(PropTypes.shape({
                    code: PropTypes.string.isRequired,
                    topic: PropTypes.string.isRequired,
                    grade: PropTypes.string.isRequired,
                    curriculum_id: PropTypes.number.isRequired
                })).isRequired,
                subject: PropTypes.string.isRequired,
                measure: PropTypes.string
            }).isRequired
        }).isRequired,
        user: PropTypes.shape({
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string,
        }).isRequired,
        streams: PropTypes.arrayOf(PropTypes.shape({
            completed: PropTypes.number.isRequired,
            progress: PropTypes.number.isRequired,
            user: PropTypes.shape({
                fname: PropTypes.string.isRequired,
                lname: PropTypes.string
            }).isRequired
        })).isRequired
    })).isRequired,
    title: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default props => <Exports {...props} dispatch={useDispatch()}/>;
