import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    DistrictDropdown,
    UserDropdown,
    ListPage,
    filterConstants,
    accessConstants,
    countriesConstants,
    LoadSchoolsAction
} from "mindsets-js-sdk";

import {LoadCurriculaAction} from "../../actions/school_actions";
import Table from "./components/Table";
import TopButtons from "./components/TopButtons";

export default function () {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.schools_curricula);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        if (!curricula.length) {
            dispatch(LoadCurriculaAction());
        }
    }, []);

    return (
        <>
            <h1>Schools</h1>
            <ListPage
                action={options => LoadSchoolsAction(Object.assign({
                    'selectors[0]': 'subscriptions',
                    'selectors[1]': 'lessons_count',
                    'selectors[2]': 'district_subscriptions',
                    'selectors[3]': 'teachers_count',
                    'selectors[4]': 'students_count',
                }, options))}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'country',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS],
                        values: Object.keys(countriesConstants.COUNTRIES).map(code => {
                            return {
                                label: countriesConstants.COUNTRIES[code],
                                value: code
                            }
                        })
                    },
                    {
                        key: 'city',
                        name: 'City',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'number_teachers',
                        name: 'No. of teachers',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'number_students',
                        name: 'No. of students',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'has_subscription',
                        name: 'Has any subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'has_school_subscription',
                        name: 'Has school subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'has_district_subscription',
                        name: 'Has district subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'district_id',
                        name: 'By School District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown
                                    onSelect={district => {
                                        if (district) {
                                            setFilterValue(district.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return district ? district.name : '';
                                    }}
                                    id='schools-district-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'student_id',
                        name: 'By Student',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setFilterValue(user.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    persona='student'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'country',
                        name: 'Country'
                    },
                    {
                        key: 'city',
                        name: 'City'
                    },
                    {
                        key: 'teachers_count',
                        name: '# of teacher accounts'
                    },
                    {
                        key: 'lessons_count',
                        name: '# of classes created'
                    },
                    {
                        key: 'students_count',
                        name: '# of students joined'
                    },
                    {
                        key: 'subscription_ends_at',
                        name: 'Subscription expiration date'
                    },
                    {
                        key: 'district_subscription_ends_at',
                        name: 'District subscription expiration date'
                    }
                ]}
                entity={'schools'}
                create_button={<TopButtons/>}
                permissions={[accessConstants.PERMISSION_CAN_SEE_SCHOOLS]}
                // export_keys={{//TODO
                //     id: 'ID',
                //     name: 'School Name',
                //     district_id: 'District ID',
                //     district: 'District name',
                //     location: 'Location',
                //     city: 'City',
                //     state: 'State',
                //     zip: 'ZIP',
                //     lessons_count: '# classes',
                //     teachers_count: '# teachers',
                //     students_count: '# students'
                // }}
            >
                <Table/>
            </ListPage>
        </>
    );
}
