import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import Text from "./CardComponentProperties/ContentComponent/Text";
import Question from "./CardComponentProperties/ContentComponent/Question";
import Iframe from "./CardComponentProperties/ContentComponent/Iframe";
import Image from "./CardComponentProperties/ContentComponent/Image";
import Table from "./CardComponentProperties/ContentComponent/Table";
import NumberLine from "./CardComponentProperties/ContentComponent/NumberLine";
import Comparison from "./CardComponentProperties/ContentComponent/Comparison";
import InfoTabs from "./CardComponentProperties/ContentComponent/InfoTabs";
import Link from "./CardComponentProperties/ContentComponent/Link";
import Graph from "./CardComponentProperties/ContentComponent/Graph";
import Speech from "./CardComponentProperties/ContentComponent/Speech";
import Graphing from "./CardComponentProperties/ContentComponent/Graphing";
import Spacer from "./CardComponentProperties/ContentComponent/Spacer";
import Divider from "./CardComponentProperties/ContentComponent/Divider";
import ClickToShow from "./CardComponentProperties/ContentComponent/ClickToShow";
import Step from "./CardComponentProperties/ContentComponent/Step";
import TextArea from "./CardComponentProperties/AnswerComponent/TextArea";
import ChoiceSingle from "./CardComponentProperties/AnswerComponent/ChoiceSingle";
import ChoiceMultiple from "./CardComponentProperties/AnswerComponent/ChoiceMultiple";
import AnswerNumberLine from "./CardComponentProperties/AnswerComponent/NumberLine";
import AnswerGraphing from "./CardComponentProperties/AnswerComponent/Graphing";
import SliderSingle from "./CardComponentProperties/AnswerComponent/SliderSingle";
import SliderMultiple from "./CardComponentProperties/AnswerComponent/SliderMultiple";
import Expression from "./CardComponentProperties/AnswerComponent/Expression";
import ExpressionMulti from "./CardComponentProperties/AnswerComponent/ExpressionMulti";
import DragAndDrop from "./CardComponentProperties/AnswerComponent/DragAndDrop";
import Poll from "./CardComponentProperties/SpecialComponent/Poll";
import Spreadsheet from "./CardComponentProperties/AnswerComponent/Spreadsheet";
import InlinePoll from "./CardComponentProperties/AnswerComponent/InlinePoll";
import Fraction from "./CardComponentProperties/AnswerComponent/Fraction";
import Matching from "./CardComponentProperties/AnswerComponent/Matching";
import NumberOnly from "./CardComponentProperties/AnswerComponent/NumberOnly";
import Selector from "./CardComponentProperties/AnswerComponent/Selector";
import {CONTENT_COMPONENTS, ANSWER_COMPONENTS} from "../../index";
import {SetChallengeBuilderChallengePagesAction} from "../../../../actions/challenge_actions";
import DataTabs from "./CardComponentProperties/ContentComponent/DataTabs";

export default function () {
    const dispatch = useDispatch();

    const index = useSelector(state => state.challenge_builder_index);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);

    function getContentComponentClass(type) {
        switch (type.toLowerCase()) {
            case 'text':
                return Text;
            case 'question':
                return Question;
            case 'iframe':
                return Iframe;
            case 'image':
                return Image;
            case 'table':
                return Table;
            case 'number_line':
                return NumberLine;
            case 'comparison':
                return Comparison;
            case 'info_tabs':
                return InfoTabs;
            case 'link':
                return Link;
            case 'graph':
                return Graph;
            case 'speech':
                return Speech;
            case 'graphing':
                return Graphing;
            case 'spacer':
                return Spacer;
            case 'divider':
                return Divider;
            case 'click_to_show':
                return ClickToShow;
            case 'step':
                return Step;
            case 'data_tabs':
                return DataTabs;
            default:
                throw 'Unknown content component type "' + type + '"';
        }
    }

    function getSpecialComponentClass(type) {
        switch (type.toLowerCase()) {
            case 'poll.block':
                return Poll
            default:
                throw 'Unknown special component type'
        }
    }

    function getAnswerComponentClass(type) {
        switch (type.toLowerCase()) {
            case 'answer.textarea':
                return TextArea;
            case 'answer.number_line':
                return AnswerNumberLine;
            case 'answer.choice_single':
                return ChoiceSingle;
            case 'answer.choice_multiple':
                return ChoiceMultiple;
            case 'answer.slider':
                return SliderSingle;
            case 'answer.range':
                return SliderMultiple;
            case 'answer.graphing':
                return AnswerGraphing;
            case 'answer.expression':
                return Expression;
            case 'answer.expression_multi':
                return ExpressionMulti;
            case 'answer.dnd':
                return DragAndDrop;
            case 'poll.inline':
                return InlinePoll;
            case 'spreadsheet':
                return Spreadsheet;
            case 'answer.fraction':
                return Fraction;
            case 'answer.matching':
                return Matching;
            case 'answer.number_only':
                return NumberOnly;
            case 'answer.selector':
                return Selector;
            default:
                throw 'Unknown answer component type: ' + type;
        }
    }

    function getComponentClass(type) {
        switch (true) {
            case type.toLowerCase().startsWith('answer.') || 'poll.inline' === type || 'spreadsheet' === type:
                return getAnswerComponentClass(type)
            case type.toLowerCase() === 'poll.block':
                return getSpecialComponentClass(type)
            default:
                return getContentComponentClass(type)
        }
    }

    function onChange(key, value) {
        let pages = JSON.parse(JSON.stringify(challenge_pages));
        const page = pages[index];
        const row = page.data.card_content.rows[selected_row_index];
        const component = row.components[selected_component_index];
        if (value !== '' && value !== []) {
            component[key] = value;
        } else {
            if (component.hasOwnProperty(key)) {
                delete component[key];
            }
        }
        pages[index].data.card_content.rows[selected_row_index].components[selected_component_index] = component;
        dispatch(SetChallengeBuilderChallengePagesAction(pages));
    }

    if (null === selected_row_index || null === selected_component_index) {
        return null;
    }

    let pages = JSON.parse(JSON.stringify(challenge_pages));
    const page = pages[index];
    const row = page.data.card_content.rows[selected_row_index];
    const component = row.components[selected_component_index];
    const ComponentClass = getComponentClass(component.type);
    const all_components = [].concat(CONTENT_COMPONENTS, ANSWER_COMPONENTS);
    const template_component = all_components.find(template_component => template_component.id === component.type);

    return (
        <div className='bordered flex-grow-1'>
            <h3>Component type: {template_component ? template_component.name : 'Unknown'}</h3>
            <h4>Selected component's properties</h4>
            <ComponentClass component={component} onChange={onChange}/>
        </div>
    );
}
