import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengeCodeAction,
    SetChallengeBuilderChallengeColorAction,
    SetChallengeBuilderChallengeNameAction
} from "../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();

    const challenge_code = useSelector(state => state.challenge_builder_challenge_code);
    const challenge_name = useSelector(state => state.challenge_builder_challenge_name);
    const challenge_color = useSelector(state => state.challenge_builder_challenge_color);

    return (
        <div className='d-flex flex-gap-5'>
            <div>
                <label htmlFor='challenge_name' className=" col-form-label">Challenge Name</label>
                <input
                    type="text"
                    className="form-control"
                    id='challenge_name'
                    value={challenge_name}
                    onChange={event => dispatch(SetChallengeBuilderChallengeNameAction(event.target.value))}
                />
            </div>
            <div>
                <label htmlFor='challenge_code' className=" col-form-label">Challenge Code</label>
                <input
                    type="text"
                    className="form-control"
                    id='challenge_code'
                    value={challenge_code}
                    onChange={event => dispatch(SetChallengeBuilderChallengeCodeAction(event.target.value))}
                />
            </div>
            <div>
                <label htmlFor='challenge_color' className=" col-form-label">Challenge color</label>
                <input
                    type="text"
                    className="form-control"
                    id='challenge_color'
                    value={challenge_color}
                    onChange={event => dispatch(SetChallengeBuilderChallengeColorAction(event.target.value))}
                />
            </div>
        </div>
    );
}
