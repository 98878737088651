import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {SetGradingProviderAction} from "mindsets-js-sdk";

const Users = function ({setUpdated}) {
    const dispatch = useDispatch();
    const grading_provider = useSelector(state => state.grading_provider);
    if (grading_provider && Array.isArray(grading_provider.users)) {
        return (
            <div className='section'>
                {grading_provider.users.map((user, index) => {
                    return (
                        <div key={user.id} className='d-flex flex-gap-5'>
                            <span>{index + 1}</span>
                            <span>{user.fname} {user.lname}({user.email})</span>
                            <button className='red' onClick={event => {
                                event.preventDefault();
                                let grading_provider_new = JSON.parse(JSON.stringify(grading_provider));
                                let users = grading_provider_new.users;
                                users.splice(users.findIndex(userX => userX.id === user.id), 1);
                                grading_provider_new.users = users;
                                dispatch(SetGradingProviderAction(grading_provider_new));
                                setUpdated(true);
                            }}>
                                <i className='fas fa-trash'/>
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
}

Users.propTypes = {
    setUpdated: PropTypes.func.isRequired
}

export default Users;
