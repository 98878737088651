import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Cookie,
    VerifyScheduleAction,
    CreateSchedulesAction,
    AddClassCodesToSelectionAction,
    LoadingAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import {
    LoadGradingProvidersAction,
    LoadPracticesAction
} from "../../actions/lesson_actions";
import Results from "./Results";
import {Link} from "react-router-dom";

export default function () {
    const dispatch = useDispatch();
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const grading_providers = useSelector(state => state.schedules_grading_providers);
    const practices = useSelector(state => state.lessons_practices);
    const [grading_provider_id, setGradingProviderId] = useState(Cookie.hasCookie('grading_provider_id') ? Cookie.getCookie('grading_provider_id') : '');
    const [lessons, setLessons] = useState({});
    const [practice_id, setPracticeId] = useState(Cookie.hasCookie('practice_id') ? Cookie.getCookie('practice_id') : '');
    const [completion, setCompletion] = useState(Cookie.hasCookie('completion') ? Cookie.getCookie('completion') : '');
    const [selected_lesson_codes_string, setSelectedLessonCodesString] = useState(selected_lesson_codes.join(', '));
    useEffect(() => {
        if (!grading_providers.length) {
            dispatch(LoadGradingProvidersAction());
        }
        if (!practices.length) {
            dispatch(LoadPracticesAction());
        }
    }, []);

    return (
        <>
            <h2>Send classes to GradeBook for QA</h2>
            <div>
                <Link to='/lesson_schedules' className='button'>Back to list</Link>
            </div>
            <div className='section'>
                <div className='d-flex flex-box-0-10'>
                    <div>
                        <label className='d-block' htmlFor='grading-provider'>Grading Provider *</label>
                        <select
                            id="grading-provider"
                            value={grading_provider_id ? grading_provider_id : ''}
                            onChange={event => {
                                const value = event.target.value;
                                setGradingProviderId(value);
                                setLessons({});
                                Cookie.setCookie('grading_provider_id', value);
                            }}
                        >
                            <option value=''>Please select</option>
                            {grading_providers.map(grading_provider => {
                                return (
                                    <option
                                        value={grading_provider.id}
                                        key={grading_provider.id}>{grading_provider.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <label className='d-block' htmlFor='practice'>Practice/Curriculum *</label>
                        <select
                            id="practice"
                            value={practice_id ? practice_id : ''}
                            onChange={event => {
                                const value = event.target.value;
                                setPracticeId(value);
                                setLessons({});
                                Cookie.setCookie('practice_id', value);
                            }}
                        >
                            <option value=''>Please select</option>
                            {practices.map(practice => {
                                return (
                                    <option value={practice.id} key={practice.id}>{practice.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <label className='d-block' htmlFor='completion'>Minimum completion level</label>
                        <select
                            id="completion"
                            value={completion ? completion : ''}
                            onChange={event => {
                                const value = event.target.value;
                                setCompletion(value);
                                setLessons({});
                                Cookie.setCookie('completion', value);
                            }}
                        >
                            <option value=''>Please select</option>
                            <option value='stuck'>Stuck</option>
                            <option value='underway'>Underway</option>
                            <option value='nearly'>Nearly completed</option>
                            <option value='completed'>Completed</option>
                            <option value='completed_bonus'>Completed bonus</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='section'>
                <div>
                    <label htmlFor='lesson-codes' className='d-block'>Class codes *</label>
                    <textarea
                        id='lesson-codes'
                        value={selected_lesson_codes_string}
                        onChange={event => {
                            const value = event.target.value;
                            setLessons({});
                            setSelectedLessonCodesString(value);
                            const array = value.split(',').map(item => item.trim()).filter(item => item !== '');
                            dispatch(AddClassCodesToSelectionAction(array));
                        }}/>
                </div>
            </div>
            <div className='section'>
                <h3>Selected {selected_lesson_codes.length} classes</h3>
            </div>
            <div className='section'>
                <button
                    onClick={event => {
                        event.preventDefault();
                        dispatch(VerifyScheduleAction({
                            grading_provider_id,
                            practice_id,
                            lesson_codes: selected_lesson_codes,
                            completion
                        })).then(response => setLessons(response.lesson_codes));
                    }}
                    disabled={!(practice_id && grading_provider_id && selected_lesson_codes.length)}
                >Verify
                </button>
            </div>
            <div className='section'>
                <Results
                    lessons={lessons}
                    setLessons={setLessons}
                    setSelectedLessonCodesString={setSelectedLessonCodesString}
                    schedule={() => {
                        dispatch(CreateSchedulesAction({
                            grading_provider_id,
                            practice_id,
                            lesson_codes: selected_lesson_codes,
                            completion
                        })).then(() => {
                            setLessons({});
                            setSelectedLessonCodesString('');
                            dispatch(AddClassCodesToSelectionAction([]));
                        }).catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                    }}
                />
            </div>
        </>
    );
};
