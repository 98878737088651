import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Poll from './Poll';


class SpecialComponent extends Component {
    getComponentClass() {
        const type = this.props.component_data.type.toLowerCase()
        switch (type) {
            case 'poll.block':
                return Poll
            default:
                throw 'Unknown component type "' + type + '"'
        }
    }

    renderComponentContent() {
        const ComponentClass = this.getComponentClass()
        return (
            <ComponentClass
                component_data={this.props.component_data}
                updateComponent={this.props.updateComponent}
            />
        )
    }

    render() {
        return (
            <div className="challenge-special-component droppable">
                {this.renderComponentContent()}
            </div>
        )
    }
}

SpecialComponent.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default SpecialComponent
