import {LOADED_CONTRACT_SCHOOLS, LOADED_CONTRACT_CHALLENGES} from "../actions/contract_actions";

const contract_schools = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CONTRACT_SCHOOLS:
            return action.payload;
        default:
            return payload;
    }
};
const contract_challenges = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CONTRACT_CHALLENGES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    contract_schools,
    contract_challenges
}
