import React from 'react';
import {Link} from "react-router-dom";
import {accessConstants, ListPage, LoadGradingProvidersAction} from "mindsets-js-sdk";

import Table from './Table';

export default function () {
    return (
        <>
            <h1>Grading Providers</h1>
            <ListPage
                action={LoadGradingProvidersAction}
                filter_columns={[]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    }
                ]}
                entity={'grading_providers'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <Link to="/grading_providers/new" className="button">+ New Grading Provider</Link>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_USERS]}
                show_quick_search={false}
            >
                <Table/>
            </ListPage>
        </>
    );
}
