import MindsetsJsSDK, {
    filterConstants,
    setLimitAction,
    setCurrentAction
} from "mindsets-js-sdk";

export const LOADED_CHALLENGE_VERSIONS_PRACTICES = '[challenge_versions] Loaded practices';
export const LOADED_CHALLENGES = '[challenges] Loaded Challenges';
export const LOADED_CHALLENGES_PAGES = '[challenges] Loaded pages';
export const LOADED_CHALLENGES_TOTAL = '[challenges] Loaded total';
export const LOADED_CHALLENGES_UPDATES = '[challenges] Loaded updates';
export const PARSING = '[challenges] Parsing challenge script';

export const SET_CHALLENGE_BUILDER_MODE = '[challenge_builder] Set mode';
export const SET_CHALLENGE_BUILDER_BUILD_MODE = '[challenge_builder] Set build mode';
export const SET_CHALLENGE_BUILDER_INDEX = '[challenge_builder] Set index';
export const SET_CHALLENGE_BUILDER_SELECTED_ROW_INDEX = '[challenge_builder] Set selected row index';
export const SET_CHALLENGE_BUILDER_SELECTED_COMPONENT_INDEX = '[challenge_builder] Set selected component index';
export const SET_CHALLENGE_BUILDER_IMPORT_RAW_STRING = '[challenge_builder] Set import raw string';
export const SET_CHALLENGE_BUILDER_CHALLENGE_CODE = '[challenge_builder] Set challenge code';
export const SET_CHALLENGE_BUILDER_CHALLENGE_NAME = '[challenge_builder] Set challenge name';
export const SET_CHALLENGE_BUILDER_CHALLENGE_COLOR = '[challenge_builder] Set challenge color';
export const SET_CHALLENGE_BUILDER_CHALLENGE_PAGES = '[challenge_builder] Set challenge pages';
export const SET_CHALLENGE_BUILDER_MOUSE_OVER_ROW = '[challenge_builder] Set mouse over row';
export const SET_CHALLENGE_BUILDER_MOUSE_OVER_COMPONENT = '[challenge_builder] Set mouse over component';

export const LoadPracticesAction = subject => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('practices', {
            order_by: 'name',
            'filters[subject][0]': filterConstants.EXPRESSION_EQUAL + '=' + subject
        }))
        .then(response => dispatch(LoadedPracticesAction(response.items)));
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_CHALLENGE_VERSIONS_PRACTICES,
    payload
});

export const LoadChallengesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => {
            options['selectors[0]'] = 'challenge_code';
            return MindsetsJsSDK().API.get('challenge_versions', options);
        })
        .then(response => {
            dispatch(LoadedChallengesAction(response.items));
            dispatch(setCurrentAction('challenges', response.pages >= response.current ? response.current : 1));
            dispatch(setLimitAction('challenges', response.limit));
            dispatch(LoadedChallengesPagesAction(response.pages));
            dispatch(LoadedChallengesTotalAction(response.total));

            return response.items;
        });
};
export const LoadedChallengesAction = payload => ({
    type: LOADED_CHALLENGES,
    payload
});
export const LoadedChallengesPagesAction = payload => ({
    type: LOADED_CHALLENGES_PAGES,
    payload
});
export const LoadedChallengesTotalAction = payload => ({
    type: LOADED_CHALLENGES_TOTAL,
    payload
});

export const SetChallengeBuilderModeAction = payload => ({
    type: SET_CHALLENGE_BUILDER_MODE,
    payload
});
export const SetChallengeBuilderBuildModeAction = payload => ({
    type: SET_CHALLENGE_BUILDER_BUILD_MODE,
    payload
});
export const SetChallengeBuilderIndexAction = payload => ({
    type: SET_CHALLENGE_BUILDER_INDEX,
    payload
});
export const SetChallengeBuilderSelectedRowIndexAction = payload => ({
    type: SET_CHALLENGE_BUILDER_SELECTED_ROW_INDEX,
    payload
});
export const SetChallengeBuilderSelectedComponentIndexAction = payload => ({
    type: SET_CHALLENGE_BUILDER_SELECTED_COMPONENT_INDEX,
    payload
});
export const SetChallengeBuilderImportRawStringAction = payload => ({
    type: SET_CHALLENGE_BUILDER_IMPORT_RAW_STRING,
    payload
});
export const SetChallengeBuilderChallengeCodeAction = payload => ({
    type: SET_CHALLENGE_BUILDER_CHALLENGE_CODE,
    payload
});
export const SetChallengeBuilderChallengeNameAction = payload => ({
    type: SET_CHALLENGE_BUILDER_CHALLENGE_NAME,
    payload
});
export const SetChallengeBuilderChallengeColorAction = payload => ({
    type: SET_CHALLENGE_BUILDER_CHALLENGE_COLOR,
    payload
});
export const SetChallengeBuilderChallengePagesAction = payload => ({
    type: SET_CHALLENGE_BUILDER_CHALLENGE_PAGES,
    payload
});

export const SetChallengeBuilderMouseOverRowAction = payload => ({
    type: SET_CHALLENGE_BUILDER_MOUSE_OVER_ROW,
    payload
});
export const SetChallengeBuilderMouseOverComponentAction = payload => ({
    type: SET_CHALLENGE_BUILDER_MOUSE_OVER_COMPONENT,
    payload
});

import JsonValidator from "../pages/ChallengeValidator/validators/JsonValidator";
import JsonChallengeValidator from "../pages/ChallengeValidator/validators/JsonChallengeValidator";
import JsonEntitiesValidator from "../pages/ChallengeValidator/validators/JsonEntitiesValidator";
import JsonEntitiesEntityValidator from "../pages/ChallengeValidator/validators/JsonEntitiesEntityValidator";
import JsonEntitiesEntityTypeValidator from "../pages/ChallengeValidator/validators/JsonEntitiesEntityTypeValidator";
import JsonEntitiesEntityReferenceValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityReferenceValidator";
import JsonEntitiesEntityDataValidator from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataValidator";
import JsonEntitiesEntityDataAnswerRequiredValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataAnswerRequiredValidator";
import JsonEntitiesEntityDataIsBonusValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataIsBonusValidator";
import JsonEntitiesEntityDataTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataTitleValidator";
import JsonEntitiesEntityDataSubTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataSubTitleValidator";
import JsonEntitiesEntityDataButtonLabelValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataButtonLabelValidator";
import JsonEntitiesEntityDataCardContentValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentValidator";
import JsonEntitiesEntityDataContentValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataContentValidator";
import JsonEntitiesEntityDataContent1Validator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataContent1Validator";
import JsonEntitiesEntityDataContent2Validator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataContent2Validator";
import JsonEntitiesEntityDataCardContentRowsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsValidator";
import JsonEntitiesEntityDataCardContentRowsRowValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowValidator";
import JsonEntitiesEntityDataCardContentRowsRowConfigValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowConfigValidator";
import JsonEntitiesEntityDataCardContentRowsRowConfigVAlignValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowConfigVAlignValidator";
import JsonEntitiesEntityDataCardContentRowsRowConfigFlexValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowConfigFlexValidator";
import JsonEntitiesEntityDataCardContentRowsRowContainerStyleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowContainerStyleValidator";
import JsonEntitiesEntityDataCardContentRowsRowContainerStyleMaxWidthValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowContainerStyleMaxWidthValidator";
import JsonEntitiesEntityDataCardContentRowsRowContainerStyleMarginValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowContainerStyleMarginValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigDirectionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigDirectionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentTypeValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentTypeValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBMaterialIdValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBMaterialIdValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMaxWidthValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMaxWidthValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMarginValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMarginValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleAlignValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleAlignValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDescriptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDescriptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHtmlValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHtmlValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSrcValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSrcValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionNumberValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionNumberValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHintValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHintValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageUrlValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageUrlValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleDirectionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleDirectionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleBackgroundValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleBackgroundValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifUrlValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifUrlValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightBubbleBackgroundValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightBubbleBackgroundValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageSrcValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageSrcValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionTitleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionOptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionOptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionDescriptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionDescriptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointFormulaValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointFormulaValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointNumberLinePointValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointNumberLinePointValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointImageValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointImageValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointTitleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataFirstNumValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataFirstNumValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLastNumValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLastNumValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataInitialPosValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataInitialPosValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataIncrementIntervalValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataIncrementIntervalValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSnapDivisionsPerIntervalValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSnapDivisionsPerIntervalValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPlaceholderValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPlaceholderValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartTypeValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartTypeValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCreditsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCreditsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisGridLineWidthValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisGridLineWidthValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTickIntervalValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTickIntervalValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBackgroundColorValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBackgroundColorValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderColorValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderColorValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderRadiusValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderRadiusValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderWidthValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderWidthValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleTextValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleTextValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisGridLineWidthValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisGridLineWidthValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTickIntervalValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTickIntervalValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendAlignValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendAlignValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemPointStartValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemPointStartValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataOptionsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataOptionsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataClassNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataClassNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMinValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMinValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMaxValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMaxValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataStepValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataStepValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataUnitValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataUnitValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMinValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMinValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMaxValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMaxValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCaptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCaptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemImageValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemImageValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemNameValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemNameValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemDescriptionValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemDescriptionValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemInformationValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemInformationValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLinkValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLinkValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsItemValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsItemValidator";
import JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonLabelValidator
    from "../pages/ChallengeValidator/validators/JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonLabelValidator";
import UnrecognizedValidator from "../pages/ChallengeValidator/validators/UnrecognizedValidator";

const getValidator = (path) => {
    switch (path) {
        case 'json':
            return new JsonValidator();
        case 'json.challenge':
            return new JsonChallengeValidator();
        case 'json.entities':
            return new JsonEntitiesValidator();
        case 'json.entities.*':
            return new JsonEntitiesEntityValidator();
        case 'json.entities.*.type':
            return new JsonEntitiesEntityTypeValidator();
        case 'json.entities.*.reference':
            return new JsonEntitiesEntityReferenceValidator();
        case 'json.entities.*.data':
            return new JsonEntitiesEntityDataValidator();
        case 'json.entities.*.data.answer_required':
            return new JsonEntitiesEntityDataAnswerRequiredValidator();
        case 'json.entities.*.data.is_bonus':
            return new JsonEntitiesEntityDataIsBonusValidator();
        case 'json.entities.*.data.title':
            return new JsonEntitiesEntityDataTitleValidator();
        case 'json.entities.*.data.subtitle':
            return new JsonEntitiesEntityDataSubTitleValidator();
        case 'json.entities.*.data.button_label':
            return new JsonEntitiesEntityDataButtonLabelValidator();
        case 'json.entities.*.data.content':
            return new JsonEntitiesEntityDataContentValidator();
        case 'json.entities.*.data.content1':
            return new JsonEntitiesEntityDataContent1Validator();
        case 'json.entities.*.data.content2':
            return new JsonEntitiesEntityDataContent2Validator();
        case 'json.entities.*.data.card_content':
            return new JsonEntitiesEntityDataCardContentValidator();
        case 'json.entities.*.data.card_content.rows':
            return new JsonEntitiesEntityDataCardContentRowsValidator();
        case 'json.entities.*.data.card_content.rows.*':
            return new JsonEntitiesEntityDataCardContentRowsRowValidator();
        case 'json.entities.*.data.card_content.rows.*.config':
            return new JsonEntitiesEntityDataCardContentRowsRowConfigValidator();
        case 'json.entities.*.data.card_content.rows.*.config.v_align':
            return new JsonEntitiesEntityDataCardContentRowsRowConfigVAlignValidator();
        case 'json.entities.*.data.card_content.rows.*.config.flex':
            return new JsonEntitiesEntityDataCardContentRowsRowConfigFlexValidator();
        case 'json.entities.*.data.card_content.rows.*.container_style':
            return new JsonEntitiesEntityDataCardContentRowsRowContainerStyleValidator();
        case 'json.entities.*.data.card_content.rows.*.container_style.maxWidth':
            return new JsonEntitiesEntityDataCardContentRowsRowContainerStyleMaxWidthValidator();
        case 'json.entities.*.data.card_content.rows.*.container_style.margin':
            return new JsonEntitiesEntityDataCardContentRowsRowContainerStyleMarginValidator();
        case 'json.entities.*.data.card_content.rows.*.components':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.type':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentTypeValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.config':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.config.direction':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigDirectionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.config.ggb':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.config.ggb.material_id':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBMaterialIdValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.container_style':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.container_style.maxWidth':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMaxWidthValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.container_style.margin':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentContainerStyleMarginValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.title':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.title.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.title.align':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTitleAlignValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.description':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDescriptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.html':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHtmlValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.src':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSrcValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.question_number':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionNumberValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.question':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataQuestionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.hint':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHintValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.imageURL':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageUrlValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.image_name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.bubble_direction':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleDirectionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.bubble_background':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataBubbleBackgroundValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.animated_gif_url':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifUrlValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.animated_git_name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataAnimatedGifNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.left':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.left.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLeftTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.right':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.right.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.right.bubble_background':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataRightBubbleBackgroundValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.image':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.image.src':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataImageSrcValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.poll_options':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.poll_options.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.poll_options.*.title':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionTitleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.poll_options.*.option':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionOptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.poll_options.*.description':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPollOptionsOptionDescriptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints.*.formula':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointFormulaValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints.*.numberLinePoint':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointNumberLinePointValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints.*.image':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointImageValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.numberPoints.*.title':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointTitleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.first_num':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataFirstNumValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.last_num':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLastNumValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.initial_pos':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataInitialPosValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.increment_interval':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataIncrementIntervalValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.snap_divisions_per_interval':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSnapDivisionsPerIntervalValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.placeholder':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataPlaceholderValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.chart':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.chart.type':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataChartTypeValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.credits':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCreditsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.xAxis':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.xAxis.title':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.xAxis.title.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTitleTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.xAxis.gridLineWidth':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisGridLineWidthValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.xAxis.tickInterval':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataXAxisTickIntervalValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.tooltip':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.tooltip.backgroundColor':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBackgroundColorValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.tooltip.borderColor':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderColorValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.tooltip.borderRadius':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderRadiusValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.tooltip.borderWidth':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataTooltipBorderWidthValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.yAxis':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.yAxis.title':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.yAxis.title.text':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTitleTextValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.yAxis.gridLineWidth':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisGridLineWidthValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.yAxis.tickInterval':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataYAxisTickIntervalValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.legend':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.legend.align':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLegendAlignValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*.name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*.data':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*.data.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*.data.*.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemDataItemItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.series.*.pointStart':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataSeriesItemPointStartValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.options':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataOptionsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.class_name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataClassNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.headers':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.headers.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataHeadersItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.data':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.data.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataDataItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.min':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMinValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.max':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataMaxValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.step':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataStepValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.unit':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataUnitValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.value':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.valueMin':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMinValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.valueMax':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValueMaxValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.caption':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataCaptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items.*.image':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemImageValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items.*.name':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemNameValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items.*.description':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemDescriptionValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.items.*.information':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemInformationValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.link':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataLinkValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.button_sets':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.button_sets.*':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonSetsItemValidator();
        case 'json.entities.*.data.card_content.rows.*.components.*.data.button_label':
            return new JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataButtonLabelValidator();
        default :
            return new UnrecognizedValidator();
    }
}
const recursivelyParseJsonObject = (value, path, hasAttributes) => {
    let result = {};

    if (Array.isArray(value)) {
        const index = path + '.*';
        const validator = getValidator(index);
        result = value.map(item => {
            const validationResult = validator.validate(item, hasAttributes)
            return {
                value: recursivelyParseJsonObject(item, index, validator.getValidationRules().hasOwnProperty('attributes')),
                validationResult: validationResult
            };
        });
    } else if (value !== null && typeof value === 'object') {
        Object.keys(value).map(key => {
            const index = path + '.' + (typeof key === 'number' ? '*' : key);
            const validator = getValidator(index);
            result[key] = {
                value: recursivelyParseJsonObject(value[key], index, validator.getValidationRules().hasOwnProperty('attributes')),
                validationResult: validator.validate(value[key], hasAttributes)
            };
        });
    } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        result = value;
    }

    return result;
}
const validate = jsonString => {
    let challengeJson;
    try {
        challengeJson = JSON.parse(jsonString);
    } catch (e) {
        return {
            challengeData: null,
            validationResult: {
                isValid: false,
                messages: ['Data is not formatted as a valid JSON string']
            }
        };
    }

    return {
        challengeData: {
            value: recursivelyParseJsonObject(challengeJson, 'json', true),
            validationResult: new JsonValidator().validate(challengeJson, true)
        },
        validationResult: {
            isValid: true,
            messages: []
        }
    };
}
const parse = payload => dispatch => dispatch(ChallengeScriptParsedAction(validate(payload)));
export const ParseChallengeScriptAction = payload => dispatch => dispatch(parse(payload));
export const ChallengeScriptParsedAction = payload => {
    return {
        type: PARSING,
        payload
    }
};
