import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {accessConstants, authUtils} from "mindsets-js-sdk";

import StudentSummary from "./components/StudentSummary";
import Streams from "./components/Streams";
import NoAccess from "../../common/NoAccess";
import {
    LoadLongitudinalChallengeStatsAction, LoadLongitudinalLessonAvgTimeSpentStatsAction,
    LoadLongitudinalStreamStatsAction, LoadLongitudinalTimeSpentStatsAction,
    LoadLongitudinalUserAction,
    LoadLongitudinalUserStatsAction
} from "../../actions/longitudinal_actions";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const user = useSelector(state => state.longitudinal_user);
    const streams = useSelector(state => state.longitudinal_stream_stats);
    const timeGroups = useSelector(state => state.longitudinal_time_spent_stats);
    const challengeStats = useSelector(state => state.longitudinal_challenge_stats);
    const schoolStats = useSelector(state => state.longitudinal_user_stats);
    const [mode, setMode] = useState('completion');
    useEffect(() => {
        dispatch(LoadLongitudinalUserAction(params.user_id));
        dispatch(LoadLongitudinalUserStatsAction(params.school_id));
        dispatch(LoadLongitudinalStreamStatsAction(params.school_id, params.user_id))
            .then(streams => {
                dispatch(LoadLongitudinalChallengeStatsAction()).then(challengeStats => {
                    dispatch(LoadLongitudinalTimeSpentStatsAction(params.user_id, streams, challengeStats));
                });
                dispatch(LoadLongitudinalLessonAvgTimeSpentStatsAction(params.user_id, streams));
            });
    }, []);
    if (!me || !user) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_SEE_USERS, accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }
    const getProgressIndex = function (stream) {
        if (stream.index > stream.no_cards - stream.no_bonus_cards) {
            return stream.no_cards - stream.no_bonus_cards;
        }

        return stream.index;
    }
    const getCompletionRate = function (stream) {
        return getProgressIndex(stream) / (stream.no_cards - stream.no_bonus_cards) * 100;
    }
    const getAverageCompletion = function () {
        if (streams.length > 0) {
            const rate = streams.reduce((accumulator, stream) => accumulator + getCompletionRate(stream), 0) / streams.length;
            if (rate) {
                return Math.round(rate);
            }
        }

        return 0;
    }
    const isStreamStuck = function (stream) {
        return stream.index < 4;
    }
    const getNoOfStuckStreams = function () {
        return streams.filter(stream => isStreamStuck(stream)).length;
    }
    const findChallenge = function (challenge_version_id) {
        return _.find(challengeStats, challenge_version => challenge_version.id === challenge_version_id);
    }
    const isStreamUnderway = function (stream) {
        const challenge_version = findChallenge(stream.challenge_version_id);

        return challenge_version && stream.index >= 4 && stream.index < challenge_version.index_complete;
    }
    const getNoOfUnderwayStreams = function () {
        return streams.filter(stream => isStreamUnderway(stream)).length;
    }
    const isStreamCompleted = function (stream) {
        const challenge_version = findChallenge(stream.challenge_version_id);

        return challenge_version && stream.index >= challenge_version.index_complete && stream.index < challenge_version.index_final;
    }
    const getNoOfCompletedStreams = function () {
        return streams.filter(stream => isStreamCompleted(stream)).length;
    }
    const isStreamCompletedBonus = function (stream) {
        const challenge_version = findChallenge(stream.challenge_version_id);

        return challenge_version && stream.index === challenge_version.index_final;
    }
    const getNoOfCompletedBonusStreams = function () {
        return streams.filter(stream => isStreamCompletedBonus(stream)).length;
    }
    const calculateSchoolStudentAvgCompletion = function () {
        return _.range(101).map(index => schoolStats.filter(student => student.avg_completion === index).length);
    }
    const getTotalTimeSpent = function () {
        return Object.keys(timeGroups).reduce((accumulator, stream_id) => accumulator + timeGroups[stream_id].totalTimeSpent, 0);
    }
    const getTotalTimeSpentAverage = function () {
        if (Object.keys(timeGroups).length > 0) {
            return getTotalTimeSpent() / Object.keys(timeGroups).length;
        }

        return null;
    }
    const getChallengeTimeSpent = function () {
        return Object.keys(timeGroups).reduce((accumulator, stream_id) => accumulator + timeGroups[stream_id].challengeTimeSpent, 0);
    }
    const getChallengeTimeSpentAverage = function () {
        if (Object.keys(timeGroups).length > 0) {
            return getChallengeTimeSpent() / Object.keys(timeGroups).length;
        }

        return null;
    }
    const getBonusTimeSpent = function () {
        return Object.keys(timeGroups).reduce((accumulator, stream_id) => accumulator + timeGroups[stream_id].bonusTimeSpent, 0);
    }
    const getBonusTimeSpentAverage = function () {
        if (Object.keys(timeGroups).length > 0) {
            return getBonusTimeSpent() / Object.keys(timeGroups).length;
        }

        return null;
    }
    const getMultiplier = function () {
        return Math.ceil((schoolStats.reduce((accumulator, student) => Math.max(accumulator, student.total_time_spent), 0) / 60) / 100);
    }
    const calculateSchoolStudentsTotalTimeSpent = function () {
        if (schoolStats.length) {
            return _.range(101).map(index => schoolStats.filter(student => Math.round(student.total_time_spent / 60) === (index * getMultiplier())).length);
        }

        return [];
    }
    const toggleMode = function () {
        setMode('completion' === mode ? 'time' : 'completion');
    }

    return (
        <div className='container roles-page section'>
            <h1>{user.fname} {user.lname}</h1>
            <div className='section'>
                <StudentSummary
                    mode={mode}
                    streams={streams}
                    averageCompletion={getAverageCompletion()}
                    noStreams={streams.length}
                    noStreamsStuck={getNoOfStuckStreams()}
                    noStreamsUnderway={getNoOfUnderwayStreams()}
                    noStreamsCompleted={getNoOfCompletedStreams()}
                    noStreamsCompletedBonus={getNoOfCompletedBonusStreams()}
                    schoolStudentsAvgCompletion={calculateSchoolStudentAvgCompletion()}
                    totalTimeSpent={getTotalTimeSpent()}
                    totalTimeSpentAverage={getTotalTimeSpentAverage()}
                    challengeTimeSpent={getChallengeTimeSpent()}
                    challengeTimeSpentAverage={getChallengeTimeSpentAverage()}
                    bonusTimeSpent={getBonusTimeSpent()}
                    bonusTimeSpentAverage={getBonusTimeSpentAverage()}
                    timeGroups={timeGroups}
                    schoolStudentsTotalTimeSpent={calculateSchoolStudentsTotalTimeSpent()}
                    getMultiplier={getMultiplier}
                />
                <Streams
                    mode={mode}
                    toggleMode={toggleMode}
                />
            </div>
        </div>
    );
}
