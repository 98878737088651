import React from 'react';
import {useSelector} from "react-redux";

export default function () {
    const users = useSelector(state => state.users);
    if (users.length > 0) {
        return (
            <div className='container my-2'>
                <h5>Other users on this page</h5>
                <div className='d-flex flex-row flex-wrap flex-gap-5'>
                    {users.map(user => {
                        return (
                            <div key={user.id} className='d-flex flex-column align-items-center flex-gap-5'>
                                <div
                                    className={'mindsets-icon ' + (user.avatar ? user.avatar : "cashtivity-icon-avatar-girl-01")}/>
                                <div>{user.fname} {user.lname}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
}
