import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "mindsets-js-sdk";

import {SetChallengeBuilderChallengePagesAction} from "../../../../actions/challenge_actions";
import Sortable from "./Sortable";

export default function () {
    const dispatch = useDispatch();

    const index = useSelector(state => state.challenge_builder_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);

    let pages = JSON.parse(JSON.stringify(challenge_pages));
    let page = pages[index];

    function renderAddRow() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <div>
                <button
                    className='button blue'
                    onClick={() => {
                        let pages = JSON.parse(JSON.stringify(challenge_pages));
                        let page = pages[index];
                        let page_data = page.data;
                        if (!page_data.hasOwnProperty('card_content')) {
                            page_data.card_content = {};
                        }
                        let card_content = page_data.card_content;
                        if (!card_content.hasOwnProperty('rows')) {
                            card_content.rows = [];
                        }
                        let rows = card_content.rows;
                        rows.push({components: []});
                        card_content.rows = rows;
                        page_data.card_content = card_content;
                        page.data = page_data;
                        pages[index] = page;
                        dispatch(SetChallengeBuilderChallengePagesAction(pages));
                    }}
                >+ ROW
                </button>
            </div>
        );
    }

    function renderAnswerRequiredCheck() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <Checkbox
                checked={!!page && !!page.data && !!page.data.answer_required}
                label='Answer required?'
                toggle={() => {
                    let page_new = JSON.parse(JSON.stringify(page));
                    if (!page_new.data) {
                        page_new.data = {};
                    }
                    page_new.data.answer_required = !page_new.data.answer_required;

                    let challenge_pages_new = JSON.parse(JSON.stringify(challenge_pages));
                    challenge_pages_new[index] = page_new;
                    dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages_new));
                }}
            />
        );
    }

    function renderIsBonusCheck() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return null;
        }

        return (
            <Checkbox
                checked={!!page && !!page.data && !!page.data.is_bonus}
                label='Is bonus?'
                toggle={() => {
                    let page_new = JSON.parse(JSON.stringify(page));
                    if (!page_new.data) {
                        page_new.data = {};
                    }
                    page_new.data.is_bonus = !page_new.data.is_bonus;

                    let challenge_pages_new = JSON.parse(JSON.stringify(challenge_pages));
                    challenge_pages_new[index] = page_new;
                    dispatch(SetChallengeBuilderChallengePagesAction(challenge_pages_new));
                }}
            />
        );
    }

    function renderStaticMessage() {
        if (['intro', 'unlock', 'final'].includes(page.type)) {
            return (
                <div>
                    <h5 className='text-warning'>This page type is static and does not support customization</h5>
                </div>
            );
        }

        return null;
    }

    return (
        <div className='bordered'>
            <div className='structure-bar'>
                <h4>Page #{index + 1}</h4>
                {renderAnswerRequiredCheck()}
                {renderIsBonusCheck()}
                <Sortable/>
                {renderAddRow()}
                {renderStaticMessage()}
            </div>
        </div>
    );
}
