import {
    SET_USER_CURRICULA,
    SET_USER_ROLES,
    LOADED_ROLES,
    LOADED_CURRICULA,
    LOADED_PERMISSIONS
} from "../actions/user_actions";

const users_roles = (payload = [], action) => {
    switch (action.type) {
        case LOADED_ROLES:
            return action.payload;
        default:
            return payload;
    }
};
const users_curricula = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CURRICULA:
            return action.payload;
        default:
            return payload;
    }
};
const users_permissions = (payload = {}, action) => {
    switch (action.type) {
        case LOADED_PERMISSIONS:
            return action.payload;
        default:
            return payload;
    }
};
const user_curricula = (payload = [], action) => {
    switch (action.type) {
        case SET_USER_CURRICULA:
            return action.payload;
        default:
            return payload;
    }
};
const user_roles = (payload = [], action) => {
    switch (action.type) {
        case SET_USER_ROLES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    users_roles,
    users_curricula,
    users_permissions,
    user_curricula,
    user_roles
}
