import React, {forwardRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";

export default forwardRef(function ({row_index, component_index, component, ...props}, ref) {
    const dispatch = useDispatch();
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);
    const mouse_over_component = useSelector(state => state.challenge_builder_mouse_over_component);
    const page_index = useSelector(state => state.challenge_builder_index);

    function renderDeleteButton(row_index, component_index) {
        if (row_index === selected_row_index && component_index === selected_component_index && undefined !== component_index && null !== component_index) {
            return (
                <i
                    className='clickable fas fa-trash text-danger'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        const pages_new = JSON.parse(JSON.stringify(pages));
                        pages_new[page_index].data.card_content.rows[row_index].components.splice(component_index, 1);
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                    }}
                />
            );
        }

        return null;
    }

    function renderDuplicateButton(row_index, component_index) {
        if (row_index === selected_row_index && component_index === selected_component_index && undefined !== component_index && null !== component_index) {
            return (
                <i
                    className='clickable fas fa-clone text-info'
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        const pages_new = JSON.parse(JSON.stringify(pages));
                        pages_new[page_index].data.card_content.rows[row_index].components.splice(component_index, 0, _.cloneDeep(pages_new[page_index].data.card_content.rows[row_index].components[component_index]));
                        dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                        dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                    }}
                />
            );
        }

        return null;
    }

    return (
        <div
            ref={ref}
            {...props}
            className={'clickable ' + ((selected_component_index === component_index && selected_row_index === row_index) || (!!mouse_over_component && component_index === mouse_over_component.component_index && row_index === mouse_over_component.row_index) ? 'selected' : '')}
        >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{_.upperFirst(component.type)} {renderDuplicateButton(row_index, component_index)} {renderDeleteButton(row_index, component_index)}
        </div>
    );
});
