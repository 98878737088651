import {LOADED_STANDARD_TOPICS, LOADED_STANDARDS_CURRICULA} from "../actions/standard_actions";

const standards_curricula = (payload = [], action) => {
    switch (action.type) {
        case LOADED_STANDARDS_CURRICULA:
            return action.payload;
        default:
            return payload;
    }
};
const standard_topics = (payload = [], action) => {
    switch (action.type) {
        case LOADED_STANDARD_TOPICS:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    standards_curricula,
    standard_topics
}
