import React from 'react'
import PropTypes from "prop-types";

import TypeArray from './TypeArray';
import RecursiveValidator from "../validators/RecursiveValidator";

class TypeObject extends TypeArray {
    content() {
        const keys = Object.keys(this.props.value);
        return keys.map((key, i) => {
            const item = this.props.value[key];

            if (!item.hasOwnProperty('value')) {
                console.error('Value not provided for key "' + key + '" in ' + this.constructor.name, item);
            }
            if (!item.hasOwnProperty('validationResult')) {
                console.error('validationResult not provided for key "' + key + '" in ' + this.constructor.name, item);
            } else if (!item.validationResult.hasOwnProperty('isValid')) {
                console.error('isValid not provided for key "' + key + '" in ' + this.constructor.name, item);
            }

            return [
                <br key={key + '_br'}/>,
                this.renderPadding() + "\u00A0\u00A0\u00A0\u00A0" + '"' + key + '":' + "\u00A0",
                <RecursiveValidator
                    key={key + '_RecursiveValidator'}
                    item={item}
                    depth={this.props.depth + 1}
                />,
                (i < (keys.length - 1) ? ',' : null)
            ];
        });
    }

    render() {
        return [
            this.props.isArrayItem ? [<br key={'pad-br'}/>, this.renderPadding()] : null,
            '{',
            this.renderErrorMessages(),
            this.content(),
            <br key='obj-br'/>,
            this.renderPadding() + '}'
        ];
    }
}

TypeObject.propTypes = {
    value: PropTypes.object.isRequired,
    isArrayItem: PropTypes.bool,
    depth: PropTypes.number
};

export default TypeObject;
