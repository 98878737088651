import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    ListPage,
    accessConstants,
    authUtils,
    filterConstants,
    LoadStreamsAction,
    ReloadListAction
} from "mindsets-js-sdk";

import Table from './components/Table';
import {
    LoadRolesAction,
    LoadLessonAction
} from "../../actions/stream_actions";
import NoAccess from "../../common/NoAccess";

export default function () {
    const params = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.streams_lesson);
    const roles = useSelector(state => state.streams_roles);
    const getItemsListThrottle = _.throttle(() => dispatch(ReloadListAction(true, 'streams')), 2000, {
        leading: false,
        trailing: true
    })
    useEffect(() => {
        if (!roles.length) {
            dispatch(LoadRolesAction({limit: 0}));
        }
        dispatch(LoadLessonAction(params.lesson_id, {'selectors[0]': 'challenge_version'}));
    }, []);
    useEffect(() => {
        const lesson_channel = pusher.subscribe('lesson_' + params.lesson_id);
        lesson_channel.bind('stream.updated', () => getItemsListThrottle());
        lesson_channel.bind('stream.paused', () => getItemsListThrottle());
        lesson_channel.bind('stream.resumed', () => getItemsListThrottle());
        lesson_channel.bind('answer.updated', () => getItemsListThrottle());
        lesson_channel.bind('spreadsheet.updated', () => getItemsListThrottle());
        lesson_channel.bind('lesson.poll.updated', () => getItemsListThrottle());

        return () => lesson_channel.unsubscribe();
    }, []);
    if (!me || !Object.keys(lesson).length || !roles.length) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <h1>{'Students and Co-Teachers in the Class ' + lesson.code.toUpperCase() + ' doing ' + lesson.challenge_version.name + ' Challenge'}</h1>
            <ListPage
                action={options => LoadStreamsAction(Object.assign({'selectors[0]': 'user'}, options))}
                //TODO Disabling for now
                // filter_columns={[
                //     {
                //         key: 'code',
                //         name: 'Code',
                //         expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                //     },
                //     {
                //         key: 'persona',
                //         name: 'Joined as',
                //         expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS],
                //         values: this.props.roles.map(role => {
                //             return {
                //                 label: role.name,
                //                 value: role.id
                //             }
                //         })
                //     },
                //     {
                //         key: 'lesson_id',
                //         name: 'Lesson',
                //         expressions: [filterConstants.EXPRESSION_EQUAL],
                //         component: setFilterValue => {
                //             return (
                //                 <LessonDropdown onSelect={lesson => {
                //                     setFilterValue(lesson.id);
                //                     this.getItemsList();
                //
                //                     return lesson.code;
                //                 }} initialValue={this.props.lesson ? this.props.lesson.code : null} id={}/>
                //             );
                //         },
                //         multiple: false
                //     }
                // ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'users.fname',
                        name: 'First Name'
                    },
                    {
                        key: 'users.lname',
                        name: 'Last Name'
                    },
                    {
                        key: 'index',
                        name: 'Progress'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    }
                ]}
                entity={'streams'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <Link to={'/lessons/' + params.lesson_id + '/streams/new'} className="button">Add
                            Co-teachers</Link>
                    </div>
                )}
                extra={{'filters[lesson_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.lesson_id}}
                permissions={[accessConstants.PERMISSION_CAN_SEE_ALL_STREAMS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
