import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Highcharts from "highcharts";
import {Checkbox} from "mindsets-js-sdk";

import {INTERVAL_COLORS} from "../index";
import Slider from "./Slider";

export default function () {
    const impact_study = useSelector(state => state.impact_study);
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(400);
    const [font_size, setFontSize] = useState(14);
    const [title, setTitle] = useState('Performance Claims');
    const [titleEdit, setTitleEdit] = useState(false);
    const [selected, setSelected] = useState([]);

    function getCategories() {
        let categories = [];
        Object.keys(impact_study.cassp).map(key => {
            Object.keys(impact_study.cassp[key]).map(category => {
                if (!categories.includes(category)) {
                    categories.push(category);
                }
            });
        });

        return categories;
    }

    function getClaims() {
        return Object.keys(impact_study.cassp).filter(claim => selected.length <= 0 || selected.includes(claim));
    }

    function getSeries() {
        let series = [];
        getCategories().map((category, index) => {
            series.push({
                name: category,
                data: getClaims().map(claim => impact_study.cassp[claim][category].no_students),
                color: INTERVAL_COLORS[category],
                index: getCategories().length - index
            });
        });

        return series;
    }

    useEffect(() => {
        if (impact_study) {
            const options = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: undefined
                },
                xAxis: {
                    categories: getClaims(),
                    labels: {
                        style: {
                            fontSize: font_size + 'px'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Student %'
                    },
                    labels: {
                        style: {
                            fontSize: font_size + 'px'
                        }
                    }
                },
                tooltip: {
                    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true
                },
                plotOptions: {
                    column: {
                        stacking: 'percent',
                        maxPointWidth: 100
                    }
                },
                series: getSeries(),
                legend: {
                    reversed: true,
                    enabled: true,
                    title: {
                        text: 'Achievement levels',
                        style: {
                            fontSize: font_size + 'px'
                        }
                    },
                    itemStyle: {
                        fontSize: font_size + 'px'
                    }
                },
                credits: {
                    enabled: false
                }
            }
            Highcharts.chart('cassp-graph', options)
        }
    }, [impact_study, font_size, selected]);
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [width]);
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [height]);

    function renderSliderFontSize() {
        if (impact_study && impact_study.cassp) {
            return (
                <div className='mb-2'>
                    <label>Font size</label>
                    <Slider
                        start={font_size}
                        step={1}
                        min={10}
                        max={40}
                        onChange={val => setFontSize(val)}
                        tooltips={true}
                    />
                </div>
            );
        }

        return null;
    }

    function renderSliderHorizontal() {
        if (impact_study && impact_study.cassp) {
            return (
                <div>
                    <label>Width</label>
                    <Slider
                        start={width}
                        step={1}
                        min={0}
                        max={100}
                        onChange={val => setWidth(val)}
                        padding={[10, 0]}
                    />
                </div>
            );
        }

        return null;
    }

    function renderSliderVertical() {
        if (impact_study && impact_study.cassp) {
            return (
                <Slider
                    start={height}
                    step={10}
                    min={0}
                    max={800}
                    onChange={val => setHeight(val)}
                    orientation='vertical'
                    padding={[100, 0]}
                />
            );
        }

        return null;
    }

    function renderTitle() {
        if (impact_study) {
            if (titleEdit) {
                return (
                    <input value={title} onChange={event => setTitle(event.target.value)}/>
                );
            }

            return title;
        }

        return null;
    }

    if (impact_study && impact_study.cassp) {
        return (
            <div>
                <div className='row'>
                    <div className='col-11'>
                        {renderSliderFontSize()}
                        {renderSliderHorizontal()}
                        <div className='d-flex justify-content-between align-items-center flex-gap-5' style={{width: width + '%'}}>
                            <h3 className='text-center mt-3 graph-title flex-grow-1'>{renderTitle()}</h3>
                            <i className='fas fa-edit clickable' onClick={() => setTitleEdit(!titleEdit)}/>
                        </div>
                        <div id="cassp-graph" style={{width: width + '%', height}}/>
                    </div>
                    <div className='col-1'>{renderSliderVertical()}</div>
                </div>
                <div className='d-flex justify-content-center mt-2 mb-4 flex-gap-5'>
                    <span>Show only: </span>
                    {Object.keys(impact_study.cassp).map(claim => {
                        return (
                            <Checkbox
                                key={claim}
                                checked={selected.includes(claim)}
                                toggle={() => {
                                    let selected_new = JSON.parse(JSON.stringify(selected));
                                    const index = selected_new.findIndex(item => item === claim);
                                    if (index >= 0) {
                                        selected_new.splice(index, 1);
                                    } else {
                                        selected_new.push(claim);
                                    }
                                    setSelected(selected_new);
                                }}
                                label={claim}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
}
