import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils, HTMLContent} from 'mindsets-js-sdk';

class PollInlineOption extends Component {
    renderBar() {
        const {option_votes, total_votes} = this.props
        return (
            <div className="poll-bar flex-auto d-flex">
                <div className="poll-bar-color" style={{flex: option_votes}}>
                </div>
                <div style={{flex: total_votes - option_votes}}>
                </div>
            </div>
        )
    }

    renderStat() {
        const {option_votes} = this.props
        return (
            <div className="poll-stat d-flex align-items-center justify-content-center">
                <div>
                    {option_votes}
                </div>
                <i className="fas fa-user"/>
            </div>
        )
    }

    renderContent() {
        const class_name_icon = 'poll-option-radio far ' + (this.props.is_selected ? 'fa-dot-circle' : 'fa-circle');

        return (
            <div className="poll-option-content-container">
                <div className="d-flex align-items-center">
                    <i className={class_name_icon}/>

                    <div className="d-flex flex-auto">
                        <HTMLContent
                            className='d-flex justify-content-start'
                            html_str={contentUtils.processStringContent(this.props.poll_option.option, {
                                keep_html: true,
                                convert_math: true,
                                convert_spelling: false,
                            })}/>
                    </div>
                </div>
            </div>
        );
    }

    renderResult() {
        if (this.props.show_graph) {
            return (
                <div className="poll-option-result-container d-flex">
                    {this.renderBar()}

                    {this.renderStat()}
                </div>
            )
        }
    }

    render() {
        const class_name_poll_option = 'poll-option ' + (this.props.is_selected ? 'poll-option-selected' : 'z-depth-2');

        return (
            <div className={class_name_poll_option}>
                {this.renderContent()}

                {this.renderResult()}
            </div>
        )
    }
}

PollInlineOption.propTypes = {
    poll_option: PropTypes.object.isRequired,
    is_selected: PropTypes.bool.isRequired,
    show_graph: PropTypes.bool.isRequired,
    option_votes: PropTypes.number.isRequired,
    total_votes: PropTypes.number.isRequired,
}

export default PollInlineOption
