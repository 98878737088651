import Validator from "./Validator";

export default class JsonEntitiesEntityDataValidator extends Validator {
  constructor() {
    super();
    this.validationRules = {
      type: 'object',
      empty: false,
      mandatory: true,
      attributes: {
        answer_required: {
          type: 'boolean',
          empty: false,
          mandatory: true
        },
        is_bonus: {
          type: 'boolean',
          empty: false,
          mandatory: false
        },
        title: {
          type: 'string',
          empty: false,
          mandatory: true
        },
        button_label: {
          type: 'string',
          empty: false,
          mandatory: false
        },
        content: {
          type: 'string',
          empty: false,
          mandatory: false
        },
        subtitle: {
          type: 'string',
          empty: false,
          mandatory: false
        },
        content1: {
          type: 'string',
          empty: false,
          mandatory: false
        },
        content2: {
          type: 'string',
          empty: false,
          mandatory: false
        },
        card_content: {
          type: 'object',
          empty: false,
          mandatory: false
        },
      }
    };
  }
}
