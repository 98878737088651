import React from 'react';
import {useSelector} from "react-redux";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.settings);

    return (
        <div className="table-responsive settings-table">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Value</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item) => {
                    return <Row
                        key={item.key}
                        item={item}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
};
