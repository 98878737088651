import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors, DragOverlay,
} from "@dnd-kit/core";
import {
    SortableContext,
    horizontalListSortingStrategy
} from '@dnd-kit/sortable';
import {
    restrictToHorizontalAxis,
    restrictToWindowEdges
} from '@dnd-kit/modifiers';

import NavPage from "./NavPage";
import {SetChallengeBuilderChallengePagesAction} from "../../../../actions/challenge_actions";
import Draggable from "./Draggable";

export default function () {
    const dispatch = useDispatch();
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const [draggable_index, setDraggableIndex] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            },
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        })
    );

    function reorder(startIndex, endIndex) {
        let pages_new = JSON.parse(JSON.stringify(pages));
        const [removed] = pages_new.splice(startIndex, 1);
        pages_new.splice(endIndex, 0, removed);

        return pages_new;
    }

    function onDragEnd({active, over}) {
        if (active && over && active.id !== over.id) {
            dispatch(SetChallengeBuilderChallengePagesAction(reorder(active.id - 1, over.id - 1)));
        }
        setDraggableIndex(null);
    }

    function onDragStart({active}) {
        setDraggableIndex(active.id);
    }

    return (
        <DndContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
        >
            <div className='d-flex flex-row' style={{overflowX: 'scroll'}}>
                <SortableContext items={pages} strategy={horizontalListSortingStrategy}>
                    {pages.map((page, index) => <Draggable index={index} key={index}/>)}
                </SortableContext>
                <DragOverlay modifiers={[restrictToWindowEdges]}>
                    {draggable_index ? <NavPage index={draggable_index - 1}/> : null}
                </DragOverlay>
            </div>
        </DndContext>
    );
}
