import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const EditButton = function ({id}) {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_DISTRICTS])) {
        return (
            <Link to={'/districts/' + id} className="button">Edit</Link>
        );
    }

    return null;
}

EditButton.propTypes = {
    id: PropTypes.number.isRequired
}

export default EditButton;
