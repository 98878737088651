import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.schedules);
    const current = useSelector(state => state.schedules_current);
    const limit = useSelector(state => state.schedules_limit);
    let number_students = 0;
    let number_students_to_grade = 0;
    let number_students_graded = 0;
    let number_responses = 0;
    let number_responses_gradable = 0;
    let number_responses_to_grade = 0;
    let number_responses_graded = 0;
    let number_responses_auto_graded = 0;
    items.map(item => {
        if (item.number_students > 0) {
            number_students += item.number_students;
        }
        if (item.number_students_to_grade > 0) {
            number_students_to_grade += item.number_students_to_grade;
        }
        if (item.number_students_graded > 0) {
            number_students_graded += item.number_students_graded;
        }
        if (item.number_responses > 0) {
            number_responses += item.number_responses;
        }
        if (item.number_responses_to_grade > 0) {
            number_responses_to_grade += item.number_responses_to_grade;
        }
        if (item.number_responses_graded > 0) {
            number_responses_graded += item.number_responses_graded;
        }
        if (item.number_responses_gradable > 0) {
            number_responses_gradable += item.number_responses_gradable;
        }
        if (item.number_responses_auto_graded > 0) {
            number_responses_auto_graded += item.number_responses_auto_graded;
        }
    });

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">Class Code</th>
                    <th scope="col">Assessment Status</th>
                    <th scope="col">Class Created on</th>
                    <th scope="col">Scheduled on</th>
                    <th scope="col">Scheduled for</th>
                    <th scope="col">Standard</th>
                    <th scope="col">Minimum completion level</th>
                    <th scope="col"># students graded/requested/total</th>
                    <th scope="col">
                        <div># responses</div>
                        <div>
                            <span>graded</span>
                            <span>/</span>
                            <span className='text-info'>requested</span>
                            <span>/</span>
                            <span className='text-warning'>auto</span>
                            <span>/</span>
                            <span>gradable</span>
                            <span>/</span>
                            <span className='text-success'>total</span>
                        </div>
                    </th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return (
                        <Row
                            key={item.id}
                            item={item}
                            row_number={getRowNumber(index, current, limit)}
                        />
                    );
                })}
                <tr>
                    <td colSpan={11}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={8}><strong>Total</strong></td>
                    <td className='text-center'>
                        <strong>
                            <span
                                className={number_students_graded !== number_students_to_grade ? 'text-danger' : ''}
                            >{number_students_graded}</span>
                            <span>/</span>
                            <span>{number_students_to_grade}</span>
                            <span>/</span>
                            <span>{number_students}</span>
                        </strong>
                    </td>
                    <td className='text-center'>
                        <strong>
                            <span
                                className={number_responses_graded !== number_responses_to_grade ? 'text-danger' : ''}
                            >{number_responses_graded}</span>
                            <span>/</span>
                            <span className='text-info'>{number_responses_to_grade}</span>
                            <span>/</span>
                            <span className='text-warning'>{number_responses_auto_graded}</span>
                            <span>/</span>
                            <span>{number_responses_gradable}</span>
                            <span>/</span>
                            <span className='text-success'>{number_responses}</span>
                        </strong>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}
