import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {objectToStyleString, parseStyleString} from "../utils";

function Style({initial, onChange, name = 'Style'}) {
    const [style, setStyle] = useState(initial ? objectToStyleString(JSON.parse(JSON.stringify(initial))) : '');

    useEffect(() => {
        onChange(parseStyleString(style));
    }, [style]);

    return (
        <div>
            <label className="col-form-label">{name}</label>
            <textarea
                className="form-control"
                value={style}
                onChange={event => setStyle(event.target.value)}
            />
        </div>
    );
}

Style.propTypes = {
    name: PropTypes.string,
    initial: PropTypes.object,
    onChange: PropTypes.func.isRequired
};

export default Style;
