import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

const Table = function ({members}) {
    const items = useSelector(state => state.users);
    const curricula = useSelector(state => state.users_curricula);
    const current = useSelector(state => state.users_current);
    const limit = useSelector(state => state.users_limit);

    if (curricula.length) {
        return (
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr className="text-center">
                        <th scope="col">No</th>
                        <th scope="col">User ID</th>
                        <th scope="col">User Email</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Curriculum</th>
                        <th scope="col">
                            <div className='no-wrap'>Available roles</div>
                            <div className='no-wrap'>(i = initial, c = current)</div>
                        </th>
                        <th scope="col">No. Classes Created</th>
                        <th scope="col">Country</th>
                        <th scope="col">Membership status</th>
                        <th scope="col">Joined School</th>
                        <th scope="col">Lesson School(s)</th>
                        <th scope="col">No. Classes Joined as Student</th>
                        <th scope="col">No. Classes Joined as Co-Teacher</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((user, index) => {
                        return (
                            <Row
                                key={user.id}
                                user={user}
                                curriculum={curricula.find(curriculum => curriculum.id === user.curriculum_id)}
                                row_number={getRowNumber(index, current, limit)}
                                online={members.includes(user.id)}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
}

Table.propTypes = {
    members: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default Table;
