import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengeNameAction,
    SetChallengeBuilderChallengePagesAction
} from "../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();
    const challenge_name = useSelector(state => state.challenge_builder_challenge_name);
    const entities = useSelector(state => state.challenge_builder_challenge_pages);
    const [json, setJson] = useState(JSON.stringify({
        challenge_name,
        entities
    }, null, 4));
    const [error, setError] = useState('');

    useEffect(() => {
        setJson(JSON.stringify({
            challenge_name,
            entities
        }, null, 4));
    }, [entities, challenge_name]);
    useEffect(() => {
        let failed = false;
        let obj = undefined;
        try {
            obj = JSON.parse(json);
        } catch (e) {
            failed = true;
            setError(e.message);
        }
        if (undefined !== obj && false === failed) {
            if (obj.challenge_name && obj.challenge_name !== challenge_name) {
                dispatch(SetChallengeBuilderChallengeNameAction(obj.challenge_name));
            }
            if (obj.entities && JSON.stringify(obj.entities) !== JSON.stringify(entities)) {
                dispatch(SetChallengeBuilderChallengePagesAction(obj.entities));
            }
        }
    }, [json]);

    return (
        <div>
            <div className='text-danger' style={{minHeight: '40px'}}>{error}</div>
            <textarea
                style={{height: '550px'}}
                value={json}
                onChange={event => {
                    let string = event.target.value;
                    let rebuilt = undefined;
                    let failed = false;
                    try {
                        rebuilt = JSON.stringify(JSON.parse(string), null, 4);
                    } catch (e) {
                        failed = true;
                    }
                    if (undefined !== rebuilt && false === failed) {
                        string = rebuilt;
                    }
                    setJson(string);
                    setError('');
                }}/>
        </div>
    );

    // let textsByLine = json.split("\n");
    // return (
    //     <div className='d-flex'>
    //         <span
    //             name="labels"
    //             style={{
    //                 display: 'inline-block',
    //                 boxSizing: 'border-box',
    //                 verticalAlign: 'top',
    //                 height: '100%',
    //                 width: '44px',
    //                 margin: 0,
    //                 padding: '5px 0px 5px 10px',
    //                 overflow: 'hidden',
    //                 color: '#D4D4D4'
    //             }}
    //         >
    //             {textsByLine.map((line, line_number) => {
    //                 return (
    //                     <div key={line_number} style={{color: "rgb(212, 212, 212)"}}>{line_number + 1}</div>
    //                 );
    //             })}
    //         </span>
    //         <span
    //             contentEditable={true}
    //             suppressContentEditableWarning={true}
    //             autoComplete="off"
    //             autoCorrect="off"
    //             autoCapitalize="off"
    //             spellCheck={true}
    //             style={{
    //                 display: 'inline-block',
    //                 boxSizing: 'border-box',
    //                 verticalAlign: 'top',
    //                 height: '100%',
    //                 flex: '1 1 0%',
    //                 margin: 0,
    //                 padding: '5px',
    //                 overflowX: 'hidden',
    //                 overflowY: 'auto',
    //                 wordWrap: 'break-word',
    //                 whiteSpace: 'pre-line',
    //                 outline: 'none'
    //             }}
    //             onKeyUp={event => {
    //                 if (event.target.hasChildNodes()) {
    //                     let string = '';
    //                     const containerNode = event.target.cloneNode(true);
    //                     const children = containerNode.childNodes;
    //                     for (let i = 0; i < children.length; i++) {
    //                         let child = children[i];
    //                         string = string + child.textContent;
    //                     }
    //                     string = string.replaceAll(/\s+/gm, ' ');
    //                     setJson(string);
    //                     let failed = false;
    //                     let obj = undefined;
    //                     try {
    //                         obj = JSON.parse(string);
    //                     } catch (e) {
    //                         failed = true;
    //                     }
    //                     if (undefined !== obj && false === failed) {
    //                         dispatch(SetChallengeBuilderChallengeNameAction(obj.challenge_name));
    //                         dispatch(SetChallengeBuilderChallengePagesAction(obj.entities));
    //                     }
    //                 }
    //             }}
    //             ref={bodyRef}
    //         >
    //             {textsByLine.map((line, line_number) => {
    //                 return (
    //                     <div key={line_number} dangerouslySetInnerHTML={{__html: line.replaceAll(' ', '&nbsp;')}}/>
    //                 );
    //             })}
    //         </span>
    //     </div>
    // );
}
