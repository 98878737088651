import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    SetGradingProviderAction,
    CreateGradingProviderAction,
    LoadingAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const grading_provider = useSelector(state => state.grading_provider);
    useEffect(() => {
        return () => dispatch(SetGradingProviderAction({}));
    }, []);
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container section'>
            <h1>Create new Grading Provider {grading_provider.name ? grading_provider.name : ''}</h1>
            <form onSubmit={event => {
                event.preventDefault();
                dispatch(CreateGradingProviderAction(grading_provider)).then(() => navigate(-1)).catch(error => {
                    dispatch(LoadingAction(false));
                    dispatch(ErrorMessageAction(error));
                    console.error(error);
                });
            }} autoComplete="off" id="create-grading_provider-form">
                <div className="row">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={grading_provider.name ? grading_provider.name : ''}
                            onChange={event => {
                                let grading_provider_new = JSON.parse(JSON.stringify(grading_provider));
                                grading_provider_new.name = event.target.value;
                                dispatch(SetGradingProviderAction(grading_provider_new));
                            }}/>
                    </div>
                </div>

                <div className='d-flex flex-gap-5'>
                    <button type="submit" className="button"
                            disabled={!grading_provider.name || grading_provider.name.length < 3}>Submit
                    </button>
                    <button className="button default" onClick={event => {
                        event.preventDefault();
                        navigate(-1);
                    }}>Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
