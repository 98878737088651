import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SpreadsheetCell from './SpreadsheetCell';
import {cellToObject} from './utils';


class SpreadsheetRow extends Component {
    renderCells() {
        return _.map(this.props.data, (cell, col_key) => {
            return (
                <SpreadsheetCell
                    key={col_key}
                    data={cellToObject(cell, this.props.getPlaceholder(col_key))}
                    updateCell={cell => {
                        let row = this.props.data;
                        row[col_key] = cell;
                        this.props.updateRow(row)
                    }}
                />
            )
        })
    }

    render() {
        return (
            <tr className="spreadsheet-row">
                {this.renderCells()}
            </tr>
        )
    }
}

SpreadsheetRow.propTypes = {
    data: PropTypes.object.isRequired,
    getPlaceholder: PropTypes.func.isRequired,
    updateRow: PropTypes.func.isRequired
}

export default SpreadsheetRow
