import React, {Component} from 'react';
import PropTypes from "prop-types";

import TypeArray from "../types/TypeArray";
import TypeObject from "../types/TypeObject";
import TypeString from "../types/TypeString";
import TypeBoolean from "../types/TypeBoolean";
import TypeNumber from "../types/TypeNumber";
import TypeUnknown from "../types/TypeUnknown";

class RecursiveValidator extends Component {
    getType() {
        if (Array.isArray(this.props.item.value)) {
            return 'array';
        } else {
            return typeof this.props.item.value;
        }
    }

    render() {
        switch (this.getType(this.props.item.value)) {
            case 'array':
                return (
                    <TypeArray
                        value={this.props.item.value}
                        validationResult={this.props.item.validationResult}
                        isArrayItem={this.props.isArrayItem}
                        depth={this.props.depth}/>
                );
            case 'object':
                return (
                    <TypeObject
                        value={this.props.item.value}
                        validationResult={this.props.item.validationResult}
                        isArrayItem={this.props.isArrayItem}
                        depth={this.props.depth}/>
                );
            case 'string':
                return (
                    <TypeString value={this.props.item.value} validationResult={this.props.item.validationResult}/>
                );
            case 'boolean':
                return (
                    <TypeBoolean value={this.props.item.value} validationResult={this.props.item.validationResult}/>
                );
            case 'number':
                return (
                    <TypeNumber value={this.props.item.value} validationResult={this.props.item.validationResult}/>
                );
            default:
                return (
                    <TypeUnknown value={this.props.item.value} validationResult={this.props.item.validationResult}/>
                );
        }
    }
}

RecursiveValidator.defaultProps = {
    isArrayItem: false,
    depth: 0
}

RecursiveValidator.propTypes = {
    item: PropTypes.shape({
        value: PropTypes.any.isRequired,
        validationResult: PropTypes.shape({
            isValid: PropTypes.bool.isRequired,
            messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
        }).isRequired
    }).isRequired,
    isArrayItem: PropTypes.bool,
    depth: PropTypes.number,
};

export default RecursiveValidator;
