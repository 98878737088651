import React, {Component} from 'react';
import PropTypes from 'prop-types';

import NumberLineBase from '../../NumberLine';


class NumberLine extends Component {
    render() {
        const {data} = this.props.component_data;
        return (
            <div className="challenge-component-numberline">
                <NumberLineBase
                    data={data}
                    value={data.initial_pos}
                />
            </div>
        );
    }
}

NumberLine.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default NumberLine
