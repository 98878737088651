import React from 'react';
import {contentUtils} from "mindsets-js-sdk";

import BaseCard from './BaseCard';

export default class WelcomeCard extends BaseCard {
    getCardType() {
        return 'welcome';
    }

    useChallengeColor() {
        return true;
    }

    getMinHeight() {
        return '400px';
    }

    renderWelcome() {
        return (
            <div className="section">
                <h2 className="header-new">
                    <input
                        type='text'
                        className='clear text-center'
                        placeholder='Put page title here'
                        value={this.props.page.data.title} onChange={event => {
                        let page = this.props.page;
                        page.data.title = event.target.value;
                        this.props.updatePage(page);
                    }}/>
                </h2>
            </div>
        )
    }

    renderSubTitle() {
        if (this.props.page.data.subtitle) {
            return (
                <div className="section">
                    <div className="text-center">
                        {contentUtils.processStringContent(this.props.page.data.subtitle)}
                    </div>
                </div>
            )
        }
    }

    renderIframe() {
        if (this.props.page.data.iframe) {
            return (
                <div className="section">
                    <div className="challenge-welcome-iframe">
                        <iframe src={this.props.page.data.iframe}/>
                    </div>
                </div>
            )
        }
    }

    renderContentOne() {
        if (this.props.page.data.content1) {
            return (
                <div className="section">
                    <div className="text-center">
                        {contentUtils.processStringContent(this.props.page.data.content1)}
                    </div>
                </div>
            )
        }
    }

    renderContentTwo() {
        if (this.props.page.data.content2) {
            return (
                <div className="section">
                    <div className="text-center">
                        {contentUtils.processStringContent(this.props.page.data.content2)}
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                {this.renderWelcome()}

                {this.renderSubTitle()}

                {this.renderContentTwo()}

                {this.renderIframe()}

                {this.renderContentOne()}
            </div>
        )
    }
}
