import React from 'react'

import BaseAnswer from '../BaseAnswer'
import Items from './Items'
import Selections from './Selections'


export default class Selector extends BaseAnswer {
    defaultAnswer() {
        return {
            text: '',
            answer_selections: [],
            total_value: 0
        }
    }

    isValid() {
        return true;
    }

    getAnswerText(answer_selections) {
        if (_.isEmpty(answer_selections)) {
            return ''
        }

        const unit_prefix = _.get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = _.get(this.props.component_data, 'data.unit_suffix', '')
        const items = _.get(this.props.component_data, 'data.items', [])
        const selection_details = answer_selections.map((selection_data) => {
            const item = _.find(items, {id: selection_data.id})
            const selection_value_text = `${unit_prefix}${item.value}${unit_suffix}`
            const selection_subtotal = item.value * selection_data.amount
            const selection_subtotal_text = `${unit_prefix}${selection_subtotal}${unit_suffix}`
            const selection_text = `${item.title}: ${selection_value_text} x ${selection_data.amount} = ${selection_subtotal_text}`
            return selection_text
        })
        const total = this.getTotalValue(answer_selections)
        const total_text = `Total = ${unit_prefix}${total}${unit_suffix}`
        const answer_text = selection_details.join('\n') + '\n' + total_text

        return answer_text
    }

    getTotalValue(answer_selections) {
        let total = 0
        const items = _.get(this.props.component_data, 'data.items', [])
        answer_selections.map((selection_data) => {
            const item = _.find(items, {id: selection_data.id})
            total += item.value * selection_data.amount
        })
        return total
    }

    onClickItem() {
        return () => {
        }
    }

    renderItems() {
        return (
            <Items
                component_data={this.props.component_data}
                onClickItem={this.onClickItem()}
            />
        )
    }

    renderSelections() {
        return (
            <Selections
                component_data={this.props.component_data}
                answer={this.defaultAnswer()}
                onClickItem={this.onClickItem()}
            />
        )
    }

    render() {
        return (
            <div className="challenge-component-selector">
                {this.renderItems()}
                {this.renderSelections()}
            </div>
        )
    }
}
