import React, {Component} from 'react';
import PropTypes from 'prop-types';


export default class MatchingInstruction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    onClick = (e) => {
        e.preventDefault()
        this.setState({
            show: !this.state.show
        })
        return false
    }

    renderTrigger() {
        return (
            <div>
                <a href="#" onClick={this.onClick}>
                    {`How do I use this?`}
                </a>
            </div>
        )
    }

    renderInstruction() {
        if (!this.state.show) {
            return null
        }

        const header_name_l = _.get(this.props.component_data, 'data.items_left.header_name')
        const header_name_r = _.get(this.props.component_data, 'data.items_right.header_name')
        return (
            <div>
                <ul>
                    <li>
                        {`Click an item under ${header_name_l} and then click the matching item under ${header_name_r}.`}
                    </li>
                    <li>
                        {`To change a match, click the item and then its new matching item.`}
                    </li>
                    <li>
                        {`Click CLEAR SELECTIONS to clear all matches.`}
                    </li>
                </ul>
            </div>
        )
    }

    render() {
        return (
            <div className="cc-matching-instruction">
                {this.renderTrigger()}

                {this.renderInstruction()}
            </div>
        )
    }
}

MatchingInstruction.propTypes = {
    component_data: PropTypes.object.isRequired
}