import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ErrorMessageAction, ImportChallengeAction, ReloadListAction} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    const [code, setCode] = useState('');
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CHALLENGES])) {
        return (
            <div>
                <div className="modal fade" id={'import-challenge-modal'} tabIndex="-1" role="dialog"
                     aria-labelledby="importChallengeModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Import new challenge</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to import a new Challenge?</p>
                                <p>To confirm, please enter the Challenge Code into the field below.</p>
                                <div className='input-field'>
                                    <input
                                        type='text'
                                        onChange={event => setCode(event.target.value)}
                                        value={code}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                                <button className="button red" onClick={event => {
                                    event.preventDefault();
                                    if (!code || '' === code) {
                                        dispatch(ErrorMessageAction('Challenge Code must not be empty'));
                                    } else {
                                        dispatch(ImportChallengeAction({challenge_code: code})).then(() => dispatch(ReloadListAction(true, 'challenges')));
                                        setCode('');
                                        bootstrap.Modal.getInstance(document.getElementById("import-challenge-modal")).hide();
                                    }
                                }}>Import
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="button" data-bs-toggle="modal" data-bs-target={'#import-challenge-modal'}>
                    + Import Challenge
                </button>
            </div>
        );
    }

    return null;
};
