import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils, filterConstants} from "mindsets-js-sdk";

const NumberLessonsJoinedAsStudent = function ({user}) {
    const me = useSelector(state => state.me);

    if (user.streams_count > 0 && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_ALL_LESSONS])) {
        return (
            <Link
                to={`/lessons?column~0=student_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${user.id}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Show all the Classes the user joined as a Student"
            >{user.streams_count}</Link>
        );
    } else {
        return user.streams_count;
    }
}

NumberLessonsJoinedAsStudent.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        streams_count: PropTypes.number.isRequired
    }).isRequired
};

export default NumberLessonsJoinedAsStudent;
