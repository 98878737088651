import React from "react";
import PropTypes from "prop-types";

const Audience = function ({audience}) {
    if (audience) {
        return (
            <div>
                {Object.keys(audience).map(audience_group => {
                    return (
                        <div key={audience_group}>
                            <div>{audience_group}:</div>
                            <div>{audience[audience_group].map(audience => {
                                return (
                                    <div key={audience}>&nbsp;&nbsp;&nbsp;&nbsp;{audience}</div>
                                );
                            })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
}

Audience.propTypes = {
    audience: PropTypes.object
};

export default Audience;
