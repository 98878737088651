import React, {Component, memo} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

require('highcharts/modules/annotations')(Highcharts);

class Graph extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'challenge-component-graph-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.draw()
    }

    componentDidUpdate() {
        this.draw()
    }

    draw() {
        const graph = _.merge({}, {
            annotationsOptions: {
                enabledButtons: false
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br />',
                pointFormat: 'x = {point.x}, y = {point.y}'
            }
        }, this.props.component_data.data)
        this.chart = Highcharts.chart(this.uniq_id, graph)
    }

    render() {
        return (
            <div className="challenge-component-graph">
                <div id={this.uniq_id}>
                </div>
            </div>
        )
    }
}

Graph.propTypes = {
    component_data: PropTypes.object.isRequired
}

function arePropsEqual(oldProps, newProps) {
    return (
        oldProps.component_data.data === newProps.component_data.data
    );
}

export default memo(function (props) {
    return <Graph {...props}/>;
}, arePropsEqual)
