import {LOADED_RESPONSES_PRACTICES} from "../actions/response_actions";

const responses_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_RESPONSES_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    responses_practices
}
