import MindsetsJsSDK, {
    filterConstants,
    setLimitAction,
    setCurrentAction
} from "mindsets-js-sdk";

export const LOADED_STUDENT_COMPLETION = '[student_completion] Loaded Student Completion';
export const LOADED_STUDENT_COMPLETION_PAGES = '[student_completion] Loaded pages';
export const LOADED_STUDENT_COMPLETION_TOTAL = '[student_completion] Loaded total';

export const LoadStudentCompletionAction = options => dispatch => {
    return Promise.resolve()
        .then(() => {
            options['selectors[0]'] = 'user_school';
            options['selectors[1]'] = 'number_stream_entities';
            options['selectors[2]'] = 'challenge_responses';
            options['selectors[3]'] = 'streams_user';
            options['selectors[4]'] = 'challengeVersion_challengeCode_standards';
            options['filters[persona][0]'] = filterConstants.EXPRESSION_EQUAL + '=student';
            return MindsetsJsSDK().API.get('lessons', options);
        })
        .then(response => {
            dispatch(LoadedStudentCompletionAction(response.items));
            dispatch(setCurrentAction('student_completion', response.pages >= response.current ? response.current : 1));
            dispatch(setLimitAction('student_completion', response.limit));
            dispatch(LoadedStudentCompletionPagesAction(response.pages));
            dispatch(LoadedStudentCompletionTotalAction(response.total));

            return response.items;
        });
};
export const LoadedStudentCompletionAction = payload => ({
    type: LOADED_STUDENT_COMPLETION,
    payload
});
export const LoadedStudentCompletionPagesAction = payload => ({
    type: LOADED_STUDENT_COMPLETION_PAGES,
    payload
});
export const LoadedStudentCompletionTotalAction = payload => ({
    type: LOADED_STUDENT_COMPLETION_TOTAL,
    payload
});
