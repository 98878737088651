import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils, HTMLContent} from "mindsets-js-sdk";


class PollBlockOption extends Component {
    renderDescription() {
        return (
            <div className="poll-option-image-description d-flex align-items-center justify-content-center text-center">
                <input
                    type='text'
                    className='clear'
                    placeholder='Put description here'
                    value={this.props.poll_option.description ? this.props.poll_option.description : ''}
                    onChange={event => {
                        let poll_option = this.props.poll_option;
                        poll_option.description = event.target.value;
                        this.props.updateOption(poll_option);
                    }}/>
            </div>
        )
    }

    render() {
        const class_name_poll_option = 'poll-option' + (this.props.is_selected ? ' poll-option-selected' : '');
        let class_name_icon = 'poll-option-radio far ' + (this.props.is_selected ? 'fa-dot-circle' : 'fa-circle');

        return (
            <div className={class_name_poll_option}>
                <div className="poll-option-image-container">
                    <img className="poll-option-image" src={this.props.poll_option.option} alt=''/>
                    {this.renderDescription()}
                </div>

                <div className="poll-option-content-container">
                    <div className="d-flex align-items-center justify-content-center">
                        <i className={class_name_icon}/>

                        <div className="poll-option-title text-center">
                            <HTMLContent
                                className='d-flex justify-content-start'
                                html_str={contentUtils.processStringContent(this.props.poll_option.title, {
                                    keep_html: true,
                                    convert_math: true,
                                    convert_spelling: false,
                                })}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PollBlockOption.propTypes = {
    poll_option: PropTypes.object.isRequired,
    is_selected: PropTypes.bool.isRequired,
    updateOption: PropTypes.func.isRequired
}

export default PollBlockOption
