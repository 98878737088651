import React from 'react';
import {Link} from "react-router-dom";

import './not_found.scss';

export default function () {
    return (
        <div className="container">
            <div className="section center not-found">
                <div className="not-found-title">UH-OH</div>
                <div className="not-found-title">Page not found</div>
                <h5>The page you are looking for can not be found or does not exist.</h5>
                <div className="not-found-back">
                    <span>Return to the</span>&nbsp;
                    <Link to="/">home page</Link><span>.</span>
                </div>
                <div className="not-found-contact-us">
                    <div className="flex-row flex-vh-center flex-box-2-5">
                        <i className="far fa-envelope"/>
                        <a href="https://learn.mindsets.com/hc/en-us/requests/new" target="_blank">Contact
                            us</a>
                    </div>
                </div>
                <img className="not-found-image" src={process.env.PUBLIC_S3_ASSETS_URL + '/application/404.png'}
                     alt="404 Page not found"/>
            </div>
        </div>
    )
}
