import React from 'react';
import PropTypes from "prop-types";
import {membershipUtils, datesConstants} from "mindsets-js-sdk";

const SchoolSubscription = function ({user}) {
    let subscriptions = JSON.parse(JSON.stringify(membershipUtils.getSchoolSubscriptions(user)));
    subscriptions.sort((a, b) => b.id - a.id);
    if (subscriptions.length > 0) {
        const valid = membershipUtils.isSchoolSubscriptionValid(subscriptions[0]);
        return (
            <div>
                <div>Has {valid ? 'valid' : 'expired'} School subscription</div>
                <div
                    className="text-right no-wrap">From: {new Date(subscriptions[0].starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                <div
                    className="text-right no-wrap">Until: {new Date(subscriptions[0].ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                <hr/>
            </div>
        );
    }

    return null;
}

SchoolSubscription.propTypes = {
    user: PropTypes.shape({
        school: PropTypes.shape({
            subscriptions: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                starts_at: PropTypes.number.isRequired,
                ends_at: PropTypes.number.isRequired
            }))
        })
    }).isRequired
};

export default SchoolSubscription;
