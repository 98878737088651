import MindsetsJsSDK, {setLimitAction, setCurrentAction} from "mindsets-js-sdk";

export const LOADED_TIMESPENT = '[timespent] Loaded Timespent';
export const LOADED_TIMESPENT_PAGES = '[timespent] Loaded pages';
export const LOADED_TIMESPENT_TOTAL = '[timespent] Loaded total';

export const LoadTimespentAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('statistics/streams/times', options))
        .then(response => {
            dispatch(LoadedTimespentAction(response.items));
            dispatch(setCurrentAction('timespent', response.pages >= response.current ? response.current : 1));
            dispatch(setLimitAction('timespent', response.limit));
            dispatch(LoadedTimespentPagesAction(response.pages));
            dispatch(LoadedTimespentTotalAction(response.total));

            return response.items;
        });
};
export const LoadedTimespentAction = payload => ({
    type: LOADED_TIMESPENT,
    payload
});
export const LoadedTimespentPagesAction = payload => ({
    type: LOADED_TIMESPENT_PAGES,
    payload
});
export const LoadedTimespentTotalAction = payload => ({
    type: LOADED_TIMESPENT_TOTAL,
    payload
});

