import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_STANDARDS_CURRICULA = '[standards] Loaded Curricula';
export const LOADED_STANDARD_TOPICS = '[standard] Loaded Topics';

export const LoadCurriculaAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('curriculums', options))
        .then(response => dispatch(LoadedCurriculaAction(response.items)));
};
export const LoadedCurriculaAction = payload => ({
    type: LOADED_STANDARDS_CURRICULA,
    payload
});

export const LoadStandardTopicsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('standards/topics', options))
        .then(response => {
            dispatch(LoadedStandardTopicsAction(response.items));

            return response.items;
        });
};
export const LoadedStandardTopicsAction = payload => ({
    type: LOADED_STANDARD_TOPICS,
    payload
});
