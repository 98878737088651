import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {CheckUpdatesAction, ErrorMessageAction, LoadingAction} from "mindsets-js-sdk";

import ActivateButton from "./ActivateButton";
import DisableButton from "./DisableButton";

const Row = function ({item}) {
    const dispatch = useDispatch();

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.challenge_code.code}</td>
            <td>{item.version}</td>
            <td>{item.challenge_code.status}</td>
            <td>{item.challenge_code.is_free ? <i className="fas fa-check"/> : null}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <button className='button' onClick={event => {
                        event.preventDefault();
                        dispatch(CheckUpdatesAction(item.id)).then(() => {
                            let updates_modal = bootstrap.Modal.getInstance(document.getElementById("check-challenge-updates-modal"));
                            if (!updates_modal) {
                                updates_modal = new bootstrap.Modal(document.getElementById('check-challenge-updates-modal'));
                            }
                            updates_modal.show();
                        }).catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                    }}>Check for updates
                    </button>
                    <ActivateButton item={item}/>
                    <DisableButton item={item}/>
                    <Link to={`/challenges/${item.challenge_code.code}`} className='button'>Versions</Link>
                    <Link
                        to={'/questions?column~0=challenge_code_id&expression~0=eq&value~0=' + item.challenge_code.id}
                        className='button'
                    >Questions</Link>
                    <Link
                        to={'/rubrics/' + item.challenge_code.code}
                        className='button'
                    >Rubrics</Link>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            is_free: PropTypes.bool.isRequired,
            is_paid_only: PropTypes.bool.isRequired
        })
    })
};

export default Row;
