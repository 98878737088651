import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PollBlockOptions from './PollBlockOptions';
import PollInlineOptions from './PollInlineOptions';


class PollOptions extends Component {
    getPollOptionsClass() {
        switch(this.props.component_data.type) {
            case 'poll.block':
                return PollBlockOptions
            case 'poll.inline':
            default:
                return PollInlineOptions
        }
    }

    render() {
        const PollOptionsClass = this.getPollOptionsClass()
        return (
            <div className="poll-options">
                <PollOptionsClass poll={this.props.component_data.data} updateComponentData={data => {
                    let component_data = this.props.component_data;
                    component_data.data = data;
                    this.props.updateComponent(component_data);
                }}/>
            </div>
        )
    }
}

PollOptions.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default PollOptions
