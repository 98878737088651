import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {HTMLContent, contentUtils} from "mindsets-js-sdk";

class Step extends Component {
    getContainerClassName() {
        const is_bonus = _.get(this.props.component_data, 'config.is_bonus');
        let classname = 'component-step-container';
        if (is_bonus) {
            classname += ' component-step-container-bonus';
        }

        return classname;
    }

    getContainerStyle() {
        const width = _.get(this.props.component_data, 'config.width')
        return {
            width
        }
    }

    renderTick() {
        const show_tick = _.get(this.props.component_data, 'config.show_tick')
        if (show_tick) {
            return (
                <div className="component-step-tick">
                    <i className="fas fa-check-circle"/>
                </div>
            );
        }

        return null
    }

    renderNext() {
        const show_next = _.get(this.props.component_data, 'config.show_next')
        if (show_next) {
            return (
                <div className="component-step-next">
                    {`NEXT`}
                </div>
            )
        }
        return null
    }

    renderImage() {
        const url = _.get(this.props.component_data, 'data.image_url')
        return (
            <div className="component-step-image">
                <img src={url} alt=''/>
            </div>
        )
    }

    renderContent() {
        const title = _.get(this.props.component_data, 'data.title')
        const description = _.get(this.props.component_data, 'data.description')
        return (
            <div className="component-step-content d-flex flex-auto flex-column justify-content-center">
                <div className="component-step-content-title">
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put title here'
                        value={title}
                        onChange={event => {
                            let component_data = this.props.component_data;
                            component_data.data.title = event.target.value;
                            this.props.updateComponent(component_data);
                        }}/>
                </div>

                <div className="component-step-content-description">
                    <HTMLContent html_str={contentUtils.processStringContent(description)}/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-step">
                <div className={this.getContainerClassName()} style={this.getContainerStyle()}>
                    <div className="d-flex">
                        {this.renderImage()}
                        {this.renderContent()}
                    </div>

                    {this.renderTick()}
                    {this.renderNext()}
                </div>
            </div>
        )
    }
}

Step.propTypes = {
    component_data: PropTypes.object.isRequired,
    updateComponent: PropTypes.func.isRequired
}

export default Step
