import React from 'react';

import BaseComponentProperty from "../index";

export default class Question extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="code">Question code</label>
                    <input
                        type='text'
                        id="code"
                        className='form-control'
                        value={this.props.component.data.code ? this.props.component.data.code.toString() : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.code = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="question">Question</label>
                    <textarea
                        id="question"
                        className='form-control'
                        value={this.props.component.data.question ? this.props.component.data.question : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.question = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="hint">Hint</label>
                    <textarea
                        id="hint"
                        className='form-control'
                        value={this.props.component.data.hint ? this.props.component.data.hint : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.hint = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }
}
