import React from 'react';

import BaseComponentProperty from "../index";

export default class Graph extends BaseComponentProperty {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.getState, {
            graph_data: JSON.stringify(props.component.data),
            is_valid: this.isValid(JSON.stringify(props.component.data))
        })
    }

    isValid(graph_data) {
        let is_valid = true;
        try {
            JSON.parse(graph_data);
        } catch (error) {
            is_valid = false;
        }

        return is_valid;
    }

    hasContainerStyle() {
        return true;
    }

    renderOwnProperties() {
        return (
            <div>
                <label htmlFor="graph_data">Graph data</label>
                <textarea
                    id="graph_data"
                    className={'form-control ' + (this.state.is_valid ? 'is-valid' : 'is-invalid')}
                    value={this.state.graph_data}
                    onChange={event => {
                        const graph_data = event.target.value;
                        let is_valid = this.isValid(graph_data);
                        this.setState({
                            graph_data,
                            is_valid
                        }, () => is_valid ? this.props.onChange('data', JSON.parse(graph_data)) : null);
                    }}
                />
            </div>
        );
    }
}
