import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: true,
            attributes: {
                title: {
                    type: ['string', 'object'],
                    empty: false,
                    mandatory: false,
                },
                name: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                first_num: {
                    type: 'number',
                    empty: false,
                    mandatory: false,
                },
                last_num: {
                    type: 'number',
                    empty: false,
                    mandatory: false,
                },
                initial_pos: {
                    type: 'number',
                    empty: false,
                    mandatory: false,
                },
                increment_interval: {
                    type: 'number',
                    empty: false,
                    mandatory: false,
                },
                snap_divisions_per_interval: {
                    type: 'number',
                    empty: false,
                    mandatory: false,
                },
                description: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                html: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                src: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                question: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                hint: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                link: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                imageURL: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                image_name: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                text: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                bubble_direction: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                bubble_background: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                animated_gif_url: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                animated_git_name: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                question_number: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                placeholder: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                min: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                max: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                step: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                unit: {
                    type: 'string',
                    empty: true,
                    mandatory: false,
                },
                value: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                caption: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                class_name: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                valueMin: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                valueMax: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                button_label: {
                    type: 'string',
                    empty: false,
                    mandatory: false,
                },
                items: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                poll_options: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                series: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                headers: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                numberPoints: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                button_sets: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                left: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                image: {
                    type: ['string', 'object'],
                    empty: false,
                    mandatory: false,
                },
                right: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                chart: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                xAxis: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                yAxis: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                tooltip: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                legend: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                data: {
                    type: 'array',
                    empty: false,
                    mandatory: false,
                },
                options: {
                    type: 'object',
                    empty: false,
                    mandatory: false,
                },
                credits: {
                    type: 'boolean',
                    empty: false,
                    mandatory: false,
                }
            }
        };
    }
}
