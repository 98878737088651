import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ReadOnly extends Component {
    render() {
        return (
            <div className="component-textarea-readonly">
                <span className="textarea-readonly-placeholder">{this.props.placeholder}</span>
            </div>
        )
    }
}

ReadOnly.propTypes = {
    placeholder: PropTypes.string.isRequired
}

export default ReadOnly
