import {
    LOADED_STREAMS_LESSON,
    LOADED_STREAMS_ROLES,
} from "../actions/stream_actions";

const streams_roles = (payload = [], action) => {
    switch (action.type) {
        case LOADED_STREAMS_ROLES:
            return action.payload;
        default:
            return payload;
    }
};
const streams_lesson = (payload = {}, action) => {
    switch (action.type) {
        case LOADED_STREAMS_LESSON:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    streams_roles,
    streams_lesson
}
