import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SpreadsheetHeader extends Component {
    renderCells(row, updateRow) {
        return Object.keys(row).map((cell_key, i) => {
            let cell = JSON.parse(JSON.stringify(row[cell_key]));
            const style = (typeof cell === 'object') ? cell.style : undefined;
            if (typeof cell === 'object') {
                if (!cell.hasOwnProperty('title')) {
                    cell.title = '';
                }
            }

            return (
                <th key={i} style={style}>
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put text here'
                        value={(typeof cell === 'object') && cell.hasOwnProperty('title') ? cell.title : cell}
                        onChange={event => {
                            if (typeof cell === 'object') {
                                if (!cell.hasOwnProperty('title')) {
                                    cell.title = '';
                                }
                                cell.title = event.target.value;
                            } else {
                                cell = event.target.value;
                            }
                            row[cell_key] = cell;
                            updateRow(row);
                        }}
                    />
                </th>
            );
        })
    }

    renderRows() {
        return this.props.data.map((row, i) => {
            return (
                <tr key={i}>
                    {this.renderCells(row, row => {
                        let rows = JSON.parse(JSON.stringify(this.props.data));
                        rows[i] = row;
                        this.props.updateRows(rows)
                    })}
                </tr>
            )
        })
    }

    render() {
        return (
            <thead>
            {this.renderRows()}
            </thead>
        )
    }
}

SpreadsheetHeader.propTypes = {
    data: PropTypes.array.isRequired,
    updateRows: PropTypes.func.isRequired
}

export default SpreadsheetHeader
