import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    authUtils,
    SoftUpdateChallengeContentAction,
    LoadedChallengeUpdatesAction,
    IncreaseChallengeVersionAction,
    LoadingAction,
    ErrorMessageAction,
    ReloadListAction
} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const challenge_updates = useSelector(state => state.challenge_updates);
    const me = useSelector(state => state.me);
    const items = useSelector(state => state.challenges);
    const renderChallengeUpdates = function () {
        if (challenge_updates) {
            return (
                <div>
                    {Object.keys(challenge_updates.data.messages).map(key =>
                        <div key={key}>{challenge_updates.data.messages[key]}</div>)}
                </div>
            );
        }

        return null;
    }
    const renderIncreaseChallengeVersionButton = function () {
        if (challenge_updates && (challenge_updates.data.dirty > 0 || challenge_updates.data.new > 0 || challenge_updates.data.removed > 0) && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CHALLENGES])) {
            return (
                <button className="button red" onClick={event => {
                    event.preventDefault();
                    if (confirm('Are you sure you want to update challenge content? It will increase challenge version.')) {
                        dispatch(IncreaseChallengeVersionAction(challenge_updates.challenge_version_id)).then(() => dispatch(ReloadListAction(true, 'challenges')));
                        bootstrap.Modal.getInstance(document.getElementById("check-challenge-updates-modal")).hide();
                    }
                }}>Create new version</button>
            );
        }

        return null;
    }
    const renderSoftUpdateChallengeButton = function () {
        if (challenge_updates && challenge_updates.data.dirty > 0 && challenge_updates.data.new <= 0 && challenge_updates.data.removed <= 0 && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CHALLENGES])) {
            return (
                <button className="button red" onClick={event => {
                    event.preventDefault();
                    const challenge_version = items.find(challenge_version => challenge_version.id === challenge_updates.challenge_version_id);
                    dispatch(SoftUpdateChallengeContentAction(challenge_version.challenge_code.code))
                        .then(() => dispatch(ReloadListAction(true, 'challenges')))
                        .then(() => dispatch(LoadedChallengeUpdatesAction(null)))
                        .then(() => bootstrap.Modal.getInstance(document.getElementById("check-challenge-updates-modal")).hide())
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }}>Soft Update content</button>
            );
        }

        return null;
    }

    return (
        <div className="modal fade" id={'check-challenge-updates-modal'} tabIndex="-1" role="dialog"
             aria-labelledby="checkChallengeUpdatesModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Check challenge updates</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        {renderChallengeUpdates()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                        {renderIncreaseChallengeVersionButton()}
                        {renderSoftUpdateChallengeButton()}
                    </div>
                </div>
            </div>
        </div>
    );
}
