import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils} from "mindsets-js-sdk";

class ClickToShow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    renderButton() {
        const onClick = () => {
            this.setState({show: true})
        }
        return (
            <button className="button large blue" onClick={onClick}>
                {_.get(this.props.component_data, 'data.button_label')}
            </button>
        )
    }

    renderContent() {
        if (this.state.show) {
            const content = _.get(this.props.component_data, 'data.html')
            return (
                <div className="text-center">
                    {contentUtils.processStringContent(content)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-clicktoshow">
                <div className="d-flex flex-column align-items-center justify-content-center flex-box-10-20">
                    <div>
                        {this.renderButton()}
                    </div>

                    <div>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

ClickToShow.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default ClickToShow
