import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    accessConstants,
    filterConstants,
    SearchResult,
    authUtils,
    Paginator,
    LoadResponsesAction, Checkbox
} from "mindsets-js-sdk";

import Table from "./components/Table";
import NoAccess from "../../common/NoAccess";
import Question from "./components/Question";
import Practice from "./components/Practice";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const me = useSelector(state => state.me);
    const responses_current = useSelector(state => state.responses_current);
    const responses_limit = useSelector(state => state.responses_limit);
    const [with_reasoning, setWithReasoning] = useState(false);
    const action = function () {
        dispatch(LoadResponsesAction({
            'filters[question_code][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.question_code,
            'filters[practice_id][0]': filterConstants.EXPRESSION_EQUAL + '=' + params.practice_id,
            current: responses_current,
            limit: responses_limit,
            'selectors[0]': 'with_score',
            'selectors[1]': 'with_ai_score',
            'selectors[2]': 'with_reasoning',
            'filters[with_trashed][0]': filterConstants.EXPRESSION_TRUE,
            'filters[not_matching][0]': filterConstants.EXPRESSION_TRUE,
            'filters[with_reasoning][0]': with_reasoning ? filterConstants.EXPRESSION_TRUE : filterConstants.EXPRESSION_FALSE,
        }));
    }
    useEffect(() => {
        action();
    }, []);
    useEffect(() => {
        action();
    }, [responses_current, responses_limit]);
    useEffect(() => {
        action();
    }, [with_reasoning]);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <h1>Grading scores review</h1>
            <Question/>
            <Practice/>
            <Checkbox checked={with_reasoning} toggle={() => setWithReasoning(!with_reasoning)} label='With reasoning only'/>
            <div className='section'>
                <SearchResult entity='responses'/>
                <Table/>
                <Paginator entity='responses'/>
            </div>
        </>
    );
}
