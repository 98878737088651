import React, {useCallback} from 'react';
import {Link} from "react-router-dom";
import {
    ListPage,
    filterConstants,
    accessConstants,
    countriesConstants,
    DistrictDropdown,
    SchoolDropdown,
    LoadContractsAction
} from "mindsets-js-sdk";

import Table from "./components/Table";

export default function () {
    const debounce = useCallback(_.debounce(a => a(), 600), []);

    return (
        <>
            <h1>Contracts</h1>
            <ListPage
                action={LoadContractsAction}
                filter_columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'country',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS],
                        values: Object.keys(countriesConstants.COUNTRIES).map(code => {
                            return {
                                label: countriesConstants.COUNTRIES[code],
                                value: code
                            }
                        })
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: 'Draft',
                                value: 'draft'
                            },
                            {
                                label: 'In Review',
                                value: 'review'
                            },
                            {
                                label: 'Approved',
                                value: 'approved'
                            }
                        ]
                    },
                    {
                        key: 'number_teachers',
                        name: 'No. of teachers',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'number_students',
                        name: 'No. of students',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'district_id',
                        name: 'By School District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown
                                    onSelect={district => {
                                        if (district) {
                                            setFilterValue(district.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return district ? district.name : '';
                                    }}
                                    id='constract-district-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='constact-school'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'is_trial',
                        name: 'Is Trial',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'is_pilot',
                        name: 'Is Pilot',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                ]}
                sort_columns={[
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'country',
                        name: 'Country'
                    },
                    {
                        key: 'number_teachers',
                        name: '# of teachers'
                    },
                    {
                        key: 'number_students',
                        name: '# of students'
                    },
                    {
                        key: 'ends_at',
                        name: 'Contract end date'
                    },
                    {
                        key: 'session_ends_at',
                        name: 'Session end date'
                    }
                ]}
                entity={'contracts'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <Link to="/contracts/new" className="button">+ New Contract</Link>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CONTRACTS]}
                default_order_by='name'
            >
                <Table/>
            </ListPage>
        </>
    );
}
