import React from 'react';
import PropTypes from "prop-types";

const AuthCodeField = function ({on_auth, auth_code, setAuthCode}) {
    if (!on_auth) {
        return null;
    }

    return (
        <div>
            <label htmlFor="auth_code">Authentication Code</label>
            <input
                type="auth_code"
                className="form-control"
                id="auth_code"
                name="auth_code"
                aria-describedby="authCodeHelp"
                placeholder="Enter Authentication Code"
                value={auth_code}
                onChange={event => setAuthCode(event.target.value)}
            />
        </div>
    );
}

AuthCodeField.propTypes = {
    on_auth: PropTypes.bool.isRequired,
    auth_code: PropTypes.string.isRequired,
    setAuthCode: PropTypes.func.isRequired,
}

export default AuthCodeField;
