import React from 'react';

import BaseComponentProperty from "../index";
import Style from "../Style";

class NumberOnly extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    hasConfigs() {
        return true;
    }

    onChange(index, key, value) {
        let data = JSON.parse(JSON.stringify(this.props.component.data));
        data.items[index][key] = value;
        if (Object.keys(value).length <= 0) {
            delete data.items[index][key];
        }
        this.props.onChange('data', data);
    }

    renderItemProperties(item, index) {
        switch (item.type) {
            case 'input':
                return (
                    <Style initial={item.style} onChange={value => this.onChange(index, 'style', value)}/>
                );
            case 'static':
                return (
                    <>
                        <Style initial={item.style} onChange={value => this.onChange(index, 'style', value)}/>
                        <div>
                            <label className="col-form-label">HTML</label>
                            <input
                                type='text'
                                className='form-control'
                                value={item.content && item.content.html ? item.content.html : ''}
                                onChange={event => {
                                    let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                    content.html = event.target.value;
                                    this.onChange(index, 'content', content);
                                }}
                            />
                            <label className="col-form-label">Text</label>
                            <input
                                type='text'
                                className='form-control'
                                value={item.content && item.content.text ? item.content.text : ''}
                                onChange={event => {
                                    let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                    content.text = event.target.value;
                                    this.onChange(index, 'content', content);
                                }}
                            />
                        </div>
                    </>
                );
            case 'hidden':
                return (
                    <div>
                        <label className="col-form-label">Text</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.content && item.content.text ? item.content.text : ''}
                            onChange={event => {
                                let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                content.text = event.target.value;
                                this.onChange(index, 'content', content);
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    renderOwnProperties() {
        return (
            <div>
                <label className="col-form-label">Items</label>
                <button className='button mb-2' onClick={() => {
                    let data = JSON.parse(JSON.stringify(this.props.component.data));
                    data.items.push({
                        type: "input",
                        style: {
                            width: "80px"
                        }
                    });
                    this.props.onChange('data', data);
                }}>+ Item
                </button>
                <div>
                    {this.props.component.data.items.map((item, index) => {
                        return (
                            <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '0', top: '5px'}}
                                    onClick={() => {
                                        let data = JSON.parse(JSON.stringify(this.props.component.data));
                                        data.items.splice(index, 1);
                                        this.props.onChange('data', data);
                                    }}
                                />
                                <div>
                                    <div>
                                        <label
                                            htmlFor={"type_" + index}
                                            className="col-form-label">Type</label>
                                        <select
                                            className="form-control"
                                            id={"type_" + index}
                                            value={item.type ? item.type : ''}
                                            onChange={event => {
                                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                                switch (event.target.value) {
                                                    case 'input':
                                                        data.items[index] = {
                                                            type: "input",
                                                            style: {}
                                                        }
                                                        break;
                                                    case 'static':
                                                        data.items[index] = {
                                                            type: "static",
                                                            content: {
                                                                html: "",
                                                                text: ""
                                                            },
                                                            style: {}
                                                        }
                                                        break;
                                                    case 'hidden':
                                                        data.items[index] = {
                                                            type: "hidden",
                                                            content: {
                                                                text: ""
                                                            }
                                                        }
                                                        break;
                                                }
                                                this.props.onChange('data', data);
                                            }}
                                        >
                                            <option value='input'>Input</option>
                                            <option value='static'>Static</option>
                                            <option value='hidden'>Hidden</option>
                                        </select>
                                    </div>
                                    {this.renderItemProperties(item, index)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default function (props) {
    return <NumberOnly {...props}/>;
}
