import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "mindsets-js-sdk";

import {parseStyleString, objectToStyleString} from "./utils";
import {SetChallengeBuilderChallengePagesAction} from "../../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();

    const index = useSelector(state => state.challenge_builder_index);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);

    const page = JSON.parse(JSON.stringify(challenge_pages[index]));
    let row;
    if (null !== selected_row_index && null === selected_component_index) {
        row = page.data.card_content.rows[selected_row_index];
    }

    const [container_style, setContainerStyle] = useState(!!row && row.hasOwnProperty('container_style') ? objectToStyleString(row.container_style) : '');

    function onChange(key, value, in_config = true) {
        const row = page.data.card_content.rows[selected_row_index];
        if (value !== '' && value !== []) {
            if (in_config && !row.hasOwnProperty('config')) {
                row.config = {};
            }
            if (in_config) {
                row.config[key] = value;
            } else {
                row[key] = value;
            }
        } else {
            if (in_config && row.hasOwnProperty('config')) {
                if (row.config.hasOwnProperty(key)) {
                    delete row.config[key];
                }
                if (Object.keys(row.config).length <= 0) {
                    delete row.config;
                }
            } else if (!in_config) {
                if (row.hasOwnProperty(key)) {
                    delete row[key];
                }
            }
        }
        page.data.card_content.rows[selected_row_index] = row;
        let pages = JSON.parse(JSON.stringify(challenge_pages));
        pages[index] = page;
        dispatch(SetChallengeBuilderChallengePagesAction(pages));
    }

    function renderVAlign() {
        let value = '';
        if (row.hasOwnProperty('config')) {
            if (row.config.hasOwnProperty('v_align')) {
                value = row.config.v_align;
            }
        }

        return (
            <div>
                <label htmlFor="v_align">Vertical align</label>
                <select
                    id="v_align"
                    className='form-control'
                    value={value}
                    onChange={event => onChange('v_align', event.target.value)}
                >
                    <option value=''>None</option>
                    <option value='start'>Start</option>
                    <option value='center'>Center</option>
                    <option value='end'>End</option>
                </select>
            </div>
        );
    }

    function renderFlex() {
        let value = true;
        if (row.hasOwnProperty('config')) {
            if (row.config.hasOwnProperty('flex')) {
                value = row.config.flex;
            }
        }

        return (
            <div>
                <Checkbox
                    checked={value}
                    label='Flex'
                    toggle={() => onChange('flex', value === true ? false : '')}
                />
            </div>
        );
    }

    function renderFlexCustom() {
        let value = '';
        if (row.hasOwnProperty('config')) {
            if (row.config.hasOwnProperty('flex_custom') && Array.isArray(row.config.flex_custom)) {
                value = row.config.flex_custom.join(',');
            }
        }

        return (
            <div>
                <label htmlFor="flex_custom">Flex custom</label>
                <textarea
                    id="flex_custom"
                    className='form-control'
                    value={value}
                    onChange={event => {
                        let value = event.target.value.split(',');
                        if (value.length === 1 && value[0] === '') {
                            value = '';
                        }
                        onChange('flex_custom', value)
                    }}
                />
            </div>
        );
    }

    function renderContainerStyle() {
        return (
            <div>
                <label htmlFor="container_style">Container Style</label>
                <textarea
                    id="container_style"
                    className='form-control'
                    value={container_style}
                    onChange={event => {
                        const value = event.target.value;
                        setContainerStyle(value);
                        onChange('container_style', parseStyleString(value), false);
                    }}
                />
            </div>
        );
    }

    if (!!row) {
        return (
            <div className='bordered flex-grow-1'>
                <h4>Selected row's properties</h4>
                <div className='d-flex flex-wrap flex-gap-10'>
                    {renderVAlign()}
                    {renderFlex()}
                    {renderFlexCustom()}
                </div>
                {renderContainerStyle()}
            </div>
        );
    }

    return null;
}
