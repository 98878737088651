import React from 'react';
import {useSelector} from "react-redux";
import {datesConstants} from "mindsets-js-sdk";

export default function () {
    const project = useSelector(state => state.project);

    function renderDistrict() {
        if (project.type === 'district' && project.district) {
            return (
                <div>{project.district.name} School District</div>
            );
        }

        return null;
    }

    function renderSchool() {
        if (project.type === 'school' && project.school) {
            return (
                <div>{project.school.name} School</div>
            );
        }

        return null;
    }

    return (
        <div>
            <div>{_.upperFirst(project.type)} Implementation</div>
            {renderDistrict()}
            {renderSchool()}
            <div>Starts on {new Date(project.starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
            <div>Ends on {new Date(project.ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
        </div>
    );
}
