import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, LoadingAction, authUtils, ErrorMessageAction, DeleteProjectBatchAction} from "mindsets-js-sdk";

const DeleteBatchButton = function ({batch, action}) {
    const me = useSelector(state => state.me);
    const project = useSelector(state => state.project);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <button
                className='button red'
                onClick={() => {
                    if (confirm('Are you sure you want to delete this Batch? This action DOES NOT delete Classes but only associations with them.')) {
                        dispatch(DeleteProjectBatchAction(project.id, batch.id)).then(action).catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                    }
                }}
                disabled={batch.status !== 'draft'}
            ><i className='fas fa-trash'/></button>
        );
    }

    return null;
}

DeleteBatchButton.propTypes = {
    batch: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
}

export default DeleteBatchButton;
