import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, DatePicker, ErrorMessageAction, CreateSchoolSubscriptionAction, LoadingAction} from "mindsets-js-sdk";

const CreateSchoolSubscriptionForm = function ({shown, setShown}) {
    const dispatch = useDispatch();
    const school = useSelector(state => state.school);
    const [starts_at, setStartsAt] = useState(null);
    const [ends_at, setEndsAt] = useState(null);
    const [term, setTerm] = useState('');
    const [notes, setNotes] = useState('');
    const [is_trial, setIsTrial] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!starts_at || '' === starts_at) {
            dispatch(ErrorMessageAction('Error! Please specify the start date of the plan.'));
            errors++;
        }
        if (!ends_at || '' === ends_at) {
            dispatch(ErrorMessageAction('Error! Please specify the end date of the plan.'));
            errors++;
        }
        if (starts_at >= ends_at) {
            dispatch(ErrorMessageAction('Error! Start date must not be greater than end date'));
            errors++;
        }

        return errors === 0;
    }
    useEffect(() => {
        setStartsAt(null);
        setEndsAt(null);
        setTerm('');
        setNotes('');
        setIsTrial(false);
    }, [shown]);
    if (shown === true) {
        return (
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(CreateSchoolSubscriptionAction(school.id, {
                        starts_at,
                        ends_at,
                        term,
                        notes,
                        is_trial
                    })).then(() => setShown(false)).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });
                }
            }} autoComplete="off" className='mb-3'>
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="datepicker-field" className="form-label">Start Date</label>
                        <DatePicker
                            date={starts_at}
                            onChange={setStartsAt}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="datepicker-field" className="form-label">End Date</label>
                        <DatePicker
                            date={ends_at}
                            onChange={setEndsAt}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <label htmlFor="plan-term" className="form-label">Term</label>
                        <input
                            type="text"
                            className="form-control"
                            id="plan-term"
                            placeholder="1 year"
                            value={term}
                            onChange={event => setTerm(event.target.value)}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label"/>
                        <Checkbox
                            checked={is_trial}
                            label='Is Trial/Pilot?'
                            toggle={() => setIsTrial(!is_trial)}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="plan-notes" className="">Notes/Comments</label>
                    <textarea
                        className="form-control"
                        id="plan-notes"
                        placeholder="Notes"
                        value={notes}
                        onChange={event => setNotes(event.target.value)}/>
                </div>

                <div className='d-flex flex-gap-5'>
                    <button type="submit" className="button">Submit</button>
                    <button className="button default" onClick={event => {
                        event.preventDefault();
                        setShown(false);
                    }}>Cancel
                    </button>
                </div>
            </form>
        );
    }

    return null;
}

CreateSchoolSubscriptionForm.propTypes = {
    setShown: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired
};

export default CreateSchoolSubscriptionForm;
