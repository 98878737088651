import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, LoadedRolesAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const permissions = useSelector(state => state.permissions);
    const roles = useSelector(state => state.roles);
    if (permissions && roles.length > 0) {
        return (
            <div className="table-responsive">
                <table className="table table-hover permissions-table">
                    <thead>
                    <tr>
                        <td>&nbsp;</td>
                        {roles.map(role => <th key={role.id}>{role.name}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(permissions).map(permission_id => {
                            const permissionName = permissions[permission_id];

                            return (
                                <tr key={permission_id}>
                                    <td>{permissionName}</td>
                                    {roles.map((role, index) => <td key={role.id}>
                                        <Checkbox
                                            checked={Array.isArray(role.permissions) ? role.permissions.filter(rolePermission => rolePermission.permission_id === permission_id).length > 0 : false}
                                            toggle={() => {
                                                const roles_new = JSON.parse(JSON.stringify(roles));
                                                console.log('role: ', role);
                                                let rolePermissions = Array.isArray(role.permissions) ? JSON.parse(JSON.stringify(role.permissions)) : [];
                                                let rolePermissionsIndex = rolePermissions.findIndex(rolePermission => rolePermission.permission_id === permission_id);
                                                if (rolePermissionsIndex >= 0) {
                                                    rolePermissions.splice(rolePermissionsIndex, 1);
                                                } else {
                                                    rolePermissions.push({permission_id});
                                                }
                                                roles_new[index].permissions = rolePermissions;
                                                dispatch(LoadedRolesAction(roles_new));
                                            }}
                                        />
                                    </td>)}
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
}
