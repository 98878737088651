export default class DataCompiler {
    compile(lessons) {
        let rows = [];
        lessons.map(lesson => {
            rows.push([lesson.user.fname + ' ' + lesson.user.lname]);
            rows.push(['Mindsets code: ' + lesson.code]);
            rows.push(['Create on: ' + new Date(lesson.created_at).toLocaleDateString('en-US')]);
            rows.push(['Class name: ' + lesson.name]);
            rows.push(['Class mode: ' + (lesson.mode === 'student' ? 'Student-paced' : 'Teacher-paced')]);
            rows.push(['Answer keys shown?: ' + (lesson.is_answer_keys_shown ? 'ON' : 'OFF')]);
            if (lesson.user.school) {
                rows.push(['School name: ' + lesson.user.school.name]);
            }
            rows.push([lesson.challenge_version.name]);
            rows.push([('science' === lesson.challenge_version.challenge_code.subject ? 'Science' : 'Math') + ' | ' + this.renderCategoryText(lesson.challenge_version.challenge_code.standards, lesson.challenge_version.challenge_code.grades, lesson.user.curriculum_id) + ' | ' + this.renderGradeText(lesson) + ' | ' + this.renderEdition(lesson)]);
            rows.push(["Students\nTotal: " + lesson.streams.length, "Student Progress\nCards in this challenge: " + lesson.number_stream_entities, "Questions completed by student\nQuestions in this challenge: " + lesson.challenge_version.number_questions]);
            lesson.streams.map(stream => {
                rows.push([stream.user.fname + ' ' + stream.user.lname, stream.progress, stream.completed]);
            });
            rows.push(['']);
        });

        return rows;
    }

    renderGradeText(item) {
        return item.challenge_version.challenge_code.standards.filter(standard => {
            if (item.user.curriculum_id) {
                return standard.curriculum_id === item.user.curriculum_id;
            } else {
                return standard.curriculum_id === 1
            }
        }).map(standard => standard.code).join(', ');
    }

    renderEdition(item) {
        if (item.user && item.user.school && item.user.school.country) {
            if ('metric' === item.challenge_version.challenge_code.measure && 'US' === item.user.school.country) {
                return 'Metric edition';
            } else if ((!item.challenge_version.challenge_code.measure || '' === item.challenge_version.challenge_code.measure || 'imperial' === item.challenge_version.challenge_code.measure) && 'US' !== item.user.school.country) {
                return 'US edition';
            } else {
                return 'US edition';
            }
        } else {
            if ('metric' === item.challenge_version.challenge_code.measure) {
                return 'Metric edition';
            } else {
                return 'US edition';
            }
        }
    }

    renderCategoryText(standards, grades, curriculum_id) {
        const standard = standards.find(standard => standard.curriculum_id === curriculum_id);
        if (!standard) {
            return grades['default'].toString();
        }
        switch (standard.grade.toString()) {
            case '5':
            case '6':
                return 'Beginner';
            case '7':
                return 'Intermediate';
            case '8':
            case '9':
                return 'Advanced';
            default:
                return grades['default'].toString();
        }
    }
}
