import React, {useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ErrorMessageAction, DeleteAnnouncementAction, ReloadListAction} from "mindsets-js-sdk";

const Delete = function ({item_id}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [id, setId] = useState('');
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ANNOUNCEMENTS])) {
        return (
            <>
                <button
                    className='button red'
                    data-bs-toggle="modal"
                    data-bs-target={'#delete-announcement-modal-' + item_id}
                >
                    <i className='fas fa-trash'/>
                </button>
                <div
                    className="modal fade"
                    id={'delete-announcement-modal-' + item_id}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">You are about to
                                    delete an Announcement</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete Announcement?</p>
                                <p>To confirm, please enter ID into the field below.</p>
                                <div className='input-field'>
                                    <input type='text' onChange={event => setId(event.target.value)} value={id}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="button" onClick={event => {
                                    event.preventDefault();
                                    if (parseInt(id) === parseInt(item_id)) {
                                        dispatch(DeleteAnnouncementAction(id))
                                            .then(() => bootstrap.Modal.getInstance(document.getElementById('delete-announcement-modal-' + id)).hide())
                                            .then(() => dispatch(ReloadListAction(true, 'announcements')));
                                    } else {
                                        dispatch(ErrorMessageAction('Please enter correct Announcement ID'));
                                    }
                                }}>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return null;
}

Delete.propTypes = {
    item_id: PropTypes.number.isRequired
}

export default Delete;
