import React from 'react';

import BaseAnswer from '../BaseAnswer';
import ReadOnly from './ReadOnly';


export default class Expression extends BaseAnswer {
    defaultAnswer() {
        return _.get(this.props.component_data, 'data.prefill', '');
    }

    render() {
        return (
            <div className="challenge-component-expression">
                <div className="challenge-component-expression-width">
                    <ReadOnly default_answer={this.defaultAnswer()}/>
                </div>
            </div>
        );
    }
}
