import React from 'react'
import PropTypes from "prop-types";

import AbstractType from './AbstractType'

class TypeString extends AbstractType {
    content() {
        return '"' + this.props.value.toString() + '"';
    }
}

TypeString.propTypes = {
    value: PropTypes.string.isRequired
};

export default TypeString;
