import React from 'react';
import {contentUtils} from "mindsets-js-sdk";

import BaseCard from './BaseCard';
import AnswerKeyButton from '../AnswerKeyButton';

export default class CompleteCard extends BaseCard {
    getCardType() {
        return 'complete'
    }

    useChallengeColor() {
        return true;
    }

    renderAnswerKey() {
        return (
            <div className="section">
                <AnswerKeyButton is_bonus={false}/>

                <div className="complete-card-answer-key-message mt-2 text-center">
                    {`Use the Answer Key to check your work.`}
                </div>
            </div>
        )
    }

    renderOtherContent() {
        return (
            <div className="d-flex flex-column align-items-center">
                {this.renderAnswerKey()}

                <div className="section"/>

                <div>
                    {`In this Challenge...`}
                </div>

                <div className="complete-card-content">
                    {
                        contentUtils.processStringContent(this.props.page.data.content, {
                            className: "d-flex flex-column align-items-center text-center"
                        })
                    }
                </div>
            </div>
        )
    }
}
