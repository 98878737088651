import {
    LOADED_LESSONS_CURRICULA,
    LOADED_LESSONS_PRACTICES,
    LOADED_SCHEDULES_GRADING_PROVIDERS
} from "../actions/lesson_actions";

const lessons_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_LESSONS_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};
const lessons_curricula = (payload = [], action) => {
    switch (action.type) {
        case LOADED_LESSONS_CURRICULA:
            return action.payload;
        default:
            return payload;
    }
};

const schedules_grading_providers = (payload = [], action) => {
    switch (action.type) {
        case LOADED_SCHEDULES_GRADING_PROVIDERS:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    lessons_practices,
    lessons_curricula,
    schedules_grading_providers
}
