import React, {Component} from 'react'
import PropTypes from 'prop-types'


class Iframe extends Component {
    getSrc() {
        return this.props.component_data.data.src
    }

    render() {
        const src = this.getSrc()
        if (src) {
            return (
                <div className="challenge-component-iframe">
                    <iframe src={src} />
                </div>
            )
        } else {
            return null
        }
    }
}

Iframe.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Iframe
