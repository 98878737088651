import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

const Table = function ({with_scores}) {
    const current = useSelector(state => state['responses_current']);
    const limit = useSelector(state => state['responses_limit']);
    const items = useSelector(state => state.responses);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">Response ID</th>
                    <th scope="col">Stream ID</th>
                    <th scope="col">Answer</th>
                    <th scope="col">Latest</th>
                    {with_scores ? (
                        <td>Points</td>
                    ) : null}
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return <Row
                        key={item.id}
                        item={item}
                        row_number={getRowNumber(index, current, limit)}
                        with_scores={with_scores}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = {
    with_scores: PropTypes.bool.isRequired
};

export default Table;
