import React from 'react';

import BaseComponentProperty from "../index";

export default class Iframe extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <label htmlFor="src">Link</label>
                <textarea
                    id="src"
                    className='form-control'
                    value={this.props.component.data.src ? this.props.component.data.src : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.src = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        );
    }
}
