import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {
    filterConstants,
    ListPage,
    SchoolDropdown,
    ChallengeDropdown,
    UserDropdown,
    DateRangeSelector,
    accessConstants,
    datesConstants,
    catchQueryFilters
} from "mindsets-js-sdk";

import './student_completion.scss';

import Table from './components/Table';
import Exports from "./components/Exports";
import {LoadStudentCompletionAction} from "../../actions/student_completion_actions";

export default function () {
    const filters = catchQueryFilters();
    const items = useSelector(state => state.student_completion);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    const getEdition = item => {
        if (item.user && item.user.school && item.user.school.country) {
            if ('metric' === item.challenge_version.challenge_code.measure && 'US' === item.user.school.country) {
                return 'Metric edition';
            } else if ((!item.challenge_version.challenge_code.measure || '' === item.challenge_version.challenge_code.measure || 'imperial' === item.challenge_version.challenge_code.measure) && 'US' !== item.user.school.country) {
                return 'US edition';
            } else {
                return 'US edition';
            }
        } else {
            if ('metric' === item.challenge_version.challenge_code.measure) {
                return 'Metric edition';
            } else {
                return 'US edition';
            }
        }
    }
    const getCategoryText = (standards, grades, curriculum_id) => {
        const standard = standards.find(standard => standard.curriculum_id === curriculum_id);
        if (!standard) {
            return grades['default'].toString();
        }
        switch (standard.grade.toString()) {
            case '5':
            case '6':
                return 'Beginner';
            case '7':
                return 'Intermediate';
            case '8':
            case '9':
                return 'Advanced';
            default:
                return grades['default'].toString();
        }
    }
    const getGradeText = item => {
        return item.challenge_version.challenge_code.standards.filter(standard => {
            if (item.user.curriculum_id) {
                return standard.curriculum_id === item.user.curriculum_id;
            } else {
                return standard.curriculum_id === 1
            }
        }).map(standard => standard.code).join(', ');
    }

    return (
        <>
            <h1>Student Completion Report</h1>
            <ListPage
                action={LoadStudentCompletionAction}
                permissions={[accessConstants.PERMISSION_CAN_SEE_USERS, accessConstants.PERMISSION_CAN_REQUEST_STATISTICS]}
                filter_columns={[
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS],
                        multiple: false
                    },
                    {
                        key: 'challenge_code',
                        name: 'Challenge code',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown
                                    onSelect={challenge_version => {
                                        if (challenge_version) {
                                            setFilterValue(challenge_version.code);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return challenge_version ? challenge_version.name : '';
                                    }}
                                    id='completion-challenge-filter'
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'subject',
                        name: 'Subject',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Math',
                                value: 'math'
                            },
                            {
                                label: 'Science',
                                value: 'science'
                            }
                        ],
                        multiple: false
                    },
                    {
                        key: 'created_at',
                        name: 'Created At',
                        expressions: [filterConstants.EXPRESSION_BETWEEN],
                        component: (setFilterValue, index) => {
                            const filters = catchQueryFilters();
                            const filter = filters[index];
                            let values = ['', ''];
                            if (filter) {
                                values = filter.value.toString().split('|');
                            }

                            return (
                                <DateRangeSelector
                                    onUpdate={data => setFilterValue([data.from, data.till].join('|'))}
                                    from={!isNaN(parseInt(values[0])) ? parseInt(values[0]) : null}
                                    till={!isNaN(parseInt(values[1])) ? parseInt(values[1]) : null}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'user_id',
                        name: 'Teacher',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <UserDropdown
                                    onSelect={user => {
                                        if (user) {
                                            setFilterValue(user.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return user ? user.fname + ' ' + user.lname : '';
                                    }}
                                    id='completion-user-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='student-completion-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'persona',
                        name: 'Persona',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [
                            {
                                label: 'Student',
                                value: 'student'
                            },
                            {
                                label: 'Teacher',
                                value: 'teacher'
                            }
                        ],
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'created_at',
                        name: 'Created At'
                    },
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    }
                ]}
                page_classes={'student-completion-page section'}
                show_quick_search={false}
                custom_export={<Exports title={'Mindsets student completion report ' + filters.map(filter => {
                    return filter.value;
                }).join('_')} items={items}/>}
                entity={'student_completion'}
            >
                {items.length ? (
                    <div className='report-preview'>
                        <div className='preview-content'>{items.map(item => {
                            return (
                                <div key={item.code} className='class-report d-flex flex-row'>
                                    <div className='class-report-title flex-fill p-3'>
                                        <div className='created-on'>Created
                                            on {new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                                        <div className='teacher'>{item.user.fname} {item.user.lname}</div>
                                        <div className='mindsets-code'>Mindsets Code: {item.code.toUpperCase()}</div>
                                        <div className='class-name'>Class name: {item.name}</div>
                                        <div className='class-name'>Class
                                            mode: {item.mode === 'student' ? 'Student-paces' : 'Teacher-paced'}</div>
                                        <div className='class-name'>Answer keys
                                            shown?: {item.is_answer_keys_shown ? 'ON' : 'OFF'}</div>
                                        {item.user.school ?
                                            <div className='class-name'>School
                                                name: {item.user.school.name}</div> : null}
                                        <div className='mt-3 challenge-name'>{item.challenge_version.name}</div>
                                        <div className='challenge-info'>
                                            <span>{'science' === item.challenge_version.challenge_code.subject ? 'Science' : 'Math'}</span>
                                            <span className='mx-1'>|</span>
                                            <span>{getCategoryText(item.challenge_version.challenge_code.standards, item.challenge_version.challenge_code.grades, item.user.curriculum_id)}</span>
                                            <span className='mx-1'>|</span>
                                            <span>{getGradeText(item)}</span>
                                            <span className='mx-1'>|</span>
                                            <span>{getEdition(item)}</span>
                                        </div>
                                    </div>
                                    <div className='class-report-data flex-fill'>
                                        <Table items={item.streams}
                                               number_questions={item.challenge_version.number_questions}
                                               number_stream_entities={item.number_stream_entities}/>
                                    </div>
                                </div>
                            );
                        })}</div>
                    </div>
                ) : (
                    <div className='empty-report-message'>Report preview will appear here once you generate a
                        report</div>
                )}
            </ListPage>
        </>
    );
}
