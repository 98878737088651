import React from "react";
import {accessConstants, authUtils} from "mindsets-js-sdk";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

export default function () {
    const params = useParams();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_RUBRICS])) {
        return (
            <Link to={`/rubrics/${params.challenge_code}?practice_id=${params.practice_code}`} className="button">Update</Link>
        );
    }

    return null;
}
