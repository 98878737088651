import React from 'react';
import {contentUtils} from "mindsets-js-sdk";

import BaseCard from './BaseCard';

export default class WinnerCard extends BaseCard {
    getCardType() {
        return 'winner';
    }

    useChallengeColor() {
        return true;
    }

    getMinHeight() {
        return '400px';
    }

    renderDummy() {
        return (
            <div className="section">
                <div className="challenge-winner-dummy">
                    <div className="text-center">
                        <i className="fas fa-trophy"/>
                    </div>
                    <div className="text-center">The image of the winning option displays here.</div>
                </div>
            </div>
        );
    }

    renderContentOne() {
        const {content1} = this.props.page.data
        if (content1) {
            return (
                <div className="section">
                    <div className="d-flex flex-row align-items-center justify-content-center text-center">
                        {contentUtils.processStringContent(content1)}
                    </div>
                </div>
            )
        }
    }

    renderContentTwo() {
        const {content2} = this.props.page.data
        if (content2) {
            return (
                <div className="section">
                    <div className="d-flex flex-row align-items-center justify-content-center text-center">
                        {contentUtils.processStringContent(content2)}
                    </div>
                </div>
            )
        }
    }

    renderOtherContent() {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h2 className="header-new text-center">
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put page title here'
                        value={this.props.page.data.title} onChange={event => {
                        let page = this.props.page;
                        page.data.title = event.target.value;
                        this.props.updatePage(page);
                    }}/>
                </h2>

                {this.renderDummy()}

                {this.renderContentOne()}

                {this.renderContentTwo()}
            </div>
        )
    }
}
