import React from 'react'
import PropTypes from "prop-types";

import AbstractType from './AbstractType'

class TypeUnknown extends AbstractType {
    content() {
        return (
            <span className='badge bg-info'>"{JSON.stringify(this.props.value)}"</span>
        );
    }
}

TypeUnknown.propTypes = {
    value: PropTypes.any.isRequired,
};

export default TypeUnknown;
