import React from 'react';

import BaseCard from './BaseCard';

export default class IntroCard extends BaseCard {
    getCardType() {
        return 'intro';
    }

    useChallengeColor() {
        return true;
    }

    getHeroImage() {
        return `https://s3.amazonaws.com/assets.go.mindsets.com/challenge-images/bbz/hero_s_1.jpeg`
    }

    cardHasComponents() {
        return false;
    }

    renderFastFacts() {
        return (
            <div className="challenge-intro-fast-facts">
                <div className="d-flex flex-row">
                    <a className="link-new" href={'!#'} target="_blank"
                       onClick={event => event.preventDefault()}>
                        <span>Fast Facts</span>
                        <i className="fas fa-file-download"/>
                    </a>
                </div>
            </div>
        );
    }

    renderOverview() {
        return (
            <div className="challenge-intro-overview">
                Challenge overview will appear here
            </div>
        )
    }

    renderTips() {
        return (
            <div className="challenge-intro-tips">
                <div className="d-flex flex-row flex-box-2-5">
                    <div><strong>TIP:</strong></div>
                    Tips content will appear here
                </div>
            </div>
        )
    }

    renderOtherContent() {
        return (
            <div className="d-flex flex-column">
                <div className="section">
                    <div className="challenge-intro-name">
                        {this.props.challenge.name ? this.props.challenge.name : 'Challenge name will appear here'}
                    </div>
                </div>

                <div className="section">
                    {this.renderFastFacts()}
                </div>

                <div className="section">
                    {this.renderOverview()}
                </div>

                <div className="section">
                    {this.renderTips()}
                </div>
            </div>
        );
    }
}
