import React from 'react';
import PropTypes from "prop-types";

const RemoveButton = function ({index, users, setUsers, updated, setUpdated}) {
    if (0 === index) {
        return null;
    }

    return (
        <a href={"#"} onClick={event => {
            event.preventDefault();
            let users_new = JSON.parse(JSON.stringify(users));
            users_new.splice(index, 1);
            setUsers(users_new);
            if (!updated) {
                setUpdated(true);
            }
        }}>
            <i className="fas fa-minus-circle fa-2x"/>
        </a>
    );
}

RemoveButton.propTypes = {
    index: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        fname: PropTypes.string,
        lname: PropTypes.string,
        email: PropTypes.string
    })).isRequired,
    updated: PropTypes.bool.isRequired,
    setUsers: PropTypes.func.isRequired,
    setUpdated: PropTypes.func.isRequired
}

export default RemoveButton;
