import React from 'react';
import PropTypes from "prop-types";
import {membershipUtils, datesConstants} from "mindsets-js-sdk";

const DistrictSubscription = function ({user}) {
    let subscriptions = JSON.parse(JSON.stringify(membershipUtils.getDistrictSubscriptions(user)));
    subscriptions.sort((a, b) => b.id - a.id);
    if (subscriptions.length > 0) {
        const valid = membershipUtils.isDistrictSubscriptionValid(subscriptions[0]);
        return (
            <div>
                <div>Has {valid ? 'valid' : 'expired'} District subscription</div>
                <div
                    className="text-right no-wrap">From: {new Date(subscriptions[0].starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                <div
                    className="text-right no-wrap">Until: {new Date(subscriptions[0].ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                <hr/>
            </div>
        );
    }

    return null;
}

DistrictSubscription.propTypes = {
    user: PropTypes.shape({
        school: PropTypes.shape({
            district: PropTypes.shape({
                subscription: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    starts_at: PropTypes.number.isRequired,
                    ends_at: PropTypes.number.isRequired
                }))
            })
        })
    }).isRequired
};

export default DistrictSubscription;
