import {
    announcement_reducers as ann_r,
    challenge_reducers as che_r,
    contract_reducers as con_r,
    curriculum_reducers,
    district_reducers,
    grading_provider_reducers,
    lesson_reducers as les_r,
    poll_reducers,
    practice_reducers as prac_r,
    project_reducers as proj_r,
    question_reducers as q_r,
    response_reducers as res_r,
    response_point_reducers,
    role_reducers,
    school_reducers as sch_r,
    setting_reducers,
    standard_reducers as sta_r,
    stream_reducers as str_r,
    toasts,
    user_reducers as usr_r,
    loading
} from "mindsets-js-sdk";


import {list_reload} from 'mindsets-js-sdk/src/react/layout/ListPage/reducers';
import autocomplete_reducers from 'mindsets-js-sdk/src/react/components/Autocomplete/autocomplete_reducers';

import announcement_reducers from "./announcement_reducers";
import challenge_reducers from "./challenge_reducers";
import contract_reducers from "./contract_reducers";
import dashboard_reducers from "./dashboard_reducers";
import impact_reducers from "./impact_reducers";
import lesson_reducers from "./lesson_reducers";
import longitudinal_reducers from "./longitudinal_reducers";
import question_reducers from "./question_reducers";
import practice_reducers from "./practice_reducers";
import project_reducers from "./project_reducers";
import response_reducers from "./response_reducers";
import school_reducers from "./school_reducers";
import standard_reducers from "./standard_reducers";
import statistics_reducers from "./statistics_reducers";
import stream_reducers from "./stream_reducers";
import student_completion_reducers from "./student_completion_reducers";
import timespent_reducers from "./timespent_reducers";
import user_reducers from "./user_reducers";

const queue_attributes = (payload = [], action) => {
    switch (action.type) {
        case 'queue_attributes':
            return action.payload;
        default:
            return payload;
    }
};

export default Object.assign(
    {toasts, loading, list_reload},
    Object.assign({}, ann_r, announcement_reducers),
    Object.assign({}, che_r, challenge_reducers),
    Object.assign({}, con_r, contract_reducers),
    curriculum_reducers,
    dashboard_reducers,
    district_reducers,
    grading_provider_reducers,
    impact_reducers,
    Object.assign({}, les_r, lesson_reducers),
    longitudinal_reducers,
    poll_reducers,
    Object.assign({}, prac_r, practice_reducers),
    Object.assign({}, proj_r, project_reducers),
    Object.assign({}, q_r, question_reducers),
    Object.assign({}, res_r, response_reducers),
    response_point_reducers,
    role_reducers,
    Object.assign({}, sch_r, school_reducers),
    setting_reducers,
    Object.assign({}, sta_r, standard_reducers),
    statistics_reducers,
    Object.assign({}, str_r, stream_reducers),
    student_completion_reducers,
    timespent_reducers,
    Object.assign({}, usr_r, user_reducers),
    {queue_attributes},
    {
        autocomplete_items: autocomplete_reducers.autocomplete_items,
        autocomplete_totals: autocomplete_reducers.autocomplete_totals
    }
);
