import React from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

const SubmitButtons = function ({updated}) {
    const navigate = useNavigate();
    if (updated) {
        return (
            <div className='d-flex flex-gap-5'>
                <button type="submit" className="button">Submit</button>
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>Cancel
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    navigate(-1);
                }}>Back
                </button>
            </div>
        );
    }
}

SubmitButtons.propTypes = {
    updated: PropTypes.bool.isRequired
}

export default SubmitButtons;
