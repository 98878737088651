import {
    LOADED_STATS,
    LOADED_DASHBOARDS,
    LOADED_DASHBOARDS_PAGES,
    LOADED_DASHBOARDS_TOTAL,
    SET_DASHBOARD
} from "../actions/dashboard_actions";
import {SET_CURRENT_POSTFIX, SET_CURRENT_PREFIX, SET_LIMIT_POSTFIX, SET_LIMIT_PREFIX} from "mindsets-js-sdk";

const searchParams = new URLSearchParams(location.search);

export const dashboard_stats = (payload = null, action) => {
    switch (action.type) {
        case LOADED_STATS:
            return Object.assign({}, payload, action.payload);
        default:
            return payload;
    }
};
export const dashboards = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARDS:
            return action.payload;
        default:
            return payload;
    }
};
const dashboards_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case SET_CURRENT_PREFIX + 'dashboards' + SET_CURRENT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const dashboards_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_DASHBOARDS_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const dashboards_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_DASHBOARDS_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const dashboards_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case SET_LIMIT_PREFIX + 'dashboards' + SET_LIMIT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
export const dashboard = (payload = null, action) => {
    switch (action.type) {
        case SET_DASHBOARD:
            return Object.assign({}, payload, action.payload);
        default:
            return payload;
    }
};

export default {
    dashboard_stats,
    dashboards,
    dashboards_current,
    dashboards_total,
    dashboards_pages,
    dashboards_limit,
    dashboard
}
