import React from 'react'
import PropTypes from "prop-types";

import AbstractType from './AbstractType'
import RecursiveValidator from "../validators/RecursiveValidator";

class TypeArray extends AbstractType {
    getRange() {
        return [...Array(this.props.depth * 4).keys()];
    }

    renderPadding() {
        return this.getRange().map(() => "\u00A0").join('');
    }

    content() {
        return this.props.value.map((item, i) => {
            if (!item.hasOwnProperty('value')) {
                console.error('Value not provided in ' + this.constructor.name, item);
            }
            if (!item.hasOwnProperty('validationResult')) {
                console.error('validationResult not provided in ' + this.constructor.name, item);
            } else if (!item.validationResult.hasOwnProperty('isValid')) {
                console.error('isValid not provided in ' + this.constructor.name, item);
            }

            return [
                <RecursiveValidator
                    key='1'
                    item={item}
                    depth={this.props.depth + 1}
                    isArrayItem={true}
                />,
                i < (this.props.value.length - 1) ? ',' : null
            ];
        });
    }

    render() {
        return [
            '[',
            this.renderErrorMessages(),
            this.content(),
            <br key='array-content-bottom'/>,
            this.renderPadding() + ']'
        ];
    }
}

TypeArray.defaultProps = {
    isArrayItem: false,
    depth: 0
}

TypeArray.propTypes = {
    value: PropTypes.array.isRequired,
    isArrayItem: PropTypes.bool,
    depth: PropTypes.number
};

export default TypeArray;
