import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    authUtils,
    LoadRolesAction,
    LoadPermissionsAction,
    UpdateRolesPermissionsAction,
    LoadMeAction,
    LoadingAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import './permissions.scss';

import NoAccess from '../../common/NoAccess';
import Permissions from "./components/Permissions";

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const roles = useSelector(state => state.roles);
    useEffect(() => {
        dispatch(LoadRolesAction({
            limit: 0,
            'selectors[0]': 'permissions'
        }));
        dispatch(LoadPermissionsAction({limit: 0}));
    }, []);
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ROLES])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div className='container full permissions-page section'>
            <h1>Role Permissions Management</h1>
            <div className='section'>
                <Permissions/>
                <button className='button' onClick={event => {
                    event.preventDefault();
                    dispatch(UpdateRolesPermissionsAction({roles}))
                        .then(() => dispatch(LoadMeAction()))
                        .then(() => dispatch(LoadRolesAction({
                            limit: 0,
                            'selectors[0]': 'permissions'
                        })))
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }}>Save
                </button>
            </div>
        </div>
    );
}
