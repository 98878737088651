import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    DndContext,
    rectIntersection,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay
} from "@dnd-kit/core";
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";
import DraggableRow from "./DraggableRow";
import RowItem from "./RowItem";
import DraggableComponent from "./DraggableComponent";
import ComponentItem from "./ComponentItem";

export default function () {
    const dispatch = useDispatch();
    const page_index = useSelector(state => state.challenge_builder_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);
    const [draggedItem, setDraggedItem] = useState(null);
    let pages = JSON.parse(JSON.stringify(challenge_pages));
    let page = pages[page_index];
    let counter = -1;
    let items = [];
    if (page && page.data && page.data.card_content && Array.isArray(page.data.card_content.rows)) {
        page.data.card_content.rows.map((row, row_index) => {
            counter++;
            items.push(
                {
                    source_index: counter,
                    type: 'row',
                    row_index,
                    row
                }
            );
            if (row && Array.isArray(row.components)) {
                row.components.map((component, component_index) => {
                    counter++;
                    items.push(
                        {
                            source_index: counter,
                            type: 'component',
                            row_index,
                            component_index,
                            component
                        }
                    );
                });
            }
        });
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        })
    );

    function onDragEnd({active, over}) {
        if (active && over && active.id !== over.id) {
            const source_item = items[active.id - 1];
            const destination_item = items[over.id - 1];
            if (source_item.type === 'component' && destination_item.type === 'row') {
                //Move above this row and put at the end
                //1. Remove from old place
                const [removed] = page.data.card_content.rows[source_item.row_index].components.splice(source_item.component_index, 1);
                //1. Copy to new place
                if (source_item.row_index >= destination_item.row_index) {
                    page.data.card_content.rows[destination_item.row_index - 1].components.push(removed);
                } else {
                    page.data.card_content.rows[destination_item.row_index].components.unshift(removed);
                }
            } else if (source_item.type === 'component' && destination_item.type === 'component') {
                //1. Remove from old place
                const [removed] = page.data.card_content.rows[source_item.row_index].components.splice(source_item.component_index, 1);
                page.data.card_content.rows[destination_item.row_index].components.splice(
                    source_item.row_index >= destination_item.row_index ? destination_item.component_index : destination_item.component_index + 1,
                    0,
                    removed
                );
            } else if (source_item.type === 'row' && destination_item.type === 'row') {
                const [removed] = page.data.card_content.rows.splice(source_item.row_index, 1);
                page.data.card_content.rows.splice(
                    destination_item.row_index,
                    0,
                    removed
                );
            } else {
                const [removed] = page.data.card_content.rows.splice(source_item.row_index, 1);
                page.data.card_content.rows.splice(
                    destination_item.row_index,
                    0,
                    removed
                );
            }
            pages[page_index] = page;
            dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
            dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
            dispatch(SetChallengeBuilderChallengePagesAction(pages));
        }
        setDraggedItem(null);
    }

    function onDragStart({active}) {
        setDraggedItem(items[active.id - 1]);
    }

    function renderDraggable(item) {
        if (item.type === 'row') {
            return (
                <DraggableRow
                    key={'row_structure_drag_' + item.row_index}
                    index={item.source_index}
                    row_index={item.row_index}/>
            )
        } else if (item.type === 'component') {
            return (
                <DraggableComponent
                    key={'component_structure_drag_' + item.row_index + '_' + item.component_index}
                    index={item.source_index}
                    row_index={item.row_index}
                    component_index={item.component_index}
                    component={item.component}
                />
            );
        }

        return null;
    }

    function renderItem(draggedItem) {
        if (draggedItem.type === 'row') {
            return (
                <RowItem
                    index={draggedItem.source_index}
                    row_index={draggedItem.row_index}/>
            )
        } else if (draggedItem.type === 'component') {
            return (
                <ComponentItem
                    index={draggedItem.source_index}
                    row_index={draggedItem.row_index}
                    component_index={draggedItem.component_index}
                    component={draggedItem.component}
                />
            );
        }

        return null;
    }

    return (
        <DndContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            sensors={sensors}
            collisionDetection={rectIntersection}
            modifiers={[restrictToVerticalAxis]}
        >
            <div style={{overflowY: 'scroll'}}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {items.map(item => renderDraggable(item))}
                </SortableContext>
                <DragOverlay modifiers={[restrictToWindowEdges]}>
                    {draggedItem ? renderItem(draggedItem) : null}
                </DragOverlay>
            </div>
        </DndContext>
    );
}
