import React from 'react';

import BaseComponentProperty from "../index";

export default class TextArea extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderOwnProperties() {
        return (
            <div>
                <label
                    htmlFor={"textarea-placeholder"}
                    className="col-form-label">Placeholder</label>
                <input
                    type='text'
                    id="textarea-placeholder"
                    className='form-control'
                    value={this.props.component.data.placeholder ? this.props.component.data.placeholder : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.placeholder = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        );
    }
}
