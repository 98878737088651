import React, {memo} from 'react';
import PropTypes from "prop-types";
import {contentUtils, HTMLContent} from "mindsets-js-sdk";

const PollOption = memo(function ({poll_option}) {
    return (
        <div>
            <div className='poll-option'>
                <div className="poll-option-content-container">
                    <div className="d-flex align-items-center">
                        <i className={'poll-option-radio far fa-circle'}/>

                        <div className="d-flex flex-auto">
                            <HTMLContent
                                className='d-flex justify-content-start'
                                html_str={contentUtils.processStringContent(poll_option.option, {
                                    keep_html: true,
                                    convert_math: true,
                                    convert_spelling: false,
                                })}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

PollOption.propTypes = {
    poll_option: PropTypes.shape({
        option: PropTypes.string.isRequired
    }).isRequired
}

export default PollOption;
