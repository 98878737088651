import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataNumberPointsPointValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: false,
            attributes: {
                formula: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                numberLinePoint: {
                    type: 'number',
                    empty: false,
                    mandatory: false
                },
                image: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                title: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                }
            }
        };
    }
}
