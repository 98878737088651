import React from 'react';

import BaseAnswer from '../../AnswerComponent/BaseAnswer';
import ExpressionSingle from './ExpressionSingle';


export default class ExpressionMulti extends BaseAnswer {
    renderExpressions() {
        return this.props.component_data.data.prefill.map((ans, key) => {
            const button_sets = _.get(this.props.component_data, 'data.button_sets', [])
            return (
                <div key={`expression_single_${key}`}>
                    <div className="expression-multi-each">
                        <ExpressionSingle
                            answer={ans}
                            button_sets={button_sets}
                        />
                    </div>
                </div>
            );
        })
    }

    renderRemoveButton() {
        return (
            <div>
                <button className="btn btn-challenge">
                    <i className="fas fa-minus">
                    </i>
                </button>
            </div>
        )
    }

    renderAddButton() {
        return (
            <div>
                <button className="btn btn-challenge">
                    <i className="fas fa-plus">
                    </i>
                </button>
            </div>
        )
    }

    renderButtons() {
        return (
            <div className="expression-multi-buttons">
                <div className="d-flex justify-content-end flex-box-0-10">
                    {this.renderRemoveButton()}

                    {this.renderAddButton()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-expression-multi">
                <div>
                    {this.renderExpressions()}
                </div>

                {this.renderButtons()}
            </div>
        );
    }
}
