import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, DeleteSubscriptionAction} from "mindsets-js-sdk";

const DeleteSubscriptionButton = function ({subscription_id}) {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const district = useSelector(state => state.district);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_DISTRICTS])) {
        return (
            <button
                className='button red'
                onClick={() => {
                    if (confirm('Are you sure you want to archive this Subscription? This action DOES NOT delete the Subscription completely but marks it as deleted. You will not see it in the list after that.')) {
                        dispatch(DeleteSubscriptionAction(district.id, subscription_id));
                    }
                }}
            >Archive</button>
        );
    }

    return null;
}
DeleteSubscriptionButton.propTypes = {
    subscription_id: PropTypes.number.isRequired
}

export default DeleteSubscriptionButton;
