import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {LoadingAction, Checkbox, ErrorMessageAction, SuccessMessageAction, ScheduleProjectBatchAction} from "mindsets-js-sdk";

import DeleteBatchButton from "./DeleteBatchButton";
import UpdateBatchButton from "./UpdateBatchButton";

function Batch({item, row_number, selected_batches, setSelectedBatches, setBatchId, action}) {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.project_practices);
    const grading_providers = useSelector(state => state.grading_providers);
    if (!practices.length || !grading_providers.length) {
        return null;
    }
    let query_string = '?column~0=project_batch_id&expression~0=eq&value~0=' + item.id;
    let index = 0;
    if (item.grading_provider_id) {
        index++;
        query_string += `&column~${index}=grading_provider_id&expression~${index}=eq&value~${index}=${item.grading_provider_id}`;
    }
    if (item.practice_id) {
        index++;
        query_string += `&column~${index}=practice_id&expression~${index}=eq&value~${index}=${item.practice_id}`;
    }
    if (item.completion) {
        index++;
        query_string += `&column~${index}=completion&expression~${index}=eq&value~${index}=${item.completion}`;
    }

    return (
        <tr>
            <td>
                <Checkbox
                    checked={selected_batches.includes(item.id)}
                    toggle={() => {
                        if (selected_batches.includes(item.id)) {
                            selected_batches.splice(selected_batches.findIndex(id => id === item.id), 1);
                        } else {
                            selected_batches.push(item.id);
                        }
                        setSelectedBatches(selected_batches);
                    }}
                    disabled={item.status !== 'draft'}
                />
            </td>
            <td>{row_number}</td>
            <td>{item.name}</td>
            <td>{item.grading_provider_id ? grading_providers.find(grading_provider => grading_provider.id === item.grading_provider_id).name : 'n/a'}</td>
            <td>{item.practice_id ? practices.find(practice => practice.id === item.practice_id).name : 'n/a'}</td>
            <td>{item.completion ? _.upperFirst(item.completion) : 'n/a'}</td>
            <td>{item.lessons ? item.lessons.length : 0}</td>
            <td>{item.status}</td>
            <td className='text-center'>
                <span
                    className={item.number_students_graded !== item.number_students_to_grade ? 'text-danger' : ''}
                >{item.number_students_graded >= 0 ? item.number_students_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_students_to_grade >= 0 ? item.number_students_to_grade : 'na'}</span>
                <span>/</span>
                <span>{item.number_students >= 0 ? item.number_students : 'na'}</span>
            </td>
            <td className='text-center'>
                <span
                    className={item.number_responses_graded !== item.number_responses_to_grade ? 'text-danger' : ''}
                >{item.number_responses_graded >= 0 ? item.number_responses_graded : 'na'}</span>
                <span>/</span>
                <span
                    className='text-info'>{item.number_responses_to_grade >= 0 ? item.number_responses_to_grade : 'na'}</span>
                <span>/</span>
                <span
                    className='text-warning'>{item.number_responses_auto_graded >= 0 ? item.number_responses_auto_graded : 'na'}</span>
                <span>/</span>
                <span>{item.number_responses_gradable >= 0 ? item.number_responses_gradable : 'na'}</span>
                <span>/</span>
                <span className='text-success'>{item.number_responses >= 0 ? item.number_responses : 'na'}</span>
            </td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <UpdateBatchButton batch={item} setBatchId={setBatchId}/>
                    <DeleteBatchButton batch={item} action={action}/>
                    <Link
                        to={'/lesson_schedules' + query_string}
                        className='button'>
                        Schedules
                    </Link>
                    <button className='button' onClick={event => {
                        event.preventDefault();
                        if (!item.grading_provider_id) {
                            dispatch(ErrorMessageAction('Grading provider is not selected'));
                            return;
                        }
                        if (!item.practice_id) {
                            dispatch(ErrorMessageAction('Practice is not selected'));
                            return;
                        }
                        if (!item.lessons || item.lessons.lessons <= 0) {
                            dispatch(ErrorMessageAction('No Classes added to the Batch'));
                            return;
                        }
                        dispatch(ScheduleProjectBatchAction(item.id)).then(() => {
                            dispatch(SuccessMessageAction('Classes in Batch ' + item.name + ' have been scheduled for Grading'));
                            action();
                        }).catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                    }}
                            disabled={item.status !== 'draft' || !item.lessons || (item.lessons && item.lessons.length === 0)}>Schedule
                    </button>
                </div>
            </td>
        </tr>
    );
}

Batch.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        grading_provider_id: PropTypes.number,
        practice_id: PropTypes.number,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        completion: PropTypes.string,
        number_students: PropTypes.number,
        number_students_to_grade: PropTypes.number,
        number_students_graded: PropTypes.number,
        number_responses: PropTypes.number,
        number_responses_to_grade: PropTypes.number,
        number_responses_graded: PropTypes.number,
        number_responses_gradable: PropTypes.number,
        number_responses_auto_graded: PropTypes.number
    }).isRequired,
    row_number: PropTypes.number.isRequired,
    selected_batches: PropTypes.array.isRequired,
    setSelectedBatches: PropTypes.func.isRequired,
    setBatchId: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
};

export default Batch;
