import React from 'react';

import BaseComponentProperty from "../index";

export default class SliderSingle extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderValue() {
        return (
            <div>
                <label htmlFor="value">Value</label>
                <input
                    type='text'
                    id="value"
                    className='form-control'
                    value={this.props.component.data.value ? this.props.component.data.value : ''}
                    onChange={event => {
                        let data = this.props.component.data;
                        data.value = event.target.value;
                        this.props.onChange('data', data);
                    }}
                />
            </div>
        );
    }

    renderOwnProperties() {
        return (
            <div className='d-flex flex-wrap flex-box-0-5'>
                <div>
                    <label htmlFor="min">Min</label>
                    <input
                        type='text'
                        id="min"
                        className='form-control'
                        value={this.props.component.data.min ? this.props.component.data.min : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.min = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="max">Max</label>
                    <input
                        type='text'
                        id="max"
                        className='form-control'
                        value={this.props.component.data.max ? this.props.component.data.max : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.max = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="step">Step</label>
                    <input
                        type='text'
                        id="step"
                        className='form-control'
                        value={this.props.component.data.step ? this.props.component.data.step : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.step = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="step">Unit prefix</label>
                    <input
                        type='text'
                        id="unit_prefix"
                        className='form-control'
                        value={this.props.component.data.unit_prefix ? this.props.component.data.unit_prefix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.unit_prefix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="step">Unit suffix</label>
                    <input
                        type='text'
                        id="unit_suffix"
                        className='form-control'
                        value={this.props.component.data.unit_suffix ? this.props.component.data.unit_suffix : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.unit_suffix = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                {this.renderValue()}
            </div>
        );
    }
}
