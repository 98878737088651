import React, {Component} from 'react';

export default class Spacer extends Component {
    render() {
        return (
            <div className="challenge-component-spacer">
                <div/>
            </div>
        )
    }
}
