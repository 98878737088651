import React from 'react';
import {Checkbox} from "mindsets-js-sdk";

import BaseComponentProperty from "../index";
import Style from "../Style";

class Fraction extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    hasConfigs() {
        return true;
    }

    onChange(index, key, value) {
        let data = JSON.parse(JSON.stringify(this.props.component.data));
        data.items[index][key] = value;
        if (Object.keys(value).length <= 0) {
            delete data.items[index][key];
        }
        this.props.onChange('data', data);
    }

    renderInputProperties(index, item, position) {
        if (item.inputs && !!item.inputs[position]) {
            return (
                <>
                    <div>
                        <label className="col-form-label">Placeholder</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.inputs[position].placeholder ? item.inputs[position].placeholder : ''}
                            onChange={event => {
                                let inputs = JSON.parse(JSON.stringify(item.inputs ? item.inputs : {}));
                                inputs[position].placeholder = event.target.value;
                                this.onChange(index, 'inputs', inputs);
                            }}
                        />
                    </div>
                    <Style
                        initial={item.inputs[position].style}
                        onChange={value => {
                            let inputs = JSON.parse(JSON.stringify(item.inputs ? item.inputs : {}));
                            inputs[position].style = value;
                            this.onChange(index, 'inputs', inputs);
                        }}/>
                </>
            );
        }

        return null;
    }

    renderInputProps(index, item, position) {
        return (
            <>
                <Checkbox checked={!!item.inputs && !!item.inputs[position]} toggle={() => {
                    let inputs = JSON.parse(JSON.stringify(item.inputs ? item.inputs : {}));
                    if (inputs[position]) {
                        delete inputs[position];
                    } else {
                        inputs[position] = {
                            placeholder: "",
                            style: {}
                        };
                    }
                    this.onChange(index, 'inputs', inputs)
                }} label={_.upperFirst(position)}/>
                {this.renderInputProperties(index, item, position)}
            </>
        );
    }

    renderTextsProperties(index, item, position) {
        if (item.texts && !!item.texts[position]) {
            return (
                <>
                    <div>
                        <label className="col-form-label">HTML</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.texts[position].content && item.texts[position].content.html ? item.texts[position].content.html : ''}
                            onChange={event => {
                                let texts = JSON.parse(JSON.stringify(item.texts ? item.texts : {}));
                                texts[position].content.html = event.target.value;
                                this.onChange(index, 'texts', texts);
                            }}
                        />
                        <label className="col-form-label">Text</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.texts[position].content && item.texts[position].content.text ? item.texts[position].content.text : ''}
                            onChange={event => {
                                let texts = JSON.parse(JSON.stringify(item.texts ? item.texts : {}));
                                texts[position].content.text = event.target.value;
                                this.onChange(index, 'texts', texts);
                            }}
                        />
                    </div>
                    <Style
                        initial={item.texts[position].style}
                        onChange={value => {
                            let texts = JSON.parse(JSON.stringify(item.texts ? item.texts : {}));
                            texts[position].style = value;
                            this.onChange(index, 'texts', texts);
                        }}/>
                </>
            );
        }

        return null;
    }

    renderTextsProps(index, item, position) {
        return (
            <>
                <Checkbox checked={!!item.texts && !!item.texts[position]} toggle={() => {
                    let texts = JSON.parse(JSON.stringify(item.texts ? item.texts : {}));
                    if (texts[position]) {
                        delete texts[position];
                    } else {
                        texts[position] = {
                            content: {
                                html: "",
                                text: ""
                            },
                            style: {}
                        };
                    }
                    this.onChange(index, 'texts', texts)
                }} label={_.upperFirst(position)}/>
                {this.renderTextsProperties(index, item, position)}
            </>
        );
    }

    renderItemProperties(item, index) {
        switch (item.type) {
            case 'fraction':
                return (
                    <>
                        <Style initial={item.style} onChange={value => this.onChange(index, 'style', value)}/>
                        <Style
                            initial={item.divider.style}
                            onChange={value => this.onChange(index, 'divider', {style: value})}
                            name='Divider style'/>
                        <div>
                            <h5>Inputs</h5>
                            {this.renderInputProps(index, item, 'top')}
                            {this.renderInputProps(index, item, 'bottom')}
                        </div>
                        <div>
                            <h5>Texts</h5>
                            {this.renderTextsProps(index, item, 'front')}
                            {this.renderTextsProps(index, item, 'bottom')}
                        </div>
                    </>
                );
            case 'integer':
                return (
                    <>
                        <Style initial={item.style} onChange={value => this.onChange(index, 'style', value)}/>
                        <div>
                            <h5>Inputs</h5>
                            {this.renderInputProps(index, item, 'v')}
                        </div>
                    </>
                );
            case 'static':
                return (
                    <>
                        <Style initial={item.style} onChange={value => this.onChange(index, 'style', value)}/>
                        <div>
                            <label className="col-form-label">HTML</label>
                            <input
                                type='text'
                                className='form-control'
                                value={item.content && item.content.html ? item.content.html : ''}
                                onChange={event => {
                                    let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                    content.html = event.target.value;
                                    this.onChange(index, 'content', content);
                                }}
                            />
                            <label className="col-form-label">Text</label>
                            <input
                                type='text'
                                className='form-control'
                                value={item.content && item.content.text ? item.content.text : ''}
                                onChange={event => {
                                    let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                    content.text = event.target.value;
                                    this.onChange(index, 'content', content);
                                }}
                            />
                        </div>
                    </>
                );
            case 'hidden':
                return (
                    <div>
                        <label className="col-form-label">Text</label>
                        <input
                            type='text'
                            className='form-control'
                            value={item.content && item.content.text ? item.content.text : ''}
                            onChange={event => {
                                let content = JSON.parse(JSON.stringify(item.content ? item.content : {}));
                                content.text = event.target.value;
                                this.onChange(index, 'content', content);
                            }}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    renderOwnProperties() {
        return (
            <div>
                <label
                    htmlFor={"textarea-placeholder"}
                    className="col-form-label">Items</label>
                <div>
                    <button className='button mb-2' onClick={() => {
                        let data = JSON.parse(JSON.stringify(this.props.component.data));
                        data.items.push({
                            type: "fraction",
                            style: {},
                            divider: {
                                style: {}
                            },
                            inputs: {
                                top: {
                                    placeholder: "",
                                    style: {
                                        width: "80px"
                                    }
                                }
                            },
                            texts: {
                                front: {
                                    content: {
                                        html: "",
                                        text: ""
                                    },
                                    style: {}
                                }
                            }
                        });
                        this.props.onChange('data', data);
                    }}>+ Item
                    </button>
                    <div>
                        {this.props.component.data.items.map((item, index) => {
                            return (
                                <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                                    <i
                                        className='fas fa-trash text-danger clickable'
                                        style={{position: 'absolute', right: '0', top: '5px'}}
                                        onClick={() => {
                                            let data = JSON.parse(JSON.stringify(this.props.component.data));
                                            data.items.splice(index, 1);
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                    <div>
                                        <div>
                                            <label
                                                htmlFor={"type_" + index}
                                                className="col-form-label">Type</label>
                                            <select
                                                className="form-control"
                                                id={"type_" + index}
                                                value={item.type ? item.type : ''}
                                                onChange={event => {
                                                    let data = JSON.parse(JSON.stringify(this.props.component.data));
                                                    switch (event.target.value) {
                                                        case 'fraction':
                                                            data.items[index] = {
                                                                type: "fraction",
                                                                style: {},
                                                                divider: {
                                                                    style: {}
                                                                },
                                                                inputs: {
                                                                    top: {
                                                                        placeholder: "",
                                                                        style: {}
                                                                    }
                                                                },
                                                                texts: {
                                                                    front: {
                                                                        content: {
                                                                            html: "",
                                                                            text: ""
                                                                        },
                                                                        style: {}
                                                                    }
                                                                }
                                                            }
                                                            break;
                                                        case 'integer':
                                                            data.items[index] = {
                                                                type: "integer",
                                                                style: {},
                                                                inputs: {
                                                                    v: {
                                                                        placeholder: "",
                                                                        style: {}
                                                                    }
                                                                }
                                                            }
                                                            break;
                                                        case 'static':
                                                            data.items[index] = {
                                                                type: "static",
                                                                content: {
                                                                    html: "",
                                                                    text: ""
                                                                },
                                                                style: {}
                                                            }
                                                            break;
                                                        case 'hidden':
                                                            data.items[index] = {
                                                                type: "hidden",
                                                                content: {
                                                                    text: ""
                                                                }
                                                            }
                                                            break;
                                                    }
                                                    this.props.onChange('data', data);
                                                }}
                                            >
                                                <option value='fraction'>Fraction</option>
                                                <option value='integer'>Integer</option>
                                                <option value='static'>Static</option>
                                                <option value='hidden'>Hidden</option>
                                            </select>
                                        </div>
                                        {this.renderItemProperties(item, index)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default function (props) {
    return <Fraction {...props}/>;
}
