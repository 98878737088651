import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {ProgressBar, datesConstants} from "mindsets-js-sdk";

import PauseButton from "./PauseButton";
import ResumeButton from "./ResumeButton";
import DeleteButton from "./DeleteButton";

const Row = function ({item, row_number}) {
    const lesson = useSelector(state => state.streams_lesson);

    if (!Object.keys(lesson).length) {
        return null;
    }

    return (
        <tr>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{item.code}</td>
            <td>
                <Link
                    to={'/users/' + item.user.id}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit User"
                >
                    {item.user.email}
                </Link>
            </td>
            <td>{item.persona}</td>
            <td>{item.status}</td>
            <td>
                <div className='d-flex flex-row'>
                    <div className={`mindsets-icon ${item.user.avatar}`}/>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='d-flex flex-row justify-content-between'>
                            <div>{item.user.fname + ' ' + item.user.lname}</div>
                            <div>
                                {item.index + 1 === lesson.challenge_version.number_cards ? 'Finished!' : ('On Page ' + (item.index + 1))}
                            </div>
                        </div>
                        <ProgressBar
                            index={item.index}
                            no_bonus_cards={lesson.challenge_version.number_cards - lesson.challenge_version.complete_index}
                            no_cards={lesson.challenge_version.number_cards}
                        />
                    </div>
                </div>
            </td>
            <td>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td className='flex-row'>
                <PauseButton item={item}/>
                <ResumeButton item={item}/>
                <DeleteButton id={item.id}/>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        persona: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
        user: PropTypes.shape({
            id: PropTypes.number.isRequired,
            fname: PropTypes.string,
            lname: PropTypes.string,
            email: PropTypes.string.isRequired,
            persona: PropTypes.string.isRequired,
        }).isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
