import {
    SET_LIMIT_POSTFIX,
    SET_LIMIT_PREFIX,
    SET_CURRENT_POSTFIX,
    SET_CURRENT_PREFIX
} from "mindsets-js-sdk";

import {
    LOADED_LONGITUDINAL_STATS,
    LOADED_LONGITUDINAL_STATS_TOTAL,
    LOADED_LONGITUDINAL_STATS_PAGES,
    SET_LONGITUDINAL_USER,
    SET_LONGITUDINAL_USER_STATS,
    SET_LONGITUDINAL_STREAM_STATS,
    SET_LONGITUDINAL_CHALLENGE_STATS,
    SET_LONGITUDINAL_TIME_SPENT_STATS,
    SET_LONGITUDINAL_LESSON_AVG_TIME_SPENT_STATS
} from "../actions/longitudinal_actions";

const searchParams = new URLSearchParams(location.search);

const longitudinal_stats = (payload = [], action) => {
    switch (action.type) {
        case LOADED_LONGITUDINAL_STATS:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_stats_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case SET_CURRENT_PREFIX + 'longitudinal_stats' + SET_CURRENT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_stats_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_LONGITUDINAL_STATS_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_stats_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_LONGITUDINAL_STATS_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_stats_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case SET_LIMIT_PREFIX + 'longitudinal_stats' + SET_LIMIT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};

const longitudinal_user = (payload = null, action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_USER:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_user_stats = (payload = [], action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_USER_STATS:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_stream_stats = (payload = [], action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_STREAM_STATS:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_challenge_stats = (payload = [], action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_CHALLENGE_STATS:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_time_spent_stats = (payload = {}, action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_TIME_SPENT_STATS:
            return action.payload;
        default:
            return payload;
    }
};
const longitudinal_lesson_avg_time_spent_stats = (payload = [], action) => {
    switch (action.type) {
        case SET_LONGITUDINAL_LESSON_AVG_TIME_SPENT_STATS:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    longitudinal_stats,
    longitudinal_stats_current,
    longitudinal_stats_total,
    longitudinal_stats_pages,
    longitudinal_stats_limit,
    longitudinal_user,
    longitudinal_user_stats,
    longitudinal_stream_stats,
    longitudinal_challenge_stats,
    longitudinal_time_spent_stats,
    longitudinal_lesson_avg_time_spent_stats
}
