import {SET_SELECTED_PRACTICE_ID} from "../actions/practice_actions";

const searchParams = new URLSearchParams(location.search);

const selected_practice_id = (payload = searchParams.has('practice_id') ? parseInt(searchParams.get('practice_id')) : null, action) => {
    switch (action.type) {
        case SET_SELECTED_PRACTICE_ID:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    selected_practice_id
}
