import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, settingsConstants, SetSettingAction} from "mindsets-js-sdk";

import StringFormField from "./StringFormField";

const FormFields = function ({updated, setUpdated}) {
    const dispatch = useDispatch();
    const setting = useSelector(state => state.setting);
    if (!setting) {
        return null;
    }
    let setting_new = JSON.parse(JSON.stringify(setting));
    switch (setting_new.type) {
        case settingsConstants.SETTING_INT:
        case settingsConstants.SETTING_DECIMAL:
        case settingsConstants.SETTING_STRING:
            return (
                <StringFormField setting_key={setting_new.key} value={setting_new.value} onChange={value => {
                    setting_new.value = value;
                    dispatch(SetSettingAction(setting_new));
                    if (!updated) {
                        setUpdated(true);
                    }
                }}/>
            );
        case settingsConstants.SETTING_ARRAY:
            return (
                <div className='array-fields-wrapper'>
                    {setting_new.value.map((item, index) => {
                        return (
                            <div className='d-flex' key={index}>
                                <StringFormField setting_key={index} value={item} onChange={value => {
                                    setting_new.value[index] = value;
                                    dispatch(SetSettingAction(setting_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}/>
                                <button className='button' onClick={() => {
                                    setting_new.value.splice(index, 1);
                                    dispatch(SetSettingAction(setting_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}>
                                    <i className="fas fa-trash"/>
                                </button>
                            </div>
                        );
                    })}
                    <button className='button' onClick={event => {
                        event.preventDefault();
                        setting_new.value.push('');
                        dispatch(SetSettingAction(setting_new));
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}>
                        <i className="fas fa-plus-circle fa-lg"/>
                    </button>
                </div>
            );
        case settingsConstants.SETTING_OBJECT:
            return (
                <div className='array-fields-wrapper'>
                    {Object.keys(setting_new.value).sort().map((key, index) => {
                        return (
                            <div className='d-flex' key={index}>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={index.toString()}
                                        placeholder="Key"
                                        value={key}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            delete setting_new.value[key];
                                            setting_new.value[event.target.value] = setting_new.value[key];
                                            dispatch(SetSettingAction(setting_new));
                                            if (!updated) {
                                                setUpdated(true);
                                            }
                                        }}/>
                                </div>
                                <StringFormField
                                    setting_key={setting_new.key}
                                    value={setting_new.value[key]}
                                    onChange={value => {
                                        value[index] = setting_new.value;
                                        setting_new.value = value;
                                        dispatch(SetSettingAction(setting_new));
                                        if (!updated) {
                                            setUpdated(true);
                                        }
                                    }}/>
                                <button className='button' onClick={() => {
                                    // let value = this.props.selected.value;
                                    // delete value[key];
                                    // this.props.onChange('value', value);//TODO
                                }}>
                                    <i className="fas fa-trash"/>
                                </button>
                            </div>
                        );
                    })}
                    <button className='button' onClick={() => {
                        setting_new.value[''] = '';
                        dispatch(SetSettingAction(setting_new));
                        if (!updated) {
                            setUpdated(true);
                        }
                    }}>
                        <i className="fas fa-plus-circle fa-2x"/>
                    </button>
                </div>
            );
        case settingsConstants.SETTING_BOOL:
            return (
                <div>
                    <Checkbox
                        checked={setting_new.value}
                        label={setting_new.name}
                        toggle={() => {
                            // setting_new[key] = ''
                            // value = '';
                            // this.props.onChange('value', !value)
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    />
                </div>
            );
        default:
            throw 'Invalid Setting Type ' + setting_new.type;
    }
}

FormFields.propTypes = {
    updated: PropTypes.bool.isRequired,
    setUpdated: PropTypes.func.isRequired
}

export default FormFields;
