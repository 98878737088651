import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

const Table = function ({school_id}) {
    const items = useSelector(state => Array.isArray(state.longitudinal_stats) ? state.longitudinal_stats : []);
    const current = useSelector(state => state.longitudinal_stats_current);
    const limit = useSelector(state => state.longitudinal_stats_limit);

    if (!school_id) {
        return null;
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">User ID</th>
                    <th scope="col">User Email</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Lesson School(s)</th>
                    <th scope="col">No. of Classes Joined</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((user, index) => {
                    return <Row
                        key={user.id}
                        user={user}
                        row_number={getRowNumber(index, current, limit)}
                        school_id={school_id}
                    />;
                })}
                </tbody>
            </table>
        </div>
    );
}

Table.propTypes = {
    school_id: PropTypes.number
};

export default Table;
