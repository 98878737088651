import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    LoadingAction,
    DatePicker,
    ErrorMessageAction,
    SetProjectAction,
    UpdateProjectAction
} from "mindsets-js-sdk";

function UpdateProjectForm({update, setUpdate, action}) {
    const params = useParams();
    const dispatch = useDispatch();
    const project = useSelector(state => state.project);
    const [updated, setUpdated] = useState(false);
    const [name, setName] = useState('');
    const [starts_at, setStartsAt] = useState(null);
    const [ends_at, setEndsAt] = useState(null);

    function validate() {
        let errors = 0;

        if (!name || name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name of the project.'));
            errors++;
        }

        return errors === 0;
    }

    function renderSubmitButton() {
        if (updated) {
            return (
                <button type="submit" className="button">Submit</button>
            );
        }

        return null;
    }

    useEffect(() => {
        if (project) {
            setName(project.name);
            setStartsAt(project.starts_at);
            setEndsAt(project.ends_at);
        }
    }, [project]);
    useEffect(() => {
        return () => {
            setName('');
            setStartsAt(null);
            setEndsAt(null);
        };
    }, []);

    if (update) {
        return (
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(UpdateProjectAction(params.project_id, {
                        name,
                        type: project.type,
                        district_id: project.district_id,
                        school_id: project.school_id,
                        starts_at,
                        ends_at
                    }))
                        .then(() => {
                            dispatch(SetProjectAction({}));
                            setName('');
                            setStartsAt(null);
                            setEndsAt(null);
                        })
                        .then(() => setUpdate(false))
                        .then(() => setUpdated(false))
                        .then(action)
                        .catch(error => {
                            dispatch(LoadingAction(false));
                            dispatch(ErrorMessageAction(error));
                            console.error(error);
                        });
                }
            }} autoComplete="off" id="project-form-update">
                <div>
                    <label htmlFor="name" className="form-label">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}/>
                </div>
                <div>
                    <label htmlFor="datepicker-field" className='d-block'>Project start day</label>
                    <DatePicker
                        date={starts_at}
                        onChange={timestamp => {
                            setStartsAt(timestamp);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="datepicker-field" className='d-block'>Project end day</label>
                    <DatePicker
                        date={ends_at}
                        onChange={timestamp => {
                            setEndsAt(timestamp);
                            if (!updated) {
                                setUpdated(true);
                            }
                        }}
                    />
                </div>
                {renderSubmitButton()}
                <button className="button default" onClick={event => {
                    event.preventDefault();
                    setUpdate(false);
                }}>Cancel
                </button>
            </form>
        );
    }

    return null;
}

UpdateProjectForm.propTypes = {
    update: PropTypes.bool.isRequired,
    setUpdate: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired
}

export default UpdateProjectForm;
