import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import Highcharts from "highcharts";

import {INTERVAL_COLORS} from "../index";

export default function () {
    const impact_study = useSelector(state => state.impact_study);

    if (impact_study && impact_study.cassp) {
        let data = [];
        Object.keys(impact_study.cassp).map(mp => {
            Object.keys(impact_study.cassp[mp]).map(category => {
                data.push([mp, category, impact_study.cassp[mp][category].no_students, impact_study.cassp[mp][category].percent]);
            });
        });

        return (
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>MP</th>
                        <th>Category</th>
                        <th># students</th>
                        <th>Percentage</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, key) => {
                        return (
                            <tr key={key}>{row.map((value, index) => {
                                return (
                                    <td key={index}>{value}</td>
                                );
                            })}</tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
}
