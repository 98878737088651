import React from 'react';
import {useSelector} from "react-redux";

import SchoolSubscriptionsRow from "./SchoolSubscriptionsRow";

export default function () {
    const school = useSelector(state => state.school);
    if (school.subscriptions && school.subscriptions.length > 0) {
        const subscriptions = JSON.parse(JSON.stringify(school.subscriptions));
        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr className="text-center">
                            <th scope="col">No</th>
                            <th scope="col">Period</th>
                            <th scope="col">Term</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Is Trial</th>
                            <th scope="col">Created by</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {subscriptions.reverse().map((item, index) => (
                            <SchoolSubscriptionsRow
                                key={item.id}
                                item={item}
                                row_number={index + 1}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        return (
            <p>No subscriptions found</p>
        );
    }
}
