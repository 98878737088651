import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {
    CustomDropdown,
    ErrorMessageAction,
    LoadedResponsePointsAction,
    UpdateResponsePointAction
} from "mindsets-js-sdk";

function ScoreInput(
    {
        question_practice_skill,
        getResponsePoints,
        response_point_index
    }
) {
    const dispatch = useDispatch();
    const schedule = useSelector(state => state.lesson_scoring_schedule);
    const response_points = useSelector(state => state.response_points);
    let response_points_new = JSON.parse(JSON.stringify(response_points));

    function validateScore(points) {
        let errors = 0;

        if (null !== points && isNaN(parseInt(points))) {
            dispatch(ErrorMessageAction('Error! Must be a whole number(integer)'));
            errors++;
        }

        if (null !== points && question_practice_skill && points > question_practice_skill.points.length - 1) {
            dispatch(ErrorMessageAction('Error! Exceeded max points for this question'));
            errors++;
        }

        return errors === 0;
    }

    function updateScore(response_point, points) {
        if (validateScore(points)) {
            dispatch(UpdateResponsePointAction(response_point.id, {points})).then(result => {
                if (result.hasOwnProperty('result') && result.result === 'success') {
                    getResponsePoints();
                }
            });
            if (response_point.points === points) {
                response_point.points = null;
            } else {
                response_point.points = points;
                if (response_point.is_correct === false) {
                    response_point.is_correct = null;
                }
            }
            if (['using', 'using_ai'].includes(response_point.use_auto)) {
                response_point.use_auto = 'edited';
            }
            response_points_new[response_point_index] = response_point;
            dispatch(LoadedResponsePointsAction(response_points_new));
        }
    }

    function renderScoringButtons() {
        const response_point = response_points_new[response_point_index];

        return question_practice_skill.points.map(point => {
            let border_color_class = 'inactive';
            if (response_point.points === point.point) {
                if (response_point.is_correct === false) {
                    border_color_class = 'red';
                } else {
                    border_color_class = 'active';
                }
            }

            return (
                <div key={point.id}
                     className={'button-border ' + border_color_class}>
                    <button
                        className={'button inactive'}
                        onClick={() => {
                            if (['using', 'using_ai'].includes(response_point.use_auto)) {
                                if (confirm('This response was already graded by a Machine. Are you sure you want to change the score?')) {
                                    updateScore(response_point, point.point);
                                }
                            } else {
                                updateScore(response_point, point.point);
                            }
                        }}
                        title={point.description}
                        disabled={!schedule || schedule.status !== 'started'}
                    >{point.point}</button>
                </div>
            );
        })
    }

    function renderReasoning() {
        const response_point = response_points_new[response_point_index];
        if (response_point && response_point.use_auto === 'using_ai' && response_point.reasoning) {
            return (
                <div>
                    <div>&nbsp;</div>
                    <CustomDropdown
                        trigger={(<i className='fas fa-info reasoning-trigger'/>)}
                        items={{
                            'reasoning': <div className='p-3'>{response_point.reasoning}</div>
                        }}
                        use_arrow={false}
                        open_on_mouse_over={true}
                        close_on_mouse_leave={true}
                        item_class='reasoning'
                    />
                </div>
            );
        }

        return null;
    }

    if (question_practice_skill && question_practice_skill.points.length > 0 && response_point_index >= 0) {
        return (
            <div className='scoring-input'>
                {renderScoringButtons()}
                {renderReasoning()}
            </div>
        );
    } else {
        return (
            <div className='badge bg-info'>NA</div>
        );
    }
}

ScoreInput.propTypes = {
    question_practice_skill: PropTypes.shape({
        points: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            point: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
        })).isRequired
    }),
    getResponsePoints: PropTypes.func.isRequired,
    response_point_index: PropTypes.number.isRequired
}

export default ScoreInput;
