import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {
    SetChallengeBuilderMouseOverComponentAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";
import ComponentItem from "./ComponentItem";

export default function ({index, row_index, component_index, component}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: index + 1});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const dispatch = useDispatch();
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);

    return (
        <ComponentItem
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            row_index={row_index}
            component_index={component_index}
            component={component}
            onClick={event => {
                event.stopPropagation();
                dispatch(SetChallengeBuilderSelectedRowIndexAction(component_index === selected_component_index && row_index === selected_row_index ? null : row_index));
                dispatch(SetChallengeBuilderSelectedComponentIndexAction(component_index === selected_component_index && selected_row_index === row_index ? null : component_index));
            }}
            onMouseOver={() => dispatch(SetChallengeBuilderMouseOverComponentAction({
                row_index,
                component_index
            }))}
            onMouseLeave={() => dispatch(SetChallengeBuilderMouseOverComponentAction(null))}
        />
    );
}
