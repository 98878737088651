import React from 'react';

import BaseCard from './BaseCard';

import AnswerKeyButton from '../AnswerKeyButton';

class FinalCard extends BaseCard {
    getCardType() {
        return 'final';
    }

    getMinHeight() {
        return '400px';
    }

    cardHasComponents() {
        return false;
    }

    renderIcon() {
        return (
            <div className="final-card-icon">
                <i className="far fa-thumbs-up fa-4x"/>
            </div>
        );
    }

    renderContent() {
        return (
            <div className="section">
                <h2 className="header-new center">Bonus finished!</h2>
            </div>
        );
    }

    renderReturnToDashboard() {
        return (
            <div>
                <button className="button large blue" onClick={event => event.preventDefault()}>
                    <i className="fas fa-home"/>
                    <span>Home</span>
                </button>
            </div>
        )
    }

    renderUseAnswerKey() {
        return (
            <div className="final-card-answer-key-message">
                {`Use the Answer Key to check your work.`}
            </div>
        )
    }

    renderBonusAnswerKey() {
        return (
            <div>
                <AnswerKeyButton is_bonus={true}/>
            </div>
        )
    }

    renderOtherContent() {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                {this.renderIcon()}

                {this.renderContent()}

                {this.renderUseAnswerKey()}

                <div className="section">
                    <div className="d-flex flex-row flex-box-10-10 flex-wrap justify-content-center">
                        {this.renderBonusAnswerKey()}

                        {this.renderReturnToDashboard()}
                    </div>
                </div>
            </div>
        );
    }
}

export default FinalCard
