import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_CONTRACT_SCHOOLS = '[contract] Loaded Schools';
export const LOADED_CONTRACT_CHALLENGES = '[contract] Loaded Challenges';

export const LoadContractSchoolsAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('schools', options))
        .then(response => dispatch(LoadedContractSchoolsAction(response.items)));
};
export const LoadedContractSchoolsAction = payload => ({
    type: LOADED_CONTRACT_SCHOOLS,
    payload
});

export const LoadChallengesAction = options => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('challenge_versions', options))
        .then(response => dispatch(LoadedContractChallengesAction(response.items)));
};
export const LoadedContractChallengesAction = payload => ({
    type: LOADED_CONTRACT_CHALLENGES,
    payload
});
