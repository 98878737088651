import React from 'react';
import PropTypes from "prop-types";
import {
    countriesConstants,
    membershipUtils,
    membershipConstants,
    datesConstants
} from "mindsets-js-sdk";

import UserRoles from "./UserRoles";
import NumberLessonsCreated from "./NumberLessonsCreated";
import DistrictSubscription from "./DistrictSubscription";
import SchoolSubscription from "./SchoolSubscription";
import School from "./School";
import EditSchool from "./EditSchool";
import NumberLessonsJoinedAsStudent from "./NumberLessonsJoinedAsStudent";
import NumberLessonsJoinedAsCoTeacher from "./NumberLessonsJoinedAsCoTeacher";
import Edit from "./Edit";
import ResetPassword from "./ResetPassword";
import InvalidateSession from "./InvalidateSession";

const Row = function ({user, row_number, online, curriculum}) {
    return (
        <tr key={user.id}>
            <td>{row_number}</td>
            <td>{user.id} {online ? <i className="fas fa-dot-circle green"/> : null}</td>
            <td>{user.email}</td>
            <td>{user.fname ? user.fname : ''} {user.lname ? user.lname : ''} {user.grade_level ? ("(Grade " + user.grade_level + ")") : ''}</td>
            <td>{curriculum ? curriculum.name : 'N/A'}</td>
            <td scope="col">
                <UserRoles roles={user.roles} persona={user.persona}/>
            </td>
            <td className="text-center"><NumberLessonsCreated user={user}/></td>
            <td>{user.country && countriesConstants.COUNTRIES.hasOwnProperty(user.country) ? countriesConstants.COUNTRIES[user.country] : 'N/A'}</td>
            <td>
                <div>Joined on: {new Date(user.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}
                    <hr/>
                </div>
                <div>Using: {user.remote_provider}
                    <hr/>
                </div>
                <DistrictSubscription user={user}/>
                <SchoolSubscription user={user}/>
                {membershipConstants.PLAN_FREE === membershipUtils.getPlanType(user) ? <div>Free account</div> : null}
                {user.teacher_subscribed === 1 ? <div>Paid subscription</div> : null}
            </td>
            <td scope="col"><School user={user}/></td>
            <td scope="col">{user.schools ? user.schools.map((school, index) => <EditSchool school={school} key={index}/>) : null}</td>
            <td className="text-center"><NumberLessonsJoinedAsStudent user={user}/></td>
            <td className="text-center"><NumberLessonsJoinedAsCoTeacher user={user}/></td>
            <td>
                <Edit user={user}/>
                <ResetPassword user={user}/>
                <InvalidateSession user={user}/>
            </td>
        </tr>
    );
}

Row.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        fname: PropTypes.string,
        lname: PropTypes.string,
        persona: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            initial: PropTypes.bool.isRequired,
        })).isRequired,
        number_lessons: PropTypes.number.isRequired,
        country: PropTypes.string,
        curriculum_id: PropTypes.number,
        school: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            subscriptions: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                starts_at: PropTypes.number.isRequired,
                ends_at: PropTypes.number.isRequired
            })),
            district: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                subscription: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    starts_at: PropTypes.number.isRequired,
                    ends_at: PropTypes.number.isRequired
                }))
            })
        }),
        schools: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        streams_count: PropTypes.number.isRequired,
        co_classes_count: PropTypes.number.isRequired,
        created_at: PropTypes.number.isRequired,
        remote_provider: PropTypes.string,
        grade_level: PropTypes.string
    }).isRequired,
    curriculum: PropTypes.shape({name: PropTypes.string.isRequired}),
    row_number: PropTypes.number.isRequired,
    online: PropTypes.bool.isRequired
};

export default Row;
