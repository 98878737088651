import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contentUtils} from "mindsets-js-sdk";

class Link extends Component {
    getLink() {
        return _.get(this.props.component_data, 'data.link')
    }

    renderImage() {
        const image = _.get(this.props.component_data, 'data.image')
        if (image) {
            return (
                <div className="text-center">
                    <img src={image} />
                </div>
            )
        }
    }

    renderTitle() {
        const title = _.get(this.props.component_data, 'data.title')
        if (title) {
            return (
                <div className="text-center">
                    {contentUtils.processStringContent(title)}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-link">
                <div className="d-flex justify-content-start">
                    <a className="component-link-a" href={this.getLink()} target="_blank">
                        {this.renderImage()}
                        {this.renderTitle()}
                    </a>
                </div>
            </div>
        )
    }
}

Link.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default Link
