import React from "react";
import PropTypes from "prop-types";

const StringFormField = function ({setting_key, value = '', onChange}) {
    return (
        <div>
            <input
                type="text"
                className="form-control"
                id={setting_key}
                placeholder="Value"
                value={value}
                onChange={event => onChange(event.target.value)}/>
        </div>
    );
}

StringFormField.p = {
    setting_key: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StringFormField;
