import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    ErrorMessageAction,
    authUtils,
    accessConstants,
    countriesConstants,
    datesConstants,
    LoadingAction,
    LoadContractAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import EditButton from "./components/EditButton";
import District from "./components/District";
import State from "./components/State";
import Schools from "./components/Schools";
import Challenges from "./components/Challenges";
import ReviewButton from "../Contracts/components/ReviewButton";
import ApproveButton from "../Contracts/components/ApproveButton";
import Status from "./components/Status";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export default function () {
    const params = useParams();
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const contract = useSelector(state => state.contract);
    const loadContract = function () {
        dispatch(LoadContractAction(params.contract_id, {
            'selectors[0]': 'district',
            'selectors[1]': 'assignee',
            'selectors[2]': 'schools',
            'selectors[3]': 'challenge_codes',
            'selectors[4]': 'approved_by'
        })).catch(error => {
            dispatch(LoadingAction(false));
            dispatch(ErrorMessageAction(error));
            console.error(error);
        });
    }

    useEffect(() => {
        loadContract();
    }, []);

    function getDuration(starts_at_timestamp, ends_at_timestamp) {
        const startsAt = new Date(starts_at_timestamp * 1000);
        const endsAt = new Date(ends_at_timestamp * 1000);
        endsAt.setTime(endsAt.getTime() + 1000);
        let duration_measure = '';
        let duration_value = '';

        switch (true) {
            case (endsAt.getMonth() - startsAt.getMonth()) % 1 === 0:
                duration_measure = 'months';
                duration_value = endsAt.getMonth() - startsAt.getMonth();
                break;
            case ((endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7)) % 1 === 0:
                duration_measure = 'weeks';
                duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7);
                break;
            case ((endsAt - startsAt) / (1000 * 60 * 60 * 24)) % 1 === 0:
                duration_measure = 'days';
                duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24);
                break;
        }

        return [duration_measure, duration_value];
    }

    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_SEE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }
    if (!contract || !Object.keys(contract).length) {
        return null;
    }

    const [contract_duration_measure, contract_duration_value] = getDuration(contract.starts_at, contract.ends_at);
    const [session_duration_measure, session_duration_value] = getDuration(contract.session_starts_at, contract.session_ends_at);

    return (
        <div className='container' id='preview-contract-page'>
            <div className='section d-flex justify-content-end flex-gap-5'>
                <Status/>
                <ReviewButton id={contract.id} status={contract.status} callback={loadContract}/>
                <ApproveButton id={contract.id} status={contract.status} callback={loadContract}/>
                <EditButton id={contract.id} status={contract.status}/>
            </div>
            <div className='section'>
                <h1 className='text-center'>{contract.name}</h1>
            </div>
            <div className='section'>
                <h5>GENERAL INFORMATION</h5>
                <div>Country: <u>{countriesConstants.COUNTRIES[contract.country]}</u></div>
                <State/>
                <District/>
                <div>Contact person: <u>{contract.contacts}</u></div>
                <div>Pilot?: {contract.is_pilot ? 'Yes' : 'No'}. Trial?: {contract.is_trial ? 'Yes' : 'No'}</div>
                <div>Assignee: {contract.assignee.fname + ' ' + contract.assignee.lname}</div>
            </div>
            <div className='section'>
                <h5>Terms(duration)</h5>
                <div>Contract Term:
                    From: <u>{contract.starts_at ? new Date(contract.starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT) : 'not selected'}</u> Through: <u>{contract.ends_at ? new Date(contract.ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT) : 'not selected'}</u>
                    ({contract_duration_value} {contract_duration_measure})
                </div>
                <div>Session Term:
                    From: <u>{contract.session_starts_at ? new Date(contract.session_starts_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT) : 'not selected'}</u> Through: <u>{contract.session_ends_at ? new Date(contract.session_ends_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT) : 'not selected'}</u>
                    ({session_duration_value} {session_duration_measure})
                </div>
            </div>
            <div className='section'>
                <h5>Financial section</h5>
                <div># students: <u>{contract.number_students}</u></div>
                <div>Price per student(USD): <u>{contract.price ? formatter.format(contract.price) : 'n/a'}</u></div>
                <div>
                    <strong>Subtotal(USD): <u>{contract.price && contract.number_students ? formatter.format(contract.price * contract.number_students) : 'n/a'}</u></strong>
                </div>
                <div>Discount(%): <u>{contract.discount ? contract.discount : 'n/a'}</u></div>
                <div>
                    <h3>Total(USD): <u>{contract.price && contract.number_students && null !== contract.discount ? formatter.format(contract.price * contract.number_students - (contract.discount ? contract.price * contract.number_students * contract.discount / 100 : 0)) : 'n/a'}</u>
                    </h3></div>
                <div>Discount notes: <u>{contract.discount_notes ? contract.discount_notes : 'n/a'}</u></div>
                <div>Quote ref: <u>{contract.quote_ref ? contract.quote_ref : 'n/a'}</u></div>
                <div>Invoice ref: <u>{contract.invoice_ref ? contract.invoice_ref : 'n/a'}</u></div>
            </div>
            <div className='section'>
                <Schools/>
            </div>
            <div className='section'>
                <Challenges/>
            </div>
            <div className='section'>
                <h5>Professional Development</h5>
                <div># teachers: <u>{contract.number_teachers}</u></div>
                <div>PD notes: <u>{contract.pd_notes ? contract.pd_notes : 'n/a'}</u></div>
            </div>
            <div className='section'>
                <h5>Assessment/Evaluation</h5>
                <div>Impact Study Notes: <u>{contract.impact_study_notes ? contract.impact_study_notes : 'n/a'}</u>
                </div>
                <div>Number of data points: <u>{contract.number_data_points ? contract.number_data_points : 'n/a'}</u>
                </div>
            </div>
            <div className='section'>
                <h5>Other</h5>
                <div>General notes: <u>{contract.notes ? contract.notes : 'n/a'}</u></div>
            </div>
        </div>
    );
}
