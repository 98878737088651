import React, {useCallback} from 'react';
import {Link} from "react-router-dom";
import {
    ListPage,
    filterConstants,
    accessConstants,
    DistrictDropdown,
    SchoolDropdown,
    LoadProjectsAction
} from "mindsets-js-sdk";

import Table from "./components/Table";
import InvalidateAllProjectsButton from "./components/InvalidateAllProjectsButton";

export default function () {
    const debounce = useCallback(_.debounce(a => a(), 600), []);

    return (
        <>
            <h1>Grading Projects</h1>
            <ListPage
                action={options => {
                    options['selectors[0]'] = 'district';
                    options['selectors[1]'] = 'school';

                    return LoadProjectsAction(options);
                }}
                filter_columns={[
                    {
                        key: 'district_id',
                        name: 'By School District',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <DistrictDropdown
                                    onSelect={district => {
                                        if (district) {
                                            setFilterValue(district.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return district ? district.name : '';
                                    }}
                                    id='constract-district-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'school_id',
                        name: 'School',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='constact-school'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'starts_at',
                        name: 'Projects start date'
                    },
                    {
                        key: 'ends_at',
                        name: 'Project end date'
                    }
                ]}
                entity={'projects'}
                create_button={(
                    <div className="d-flex justify-content-end flex-gap-5">
                        <Link to="/projects/new" className="button">+ New Project</Link>
                        <InvalidateAllProjectsButton/>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CONTRACTS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
