import React from 'react';
import PropTypes from 'prop-types';

function Selection({component_data, item, selection_data, onClickItem, answer}) {
    function renderValue() {
        const unit_prefix = _.get(component_data, 'data.unit_prefix', '');
        const unit_suffix = _.get(component_data, 'data.unit_suffix', '');
        const value_prefix = _.get(component_data, 'data.value_prefix', '');
        const value_suffix = _.get(component_data, 'data.value_suffix', '');
        const value_text = `${value_prefix}${unit_prefix}${item.value}${unit_suffix}${value_suffix}`;

        return (
            <div className="cc-selector-selections-item-value">
                {value_text}
            </div>
        );
    }

    function renderAmount() {
        const amount = _.get(selection_data, 'amount', 0);
        const onClickMinus = event => {
            event.preventDefault();
            onClickItem(item.id, -1);
        }
        const onClickPlus = event => {
            event.preventDefault();
            onClickItem(item.id, 1);
        }
        const total_value = answer.total_value;
        const value_maximum = _.get(component_data, 'data.value_maximum', Number.MAX_SAFE_INTEGER);
        const total_amount = _.sumBy(answer.answer_selections, 'amount');
        const amount_maximum = _.get(component_data, 'data.amount_maximum', Number.MAX_SAFE_INTEGER);
        const plus_disabled = (total_value >= value_maximum) || (total_amount >= amount_maximum);

        return (
            <div className="cc-selector-selections-item-amount">
                <div className="cc-selector-selections-item-amount-minus">
                    <button className="btn btn-challenge btn-small" onClick={onClickMinus}>
                        <i className="fas fa-minus"/>
                    </button>
                </div>
                <div className="cc-selector-selections-item-amount-text">
                    {amount}
                </div>
                <div className="cc-selector-selections-item-amount-plus">
                    <button className="btn btn-challenge btn-small" onClick={onClickPlus} disabled={plus_disabled}>
                        <i className="fas fa-plus"/>
                    </button>
                </div>
            </div>
        );
    }

    function renderSubtotal() {
        const value = _.get(item, 'value', 0);
        const amount = _.get(selection_data, 'amount', 0);
        const subtotal = value * amount;
        const unit_prefix = _.get(component_data, 'data.unit_prefix', '');
        const unit_suffix = _.get(component_data, 'data.unit_suffix', '');
        const subtotal_text = `${unit_prefix}${subtotal}${unit_suffix}`;
        return (
            <div className="cc-selector-selections-item-subtotal">
                {subtotal_text}
            </div>
        );
    }

    return (
        <div className="cc-selector-selections-item">
            <div className="cc-selector-selections-item-wrapper">
                <div className="cc-selector-selections-item-img">
                    <img
                        src={item.image_url}
                        alt={item.image_alt}
                    />
                </div>
                <div className="cc-selector-selections-item-title">
                    {item.title}
                </div>
                {renderValue()}
                {renderAmount()}
                {renderSubtotal()}
            </div>
        </div>
    );
}

Selection.propTypes = {
    component_data: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    selection_data: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired,
    answer: PropTypes.object.isRequired
}

export default Selection
