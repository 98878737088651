import React from "react";
import {useSelector} from "react-redux";

export default function () {
    const streams = useSelector(state => state.impact_streams);
    if (streams.length > 0) {
        return (
            <div>
                <div>Student streams: {streams.length}</div>
                <div>Class codes: {_.uniq(streams.map(stream => stream.lesson.code)).join(', ')}</div>
            </div>
        );
    }

    return (
        <div>Nothing found</div>
    );
};
