import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
    filterConstants,
    datesConstants,
    DateRangeSelector
} from "mindsets-js-sdk";


import TeacherSignups from "./components/TeacherSignups";
import StudentSignups from "./components/StudentSignups";
import LessonsCreated from "./components/LessonsCreated";
import ChallengesUsed from "./components/ChallengesUsed";
import {LoadDashboardStatsAction} from "../../actions/dashboard_actions";

export default function () {
    const dispatch = useDispatch();
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 7);
    defaultFrom.setUTCHours(0, 0, 0, 0);
    const [from, setFrom] = useState(defaultFrom.getTime() / 1000);
    const defaultTill = new Date();
    defaultTill.setUTCHours(23, 59, 59, 999);
    const [till, setTill] = useState(defaultTill.getTime() / 1000);
    const dateFrom = new Date(from * 1000);
    dateFrom.setUTCHours(0, 0, 0, 0);
    const dateTill = new Date(till * 1000);
    dateTill.setUTCHours(23, 59, 59, 999);

    const days = getRangeOfDays(dateFrom, dateTill);

    function getRangeOfDays(startDate, endDate) {
        let range = [];
        const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
        for (let i = 0; i <= days; i++) {
            const current = new Date(startDate);
            current.setDate(current.getDate() + i);
            range.push(current);
        }

        return range;
    }

    useEffect(() => {
        let options = {};
        const dateFrom = new Date(from * 1000);
        dateFrom.setUTCHours(0, 0, 0, 0);
        options['filters[created_at][0]'] = filterConstants.EXPRESSION_GTE + '=' + (dateFrom.getTime() / 1000);
        dispatch(LoadDashboardStatsAction(options));
    }, []);
    useEffect(() => {
        let options = {};
        if (from) {
            const dateFrom = new Date(from * 1000);
            dateFrom.setUTCHours(0, 0, 0, 0);
            options['filters[created_at][0]'] = filterConstants.EXPRESSION_GTE + '=' + (dateFrom.getTime() / 1000);
        }
        if (till) {
            const dateTill = new Date(till * 1000);
            dateTill.setUTCHours(23, 59, 59, 999);
            options['filters[created_at][1]'] = filterConstants.EXPRESSION_LTE + '=' + (dateTill.getTime() / 1000);
        }
        dispatch(LoadDashboardStatsAction(options))
    }, [from, till]);

    return (
        <>
            <div className='container section'>
                <h1>Dashboard</h1>
                <div>
                    <DateRangeSelector
                        id={'dashboard_filter'}
                        onUpdate={data => {
                            setFrom(data.from);
                            setTill(data.till);
                        }}
                        as='buttons'
                        default={datesConstants.DATE_RANGE_LAST_7_DAYS}
                        from={from}
                        till={till}
                    />
                </div>
                <TeacherSignups days={days}/>
                <StudentSignups days={days}/>
                <LessonsCreated days={days}/>
                <ChallengesUsed/>
            </div>
        </>
    );
}
