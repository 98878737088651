import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {ChallengeDropdown, Checkbox} from "mindsets-js-sdk";
import PropTypes from "prop-types";

const Filters = function (
    {
        action,
        question_id,
        setQuestionId,
        practice_id,
        setPracticeId,
        setChallengeVersion,
        graded,
        setGraded,
        with_trashed,
        setWithTrashed,
        with_scores,
        setWithScores,
        lesson_code,
        setLessonCode
    }
) {
    const questions = useSelector(state => state.questions);
    const practices = useSelector(state => state.responses_practices);

    function renderPractice() {
        if (with_scores || graded) {
            return (
                <div className='d-flex flex-column mb-2'>
                    <label>Practice</label>
                    <select
                        value={practice_id}
                        onChange={event => setPracticeId(parseInt(event.target.value))}
                    >
                        <option value=''>Select a Practice</option>
                        {practices.map(practice => {
                            return (
                                <option key={practice.id} value={practice.id}>{practice.name}</option>
                            );
                        })}
                    </select>
                </div>
            );
        }

        return null;
    }

    return (
        <div className='section'>
            <div className='d-flex flex-column mb-2'>
                <label>Challenge</label>
                <ChallengeDropdown onSelect={challenge_version => {
                    setChallengeVersion(challenge_version ? challenge_version : {id: null});

                    return challenge_version ? challenge_version.name : '';
                }} unversioned={true} id='answer-review-challenge' debounce={useCallback(_.debounce(a => a(), 600), [])}/>
            </div>
            <div className='d-flex flex-column mb-2'>
                <label>Question</label>
                <select
                    onChange={event => setQuestionId(parseInt(event.target.value))}
                    value={question_id}
                    className='w-100'
                >
                    <option value=''>Please select</option>
                    {questions.map(question => {
                        return (
                            <option
                                key={question.id}
                                value={question.id}
                            >Q{question.number} {question.title}</option>
                        );
                    })}
                </select>
            </div>
            <div className='d-flex mb-2 flex-gap-5'>
                <div>
                    <Checkbox
                        checked={graded}
                        toggle={() => setGraded(!graded)}
                        disabled={!question_id}
                        label='Graded only'
                    />
                </div>
                <div>
                    <Checkbox
                        checked={with_trashed}
                        toggle={() => setWithTrashed(!with_trashed)}
                        disabled={!question_id}
                        label='Include old/edited responses'
                    />
                </div>
                <div>
                    <Checkbox
                        checked={with_scores}
                        toggle={() => setWithScores(!with_scores)}
                        disabled={!question_id}
                        label='With scores'
                    />
                </div>
            </div>
            {renderPractice()}
            <div className='d-flex flex-column mb-2'>
                <label>Class codes(comma separated)</label>
                <input value={lesson_code} onChange={event => setLessonCode(event.target.value)}/>
            </div>
            <button
                className='large'
                onClick={event => {
                    event.preventDefault();
                    action(1);
                }}
                disabled={!question_id}
            >GET
            </button>
        </div>
    );
}

Filters.propTypes = {
    action: PropTypes.func.isRequired,
    question_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setQuestionId: PropTypes.func.isRequired,
    setChallengeVersion: PropTypes.func.isRequired,
    lesson_code: PropTypes.string,
    setLessonCode: PropTypes.func.isRequired,
    practice_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setPracticeId: PropTypes.func.isRequired,
    graded: PropTypes.bool.isRequired,
    setGraded: PropTypes.func.isRequired,
    with_trashed: PropTypes.bool.isRequired,
    setWithTrashed: PropTypes.func.isRequired,
    with_scores: PropTypes.bool.isRequired,
    setWithScores: PropTypes.func.isRequired
}

export default Filters;
