import React, {Component} from 'react'
import PropTypes from 'prop-types'

class InfoTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: 0
        }
    }

    onSelect(selected) {
        return (e) => {
            e.preventDefault()
            this.setState({selected})
        }
    }

    getSelected() {
        if (this.props.component_data.data.items.length > 0) {
            return this.props.component_data.data.items[this.state.selected]
        }

        return null;
    }

    renderIcon() {
        return (
            <div className="infotabs-chat-icon">
                <i className="fas fa-comment"/>
            </div>
        )
    }

    renderTabs() {
        const tabs = this.props.component_data.data.items.map((tab, i) => {
            return (
                <div className="infotabs-tabs-item" key={i} onClick={this.onSelect(i)}>
                    <div className="text-center">
                        <img src={tab.image} alt={tab.name}/>
                    </div>
                    <div className="text-center">
                        <input
                            type='text'
                            className='clear'
                            placeholder='Put name here'
                            value={tab.name}
                            size={tab.name.length > 3 ? tab.name.length : 3}
                            onChange={event => {
                                let component_data = this.props.component_data;
                                component_data.data.items[i].name = event.target.value;
                                this.props.updateComponent(component_data);
                            }}/>
                    </div>
                    {
                        i === this.state.selected &&
                        this.renderIcon()
                    }
                </div>
            )
        })
        return (
            <div className="infotabs-tabs d-flex flex-wrap justify-content-start">
                {tabs}
            </div>
        );
    }

    renderContent() {
        const tab = this.getSelected();
        if (!!tab) {
            return (
                <div className="infotabs-content">
                    <h5 className="header-new">
                        {`${tab.name} says...`}
                    </h5>
                    <input
                        type='text'
                        className='clear'
                        placeholder='Put information here'
                        value={tab.information}
                        onChange={event => {
                            let component_data = this.props.component_data;
                            component_data.data.items[this.state.selected].information = event.target.value;
                            this.props.updateComponent(component_data);
                        }}/>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="challenge-component-infotabs max-width-850">
                {this.renderTabs()}

                {this.renderContent()}
            </div>
        )
    }
}

InfoTabs.propTypes = {
    component_data: PropTypes.object.isRequired
}

export default InfoTabs
