import React, {useState} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, ErrorMessageAction, ResetUserPasswordAction} from "mindsets-js-sdk";

const ResetPassword = function ({user}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const [password, setPassword] = useState('');

    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <div>
                <button
                    className='button red mt-2 no-wrap'
                    data-bs-toggle="modal"
                    data-bs-target={'#reset-user-password-modal-' + user.id}
                >Reset Password
                </button>
                <div className="modal fade" id={'reset-user-password-modal-' + user.id} tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">You are about to
                                    reset user {user.fname} {user.lname} password</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to reset the password?</p>
                                <p>This action cannot be undone</p>
                                <p>To confirm, please enter new password into the field below.</p>
                                <div className='input-field'>
                                    <input
                                        type='text'
                                        onChange={event => setPassword(event.target.value)}
                                        value={password}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="button default" data-bs-dismiss="modal">
                                    Dismiss
                                </button>
                                <button type="button" className="button" onClick={event => {
                                    event.preventDefault();
                                    if (password && password.length >= 6) {
                                        dispatch(ResetUserPasswordAction(user.id, password))
                                            .then(() => {
                                                setPassword('');
                                                bootstrap.Modal.getInstance(document.getElementById('reset-user-password-modal-' + user.id)).hide();
                                            });
                                    } else {
                                        dispatch(ErrorMessageAction('Password must have at least 6 characters'));
                                    }
                                }} disabled={password.length < 6}>Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

ResetPassword.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string
    }).isRequired
};

export default ResetPassword;
