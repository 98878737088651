import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {HTMLContent, contentUtils} from "mindsets-js-sdk";


class MatchingItem extends Component {
    getStyle = () => {
        const style = {
        }
        if (this.props.match_color) {
            style.borderColor = this.props.match_color
        }

        return style
    }

    renderImage() {
        const {image_url, image_alt} = this.props.item_data

        if (!image_url) {
            this.props.itemReady()
            return null
        }

        return (
            <div className="cc-matching-item-content-image">
                <img src={image_url} alt={image_alt} onLoad={this.props.itemReady} />
            </div>
        )
    }

    renderContent() {
        const {html_content_1, html_content_2} = this.props.item_data

        return (
            <div className="cc-matching-item-content">
                <HTMLContent html_str={contentUtils.processStringContent(html_content_1)}/>
                {this.renderImage()}
                <HTMLContent html_str={contentUtils.processStringContent(html_content_2)}/>
            </div>
        )
    }

    render() {
        const item_id = `matching-${this.props.item_group}-${this.props.item_data.id}`

        return (
            <div id={item_id} className={'cc-matching-item ' + (this.props.is_active ? 'cc-matching-item-active' : '') + ' ' + (this.props.is_matched ? 'cc-matching-item-matched' : '')} style={this.getStyle()} onClick={this.props.onClick}>
                {this.renderContent()}
            </div>
        )
    }
}

MatchingItem.propTypes = {
    item_data: PropTypes.object.isRequired,
    item_group: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    is_matched: PropTypes.bool.isRequired,
    match_color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    itemReady: PropTypes.func.isRequired
}

export default MatchingItem
