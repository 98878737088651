import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

export default function () {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_DISTRICTS])) {
        return (
            <div className="d-flex justify-content-end">
                <Link to="/districts/new" className="button">+ New District</Link>
            </div>
        );
    }

    return null;
}
