import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataItemsItemValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: false,
            attributes: {
                image: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                name: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                description: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                },
                information: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                }
            }
        };
    }
}
