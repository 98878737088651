import React from 'react';

import BaseComponentProperty from "../index";

export default class Poll extends BaseComponentProperty {
    hasConfigs() {
        return true;
    }

    hasQuestionNumber() {
        return this.props.component.type === 'poll.inline';
    }

    renderOwnProperties() {
        return (
            <div>
                <h4>Poll Options</h4>
                <div>
                    {this.props.component.data.poll_options.map((poll_option, index) => {
                        return (
                            <div key={index} className='position-relative bordered my-2'>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '5px', top: '5px'}}
                                    onClick={() => {
                                        let data = this.props.component.data;
                                        data.poll_options.splice(index, 1);
                                        this.props.onChange('data', data);
                                    }}
                                />
                                <div>
                                    <label htmlFor={"title" + index} className=" col-form-label">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={"title" + index}
                                        value={poll_option.title ? poll_option.title : ''}
                                        onChange={event => {
                                            let data = this.props.component.data;
                                            let poll_options = data.poll_options;
                                            poll_option.title = event.target.value;
                                            poll_options[index] = poll_option;
                                            data.poll_options = poll_options;
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor={"option" + index} className="col-form-label">Option</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={"option" + index}
                                        value={poll_option.option ? poll_option.option : ''}
                                        onChange={event => {
                                            let data = this.props.component.data;
                                            let poll_options = data.poll_options;
                                            poll_option.option = event.target.value;
                                            poll_options[index] = poll_option;
                                            data.poll_options = poll_options;
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let poll_options = data.poll_options;
                    poll_options.push({
                        title: "",
                        option: "",
                        description: ""
                    });
                    data.poll_options = poll_options;
                    this.props.onChange('data', data);
                }}>+
                </button>
            </div>
        );
    }
}
