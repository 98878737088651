import React from "react";
import PropTypes from "prop-types";
import {announcementTargetsConstants} from "mindsets-js-sdk";

const Target = function ({target}) {
    if (target) {
        return (
            <div>
                {Object.keys(target).map(target_group => {
                    return (
                        <div key={target_group}>
                            <div>{target_group}:</div>
                            <div>{target[target_group].map(target => {
                                return (
                                    <div
                                        key={target}
                                        className='no-wrap'>&nbsp;&nbsp;&nbsp;&nbsp;{announcementTargetsConstants.TARGETS[target_group][target]}</div>
                                );
                            })}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
}

Target.propTypes = {
    target: PropTypes.object
};

export default Target;
