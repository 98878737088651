import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    ErrorMessageAction,
    SuccessMessageAction,
    authUtils,
    accessConstants,
    countriesConstants,
    datesConstants,
    DatePicker,
    Checkbox,
    LoadingAction,
    UserDropdown,
    LoadContractAction,
    SetContractAction,
    UpdateContractAction,
    StateField
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import DistrictSelector from "../ContractCreate/components/DistrictSelector";
import Schools from "../ContractCreate/components/Schools";
import Challenges from "./components/Challenges";

const district_selector_id = 'district-selector-' + Date.now() + '-' + Math.floor(Math.random() * 100);

export default function () {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(state => state.me);
    const contract_state = useSelector(state => state.contract);
    let contract = JSON.parse(JSON.stringify(contract_state));
    const [contract_duration_measure, setContractDurationMeasure] = useState('');
    const [contract_duration_value, setContractDurationValue] = useState('');
    const [session_duration_measure, setSessionDurationMeasure] = useState('');
    const [session_duration_value, setSessionDurationValue] = useState('');
    const [starts_at, setStartsAt] = useState(null);
    const [session_starts_at, setSessionStartsAt] = useState(null);

    const validate = function () {
        let errors = 0;

        if (!contract.name || contract.name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name.'));
            errors++;
        }

        if (!contract.country || contract.country === '') {
            dispatch(ErrorMessageAction('Error! Please choose a country.'));
            errors++;
        }

        if (!contract.district_id && !(contract.schools && contract.schools.length)) {
            dispatch(ErrorMessageAction('Error! Please choose a district and/or at least one school'));
            errors++;
        }

        return errors === 0;
    }
    const getContractEndDate = function (string = false) {
        if (contract.starts_at && contract_duration_measure && contract_duration_value) {
            const endsAt = new Date(contract.starts_at * 1000);
            if (contract_duration_measure === 'days') {
                endsAt.setDate(endsAt.getDate() + contract_duration_value);
                endsAt.setDate(endsAt.getDate() - 1);
            } else if (contract_duration_measure === 'weeks') {
                endsAt.setDate(endsAt.getDate() + (contract_duration_value * 7));
                endsAt.setDate(endsAt.getDate() - 1);
            } else if (contract_duration_measure === 'months') {
                endsAt.setMonth(endsAt.getMonth() + contract_duration_value);
                endsAt.setDate(endsAt.getDate() - 1);
            }
            endsAt.setUTCHours(23, 59, 59, 999);
            if (string) {
                return endsAt.toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT);
            } else {
                return Math.floor(endsAt.getTime() / 1000);
            }
        }

        return '';
    }
    const getSessionEndDate = function (string = false) {
        if (contract.session_starts_at && session_duration_measure && session_duration_value) {
            const endsAt = new Date(contract.session_starts_at * 1000);
            if (session_duration_measure === 'days') {
                endsAt.setDate(endsAt.getDate() + session_duration_value);
                endsAt.setDate(endsAt.getDate() - 1);
            } else if (session_duration_measure === 'weeks') {
                endsAt.setDate(endsAt.getDate() + (session_duration_value * 7));
                endsAt.setDate(endsAt.getDate() - 1);
            } else if (session_duration_measure === 'months') {
                endsAt.setMonth(endsAt.getMonth() + session_duration_value);
                endsAt.setDate(endsAt.getDate() - 1);
            }
            endsAt.setUTCHours(23, 59, 59, 999);
            if (string) {
                return endsAt.toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT);
            } else {
                return Math.floor(endsAt.getTime() / 1000);
            }
        }

        return '';
    }

    useEffect(() => {
        dispatch(LoadContractAction(params.contract_id, {
            'selectors[0]': 'district',
            'selectors[1]': 'assignee',
            'selectors[2]': 'schools',
            'selectors[3]': 'challenge_codes'
        }))
            .then(contract => {
                if (contract.starts_at && contract.ends_at && !contract_duration_measure) {
                    const startsAt = new Date(contract.starts_at * 1000);
                    const endsAt = new Date(contract.ends_at * 1000);
                    endsAt.setTime(endsAt.getTime() + 1000);
                    let duration_measure = '';
                    let duration_value = '';

                    switch (true) {
                        case (endsAt.getMonth() - startsAt.getMonth()) % 1 === 0:
                            duration_measure = 'months';
                            duration_value = endsAt.getMonth() - startsAt.getMonth();
                            break;
                        case ((endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7)) % 1 === 0:
                            duration_measure = 'weeks';
                            duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7);
                            break;
                        case ((endsAt - startsAt) / (1000 * 60 * 60 * 24)) % 1 === 0:
                            duration_measure = 'days';
                            duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24);
                            break;
                    }
                    setContractDurationMeasure(duration_measure);
                    setContractDurationValue(duration_value);
                }
                if (contract.session_starts_at && contract.session_ends_at && !session_duration_measure) {
                    const startsAt = new Date(contract.session_starts_at * 1000);
                    const endsAt = new Date(contract.session_ends_at * 1000);
                    let duration_measure = '';
                    let duration_value = '';
                    switch (true) {
                        case (endsAt.getMonth() - startsAt.getMonth()) % 1 === 0:
                            duration_measure = 'months';
                            duration_value = endsAt.getMonth() - startsAt.getMonth();
                            break;
                        case ((endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7)) % 1 === 0:
                            duration_measure = 'weeks';
                            duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24 * 7);
                            break;
                        case ((endsAt - startsAt) / (1000 * 60 * 60 * 24)) % 1 === 0:
                            duration_measure = 'days';
                            duration_value = (endsAt - startsAt) / (1000 * 60 * 60 * 24);
                            break;
                    }
                    setSessionDurationMeasure(duration_measure);
                    setSessionDurationValue(duration_value);
                }
            })
            .catch(error => {
                dispatch(LoadingAction(false));
                dispatch(ErrorMessageAction(error));
                console.error(error);
            });

        return () => {
            dispatch(SetContractAction({}));
            setContractDurationMeasure('');
            setContractDurationValue('');
            setSessionDurationMeasure('');
            setSessionDurationValue('');
        };
    }, []);
    useEffect(() => {
        let contract = JSON.parse(JSON.stringify(contract_state));
        if (contract_duration_measure && contract_duration_value) {
            contract.ends_at = getContractEndDate();
        } else {
            delete contract.ends_at;
        }
        dispatch(SetContractAction(contract));
    }, [contract.starts_at, contract_duration_measure, contract_duration_value]);
    useEffect(() => {
        let contract = JSON.parse(JSON.stringify(contract_state));
        if (session_duration_measure && session_duration_value) {
            contract.session_ends_at = getSessionEndDate();
        } else {
            delete contract.session_ends_at;
        }
        dispatch(SetContractAction(contract));
    }, [contract.session_starts_at, session_duration_measure, session_duration_value]);
    useEffect(() => {
        if (starts_at) {
            contract.starts_at = starts_at;
            dispatch(SetContractAction(contract));
        }
    }, [starts_at]);
    useEffect(() => {
        if (session_starts_at) {
            contract.session_starts_at = session_starts_at;
            dispatch(SetContractAction(contract));
        }
    }, [session_starts_at]);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_CONTRACTS])) {
        return (
            <NoAccess/>
        );
    }
    if (!Object.keys(contract).length) {
        return null;
    }
    if (contract.status === 'approved') {
        return <NoAccess/>;
    }

    return (
        <div className='container' id='update-contract-page'>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    if (validate()) {
                        dispatch(UpdateContractAction(params.contract_id, contract))
                            .then(() => {
                                dispatch(SuccessMessageAction('The Contract has been updated.'))
                                navigate(-1);
                            })
                            .catch(error => {
                                dispatch(LoadingAction(false));
                                dispatch(ErrorMessageAction(error));
                                console.error(error);
                            });
                    }
                }}
                autoComplete="off"
                id="update-contract-form"
            >
                <h1>Update Contract</h1>

                <div className='section'>
                    <div>
                        <label htmlFor="name">Name*</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={contract.name ? contract.name : ''}
                            onChange={event => {
                                contract.name = event.target.value;
                                dispatch(SetContractAction(contract));
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <label htmlFor="country" className="form-label">Country*</label>
                            <select
                                className="form-control"
                                id="country"
                                value={contract.country ? contract.country : ''}
                                onChange={event => {
                                    contract.country = event.target.value;
                                    delete contract.district_id;
                                    delete contract.state;
                                    dispatch(SetContractAction(contract));
                                    const reset_event = new CustomEvent("mindsets-autocomplete-reset-" + district_selector_id);
                                    window.dispatchEvent(reset_event);
                                }}
                            >
                                <option value='' disabled={true}>SELECT COUNTRY</option>
                                {Object.keys(countriesConstants.COUNTRIES).map(country_code => {
                                    return (
                                        <option
                                            key={country_code}
                                            value={country_code}
                                        >{countriesConstants.COUNTRIES[country_code]}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-sm-3">
                            <StateField
                                country={contract.country}
                                value={contract.state}
                                onChange={value => {
                                    contract.state = value;
                                    if (contract.district_id) {
                                        delete contract.district_id;
                                    }
                                    dispatch(SetContractAction(contract));
                                    const reset_event = new CustomEvent("mindsets-autocomplete-reset-" + district_selector_id);
                                    window.dispatchEvent(reset_event);
                                }}
                            />
                        </div>
                        <div className="col-sm-6">
                            <DistrictSelector district_selector_id={district_selector_id}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Contract start day</label>
                            <DatePicker
                                date={contract.starts_at}
                                onChange={setStartsAt}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Duration</label>
                            <select
                                value={contract_duration_measure}
                                onChange={event => setContractDurationMeasure(event.target.value)}
                            >
                                <option value='' disabled={true}>Please select</option>
                                <option value='days'>Days</option>
                                <option value='weeks'>Weeks</option>
                                <option value='months'>Months</option>
                            </select>
                            <input type='number' min={0} value={contract_duration_value} onChange={event => {
                                let value = event.target.value;
                                if (value === '') {
                                    setContractDurationValue('');
                                } else if (!isNaN(parseInt(value))) {
                                    setContractDurationValue(parseInt(value));
                                }
                            }}/>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Contract end day</label>
                            <input type='text' defaultValue={getContractEndDate(true)} disabled={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Session start day</label>
                            <DatePicker
                                date={contract.session_starts_at}
                                onChange={setSessionStartsAt}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Duration</label>
                            <select
                                value={session_duration_measure}
                                onChange={event => setSessionDurationMeasure(event.target.value)}
                            >
                                <option value='' disabled={true}>Please select</option>
                                <option value='days'>Days</option>
                                <option value='weeks'>Weeks</option>
                                <option value='months'>Months</option>
                            </select>
                            <input type='number' min={0} value={session_duration_value} onChange={event => {
                                let value = event.target.value;
                                if (value === '') {
                                    setSessionDurationValue('');
                                } else if (!isNaN(parseInt(value))) {
                                    setSessionDurationValue(parseInt(value));
                                }
                            }}/>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="datepicker-field" className='d-block'>Session end day</label>
                            <input type='text' defaultValue={getSessionEndDate(true)} disabled={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2">
                            <Checkbox
                                checked={undefined === contract.is_pilot ? false : contract.is_pilot}
                                label='Is Pilot'
                                toggle={() => {
                                    contract.is_pilot = !contract.is_pilot;
                                    dispatch(SetContractAction(contract));
                                }}
                            />
                        </div>
                    </div>
                </div>

                <hr/>

                <div className='section'>
                    <h4>Pricing</h4>
                    <div className="row">
                        <div className="col-sm-2">
                            <label htmlFor="number_teachers">Number teachers</label>
                            <input
                                id='number_teachers'
                                className='form-control'
                                type='number'
                                min={0}
                                value={contract.number_teachers ? contract.number_teachers : ''}
                                onChange={event => {
                                    let value = event.target.value;
                                    if (value === '') {
                                        delete contract.number_teachers;
                                    } else if (!isNaN(parseInt(value))) {
                                        contract.number_teachers = parseInt(value);
                                    }
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                        <div className="col-sm-2">
                            <label htmlFor="number_students">Number students</label>
                            <input
                                id='number_students'
                                className='form-control'
                                type='number'
                                min={0}
                                value={contract.number_students ? contract.number_students : ''}
                                onChange={event => {
                                    let value = event.target.value;
                                    if (value === '') {
                                        delete contract.number_students;
                                    } else if (!isNaN(parseInt(value))) {
                                        contract.number_students = parseInt(value);
                                    }
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="price">Total Amount</label>
                            <input
                                id='price'
                                className='form-control'
                                type='number'
                                step='any'
                                value={contract.price ? contract.price : ''}
                                onChange={event => {
                                    let value = event.target.value;
                                    if (value === '') {
                                        delete contract.price;
                                    } else if (!isNaN(parseFloat(value))) {
                                        contract.price = Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
                                    }
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="discount_notes">Discount Notes</label>
                            <textarea
                                className="form-control"
                                id="discount_notes"
                                value={contract.discount_notes ? contract.discount_notes : ''}
                                onChange={event => {
                                    contract.discount_notes = event.target.value;
                                    dispatch(SetContractAction(contract));
                                }}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label htmlFor="discount">Discount</label>
                            <input
                                id='discount'
                                className='form-control'
                                type='number'
                                value={contract.discount ? contract.discount : ''}
                                onChange={event => {
                                    let value = event.target.value;
                                    if (value === '') {
                                        delete contract.discount;
                                    } else if (!isNaN(parseInt(value))) {
                                        contract.discount = parseInt(value);
                                    }
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className='section'>
                    <h4>Schools in the scope of the contract(Optional when district is selected)</h4>
                    <Schools/>
                </div>

                <hr/>

                <div className='section'>
                    <h4>Notes</h4>
                    <div>
                        <label htmlFor="notes">General Notes</label>
                        <textarea
                            className="form-control"
                            id="notes"
                            placeholder="Notes"
                            value={contract.notes ? contract.notes : ''}
                            onChange={event => {
                                contract.notes = event.target.value;
                                dispatch(SetContractAction(contract));
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="pd_notes">PD Notes</label>
                        <textarea
                            className="form-control"
                            id="pd_notes"
                            placeholder="Professional Development Notes"
                            value={contract.pd_notes ? contract.pd_notes : ''}
                            onChange={event => {
                                contract.pd_notes = event.target.value;
                                dispatch(SetContractAction(contract));
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <label htmlFor="impact_study_notes">Impact Study Notes</label>
                            <textarea
                                className="form-control"
                                id="impact_study_notes"
                                value={contract.impact_study_notes ? contract.impact_study_notes : ''}
                                onChange={event => {
                                    contract.impact_study_notes = event.target.value;
                                    dispatch(SetContractAction(contract));
                                }}
                            />
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="number_data_points">Number of data points</label>
                            <input
                                id='number_data_points'
                                className='form-control'
                                type='number'
                                min={0}
                                value={contract.number_data_points ? contract.number_data_points : ''}
                                onChange={event => {
                                    let value = event.target.value;
                                    if (value === '') {
                                        delete contract.number_data_points;
                                    } else if (!isNaN(parseInt(value))) {
                                        contract.number_data_points = parseInt(value);
                                    }
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="contacts">Contacts</label>
                        <textarea
                            className="form-control"
                            id="contacts"
                            value={contract.contacts ? contract.contacts : ''}
                            onChange={event => {
                                contract.contacts = event.target.value;
                                dispatch(SetContractAction(contract));
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <label htmlFor="quote_ref">Quote reference</label>
                            <input
                                id='quote_ref'
                                className='form-control'
                                type='text'
                                value={contract.quote_ref ? contract.quote_ref : ''}
                                onChange={event => {
                                    contract.quote_ref = event.target.value;
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor="invoice_ref">Invoice reference</label>
                            <input
                                id='invoice_ref'
                                className='form-control'
                                type='text'
                                value={contract.invoice_ref ? contract.invoice_ref : ''}
                                onChange={event => {
                                    contract.invoice_ref = event.target.value;
                                    dispatch(SetContractAction(contract));
                                }}/>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className='section'>
                    <h4>Locked Challenges in the scope of the contract(Optional)</h4>
                    <Challenges/>
                </div>

                <hr/>

                <div className='section'>
                    <UserDropdown
                        onSelect={user => {
                            if (user) {
                                contract.assignee_id = user.id;
                                contract.assignee = user;
                            } else {
                                contract.assignee_id = null;
                                delete contract.assignee;
                            }
                            dispatch(SetContractAction(contract));

                            return user ? user.fname + ' ' + user.lname : '';
                        }}
                        default_value={contract.assignee ? (contract.assignee.fname + ' ' + contract.assignee.lname) : (me.fname + ' ' + me.lname)}
                        label='Assignee/Responsible person/Manager'
                        show_counts={true}
                        debounce={debounce}
                    />
                </div>

                <hr/>

                <div className='section'>
                    <div className='d-flex flex-gap-5'>
                        <button type="submit" className="button">Submit</button>
                        <button className="button default" onClick={event => {
                            event.preventDefault();
                            navigate(-1);
                        }}>Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
