import React from "react";
import PropTypes from "prop-types";

const CreateButton = function ({shown, setShown}) {
    if (shown === false) {
        return (
            <button className="button" onClick={() => setShown(true)}>Create</button>
        );
    }

    return null;
}

CreateButton.p = {
    shown: PropTypes.bool.isRequired,
    setShown: PropTypes.func.isRequired
}

export default CreateButton;
