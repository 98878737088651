import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const School = function ({user}) {
    const me = useSelector(state => state.me);

    if (user.school && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_SEE_SCHOOLS])) {
        return (
            <Link
                to={'/schools/' + user.school.id}
                data-toggle="tooltip"
                data-placement="top"
                title="Edit school"
            >{user.school.name}</Link>
        );
    } else if (user.school) {
        return user.school.name;
    }

    return null;
}

School.propTypes = {
    user: PropTypes.shape({
        school: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            subscriptions: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                starts_at: PropTypes.number.isRequired,
                ends_at: PropTypes.number.isRequired
            })),
            district: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                subscription: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    starts_at: PropTypes.number.isRequired,
                    ends_at: PropTypes.number.isRequired
                }))
            })
        })
    }).isRequired
};

export default School;
