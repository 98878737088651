import React from 'react';

import BaseComponentProperty from "../index";

export default class InfoTabs extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let items = data.items;
                    items.push({
                        image: "",
                        name: "",
                        description: "",
                        information: ""
                    });
                    data.items = items;
                    this.props.onChange('data', data);
                }}>+
                </button>
                <div className='d-flex flex-row flex-wrap'>
                    {this.props.component.data.items.map((item, index) => {
                        return (
                            <div key={index} style={{position: 'relative'}}>
                                <i
                                    className='fas fa-trash text-danger clickable'
                                    style={{position: 'absolute', right: '-15px', top: '-10px'}}
                                    onClick={() => {
                                        let data = this.props.component.data;
                                        data.items.splice(index, 1);
                                        this.props.onChange('data', data);
                                    }}
                                />
                                <div className="row">
                                    <label htmlFor="image" className="col-sm-1 col-form-label">Image</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="image"
                                            value={item.image ? item.image : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.image = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="description" className="col-sm-1 col-form-label">Description</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            value={item.description ? item.description : ''}
                                            onChange={event => {
                                                let data = this.props.component.data;
                                                let items = data.items;
                                                item.description = event.target.value;
                                                items[index] = item;
                                                data.items = items;
                                                this.props.onChange('data', data);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
