import React, {Component} from 'react';
import PropTypes from 'prop-types';

// import {graphingContentConfig} from 'utils/ggb';


class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'challenge-component-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100)
        this.state = {
            open: false
        }
    }

    componentDidMount() {
        // this.ggbInit()
    }

    // ggbInit() {
    //     const {config={}} = this.props.component_data
    //     const ggb_config = {
    //         ...graphingContentConfig(this.uniq_id),
    //         appletOnLoad: (graphing_instance) => {
    //             this.graphing_instance = graphing_instance
    //         },
    //         ...config.ggb
    //     }
    //
    //     this.ggbApp = new GGBApplet(ggb_config, true)
    //     this.ggbApp.inject(this.uniq_id)
    // }

    renderOptionsContainer() {
        const onClick = (e) => {
            e.preventDefault()
            this.setState({open: !this.state.open})
        }

        const extra_class = this.state.open ? ' selected ' : ''

        return (
            <div className="component-graphing-options-container">
                <div className="d-flex">
                    <a className={"link-new " + extra_class} href="#" onClick={onClick}>
                        {`Click to open the online graph`}
                    </a>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-graphing" id={`component-${this.uniq_id}`}>
                {this.renderOptionsContainer()}

                <div className="component-graphing-content-container">
                    <div
                        className="component-graphing-content-graph" style={{height: this.state.open ? 'auto' : '0'}}>
                        <div id={this.uniq_id}/>
                    </div>
                </div>
            </div>
        )
    }
}

Graphing.propTypes = {
    component_data: PropTypes.object
}

export default Graphing
