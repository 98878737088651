import React from 'react';
import {NumericValueField} from "mindsets-js-sdk";

import BaseComponentProperty from "../index";

export default class NumberLine extends BaseComponentProperty {
    constructor(props) {
        super(props);
        this.state = {
            initial_pos: props.component && props.component.data && props.component.data.initial_pos ? props.component.data.initial_pos : '',
            increment_interval: props.component && props.component.data && props.component.data.increment_interval ? props.component.data.increment_interval : ''
        }
    }

    hasQuestionNumber() {
        return true;
    }

    renderOwnProperties() {
        return (
            <div>
                <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                    <div>
                        <label htmlFor="first_num">First number</label>
                        <input
                            type='text'
                            id="first_num"
                            className='form-control'
                            value={this.props.component.data.first_num ? this.props.component.data.first_num.toString() : ''}
                            onChange={event => {
                                const value = event.target.value;
                                let data = this.props.component.data;
                                data.first_num = !isNaN(parseInt(value)) ? parseInt(value) : '';
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="last_num">Last number</label>
                        <input
                            type='text'
                            id="last_num"
                            className='form-control'
                            value={this.props.component.data.last_num ? this.props.component.data.last_num.toString() : ''}
                            onChange={event => {
                                const value = event.target.value;
                                let data = this.props.component.data;
                                data.last_num = !isNaN(parseInt(value)) ? parseInt(value) : '';
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="initial_pos">Initial position</label>
                        <input
                            type='text'
                            id="initial_pos"
                            className='form-control'
                            value={this.state.initial_pos}
                            onChange={event => {
                                const value = event.target.value;
                                this.setState({initial_pos: value}, () => {
                                    if (!isNaN(parseFloat(value))) {
                                        let data = this.props.component.data;
                                        data.initial_pos = parseFloat(value);
                                        this.props.onChange('data', data);
                                    }
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="increment_interval">Increment interval</label>
                        <input
                            type='text'
                            id="increment_interval"
                            className='form-control'
                            value={this.state.increment_interval}
                            onChange={event => {
                                const value = event.target.value;
                                this.setState({increment_interval: value}, () => {
                                    if (!isNaN(parseFloat(value))) {
                                        let data = this.props.component.data;
                                        data.increment_interval = parseFloat(value);
                                        this.props.onChange('data', data);
                                    }
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="snap_divisions_per_interval">Snap divisions per interval</label>
                        <input
                            type='text'
                            id="snap_divisions_per_interval"
                            className='form-control'
                            value={this.props.component.data.snap_divisions_per_interval ? this.props.component.data.snap_divisions_per_interval.toString() : ''}
                            onChange={event => {
                                const value = event.target.value;
                                let data = this.props.component.data;
                                data.snap_divisions_per_interval = !isNaN(parseInt(value)) ? parseInt(value) : '';
                                this.props.onChange('data', data);
                            }}
                        />
                    </div>
                </div>
                <div className='mt-2'>
                    <div className='d-flex align-items-center mb-2 flex-gap-5'>
                        <span>Number Points: </span>
                        <button className='button' onClick={() => {
                            let data = this.props.component.data;
                            let items = data.numberPoints;
                            items.push({
                                formula: "",
                                image: "",
                                title: "",
                                numberLinePoint: 1
                            });
                            data.numberPoints = items;
                            this.props.onChange('data', data);
                        }}>+
                        </button>
                    </div>
                    <div className='d-flex flex-row flex-wrap flex-box-0-10'>
                        {this.props.component.data.numberPoints.map((point, index) => {
                            return (
                                <div key={index} style={{position: 'relative'}}>
                                    <i
                                        className='fas fa-trash text-danger clickable'
                                        style={{position: 'absolute', right: '-15px', top: '-10px'}}
                                        onClick={() => {
                                            let data = this.props.component.data;
                                            data.numberPoints.splice(index, 1);
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                    <div className="row">
                                        <label htmlFor="formula" className="col-sm-2 col-form-label">Formula</label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="formula"
                                                value={point.formula ? point.formula : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let numberPoints = data.numberPoints;
                                                    point.formula = event.target.value;
                                                    numberPoints[index] = point;
                                                    data.numberPoints = numberPoints;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label htmlFor="image" className="col-sm-2 col-form-label">Image URL</label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="image"
                                                value={point.image ? point.image : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let numberPoints = data.numberPoints;
                                                    point.image = event.target.value;
                                                    numberPoints[index] = point;
                                                    data.numberPoints = numberPoints;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label htmlFor="title" className="col-sm-2 col-form-label">Title</label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                value={point.title ? point.title : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let numberPoints = data.numberPoints;
                                                    point.title = event.target.value;
                                                    numberPoints[index] = point;
                                                    data.numberPoints = numberPoints;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label htmlFor="numberLinePoint" className="col-sm-2 col-form-label">Number Line
                                            Point</label>
                                        <div className="col-sm-10">
                                            <NumericValueField
                                                callback={value => {
                                                    let data = this.props.component.data;
                                                    let numberPoints = data.numberPoints;
                                                    point.numberLinePoint = value;
                                                    numberPoints[index] = point;
                                                    data.numberPoints = numberPoints;
                                                    this.props.onChange('data', data);
                                                }}
                                                initial={point.numberLinePoint}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
