import {
    SET_LIMIT_POSTFIX,
    SET_LIMIT_PREFIX,
    SET_CURRENT_POSTFIX,
    SET_CURRENT_PREFIX
} from "mindsets-js-sdk";
import {LOADED_CHALLENGE_CODE} from "mindsets-js-sdk/src/react/actions/challenge_actions";

import {
    LOADED_CHALLENGES,
    LOADED_CHALLENGES_TOTAL,
    LOADED_CHALLENGES_PAGES,
    LOADED_CHALLENGE_VERSIONS_PRACTICES,
    PARSING,
    LOADED_CHALLENGES_UPDATES,
    SET_CHALLENGE_BUILDER_INDEX,
    SET_CHALLENGE_BUILDER_SELECTED_ROW_INDEX,
    SET_CHALLENGE_BUILDER_SELECTED_COMPONENT_INDEX,
    SET_CHALLENGE_BUILDER_IMPORT_RAW_STRING,
    SET_CHALLENGE_BUILDER_CHALLENGE_CODE,
    SET_CHALLENGE_BUILDER_CHALLENGE_NAME,
    SET_CHALLENGE_BUILDER_CHALLENGE_COLOR,
    SET_CHALLENGE_BUILDER_CHALLENGE_PAGES,
    SET_CHALLENGE_BUILDER_MOUSE_OVER_ROW,
    SET_CHALLENGE_BUILDER_MOUSE_OVER_COMPONENT,
    SET_CHALLENGE_BUILDER_MODE,
    SET_CHALLENGE_BUILDER_BUILD_MODE
} from "../actions/challenge_actions";

const searchParams = new URLSearchParams(location.search);

export const challenge_script = (state = {}, action) => {
    switch (action.type) {
        case PARSING:
            return action.payload;
        default:
            return state;
    }
};
const challenges = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CHALLENGES:
            return action.payload;
        default:
            return payload;
    }
};
const challenges_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case SET_CURRENT_PREFIX + 'challenges' + SET_CURRENT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const challenges_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_CHALLENGES_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const challenges_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_CHALLENGES_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const challenges_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case SET_LIMIT_PREFIX + 'challenges' + SET_LIMIT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_code = (payload = null, action) => {
    switch (action.type) {
        case LOADED_CHALLENGE_CODE:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_versions_practices = (payload = [], action) => {
    switch (action.type) {
        case LOADED_CHALLENGE_VERSIONS_PRACTICES:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_updates = (payload = null, action) => {
    switch (action.type) {
        case LOADED_CHALLENGES_UPDATES:
            return action.payload;
        default:
            return payload;
    }
};

const challenge_builder_mode = (payload = 'default', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_MODE:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_build_mode = (payload = 'skeleton', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_BUILD_MODE:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_index = (payload = 0, action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_INDEX:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_selected_row_index = (payload = null, action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_SELECTED_ROW_INDEX:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_selected_component_index = (payload = null, action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_SELECTED_COMPONENT_INDEX:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_import_string = (payload = '', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_IMPORT_RAW_STRING:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_challenge_code = (payload = '', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_CHALLENGE_CODE:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_challenge_name = (payload = '', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_CHALLENGE_NAME:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_challenge_color = (payload = '#6A2E90', action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_CHALLENGE_COLOR:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_challenge_pages = (payload = [], action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_CHALLENGE_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_mouse_over_row = (payload = null, action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_MOUSE_OVER_ROW:
            return action.payload;
        default:
            return payload;
    }
};
const challenge_builder_mouse_over_component = (payload = null, action) => {
    switch (action.type) {
        case SET_CHALLENGE_BUILDER_MOUSE_OVER_COMPONENT:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    challenge_script,
    challenges,
    challenges_current,
    challenges_total,
    challenges_pages,
    challenges_limit,
    challenge_code,
    challenge_versions_practices,
    challenge_updates,
    challenge_builder_mode,
    challenge_builder_build_mode,
    challenge_builder_index,
    challenge_builder_selected_row_index,
    challenge_builder_selected_component_index,
    challenge_builder_import_string,
    challenge_builder_challenge_code,
    challenge_builder_challenge_name,
    challenge_builder_challenge_color,
    challenge_builder_challenge_pages,
    challenge_builder_mouse_over_row,
    challenge_builder_mouse_over_component
}
