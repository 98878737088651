import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {countriesConstants, filterConstants, datesConstants} from "mindsets-js-sdk";

import EditButton from "./EditButton";

const Row = function ({item, row_number}) {
    return (
        <tr key={item.id} className='curriculums-table-row'>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{item.code}</td>
            <td>{item.name}</td>
            <td>{countriesConstants.COUNTRIES.hasOwnProperty(item.country_code) ? countriesConstants.COUNTRIES[item.country_code] : 'N/A'}</td>
            <td>{item.state_code ? item.state_code : 'N/A'}</td>
            <td>{item.grade_term}</td>
            <td>{item.is_enabled ? <i className="fas fa-check"/> : null}</td>
            <td>{item.is_code_shown ? <i className="fas fa-check"/> : null}</td>
            <td>{item.is_default ? <i className="fas fa-check"/> : null}</td>
            <td>
                <a href={`${process.env.PUBLIC_APP_URL}/curriculum/${item.code}/map.pdf`}
                   target='_blank'>Open</a>
            </td>
            <td>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td>{new Date(item.updated_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <EditButton id={item.id}/>
                    <Link
                        to={`/standards?column~0=curriculum_id&expression~0=${filterConstants.EXPRESSION_EQUAL}&value~0=${item.id}`}
                        className='button'
                        title="Show all the Standards in the Curriculum"
                    >Standards</Link>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
        state_code: PropTypes.string,
        grade_term: PropTypes.string,
        is_enabled: PropTypes.bool.isRequired,
        is_code_shown: PropTypes.bool.isRequired,
        is_default: PropTypes.bool.isRequired,
        created_at: PropTypes.number.isRequired,
        updated_at: PropTypes.number.isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
