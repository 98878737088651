import React, {forwardRef} from 'react';
import {contentUtils} from "mindsets-js-sdk";
import {useDispatch, useSelector} from "react-redux";

import {
    SetChallengeBuilderChallengePagesAction,
    SetChallengeBuilderIndexAction,
    SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../actions/challenge_actions";

export default forwardRef(function ({index, ...props}, ref) {
    const dispatch = useDispatch();
    const page_index = useSelector(state => state.challenge_builder_index);
    const pages = useSelector(state => state.challenge_builder_challenge_pages);
    const page = pages[index];

    return (
        <div className='navigation-card' ref={ref} {...props}>
            <div
                className={'card clickable position-relative ' + (page_index === index ? 'navigation-card-selected' : '')}
                onClick={() => {
                    dispatch(SetChallengeBuilderIndexAction(index));
                    dispatch(SetChallengeBuilderSelectedRowIndexAction(null));
                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                }}
            >
                <i
                    className='fas fa-trash text-danger clickable position-absolute'
                    style={{right: '2px', top: '2px'}}
                    onClick={event => {
                        event.stopPropagation();
                        if (confirm(`Are you sure you want to delete the page #${index + 1}? Data will be lost permanently.`)) {
                            let pages_new = JSON.parse(JSON.stringify(pages));
                            pages_new.splice(index, 1);
                            dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                        }
                    }}
                />
                <div className='card-body'>
                    <div>Page #{index + 1}</div>
                    <h5>{contentUtils.capitalizeFirstLetter(page.type ? page.type : 'N/A')}</h5>
                </div>
                <i
                    className='fas fa-clone text-info clickable position-absolute'
                    style={{right: '2px', bottom: '2px'}}
                    onClick={event => {
                        event.stopPropagation();
                        let pages_new = JSON.parse(JSON.stringify(pages));
                        pages_new.splice(index, 0, _.cloneDeep(pages_new[index]));
                        dispatch(SetChallengeBuilderChallengePagesAction(pages_new));
                        dispatch(SetChallengeBuilderIndexAction(index + 1));
                    }}
                />
            </div>
        </div>
    );
});
