import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => state.streams);
    const current = useSelector(state => state.streams_current);
    const limit = useSelector(state => state.streams_limit);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">ID</th>
                    <th scope="col">Code</th>
                    <th scope="col">Email</th>
                    <th scope="col">Joined as</th>
                    <th scope="col">Status</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Joined At</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return (
                        <Row
                            key={item.id}
                            item={item}
                            row_number={getRowNumber(index, current, limit)}
                        />
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}
