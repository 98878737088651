import React from 'react';

import BaseAnswer from "../BaseAnswer";
import PollOption from "./PollOption";

export default class InlinePoll extends BaseAnswer {
    render() {
        return (
            <div className="challenge-component-poll">
                <div className='poll-new'>
                    <div className="section">
                        <div className="poll-options">
                            <div className="poll-inline-options">
                                <div className="d-flex justify-content-start">
                                    <div className="d-flex flex-column flex-box-5-10">
                                        {this.props.component_data.data.poll_options.map((poll_option, index) => (
                                            <PollOption
                                                key={index}
                                                poll_option={poll_option}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
