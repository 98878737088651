import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    ChallengeDropdown,
    filterConstants,
    accessConstants,
    ListPage,
    LoadPracticesAction,
    LoadQuestionPracticeSkillGroupsAction
} from 'mindsets-js-sdk';

import Table from './components/Table';

export default function () {
    const dispatch = useDispatch();
    const practices = useSelector(state => state.practices);

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        dispatch(LoadPracticesAction());
    }, []);

    if (!practices.length) {
        return null;
    }

    return (
        <>
            <h1>Existing rubrics</h1>
            <ListPage
                action={LoadQuestionPracticeSkillGroupsAction}
                filter_columns={[
                    {
                        key: 'practice_id',
                        name: 'Practice',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: practices.map(practice => {
                            return {
                                label: practice.name,
                                value: practice.id
                            }
                        })
                    },
                    {
                        key: 'challenge_code',
                        name: 'Challenge',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <ChallengeDropdown
                                    onSelect={challenge_version => {
                                        if (challenge_version) {
                                            setFilterValue(challenge_version.challenge_code.code);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return challenge_version ? challenge_version.name : '';
                                    }}
                                    id='rubrics-challenge-filter'
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    }
                ]}
                sort_columns={[
                    {
                        key: 'challenge_name',
                        name: 'Challenge'
                    },
                ]}
                default_order_by={'challenge_name'}
                entity={'question_practice_skill_groups'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_RUBRICS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
