import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {LoadPracticeAction} from "mindsets-js-sdk";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const practice = useSelector(state => state.practice);

    useEffect(() => {
        dispatch(LoadPracticeAction(params.practice_id));
    }, []);

    if (practice) {
        return (
            <div className='section'>
                <div>{practice.name}</div>
            </div>
        );
    }

    return null;
}
