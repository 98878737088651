import React from "react";
import PropTypes from "prop-types";

const Expander = function ({length, reduced, setReduced}) {
    if (length <= 5) {
        return null;
    }

    return (
        <tr>
            <td colSpan={3} className='extensor'>
                <a href={'!#'} onClick={event => {
                    event.preventDefault();
                    setReduced(!reduced);
                }}>{reduced ? 'Show all' : 'Show less'}</a>
            </td>
        </tr>
    );
}

Expander.propTypes = {
    length: PropTypes.number.isRequired,
    reduced: PropTypes.bool.isRequired,
    setReduced: PropTypes.func.isRequired,
};

export default Expander;
