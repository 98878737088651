import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ListPage, filterConstants, accessConstants, LoadStandardsAction} from "mindsets-js-sdk";

import Table from "./components/Table";
import {LoadCurriculaAction} from "../../actions/standard_actions";

export default function () {
    const dispatch = useDispatch();
    const curricula = useSelector(state => state.standards_curricula);
    useEffect(() => {
        if (!curricula.length) {
            dispatch(LoadCurriculaAction({limit: 0}));
        }
    }, []);

    return (
        <>
            <h1>Standards</h1>
            <ListPage
                action={LoadStandardsAction}
                filter_columns={[
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'grade',
                        name: 'Grade',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL]
                    },
                    {
                        key: 'topic',
                        name: 'Topic',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'subject',
                        name: 'Subject',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [
                            {
                                label: 'Math',
                                value: 'math'
                            },
                            {
                                label: 'Science',
                                value: 'science'
                            }
                        ]
                    },
                    {
                        key: 'curriculum_id',
                        name: 'Curriculum',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: curricula.map(curriculum => {
                            return {
                                label: curriculum.name,
                                value: curriculum.id
                            }
                        })
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    }
                ]}
                entity={'standards'}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CURRICULUMS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
