import React from 'react';

import BaseComponentProperty from "../index";

export default class Comparison extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="text">Html</label>
                    <textarea
                        id="text"
                        className='form-control'
                        value={this.props.component.data.text ? this.props.component.data.text : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.text = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="imageURL">Image URL</label>
                    <textarea
                        id="imageURL"
                        className='form-control'
                        value={this.props.component.data.imageURL ? this.props.component.data.imageURL : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.imageURL = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="image_name">Image name</label>
                    <input
                        type='text'
                        id="image_name"
                        className='form-control'
                        value={this.props.component.data.image_name ? this.props.component.data.image_name : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.image_name = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="animated_gif_url">Animated gif URL</label>
                    <textarea
                        id="animated_gif_url"
                        className='form-control'
                        value={this.props.component.data.animated_gif_url ? this.props.component.data.animated_gif_url : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.animated_gif_url = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="animated_git_name">Animated gif name</label>
                    <input
                        type='text'
                        id="animated_git_name"
                        className='form-control'
                        value={this.props.component.data.animated_git_name ? this.props.component.data.animated_git_name : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.animated_git_name = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="top">Top</label>
                    <input
                        type='text'
                        id="top"
                        className='form-control'
                        value={this.props.component.data.top ? this.props.component.data.top : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.top = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="bubble_background">Bubble background</label>
                    <select
                        id="bubble_background"
                        className='form-control'
                        value={this.props.component.data.bubble_background ? this.props.component.data.bubble_background : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.bubble_background = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    >
                        <option value=''>Select bubble background color</option>
                        <option value='blue'>Blue</option>
                        <option value='pink'>Pink</option>
                        <option value='purple'>Purple</option>
                        <option value='green'>Green</option>
                        <option value='orange'>Orange</option>
                        <option value='teal'>Teal</option>
                        <option value='brown'>Brown</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="bubble_direction">Bubble direction</label>
                    <select
                        id="bubble_direction"
                        className='form-control'
                        value={this.props.component.data.bubble_direction ? this.props.component.data.bubble_direction : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.bubble_direction = event.target.value === 'NULL' ? null : event.target.value;
                            this.props.onChange('data', data);
                        }}
                    >
                        <option value=''>Select bubble direction</option>
                        <option value={null}>NULL</option>
                        <option value='top'>Top</option>
                        <option value='bottom'>Bottom</option>
                        <option value='left'>Left</option>
                        <option value='right'>Right</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="bubble_corner">Bubble corner</label>
                    <input
                        type='text'
                        id="bubble_corner"
                        className='form-control'
                        value={this.props.component.data.bubble_corner ? this.props.component.data.bubble_corner : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.bubble_corner = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }
}
