import React from 'react';
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {DeleteRoleAction} from "mindsets-js-sdk";

const Row = function ({item}) {
    const dispatch = useDispatch();
    return (
        <tr key={item.id}>
            <td>{item.name}</td>
            <td>
                <button className='button red' onClick={() => dispatch(DeleteRoleAction(item.id))}>
                    <i className='fas fa-trash'/>
                </button>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired
};

export default Row;
