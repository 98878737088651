import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const EditButton = function ({item}) {
    const me = useSelector(state => state.me);
    if ((authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_RUBRICS])) || authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MASTER_RUBRICS])) {
        let url = `/rubrics/${item.challenge_code}?practice_id=${item.practice_id}`;

        return (
            <Link to={url} className="button">Update</Link>
        );
    }

    return null;
}

EditButton.propTypes = {
    item: PropTypes.shape({
        challenge_code: PropTypes.string.isRequired,
        practice_id: PropTypes.number.isRequired
    })
}

export default EditButton;
