import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ContentComponent from './ContentComponent'
import AnswerComponent from './AnswerComponent'
import SpecialComponent from './SpecialComponent'
import CardComponentContainer from './CardComponentContainer'


class CardComponent extends Component {
    getContentComponent() {
        return (
            <ContentComponent component_data={this.props.component_data} updateComponent={this.props.updateComponent}/>
        );
    }

    getAnswerComponent() {
        return (
            <AnswerComponent component_data={this.props.component_data} updateComponent={this.props.updateComponent}/>
        );
    }

    getSpecialComponent() {
        return (
            <SpecialComponent component_data={this.props.component_data} updateComponent={this.props.updateComponent}/>
        );
    }

    getComponent() {
        const type = this.props.component_data.type.toLowerCase();
        switch (true) {
            case type.startsWith('answer.') || type === 'spreadsheet' || type === 'poll.inline':
                return this.getAnswerComponent();
            case type === 'poll.block':
                return this.getSpecialComponent();
            default:
                return this.getContentComponent();
        }
    }

    renderComponent() {
        return (
            <CardComponentContainer component_data={this.props.component_data}>
                {this.getComponent()}
            </CardComponentContainer>
        )
    }

    render() {
        return (
            <div
                className={"challenge-card-component " + (this.props.selected ? 'selected' : '')}
                onClick={event => {
                    event.stopPropagation();
                    this.props.selectComponent();
                }}
                onMouseOver={() => this.props.setMouseOverComponent(true)}
                onMouseLeave={() => this.props.setMouseOverComponent(false)}
            >
                {this.renderComponent()}
            </div>
        )
    }
}

CardComponent.propTypes = {
    component_data: PropTypes.shape({
        type: PropTypes.string.isRequired,
        data: PropTypes.object
    }).isRequired,
    selectComponent: PropTypes.func.isRequired,
    updateComponent: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    setMouseOverComponent: PropTypes.func.isRequired
}

export default CardComponent
