import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import NavPage from "./NavPage";

export default function ({index}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: index + 1});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <NavPage index={index} ref={setNodeRef} style={style} {...attributes} {...listeners}/>
    );
}
