import React from 'react';

import BaseComponentProperty from "../index";

export default class Graphing extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    hasConfigs() {
        return true;
    }
}
