import React from 'react';
import {useSelector} from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';

export default function () {
    const dashboard_stats = useSelector(state => state.dashboard_stats);
    const challenge_versions = dashboard_stats ? dashboard_stats.challenge_versions : [];
    const compileData = function () {
        let data = [];
        if (challenge_versions.length) {
            data.push({
                name: '#challenges',
                data: challenge_versions.map(challenge_version => challenge_version.count)
            });
        }

        return data;
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Challenges used'
                    },
                    xAxis: {
                        title: {
                            text: 'Challenge name'
                        },
                        categories: challenge_versions.map(challenge_version => challenge_version.challenge_name),
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '# challenges'
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: compileData(),
                    credits: {
                        enabled: false
                    },
                    accessibility: {
                        enabled: false
                    }
                }}
            />
        </div>
    );
}
