import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    accessConstants,
    countriesConstants,
    ErrorMessageAction,
    filterConstants,
    ListPage,
    SchoolDropdown,
    LoadUsersAction
} from "mindsets-js-sdk";

import Table from './components/Table';
import {
    LoadCurriculaAction,
    LoadPermissionsAction,
    LoadRolesAction,
} from "../../actions/user_actions";

export default function () {
    const [members, setMembers] = useState([]);
    const roles = useSelector(state => state.users_roles);
    const curricula = useSelector(state => state.users_curricula);
    const permissions = useSelector(state => state.users_permissions);
    const dispatch = useDispatch();

    const debounce = useCallback(_.debounce(a => a(), 600), []);

    useEffect(() => {
        dispatch(LoadRolesAction());
        dispatch(LoadPermissionsAction());
        dispatch(LoadCurriculaAction());
    }, []);
    useEffect(() => {
        const presence_channel = pusher.subscribe('presence-online');
        presence_channel.bind('pusher:subscription_succeeded', data => setMembers(Object.keys(data.members).map(id => parseInt(id))));
        presence_channel.bind('pusher:subscription_error', error => {
            console.error(error);
            dispatch(ErrorMessageAction('Internet connection problem found. Please check your connection and refresh the page.', true));
        });
        presence_channel.bind('pusher:member_added', member => {
            if (!members.includes(parseInt(member.id))) {
                let membersOnline = JSON.parse(JSON.stringify(members));
                membersOnline.push(parseInt(member.id));
                setMembers(membersOnline);
            }
        });
        presence_channel.bind('pusher:member_removed', member => {
            if (members.includes(parseInt(member.id))) {
                let membersOnline = JSON.parse(JSON.stringify(members));
                membersOnline.splice(membersOnline.indexOf(parseInt(member.id)), 1);
                setMembers(membersOnline);
            }
        });

        return () => presence_channel.unsubscribe();
    }, []);

    return (
        <>
            <h1>Users ({members.length} online)</h1>
            <ListPage
                action={options => LoadUsersAction(Object.assign({
                    'selectors[0]': 'streams_count',
                    'selectors[1]': 'roles',
                    'selectors[2]': 'co_classes_count',
                    'selectors[3]': 'number_lessons',
                    'selectors[4]': 'schools',
                    'selectors[5]': 'school',
                    'selectors[6]': 'school_subscriptions',
                    'selectors[7]': 'district_subscriptions',
                    'selectors[8]': 'grade_level',
                    'selectors[9]': 'is_authenticated'
                }, options))}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'email',
                        name: 'E-mail',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'fname',
                        name: 'First Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'lname',
                        name: 'Last Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'persona',
                        name: 'Current Persona',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: roles.map(role => {
                            return {
                                label: role.name,
                                value: role.id
                            }
                        })
                    },
                    {
                        key: 'curriculum_id',
                        name: 'Curriculum',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: [{
                            label: 'N/A',
                            value: 'null'
                        }].concat(curricula.map(curriculum => {
                            return {
                                label: curriculum.name,
                                value: curriculum.id
                            }
                        }))
                    },
                    {
                        key: 'country',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: Object.keys(countriesConstants.COUNTRIES).map(code => {
                            return {
                                label: countriesConstants.COUNTRIES[code],
                                value: code
                            }
                        })
                    },
                    {
                        key: 'number_lessons',
                        name: 'No. Classes Created',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'streams_count',
                        name: 'No. Classes Joined as Student',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'co_classes_count',
                        name: 'No. Classes Joined as Co-Teacher',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'has_role',
                        name: 'Has role',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: roles.map(role => {
                            return {
                                label: role.name,
                                value: role.id
                            }
                        })
                    },
                    {
                        key: 'has_permission',
                        name: 'Has permission',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: Object.keys(permissions).map(key => {
                            return {
                                label: permissions[key],
                                value: key
                            }
                        })
                    },
                    {
                        key: 'is_free',
                        name: 'Free Account',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'on_school_subscription',
                        name: 'On school subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'on_district_subscription',
                        name: 'On district subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'lesson_id',
                        name: 'Owner of Class by Class ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'lesson_co_id',
                        name: 'Co-teachers of Class by Class ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'student_lesson_id',
                        name: 'Students of Class by Class ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL]
                    },
                    {
                        key: 'school_id',
                        name: 'By Joined School ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='users-joined-school-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'student_school_id',
                        name: 'By Class School ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        component: setFilterValue => {
                            return (
                                <SchoolDropdown
                                    onSelect={school => {
                                        if (school) {
                                            setFilterValue(school.id);
                                        } else {
                                            setFilterValue('');
                                        }

                                        return school ? school.name : '';
                                    }}
                                    id='users-student-school-filter'
                                    show_counts={true}
                                    debounce={debounce}
                                />
                            );
                        },
                        multiple: false
                    },
                    {
                        key: 'id_online',
                        name: 'Online',
                        expressions: [filterConstants.EXPRESSION_IN],
                        default: members.join(','),
                        multiple: false
                    },
                    {
                        key: 'status',
                        name: 'Status',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [{
                            label: 'Active',
                            value: 'active'
                        }, {
                            label: 'Inactive',
                            value: 'inactive'
                        }],
                        multiple: false
                    },
                    {
                        key: 'remote_provider',
                        name: 'Joined with',
                        expressions: [filterConstants.EXPRESSION_EQUAL],
                        values: [{
                            label: 'Email',
                            value: 'registered-with-email'
                        }, {
                            label: 'Google',
                            value: 'google'
                        }, {
                            label: 'Microsoft',
                            value: 'office'
                        }, {
                            label: 'Clever',
                            value: 'clever'
                        }, {
                            label: 'ClassLink',
                            value: 'classlink'
                        }, {
                            label: 'Schoology',
                            value: 'schoology'
                        }, {
                            label: 'As guest',
                            value: 'quick-join'
                        }, {
                            label: 'As guest OLD',
                            value: 'cashtivity-quick-join'
                        }, {
                            label: 'Created by admin',
                            value: 'admin'
                        }],
                        multiple: false
                    },
                    {
                        key: 'grade_level',
                        name: 'Grade Level',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'is_authenticated',
                        name: 'Authenticated',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'teacher_subscribed',
                        name: 'Subscribed',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'password_reset',
                        name: 'Password reset requested',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'email',
                        name: 'E-mail'
                    },
                    {
                        key: 'fname',
                        name: 'First Name'
                    },
                    {
                        key: 'lname',
                        name: 'Last Name'
                    },
                    {
                        key: 'country',
                        name: 'Country'
                    },
                    {
                        key: 'number_lessons',
                        name: 'No. of Classes Created'
                    },
                    {
                        key: 'streams_count',
                        name: 'No. of Classes Joined'
                    }
                ]}
                entity={'users'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <Link to="/users/new" className="button">+ New User/s</Link>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_USERS]}
            >
                <Table members={members}/>
            </ListPage>
        </>
    );
}
