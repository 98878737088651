import React from 'react';

import BaseComponentProperty from "../index";
import Style from "../Style";

export default class DragAndDrop extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderAnswerGroups() {
        return (
            <div>
                <a data-toggle="collapse" href={"#collapseAnswerGroups"} aria-expanded="false"
                   aria-controls="collapseAnswerGroups">
                    <h5>Answer Groups</h5>
                </a>
                <div className="collapse" id="collapseAnswerGroups">
                    <button className='button mb-2' onClick={() => {
                        let data = this.props.component.data;
                        let answer_groups = data.answer_groups;
                        answer_groups.push({
                            dnd_type: "group-" + Math.floor(Math.random() * 100),
                            direction: "horizontal",
                            color_light: "",
                            color_dark: "",
                            droppable_style: {},
                            draggable_style: {},
                            options: []
                        });
                        data.answer_groups = answer_groups;
                        this.props.onChange('data', data);
                    }}>+ group
                    </button>
                    <div>
                        {this.props.component.data.answer_groups.map((item, index) => {
                            return (
                                <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                                    <i
                                        className='fas fa-trash text-danger clickable'
                                        style={{position: 'absolute', right: '0', top: '5px'}}
                                        onClick={() => {
                                            let data = this.props.component.data;
                                            data.answer_groups.splice(index, 1);
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                    <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                                        <div>
                                            <label
                                                htmlFor={"dnd_type" + index}
                                                className="col-form-label">DnD type</label>
                                            <input
                                                type='text'
                                                className="form-control"
                                                id={"dnd_type" + index}
                                                value={item.dnd_type ? item.dnd_type : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let answer_groups = data.answer_groups;
                                                    item.dnd_type = event.target.value;
                                                    answer_groups[index] = item;
                                                    data.answer_groups = answer_groups;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={"direction" + index}
                                                className="col-form-label">Direction</label>
                                            <select
                                                className="form-control"
                                                id={"direction" + index}
                                                value={item.direction ? item.direction : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let answer_groups = data.answer_groups;
                                                    item.direction = event.target.value;
                                                    answer_groups[index] = item;
                                                    data.answer_groups = answer_groups;
                                                    this.props.onChange('data', data);
                                                }}
                                            >
                                                <option value=''>Select direction</option>
                                                <option value='horizontal'>Horizontal</option>
                                                <option value='vertical'>Vertical</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={"color_light" + index}
                                                className="col-form-label">Color light</label>
                                            <input
                                                type='text'
                                                className="form-control"
                                                id={"color_light" + index}
                                                value={item.color_light ? item.color_light : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let answer_groups = data.answer_groups;
                                                    item.color_light = event.target.value;
                                                    answer_groups[index] = item;
                                                    data.answer_groups = answer_groups;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor={"color_dark" + index}
                                                className="col-form-label">Color dark</label>
                                            <input
                                                type='text'
                                                className="form-control"
                                                id={"color_dark" + index}
                                                value={item.color_dark ? item.color_dark : ''}
                                                onChange={event => {
                                                    let data = this.props.component.data;
                                                    let answer_groups = data.answer_groups;
                                                    item.color_dark = event.target.value;
                                                    answer_groups[index] = item;
                                                    data.answer_groups = answer_groups;
                                                    this.props.onChange('data', data);
                                                }}
                                            />
                                        </div>
                                        <Style
                                            initial={item.droppable_style}
                                            name='Droppable Style'
                                            onChange={droppable_style => {
                                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                                data.answer_groups[index].droppable_style = droppable_style;
                                                this.props.onChange('data', data);
                                            }}/>
                                        <Style
                                            initial={item.draggable_style}
                                            name='Draggable Style'
                                            onChange={draggable_style => {
                                                let data = JSON.parse(JSON.stringify(this.props.component.data));
                                                data.answer_groups[index].draggable_style = draggable_style;
                                                this.props.onChange('data', data);
                                            }}/>
                                    </div>
                                    <h5 className='mt-2'>Options</h5>
                                    <button className='button mb-2' onClick={() => {
                                        let data = this.props.component.data;
                                        item.options.push({
                                            dnd_id: `group-${index}-` + Math.floor(Math.random() * 100),
                                            html: "",
                                            text: ""
                                        });
                                        data.answer_groups[index] = item;
                                        this.props.onChange('data', data);
                                    }}>+ option
                                    </button>
                                    <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                                        {item.options.map((option, i) => {
                                            return (
                                                <div key={i} style={{position: 'relative'}} className='bordered'>
                                                    <i
                                                        className='fas fa-trash text-danger clickable'
                                                        style={{position: 'absolute', right: '0', top: '5px'}}
                                                        onClick={() => {
                                                            let data = this.props.component.data;
                                                            data.answer_groups[index].options.splice(i, 1);
                                                            this.props.onChange('data', data);
                                                        }}
                                                    />
                                                    <div>
                                                        <label htmlFor={"dnd_id-" + index + '-' + i}>DnD ID</label>
                                                        <input
                                                            type='text'
                                                            id={"dnd_id" + index + '-' + i}
                                                            className='form-control'
                                                            value={option.dnd_id ? option.dnd_id : ''}
                                                            onChange={event => {
                                                                let data = this.props.component.data;
                                                                option.dnd_id = event.target.value;
                                                                item.options[i] = option;
                                                                data.answer_groups[index] = item;
                                                                this.props.onChange('data', data);
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor={"html-" + index + '-' + i}>Html</label>
                                                        <textarea
                                                            id={"html" + index + '-' + i}
                                                            className='form-control'
                                                            value={option.html ? option.html : ''}
                                                            onChange={event => {
                                                                let data = this.props.component.data;
                                                                option.html = event.target.value;
                                                                item.options[i] = option;
                                                                data.answer_groups[index] = item;
                                                                this.props.onChange('data', data);
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor={"text-" + index + '-' + i}>Text</label>
                                                        <input
                                                            type='text'
                                                            id={"text" + index + '-' + i}
                                                            className='form-control'
                                                            value={option.text ? option.text : ''}
                                                            onChange={event => {
                                                                let data = this.props.component.data;
                                                                option.text = event.target.value;
                                                                item.options[i] = option;
                                                                data.answer_groups[index] = item;
                                                                this.props.onChange('data', data);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    renderAnswerLayoutItemStatic(index, item) {
        return (
            <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                <div>
                    <label
                        htmlFor={"html" + index}
                        className="col-form-label">Html</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"html" + index}
                        value={item.content && item.content.html ? item.content.html : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            if (!item.hasOwnProperty('content')) {
                                item.content = {}
                            }
                            item.content.html = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor={"text" + index}
                        className="col-form-label">Text</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"text" + index}
                        value={item.content && item.content.text ? item.content.text : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            if (!item.hasOwnProperty('content')) {
                                item.content = {}
                            }
                            item.content.text = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }

    renderAnswerLayoutItemHidden(index, item) {
        return (
            <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                <div>
                    <label
                        htmlFor={"text" + index}
                        className="col-form-label">Text</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"text" + index}
                        value={item.content && item.content.text ? item.content.text : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            if (!item.hasOwnProperty('content')) {
                                item.content = {}
                            }
                            item.content.text = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }

    renderAnswerLayoutItemDND(index, item) {
        return (
            <div className='d-flex flex-row flex-wrap flex-box-0-5'>
                <div>
                    <label
                        htmlFor={"dnd_type" + index}
                        className="col-form-label">DnD type</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"dnd_type" + index}
                        value={item.dnd_type ? item.dnd_type : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            item.dnd_type = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor={"dnd_id" + index}
                        className="col-form-label">DnD ID</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"dnd_id" + index}
                        value={item.dnd_id ? item.dnd_id : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            item.dnd_id = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor={"placeholder" + index}
                        className="col-form-label">Placeholder</label>
                    <input
                        type='text'
                        className="form-control"
                        id={"placeholder" + index}
                        value={item.placeholder ? item.placeholder : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            item.placeholder = event.target.value;
                            answer_layout[index] = item;
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <Style
                    initial={item.droppable_style}
                    name='Droppable Style'
                    onChange={droppable_style => {
                        let data = JSON.parse(JSON.stringify(this.props.component.data));
                        data.answer_layout[index].droppable_style = droppable_style;
                        this.props.onChange('data', data);
                    }}/>
            </div>
        );
    }

    renderAnswerLayoutItem(index, item) {
        if (item.type === 'static') {
            return this.renderAnswerLayoutItemStatic(index, item);
        } else if (item.type === 'dnd') {
            return this.renderAnswerLayoutItemDND(index, item);
        } else if (item.type === 'hidden') {
            return this.renderAnswerLayoutItemHidden(index, item);
        }

        return null;
    }

    renderAnswerLayout() {
        return (
            <div>
                <a data-toggle="collapse" href={"#collapseAnswerLayout"} aria-expanded="false"
                   aria-controls="collapseAnswerLayout">
                    <h5>Answer Layout</h5>
                </a>
                <div className="collapse" id="collapseAnswerLayout">
                    <div className='d-flex flex-box-0-5'>
                        <button className='button mb-2' onClick={() => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            answer_layout.push({
                                type: "static",
                                content: {
                                    html: "",
                                    text: ""
                                }
                            });
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}>+ static
                        </button>
                        <button className='button mb-2' onClick={() => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            answer_layout.push({
                                type: "dnd",
                                dnd_type: "group-" + Math.floor(Math.random() * 100),
                                dnd_id: "answer-group-" + Math.floor(Math.random() * 100),
                                placeholder: "",
                                droppable_style: {}
                            });
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}>+ DnD
                        </button>
                        <button className='button mb-2' onClick={() => {
                            let data = this.props.component.data;
                            let answer_layout = data.answer_layout;
                            answer_layout.push({
                                type: "hidden",
                                content: {text: ''}
                            });
                            data.answer_layout = answer_layout;
                            this.props.onChange('data', data);
                        }}>+ Hidden
                        </button>
                    </div>
                    <div>
                        {this.props.component.data.answer_layout.map((item, index) => {
                            return (
                                <div key={index} style={{position: 'relative'}} className='bordered mb-2'>
                                    <i
                                        className='fas fa-trash text-danger clickable'
                                        style={{position: 'absolute', right: '0', top: '5px'}}
                                        onClick={() => {
                                            let data = this.props.component.data;
                                            data.answer_layout.splice(index, 1);
                                            this.props.onChange('data', data);
                                        }}
                                    />
                                    {this.renderAnswerLayoutItem(index, item)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    renderOwnProperties() {
        return (
            <div>
                {this.renderAnswerGroups()}
                {this.renderAnswerLayout()}
            </div>
        );
    }
}
