import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentConfigGGBValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: false,
            attributes: {
                material_id: {
                    type: 'string',
                    empty: false,
                    mandatory: false
                }
            }
        };
    }
}
