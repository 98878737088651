import React from 'react';
import PropTypes from "prop-types";
import UserRole from "./UserRole";

const UserRoles = function ({roles, persona}) {
    return roles.map(user_role => <UserRole key={user_role.id} user_role={user_role} persona={persona}/>);
}

UserRoles.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
    })).isRequired,
    persona: PropTypes.string.isRequired
};

export default UserRoles;
