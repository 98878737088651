import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {authUtils, accessConstants} from "mindsets-js-sdk";

function QAButton({schedule}) {
    const me = useSelector(state => state.me);

    if (!me) {
        return null;
    }

    if (schedule.status === 'finished' && authUtils.iCan(me, [accessConstants.PERMISSION_CAN_VERIFY_GRADING])) {
        return (
            <Link
                to={`/qa/${schedule.id}`}
                className="button d-inline-block">QA&nbsp;<span className='badge bg-danger'>beta</span></Link>
        );

    }

    return null;
}

QAButton.propTypes = {
    schedule: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
}

export default QAButton;
