import React from 'react';
import PropTypes from "prop-types";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

// import Results from './Results';

function Preview({mode}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const response_points = useSelector(state => state.response_points);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const questions = useSelector(state => state.questions).filter(question => question_practice_skills.map(qps => qps.question_code).includes(question.code));
    const schedule = useSelector(state => state.lesson_scoring_schedule);

    // const [preview_mode, setPreviewMode] = useState(searchParams.has('preview_mode') ? searchParams.get('preview_mode') : 'achievement');

    function renderScore(response_point, max_points) {
        if (!response_point) {
            return (
                <div className='badge bg-warning'>NR</div>
            );
        } else {
            if (response_point.points !== null) {
                let color = '';
                if (response_point.use_auto === 'using_ai') {
                    color = 'badge bg-blue'
                } else if (response_point.use_auto === 'using') {
                    color = 'badge bg-grey'
                }
                if (response_point.points > max_points) {
                    color = 'badge bg-danger'
                }

                return (
                    <div
                        className={color}>{response_point.points}</div>
                );
            } else {
                return (
                    <div className='badge bg-danger'>NS</div>
                );
            }
        }
    }

    // function renderPreviewModeButtons() {
    //     return (
    //         <div className='d-flex flex-gap-5'>
    //             <button
    //                 className={'button ' + (preview_mode === 'achievement' ? '' : 'inactive')}
    //                 onClick={event => {
    //                     event.preventDefault();
    //                     setPreviewMode('achievement');
    //                     searchParams.set('preview_mode', 'achievement');
    //                     setSearchParams(searchParams);
    //                 }}
    //             >Achievement
    //             </button>
    //             <button
    //                 className={'button ' + (preview_mode === 'potential' ? '' : 'inactive')}
    //                 onClick={event => {
    //                     event.preventDefault();
    //                     setPreviewMode('potential');
    //                     searchParams.set('preview_mode', 'potential');
    //                     setSearchParams(searchParams);
    //                 }}
    //             >Potential
    //             </button>
    //         </div>
    //     );
    // }

    const stream_ids = [];
    response_points.map(response_point => {
        if (!stream_ids.includes(response_point.stream_id)) {
            stream_ids.push(response_point.stream_id)
        }
    });

    if (!schedule) {
        return null;
    }

    if (questions.length > 0 && mode === 'preview') {
        return (
            <div className='section'>
                <div className='container'>
                    <div className='d-flex justify-content-end flex-gap-5'>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-warning'>NR</div>
                            <div>No response from student</div>
                        </div>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-danger'>NS</div>
                            <div>Score Not Set</div>
                        </div>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-grey'>0</div>
                            <div>Automatically graded</div>
                        </div>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-blue'>0</div>
                            <div>AI graded</div>
                        </div>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-danger'>{"<score>"}</div>
                            <div>Exceeding MAX points</div>
                        </div>
                        <div className='d-flex flex-gap-5'>
                            <div className='badge bg-info'>Non-math questions are not shown here</div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className='table table-bordered table-sm sticky'>
                        <thead>
                        <tr>
                            <th style={{minWidth: '50px'}}>
                                <div>Question</div>
                                <div>(max points)</div>
                            </th>
                            {stream_ids.map((stream_id, index) => {
                                return (
                                    <td
                                        key={stream_id}
                                    >{'Student ' + (searchParams.has('show_stream_id') ? ('ID' + stream_id) : ('#' + (index + 1)))}</td>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {questions.map((question, index) => {
                            const question_practice_skill = question_practice_skills.find(qps => qps.question_code === question.code);
                            const max_points = question_practice_skill && question_practice_skill.points ? question_practice_skill.points.length - 1 : null;
                            const question_practice_skill_points = null !== max_points ? ('(' + (question_practice_skill.points.length - 1) + ')') : '';

                            return (
                                <tr key={question.id}>
                                    <td>
                                        {'Q' + (question.number ? question.number : index + 1) + question_practice_skill_points + (question.is_bonus ? '(BONUS)' : '')}
                                    </td>
                                    {stream_ids.map(stream_id => {
                                        if (!question_practice_skill) {
                                            return (
                                                <td key={stream_id}>
                                                    <div className='d-flex'>
                                                        <div className='badge bg-info'>NA</div>
                                                    </div>
                                                </td>
                                            );
                                        }

                                        const response_point = response_points.find(response_point => response_point.stream_id === stream_id && response_point.question_id === question.id);

                                        return (
                                            <td key={stream_id}>
                                                {renderScore(response_point, max_points)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {/*<Results mode={preview_mode}/>*/}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return null;
}

Preview.propTypes = {
    mode: PropTypes.string.isRequired,
};

export default Preview;
