import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, PauseStreamAction, ReloadListAction} from "mindsets-js-sdk";

const PauseButton = function ({item}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_STREAMS]) && 'started' === item.status) {
        return (
            <button className='button' onClick={event => {
                event.preventDefault();
                dispatch(PauseStreamAction(item.id)).then(() => dispatch(ReloadListAction(true, 'streams')));
            }}>Pause</button>
        );
    }

    return null;
}

PauseButton.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired
};

export default PauseButton;
