import React from 'react';
import {useSelector} from "react-redux";
import {getRowNumber} from "mindsets-js-sdk";

import Row from './Row';

export default function () {
    const items = useSelector(state => Array.isArray(state.districts) ? state.districts : []);
    const current = useSelector(state => state.districts_current);
    const limit = useSelector(state => state.districts_limit);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">ID</th>
                    <th scope="col">District Name</th>
                    <th scope="col">State</th>
                    <th scope="col">Country</th>
                    <th scope="col">No. schools</th>
                    <th scope="col">No. teachers</th>
                    <th scope="col">No. students</th>
                    <th scope="col">
                        <div>Subscription Plan</div>
                        <div>(period)</div>
                    </th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => <Row
                    key={item.id}
                    item={item}
                    row_number={getRowNumber(index, current, limit)}
                />)}
                </tbody>
            </table>
        </div>
    );
}
