import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import EditButton from "./EditButton";

const Row = function ({item, row_number}) {
    return (
        <tr>
            <td>{row_number}</td>
            <td>{item.challenge_version_name}</td>
            <td>{item.practice_name}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <Link
                        to={`/challenge/${item.challenge_code}/practice/${item.practice_id}/preview`}
                        className="button">Preview</Link>
                    <EditButton item={item}/>
                </div>
            </td>
        </tr>
    );
}

Row.propTypes = {
    row_number: PropTypes.number.isRequired,
    item: PropTypes.shape({
        challenge_code: PropTypes.string.isRequired,
        challenge_version_name: PropTypes.string.isRequired,
        practice_name: PropTypes.string.isRequired,
        practice_id: PropTypes.number.isRequired
    })
};

export default Row;
