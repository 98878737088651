import React, {useEffect, useState} from 'react';
import {useRouteError} from "react-router-dom";
import {useSelector} from "react-redux";

import "./error.scss";

export default function () {
    const error = useRouteError();
    const me = useSelector(state => state.me);
    const [show, setShow] = useState(false);

    const renderShowErrorMessage = function () {
        if (error && (error.message || error.statusText)) {
            return (
                <a href={'!#'} onClick={event => {
                    event.preventDefault();
                    setShow(!show);
                }}>{show ? 'Hide details' : 'Show details'}</a>
            );
        }

        return null;
    }
    const renderErrorMessage = function () {
        if (show && error && error.message) {
            return (
                <p>
                    <i>{error.message}</i>
                </p>
            );
        }

        return null;
    }
    const renderErrorStatusText = function () {
        if (show && error && error.statusText) {
            return (
                <p>
                    <i>{error.statusText}</i>
                </p>
            );
        }

        return null;
    }
    const renderErrorFileName = function () {
        if (show && error && error.fileName) {
            return (
                <p>
                    <i>File: {error.fileName}</i>
                </p>
            );
        }

        return null;
    }
    const renderErrorLineNumber = function () {
        if (show && error && error.lineNumber) {
            return (
                <p>
                    <i>Line: {error.lineNumber}</i>
                </p>
            );
        }

        return null;
    }
    const renderErrorColumnNumber = function () {
        if (show && error && error.columnNumber) {
            return (
                <p>
                    <i>Line: {error.columnNumber}</i>
                </p>
            );
        }

        return null;
    }
    const renderErrorStack = function () {
        if (show && error && error.stack) {
            return (
                <p style={{textAlign: 'left'}}>
                    <i dangerouslySetInnerHTML={{__html: error.stack.replaceAll('\n', '<br/>')}}/>
                </p>
            );
        }

        return null;
    }

    useEffect(() => {
        if (process.env.PUBLIC_LOG_ERROR_TO_SLACK) {
            try {
                const now = new Date();
                let text = `ERROR(${process.env.PUBLIC_APP_ENV})\nOn: ${now.toISOString()}\nUID: ${me ? me.id : 0}\nURI: ${window.location.pathname}${window.location.search}`;
                if (typeof error === 'object' && Array.isArray(Object.keys(error)) && Object.keys(error).length > 0) {
                    Object.keys(error).map(key => {
                        text += "\nError: " + key + ': ' + error[key];
                    });
                } else {
                    text += "\n" + error.toString();
                }
                text += "\nSTACK:\n";
                text += error.stack;
                fetch(process.env.PUBLIC_SLACK_ERROR_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify({text})
                }).catch(error => console.error('Failed sending error to Slack(fetch).', error));
            } catch (e) {
                console.error('Failed sending error to Slack.', e);
            }
        }
    }, [error]);

    return (
        <div id="error-page">
            <div className="container">
                <div className="content">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>Please, contact Mindsets engineers at <a href='mailto:dev@mindsets.com'>dev@mindsets.com</a>.</p>
                    {renderShowErrorMessage()}
                    {renderErrorMessage()}
                    {renderErrorStatusText()}
                    {renderErrorFileName()}
                    {renderErrorLineNumber()}
                    {renderErrorColumnNumber()}
                    {renderErrorStack()}
                </div>
            </div>
        </div>
    )
};
