import React from "react";
import {useSelector} from "react-redux";

export default function () {
    const practice = useSelector(state => state.practice);
    const questions = useSelector(state => state.questions);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const practice_skills = practice.practice_skills;
    let summary = [];
    question_practice_skills.filter(qps => {
        const question = questions.find(question => question.code === qps.question_code);

        return !!question && question.is_bonus === true;
    }).map(qps => {
        const question = questions.find(question => question.code === qps.question_code);
        const categoryIndex = summary.findIndex(category => category.practice_skill_id === qps.practice_skill_id);
        if (categoryIndex >= 0) {
            let category = summary[categoryIndex];
            let question_numbers = category.question_numbers;
            question_numbers.push('Q' + question.number);
            category.question_numbers = question_numbers
            category.max_points = parseInt(category.max_points) + (qps.points.length - 1)
            summary[categoryIndex] = category;
        } else {
            summary.push({
                practice_skill_id: qps.practice_skill_id,
                question_numbers: ['Q' + question.number],
                max_points: qps.points.length - 1
            });
        }
    });
    let max_points = 0;

    return (
        <div className='mt-4'>
            <h3>Summary Table - Bonus questions</h3>
            <div className="table-responsive mt-4">
                <table className='table table-bordered table-sm'>
                    <thead>
                    <tr>
                        <td>Category</td>
                        <td>Questions in the Category</td>
                        <td>Points Possible</td>
                    </tr>
                    </thead>
                    <tbody>
                    {summary.map(category => {
                        const practice_skill = practice_skills.find(practice_skill => practice_skill.id === category.practice_skill_id);
                        max_points += parseInt(category.max_points);

                        return (
                            <tr key={category.practice_skill_id}>
                                <td>{!!practice_skill ? practice_skill.code : 'n/a'} {!!practice_skill ? practice_skill.description : 'n/a'}</td>
                                <td>{category.question_numbers.join(', ')}</td>
                                <td>{category.max_points}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={2}>Total</td>
                        <td>{max_points}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
