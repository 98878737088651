import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    accessConstants,
    authUtils,
    LoadChallengeVersionsAction,
    filterConstants,
    LoadChallengeCodesAction,
    ErrorMessageAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import Filters from "./components/Filters";
import MPGraph from "./components/MPGraph";
import CASSPGraph from "./components/CASSPGraph";
import {LoadImpactStudyAction, LoadPracticesAction} from "../../actions/impact_actions";
import MPData from "./components/MPData";
import CASSPData from "./components/CASSPData";

import './impact_study.scss';

export const INTERVAL_COLORS = {
    'Exceeded': '#19A8FF',
    'Met': '#74C043',
    'Nearly met': '#D6DF28',
    'Not met': '#F79768',
    'Level 5': '#8264A2',
    'Level 4': '#0470C0',
    'Level 3': '#06B050',
    'Level 2': '#E56C0B',
    'Level 1': '#C00000',
    'Above Standard': '#19A8FF',
    'Near Standard': '#D6DF28',
    'Below Standard': '#F79768',
}

export default function () {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const challenge_codes = useSelector(state => state.challenge_codes);
    const [practice_code, setPracticeCode] = useState('ccmps');
    const [class_codes, setClassCodes] = useState('');
    const [with_bonus, setWithBonus] = useState(false);
    const [status, setStatus] = useState('finished');
    const [intervals, setIntervals] = useState(4);
    const [challenges, setChallenges] = useState([]);
    const [selected_challenge_versions, setSelectedChallengeVersions] = useState([]);
    const [district_id, setDistrictId] = useState(null);
    const [selected_schools, setSelectedSchools] = useState([]);
    const [selected_users, setSelectedUsers] = useState([]);
    const [mode, setMode] = useState('achievement');
    const [source, setSource] = useState('manual');
    const [after, setAfter] = useState(null);
    const [before, setBefore] = useState(null);
    const action = function () {
        if (!practice_code) {
            dispatch(ErrorMessageAction('Please select a Practice'));
            return;
        }
        if (!class_codes && challenges.length <= 0 && selected_challenge_versions.length <= 0 && selected_schools.length <= 0 && !district_id && selected_users.length <= 0) {
            dispatch(ErrorMessageAction('Please provide some Class Codes, select some Challenge Codes, Challenge Versions, a District, Schools or Teachers'));
            return;
        }
        let options = {practice_code, with_bonus: with_bonus ? '1' : '0', intervals, as_array: 1, mode, source};
        if (status) {
            options.status = status;
        }
        if (class_codes) {
            options.class_codes = class_codes;
        }
        if (challenges.length > 0 && selected_challenge_versions.length <= 0) {
            options.challenge_codes = challenges.join(',');
        }
        if (selected_challenge_versions.length > 0) {
            options.challenge_version_ids = selected_challenge_versions.join(',');
        }
        if (selected_schools.length > 0) {
            options.school_ids = selected_schools.map(school => school.id).join(',');
        }
        if (after) {
            options.after = new Date(after * 1000).toISOString().substring(0, 10);
        }
        if (before) {
            options.before = new Date(before * 1000).toISOString().substring(0, 10);
        }
        if (district_id) {
            options.district_id = district_id;
        }
        if (selected_users.length) {
            options.user_ids = selected_users.map(user => user.id).join(',');
        }
        dispatch(LoadImpactStudyAction(options));
    }

    useEffect(() => {
        dispatch(LoadPracticesAction());
        dispatch(LoadChallengeCodesAction({
            'selectors[0]': 'latest_challenge_version_name',
            limit: 0
        }));
        dispatch(LoadChallengeVersionsAction({
            limit: 0,
            'filters[unversioned][0]': filterConstants.EXPRESSION_TRUE
        }));
    }, []);
    useEffect(() => {
        setSelectedChallengeVersions([]);
        let options = {
            'filters[unversioned][0]': filterConstants.EXPRESSION_TRUE,
            limit: 0
        }
        if (challenges.length) {
            const challenge_code_ids = challenge_codes.filter(challenge_code => challenges.includes(challenge_code.code)).map(challenge_code => challenge_code.id);
            options['filters[challenge_code_id][0]'] = filterConstants.EXPRESSION_IN + '=' + challenge_code_ids.join(',');
        }
        dispatch(LoadChallengeVersionsAction(options));
    }, [challenges]);

    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_REQUEST_STATISTICS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <div id='impact-study-page'>
            <h1>Impact Study</h1>
            <Filters
                action={action}
                practice_code={practice_code}
                setPracticeCode={setPracticeCode}
                class_codes={class_codes}
                setClassCodes={setClassCodes}
                with_bonus={with_bonus}
                setWithBonus={setWithBonus}
                status={status}
                setStatus={setStatus}
                intervals={intervals}
                setIntervals={setIntervals}
                selected_challenges={challenges}
                setSelectedChallenges={setChallenges}
                selected_challenge_versions={selected_challenge_versions}
                setSelectedChallengeVersions={setSelectedChallengeVersions}
                selected_schools={selected_schools}
                setSelectedSchools={setSelectedSchools}
                mode={mode}
                setMode={setMode}
                after={after}
                setAfter={setAfter}
                before={before}
                setBefore={setBefore}
                district_id={district_id}
                setDistrictId={setDistrictId}
                selected_users={selected_users}
                setSelectedUsers={setSelectedUsers}
                source={source}
                setSource={setSource}
            />
            <div className='section'>
                <MPGraph/>
                <div className='container'>
                    <MPData/>
                </div>
            </div>
            <div className='section'>
                <CASSPGraph/>
                <div className='container'>
                    <CASSPData/>
                </div>
            </div>
        </div>
    );
}
