import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";
import {InvalidateAllProjectsCacheAction} from "../../../actions/project_actions";


export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_PROJECTS])) {
        return (
            <button
                className='button warning'
                onClick={() => {
                    dispatch(InvalidateAllProjectsCacheAction());
                }}
            >
                Invalidate All projects cache
            </button>
        );
    }

    return null;
}
