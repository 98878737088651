import {
    SET_LIMIT_POSTFIX,
    SET_LIMIT_PREFIX,
    SET_CURRENT_POSTFIX,
    SET_CURRENT_PREFIX
} from "mindsets-js-sdk";

import {
    LOADED_STUDENT_COMPLETION,
    LOADED_STUDENT_COMPLETION_TOTAL,
    LOADED_STUDENT_COMPLETION_PAGES
} from "../actions/student_completion_actions";

const searchParams = new URLSearchParams(location.search);

const student_completion = (payload = [], action) => {
    switch (action.type) {
        case LOADED_STUDENT_COMPLETION:
            return action.payload;
        default:
            return payload;
    }
};
const student_completion_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case SET_CURRENT_PREFIX + 'student_completion' + SET_CURRENT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const student_completion_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_STUDENT_COMPLETION_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const student_completion_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_STUDENT_COMPLETION_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const student_completion_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case SET_LIMIT_PREFIX + 'student_completion' + SET_LIMIT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};

export default {
    student_completion,
    student_completion_current,
    student_completion_total,
    student_completion_pages,
    student_completion_limit
}
