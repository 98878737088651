import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import {SetQuestionPracticeSkillsUpdatedAction} from "../../actions/question_actions";

const Points = function ({question_practice_skill_updated}) {
    const dispatch = useDispatch();
    const selected_practice_id = useSelector(state => state.selected_practice_id);
    const question_practice_skills_updated = useSelector(state => state.question_practice_skills_updated);
    const getUniqueDummyRubricPointId = function () {
        const ids = question_practice_skill_updated.points.map(point => point.id);

        let id = -1;
        do {
            id--;
        } while (ids.includes(id));

        return id;
    };

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <div>Assign points</div>
                <button
                    className='button'
                    onClick={event => {
                        event.preventDefault();
                        const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                        question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id)].points.push({
                            id: getUniqueDummyRubricPointId(),
                            point: question_practice_skill_updated.points.length,
                            description: '',
                            example_responses: null
                        });
                        dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                    }}
                >+ Add point
                </button>
            </div>
            <div className='mt-2'>
                {question_practice_skill_updated.points.map((point, pointIndex) => {
                    return (
                        <div key={'point_' + point.id}>
                            <div className='d-flex flex-row align-items-center flex-gap-5'>
                                <h3><label className="col-form-label">{point.point}</label></h3>
                                <div className='d-flex flex-1 flex-gap-5'>
                                    <textarea
                                        className="form-control flex-1"
                                        value={point.description}
                                        onChange={event => {
                                            const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id)].points[pointIndex].description = event.target.value;
                                            dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                        }}
                                        placeholder="Description"
                                    />
                                    <div className='bordered flex-2'>
                                        <div>
                                            <button
                                                className='button small'
                                                onClick={event => {
                                                    event.preventDefault();
                                                    const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                                    const index = question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id);
                                                    const point = JSON.parse(JSON.stringify(question_practice_skills_updated_x[index].points[pointIndex]));
                                                    if (!Array.isArray(point.example_responses)) {
                                                        point.example_responses = [];
                                                    }
                                                    point.example_responses.push('');
                                                    question_practice_skills_updated_x[index].points[pointIndex] = point;
                                                    dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                                }}
                                            >+ Add Example
                                            </button>
                                        </div>
                                        <div>
                                            {Array.isArray(point.example_responses) && point.example_responses.length ? point.example_responses.map((example, exampleIndex) => {
                                                return (
                                                    <div key={exampleIndex} className='d-flex flex-gap-5'>
                                                        <input
                                                            className='form-control'
                                                            value={example ? example : ''}
                                                            onChange={event => {
                                                                const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                                                const index = question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id);
                                                                question_practice_skills_updated_x[index].points[pointIndex].example_responses[exampleIndex] = event.target.value;
                                                                dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                                            }}/>
                                                        <button
                                                            className='button red'
                                                            onClick={event => {
                                                                event.preventDefault();
                                                                const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                                                const skillIndex = question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id);
                                                                question_practice_skills_updated_x[skillIndex].points[pointIndex].example_responses.splice(exampleIndex, 1);
                                                                if (question_practice_skills_updated_x[skillIndex].points[pointIndex].example_responses.length <= 0) {
                                                                    question_practice_skills_updated_x[skillIndex].points[pointIndex].example_responses = null;
                                                                }
                                                                dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                                            }}
                                                        >
                                                            <i className='fas fa-trash'/>
                                                        </button>
                                                    </div>
                                                );
                                            }) : null}
                                        </div>
                                    </div>
                                </div>
                                {question_practice_skill_updated.points.length - 1 === pointIndex ? (
                                    <button
                                        className='button red'
                                        onClick={event => {
                                            event.preventDefault();
                                            const question_practice_skills_updated_x = JSON.parse(JSON.stringify(question_practice_skills_updated));
                                            question_practice_skills_updated_x[question_practice_skills_updated_x.findIndex(r => r.question_code === question_practice_skill_updated.question_code && r.practice_id === selected_practice_id)].points.splice(pointIndex, 1);
                                            dispatch(SetQuestionPracticeSkillsUpdatedAction(question_practice_skills_updated_x));
                                        }}
                                    >
                                        <i className='fas fa-trash'/>
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

Points.propTypes = {
    question_practice_skill_updated: PropTypes.shape({
        id: PropTypes.number,
        points: PropTypes.array
    }).isRequired
}

export default Points;
