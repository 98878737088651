import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import Card from "./ChallengeContent/Card";
import RowPropertyBar from "./ChallengeContent/RowPropertyBar";
import ComponentPropertyBar from "./ChallengeContent/ComponentPropertyBar";
import {SetChallengeBuilderBuildModeAction} from "../../../actions/challenge_actions";
import StructureBar from "./StructureBar";
import ComponentLibrary from "./ComponentLibrary";

export default function () {
    const dispatch = useDispatch();

    const build_mode = useSelector(state => state.challenge_builder_build_mode);
    const index = useSelector(state => state.challenge_builder_index);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);

    function renderBuildModes() {
        return (
            <div className='button-set'>
                <button className={'button first' + (build_mode === 'skeleton' ? ' inactive' : '')} onClick={() => {
                    dispatch(SetChallengeBuilderBuildModeAction('skeleton'));
                }}>Skeleton
                </button>
                <button className={'button last' + (build_mode === 'refine' ? ' inactive' : '')} onClick={() => {
                    dispatch(SetChallengeBuilderBuildModeAction('refine'));
                }}>Refine
                </button>
            </div>
        );
    }

    function renderSkeletonMode() {
        if (build_mode === 'skeleton') {
            return (
                <ComponentLibrary/>
            );
        }

        return null;
    }

    function renderRefineMode() {
        if (build_mode === 'refine') {
            return (
                <Card/>
            );
        }

        return null;
    }

    if (challenge_pages.length && index < challenge_pages.length) {
        return (
            <>
                {renderBuildModes()}
                <div className='d-flex flex-row flex-gap-5'>
                    <StructureBar/>
                    {renderSkeletonMode()}
                    {renderRefineMode()}
                    <RowPropertyBar/>
                    <ComponentPropertyBar/>
                </div>
            </>
        );
    } else if (challenge_pages.length <= 0) {
        return (
            <h4 className='text-center text-warning'>Add your first page by clicking the plus button above</h4>
        );
    } else {
        return (
            <h4 className='text-center'>Please select a page above</h4>
        );
    }
}
