import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    HTMLContent,
    contentUtils,
    UpdateResponsePointAction,
    LoadedResponsePointsAction,
    ResponseContent
} from "mindsets-js-sdk";
import VerifyButtons from "./VerifyButtons";

export default function ({getResponsePoints}) {
    const dispatch = useDispatch();
    const responses = useSelector(state => state.responses);
    const question_practice_skills = useSelector(state => state.question_practice_skills);
    const questions = useSelector(state => state.questions);
    const practice_skills = useSelector(state => state.practice_skills);
    const response_points = useSelector(state => state.response_points);
    let response_points_new = JSON.parse(JSON.stringify(response_points));

    function updateScore(response_point, response_point_index, is_correct) {
        let response_point_new = JSON.parse(JSON.stringify(response_point));
        dispatch(UpdateResponsePointAction(response_point_new.id, {is_correct})).then(result => {
            if (result.hasOwnProperty('result') && result.result === 'success') {
                getResponsePoints();
            }
        });
        if (response_point_new.is_correct === is_correct) {
            response_point_new.is_correct = null;
        } else {
            response_point_new.is_correct = is_correct;
        }
        response_points_new[response_point_index] = response_point_new;
        dispatch(LoadedResponsePointsAction(response_points_new));
    }

    if (!questions.length) {
        return null;
    }

    console.log('response_points: ', response_points)

    return (
        <div className="table-responsive mb-4">
            <table className='table table-bordered table-sm'>
                <thead>
                <tr>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Score</th>
                    <th>QA</th>
                </tr>
                </thead>
                <tbody>
                {response_points.map((response_point, response_point_index) => {
                    const question = questions.find(question => question.id === response_point.question_id);
                    if (!question) {
                        return null;
                    }
                    const question_practice_skill = question_practice_skills.find(qps => qps.question_code === question.code);
                    let practice_skill = undefined;
                    let dok = undefined;
                    if (question_practice_skill) {
                        practice_skill = practice_skills.find(practice_skill => practice_skill.id === question_practice_skill.practice_skill_id);
                        dok = question_practice_skill.dok;
                    }

                    let response = null;
                    if (response_point && response_point.response) {
                        response = response_point.response;
                    } else {
                        if (!question_practice_skill) {
                            const stream_responses = JSON.parse(JSON.stringify(responses)).filter(response => response.stream_id === response_point.stream_id && response.question_id === response_point.question_id).reverse();
                            if (stream_responses.length > 0) {
                                response = stream_responses[0];
                            }
                        }
                    }

                    return (
                        <tr key={response_point.id}>
                            <td>
                                <HTMLContent
                                    html_str={contentUtils.processStringContent((question.number ? 'Q' + question.number : '') + (question.is_bonus ? '(BONUS)' : '') + ' ' + question.title, {convert_spelling: false})}/>
                                <div>Max
                                    points: {question_practice_skill ? question_practice_skill.points.length - 1 : 'Not defined'}</div>
                                <div>Practice
                                    Category: {practice_skill ? (practice_skill.code + ' ' + practice_skill.description) : 'Not defined'}</div>
                                <HTMLContent
                                    html_str={contentUtils.processStringContent('Example solution: ' + (question_practice_skill ? question_practice_skill.example_solution : 'Not defined'), {convert_spelling: false})}/>
                                <div>DOK: {dok ? dok : 'Not defined'}</div>
                            </td>
                            <td>
                                <ResponseContent
                                    question={question}
                                    response={response && response.data ? response.data.answer: null}/>
                            </td>
                            <td>
                                {response_point.points}
                            </td>
                            <td>
                                <VerifyButtons
                                    response_point={response_point}
                                    updateScore={is_correct => updateScore(response_point, response_point_index, is_correct)}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}
