import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowComponentsComponentDataUnitValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'string',
            empty: true,
            mandatory: false
        };
    }
}
