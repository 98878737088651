import IntroCard from './Card/IntroCard'
import WelcomeCard from './Card/WelcomeCard'
import PollCard from './Card/PollCard'
import WinnerCard from './Card/WinnerCard'
import InstructionCard from './Card/InstructionCard'
import QuestionCard from './Card/QuestionCard'
import CompleteCard from './Card/CompleteCard'
import UnlockCard from './Card/UnlockCard'
import FinalCard from './Card/FinalCard'


export const getCardClass = (page) => {
    switch (page.type) {
        case 'intro':
            return IntroCard;
        case 'welcome':
            return WelcomeCard;
        case 'poll':
            return PollCard;
        case 'winner':
            return WinnerCard;
        case 'instruction':
            return InstructionCard;
        case 'question':
            return QuestionCard;
        case 'complete':
            return CompleteCard;
        case 'unlock':
            return UnlockCard;
        case 'final':
            return FinalCard;
    }

    throw 'Invalid stream entity';
}

export const isBonus = (page) => {
    const {data = {}} = page;
    const {is_bonus = false} = data;
    return page.type === 'unlock' || is_bonus || page.type === 'final';
}

export const parseStyleString = string => {
    let object = {};
    string.split("\n").map(line => {
        line = line.trim();
        if (line.endsWith(';')) {
            line = line.substring(0, line.length - 1);
        }
        const parts = line.split(':');
        if (parts.length === 2) {
            object[parts[0].trim()] = parts[1].trim();
        }
    });

    return object;
}

export const objectToStyleString = object => {
    return Object.keys(object).map(key => {
        return key + ': ' + object[key] + ';';
    }).join("\n");
}
