import React from 'react';
import {Link} from "react-router-dom";
import {
    ListPage,
    filterConstants,
    accessConstants,
    countriesConstants,
    LoadCurriculaAction
} from "mindsets-js-sdk";

import Table from "./components/Table";
import SyncButton from "./components/SyncButton";

export default function () {
    return (
        <>
            <h1>Curricula</h1>
            <ListPage
                action={LoadCurriculaAction}
                filter_columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'code',
                        name: 'Code',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'country_code',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL],
                        values: Object.keys(countriesConstants.COUNTRIES).map(country_code => {
                            return {
                                label: countriesConstants.COUNTRIES[country_code],
                                value: country_code
                            }
                        })
                    },
                    {
                        key: 'is_enabled',
                        name: 'Enabled',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    },
                    {
                        key: 'is_code_shown',
                        name: 'Code Shown',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'code',
                        name: 'Code'
                    }
                ]}
                entity={'curricula'}
                create_button={(
                    <div className="d-flex justify-content-end flex-gap-5">
                        <Link to="/curricula/new" className="button">+ New Curriculum</Link>
                        <SyncButton/>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_CURRICULUMS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
