import React from "react";
import PropTypes from "prop-types";
import {datesConstants} from "mindsets-js-sdk";

const SchoolSubscriptionPeriod = function ({school}) {
    if (!!school.subscriptions && school.subscriptions.length > 0) {
        const subscription = school.subscriptions[school.subscriptions.length - 1];
        const from = new Date(subscription.starts_at * 1000);
        const to = new Date(subscription.ends_at * 1000);
        if (!!from && !!to) {
            const now = new Date();

            return (
                <div>
                    <div className="text-center">
                        <i className={"fas " + (now >= from && now <= to ? 'fa-check text-success' : 'fa-times text-danger')}/>
                    </div>
                    <div className="text-right no-wrap">From: {from.toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                    <div className="text-right no-wrap">Until: {to.toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</div>
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

SchoolSubscriptionPeriod.propTypes = {
    school: PropTypes.shape({
        id: PropTypes.number.isRequired,
        subscriptions: PropTypes.arrayOf(PropTypes.shape({
            starts_at: PropTypes.number.isRequired,
            ends_at: PropTypes.number.isRequired
        }))
    }).isRequired
};

export default SchoolSubscriptionPeriod;
