import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PollBlockOption from './PollBlockOption';


class PollBlockOptions extends Component {
    renderOptions() {
        return this.props.poll.poll_options.map((o, i) => {
            return (
                <div className="" key={i}>
                    <PollBlockOption
                        poll_option={o}
                        is_selected={false}
                        updateOption={option => {
                            let data = this.props.poll;
                            data.poll_options[i] = option;
                            this.props.updateComponentData(data);
                        }}
                    />
                </div>
            )
        })
    }

    renderFilling() {
        const n = Math.ceil(this.props.poll.poll_options.length / 12) * 12
        const array_to_fill = Array(n - this.props.poll.poll_options.length)
        return _.map(array_to_fill, (v, k) => {
            return (
                <div className="poll-block-options-filling" key={`filling-${k}`}>
                    <div className="poll-option">
                        <div className="poll-option-image-container">
                            <img src={_.get(this.props.poll.poll_options, '0.option')} alt=''/>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className="poll-block-options">
                <div className="d-flex justify-content-between flex-wrap flex-box-15-15">
                    {this.renderOptions()}
                    {this.renderFilling()}
                </div>
            </div>
        )
    }
}

PollBlockOptions.propTypes = {
    poll: PropTypes.object.isRequired,
    updateComponentData: PropTypes.func.isRequired
}

export default PollBlockOptions
