import React from 'react';

import BaseAnswer from '../BaseAnswer';
import MatchingInstruction from './MatchingInstruction';
import MatchingItem from './MatchingItem';
import MatchingLine from './MatchingLine';

const MATCH_COLORS = [
    undefined, '#731E67', '#FF5907', '#2051A3', '#008C12', '#FD4596', '#FCB729', '#02A299', '#4F2E1B'
]


export default class Matching extends BaseAnswer {
    defaultAnswer() {
        return {
            text: '',
            answer_selections: []
        }
    }

    isValid() {
        return true
    }

    getMatchColor = (group) => (id) => {
        const match_item = _.find([], (a_s) => {
            const [id_left, id_right] = a_s
            if (group === 'left') {
                return id_left === id
            } else if (group === 'right') {
                return id_right === id
            } else {
                return false
            }
        })
        const key = _.get(match_item, 0)
        if (!key) {
            return undefined
        } else {
            return _.get(MATCH_COLORS, key)
        }
    }

    onClickItem = (group) => (id) => () => {
    }

    // This function is a solution (temporary) to the problem
    // where the lines are drawn before the images are fully loaded
    // and the lines become misplaced.
    // This function re-renders the component when the count of ready items
    // === to the total number of items. This happens only one time.
    itemReady = () => {
        if (this.ready_items === undefined) {
            this.ready_items = 0
        }
        this.ready_items++
    }

    renderLeft() {
        const items_left = this.props.component_data.data.items_left;
        const header_name = items_left.header_name;
        const all_matched_left = [].map((a_s) => a_s[0]);
        const render_items_left = items_left.items.map((item_data, index) => {
            return (
                <MatchingItem
                    key={index}
                    item_data={item_data}
                    item_group="left"
                    is_active={false}
                    is_matched={all_matched_left.includes(item_data.id)}
                    match_color={this.getMatchColor('left')(item_data.id)}
                    onClick={this.onClickItem('left')(item_data.id)}
                    itemReady={this.itemReady}
                />
            )
        });

        return (
            <div className="cc-matching-group cc-matching-group-left d-flex flex-column align-items-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="d-flex flex-auto flex-column flex-evenly">
                    {render_items_left}
                </div>
            </div>
        )
    }

    renderRight() {
        const items_right = this.props.component_data.data.items_right;
        const header_name = items_right.header_name;
        const all_matched_right = [].map((a_s) => a_s[1]);
        const render_items_right = items_right.items.map((item_data, index) => {
            return (
                <MatchingItem
                    key={index}
                    item_data={item_data}
                    item_group="right"
                    is_active={false}
                    is_matched={all_matched_right.includes(item_data.id)}
                    match_color={this.getMatchColor('right')(item_data.id)}
                    onClick={this.onClickItem('right')(item_data.id)}
                    itemReady={this.itemReady}
                />
            )
        });

        return (
            <div className="cc-matching-group cc-matching-group-right d-flex flex-column align-items-center">
                <div className="cc-matching-group-header">
                    {header_name}
                </div>
                <div className="d-flex flex-auto flex-column flex-evenly">
                    {render_items_right}
                </div>
            </div>
        );
    }

    renderLines() {
        const render_lines = [].map((pair) => {
            const [id_left, id_right] = pair
            return (
                <MatchingLine
                    key={id_left}
                    start_id={`matching-left-${id_left}`}
                    end_id={`matching-right-${id_right}`}
                    match_color={this.getMatchColor('left')(id_left)}
                />
            )
        })

        return (
            <div className="cc-matching-lines">
                {render_lines}
            </div>
        )
    }

    renderClear() {
        return (
            <div className="cc-matching-clear d-flex justify-content-center">
                <button className="btn btn-on-white">
                    {`Clear selections`}
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-matching">
                <MatchingInstruction
                    component_data={this.props.component_data}
                />

                {this.renderClear()}

                <div className="d-flex justify-content-between">
                    {this.renderLeft()}

                    {this.renderRight()}
                </div>

                {this.renderLines()}
            </div>
        )
    }
}
