import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {contentUtils, HTMLContent} from "mindsets-js-sdk";

const AnswerOption = memo(function ({option, option_index, answer_group, extra_style}) {
    function getDraggableStyle() {
        const color_light = _.get(answer_group, 'color_light');
        const color_dark = _.get(answer_group, 'color_dark');
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            minHeight: "50px",
            minWidth: "50px",
            background: color_light,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color_dark,
            borderRadius: "4px",
            ..._.get(answer_group, 'draggable_style'),
            ...extra_style
        }
    }

    return (
        <div style={getDraggableStyle()}>
            <HTMLContent
                html_str={contentUtils.processStringContent(option.html, {
                    keep_html: true,
                    convert_math: true,
                    convert_spelling: false,
                })}/>
        </div>
    );
});

AnswerOption.propTypes = {
    answer_group: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    option_index: PropTypes.number.isRequired,
    extra_style: PropTypes.object
}

export default AnswerOption
