import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {ResponseContent} from "mindsets-js-sdk";

function Row({item, row_number, with_scores}) {
    const questions = useSelector(state => state.questions);
    const question = questions.find(question => question.id === item.question_id);

    function renderPoints() {
        if (with_scores) {
            return (
                <td>{item.points}</td>
            );
        }

        return null;
    }

    if (question) {
        return (
            <tr>
                <td>{row_number}</td>
                <td>{item.id}</td>
                <td>{item.stream_id}</td>
                <td style={{wordBreak: 'break-word'}}>
                    <ResponseContent
                        question={question}
                        response={item.data.answer}/>
                </td>
                <td>{item.is_active ? <i className="fas fa-check"/> : null}</td>
                {renderPoints()}
            </tr>
        );
    }

    return null;
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        question_id: PropTypes.number.isRequired,
        stream_id: PropTypes.number.isRequired,
        points: PropTypes.number,
        data: PropTypes.shape({
            answer: PropTypes.any.isRequired
        }).isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
