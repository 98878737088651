import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {accessConstants, authUtils, PauseLessonAction, ReloadListAction} from "mindsets-js-sdk";

const PauseMenuItem = function ({id, status}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS]) && 'started' === status) {
        return (
            <button className='button mx-2 my-1' onClick={event => {
                event.preventDefault();
                dispatch(PauseLessonAction(id)).then(() => dispatch(ReloadListAction(true, 'lessons')));
            }}>Pause</button>
        );
    }

    return null;
}

PauseMenuItem.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
}

export default PauseMenuItem;
