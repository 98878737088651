import React, {Component} from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import {ParseChallengeScriptAction} from "../../actions/challenge_actions";
import RecursiveValidator from './validators/RecursiveValidator';

class Validator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json: '',
        };
        this.parseScriptDebounced = _.debounce(() => this.props.dispatch(ParseChallengeScriptAction(this.state.json)), 500);
    }

    onTextChange(json) {
        this.setState({json}, () => this.parseScriptDebounced());
    }

    renderValidationResult() {
        if (this.props.challengeScript.hasOwnProperty('validationResult') && !this.props.challengeScript.validationResult.isValid) {
            return (
                <span className="bg-warning"
                      key='errors'>{this.props.challengeScript.validationResult.messages.join(', ')}</span>
            );
        }

        return '';
    }

    renderChallengeDataValidationResult() {
        if (this.props.challengeScript.hasOwnProperty('challengeData') && this.props.challengeScript.challengeData.hasOwnProperty('validationResult') && !this.props.challengeScript.challengeData.validationResult.isValid) {
            return (
                <div key='ChallengeDataValidationResult'>
                    {}
                    {this.props.challengeScript.challengeData.validationResult.messages.join(', ')}
                </div>
            );
        }

        return '';
    }

    renderChallengeDataResult() {
        if (this.props.challengeScript.hasOwnProperty('challengeData') && this.props.challengeScript.challengeData.hasOwnProperty('validationResult') && this.props.challengeScript.challengeData.validationResult.isValid) {
            return (
                <RecursiveValidator item={this.props.challengeScript.challengeData} key='RecursiveValidator'/>
            );
        }

        return '';
    }

    renderResult() {
        if (this.props.challengeScript.hasOwnProperty('validationResult') && this.props.challengeScript.validationResult.isValid) {
            return [
                this.renderChallengeDataValidationResult(),
                this.renderChallengeDataResult()
            ];
        }

        return '';
    }

    render() {
        return (
            <div className='challenge-validator-page section'>
                <h2 className="display-4">Your challenge.json Validator</h2>
                <hr className="my-4"/>
                <p>Copy and paste your challenge.json configuration below to validate it.</p>
                <div className="row">
                    <div className="col-6">
                        <div className="json-field">
                            <label htmlFor="json">Test your challenge.json</label>
                            <textarea
                                className="form-control"
                                id="json"
                                value={this.state.json}
                                rows={20}
                                onChange={event => {
                                    event.preventDefault();
                                    this.onTextChange(event.target.value);
                                }}/>
                        </div>
                    </div>
                    <div className="col-6">
                        <label>The Result of validation</label>
                        <div className="validation-result">
                            {this.renderValidationResult()}
                            {this.renderResult()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Validator.propTypes = {
    dispatch: PropTypes.func.isRequired,
    challengeScript: PropTypes.object.isRequired
};

export default () => <Validator dispatch={useDispatch()}
                                challengeScript={useSelector(state => state.challenge_script)}/>;
