import React from "react";
import {useSelector} from "react-redux";

export default function ({question_id}) {
    const questions = useSelector(state => state.questions);
    const question = questions.find(question => question.id === question_id);
    if (question) {
        return (
            <div className='section'>
                <div>Q{question.number} {question.title}</div>
                <div>{question.answer_type}</div>
            </div>
        );
    }

    return null;
}
