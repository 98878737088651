import React from 'react';

import BaseComponentProperty from "../index";

export default class Link extends BaseComponentProperty {
    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="image">Image</label>
                    <textarea
                        id="image"
                        className='form-control'
                        value={this.props.component.data.image ? this.props.component.data.image : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.image = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="link">Link</label>
                    <textarea
                        id="link"
                        className='form-control'
                        value={this.props.component.data.link ? this.props.component.data.link : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.link = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="title">Title</label>
                    <input
                        type='text'
                        id="title"
                        className='form-control'
                        value={this.props.component.data.title ? this.props.component.data.title : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.title = event.target.value;
                            this.props.onChange('data', data);
                        }}
                    />
                </div>
            </div>
        );
    }
}
