import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    accessConstants,
    authUtils,
    ErrorMessageAction,
    Checkbox,
    LoadLessonAction,
    SetLessonAction,
    UpdateLessonAction,
    LoadingAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";

export default function () {
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const lesson = useSelector(state => state.lesson);
    useEffect(() => {
        dispatch(LoadLessonAction(params.lesson_id));

        return () => dispatch(SetLessonAction({}));
    }, []);
    if (!me || !lesson) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }
    const [updated, setUpdated] = useState(false);
    const validate = function () {
        let errors = 0;

        if (!lesson.name || lesson.name === '') {
            dispatch(ErrorMessageAction('Error! Please specify the name.'));
            errors++;
        }

        if (!lesson.code || lesson.code === '') {
            dispatch(ErrorMessageAction('Error! Please specify the code.'));
            errors++;
        } else if (lesson.code.length > 6) {
            dispatch(ErrorMessageAction('Error! Code must not have more that 6 characters.'));
            errors++;
        } else if (!/[a-zA-Z]{4,5}[1-9]{1,2}/g.test(lesson.code)) {
            dispatch(ErrorMessageAction('Error! Invalid Code format'));
            errors++;
        }

        if (!lesson.mode || lesson.mode === '') {
            dispatch(ErrorMessageAction('Error! Please select a mode.'));
            errors++;
        } else if (!['student', 'teacher'].includes(lesson.mode)) {
            dispatch(ErrorMessageAction('Error! Mode is invalid.'));
            errors++;
        }

        return errors === 0;
    }

    return (
        <div className='container roles-page section'>
            <form onSubmit={event => {
                event.preventDefault();
                if (validate()) {
                    dispatch(UpdateLessonAction(params.lesson_id, lesson)).then(() => navigate(-1)).catch(error => {
                        dispatch(LoadingAction(false));
                        dispatch(ErrorMessageAction(error));
                        console.error(error);
                    });

                }
            }} autoComplete="off" id="lesson-form">
                <h1>Update Class {lesson.code ? lesson.code.toUpperCase() : ''}</h1>

                <div className="row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={lesson.name ? lesson.name : ''}
                            onChange={event => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.name = event.target.value;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="title" className="col-sm-2 col-form-label">Class Code</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="code"
                            placeholder="Class Code"
                            value={lesson.code ? lesson.code : ''}
                            onChange={event => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.code = event.target.value;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}/>
                        <small className="form-text text-muted">6 chars max. 4-5 letters and 1-2 digits</small>
                    </div>
                </div>

                <div className="row">
                    <label htmlFor="mode" className="col-sm-2 col-form-label">Teaching mode</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            id="mode"
                            value={lesson.mode ? lesson.mode : ''}
                            onChange={event => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.mode = event.target.value;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        >
                            <option value='student'>Student-paced</option>
                            <option value='teacher'>Teacher-driven</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3">
                        <Checkbox
                            checked={undefined !== lesson.is_answer_keys_shown ? lesson.is_answer_keys_shown : false}
                            label='Is Answer Keys Shown?'
                            toggle={() => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.is_answer_keys_shown = !lesson_new.is_answer_keys_shown;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Checkbox
                            checked={undefined !== lesson.is_open ? lesson.is_open : false}
                            label='Is Open?'
                            toggle={() => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.is_open = !lesson_new.is_open;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Checkbox
                            checked={undefined !== lesson.is_hidden ? lesson.is_hidden : false}
                            label='Is Hidden?'
                            toggle={() => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.is_hidden = !lesson_new.is_hidden;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Checkbox
                            checked={undefined !== lesson.is_done ? lesson.is_done : false}
                            label='Is Completed?'
                            toggle={() => {
                                let lesson_new = JSON.parse(JSON.stringify(lesson));
                                lesson_new.is_done = !lesson_new.is_done;
                                dispatch(SetLessonAction(lesson_new));
                                if (!updated) {
                                    setUpdated(true);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="d-flex flex-gap-10 flex-wrap">
                    {['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'Elementary', 'Middle', 'High School', 'All'].map(grade_level => {
                        return (
                            <Checkbox
                                key={grade_level}
                                checked={Array.isArray(lesson.grade_level) && lesson.grade_level.includes(grade_level)}
                                label={'Grade ' + grade_level}
                                toggle={() => {
                                    let grade_levels = JSON.parse(JSON.stringify(lesson.grade_level));
                                    if (Array.isArray(grade_levels)) {
                                        if (grade_levels.includes(grade_level)) {
                                            grade_levels.splice(grade_levels.findIndex(level => level === grade_level), 1);
                                        } else {
                                            grade_levels.push(grade_level);
                                        }
                                    } else {
                                        grade_levels = [grade_level];
                                    }
                                    if (grade_levels.length === 0) {
                                        grade_levels = null;
                                    } else {
                                        grade_levels.sort((a, b) => a - b);
                                    }
                                    let lesson_new = JSON.parse(JSON.stringify(lesson));
                                    lesson_new.grade_level = grade_levels;
                                    dispatch(SetLessonAction(lesson_new));
                                    if (!updated) {
                                        setUpdated(true);
                                    }
                                }}
                            />
                        );
                    })}
                </div>

                <div className='mt-5 d-flex flex-gap-5'>
                    <button type="submit" className="button" disabled={!updated}>Submit</button>
                    <button className="button default" onClick={event => {
                        event.preventDefault();
                        navigate(-1);
                    }}>Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
