import React, {useState} from 'react';
import PropTypes from "prop-types";

import BatchesTable from "./BatchesTable";
import CreateBatchForm from "./CreateBatchForm";
import UpdateBatchForm from "./UpdateBatchForm";
import BulkActionsButton from "./BulkActionsButton";

function Batches({action, update}) {
    const [create_form_shown, setCreateFormShown] = useState(false);
    const [batch_id, setBatchId] = useState(null);
    const [selected_batches, setSelectedBatches] = useState([]);

    if (update) {
        return null;
    }

    return (
        <div>
            <h3>Batches</h3>
            <div className='d-flex justify-content-between'>
                {create_form_shown === false && batch_id === null ?
                    <button className="button" onClick={() => setCreateFormShown(true)}>Create</button> : null}
                {create_form_shown === false && batch_id === null ?
                    <span className='badge bg-warning'>Attention!!! Numbers might not be correct until ALL the classes are scheduled for grading</span> : null}
                {create_form_shown === false && batch_id === null ?
                    <BulkActionsButton
                        disabled={selected_batches.length <= 0}
                        selected_batches={selected_batches}
                        setSelectedBatches={setSelectedBatches}
                        action={action}
                    /> : null}
            </div>
            {create_form_shown === true ? <CreateBatchForm setShown={setCreateFormShown} action={action}/> : null}
            {batch_id !== null ? <UpdateBatchForm action={action} batch_id={batch_id} setBatchId={setBatchId}/> : null}
            <BatchesTable
                form_shown={create_form_shown || batch_id !== null}
                setBatchId={setBatchId}
                action={action}
                selected_batches={selected_batches}
                setSelectedBatches={setSelectedBatches}
            />
        </div>
    );
}

Batches.propTypes = {
    action: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired
}

export default Batches;
