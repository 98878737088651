import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {accessConstants, authUtils} from "mindsets-js-sdk";

const ManageButton = function ({id}) {
    const me = useSelector(state => state.me);
    if (authUtils.iCan(me, [accessConstants.PERMISSION_CAN_MANAGE_USERS])) {
        return (
            <Link to={'/grading_providers/' + id} className='button'>Manage</Link>
        );
    }

    return null;
}

ManageButton.f = {
    id: PropTypes.number.isRequired
}

export default ManageButton;
