import React from 'react';
import {useSelector} from "react-redux";
import {datesConstants} from "mindsets-js-sdk";

export default function () {
    const contract = useSelector(state => state.contract);
    if (contract.status === 'approved') {
        return (
            <div>
                Approved
                by: {contract.approved_by.fname} {contract.approved_by.lname} on {new Date(contract.approved_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}
            </div>
        );
    } else {
        return (
            <div className='badge bg-info d-flex align-items-center'>{_.capitalize(contract.status)}</div>
        );
    }
}
