import React, {Component} from 'react';
import PropTypes from "prop-types";
import {ProgressBar, datesConstants} from "mindsets-js-sdk";

class Stream extends Component {
    getProgressIndex() {
        if (this.props.stream.index > this.getNumberCardsCompleted()) {
            return this.getNumberCardsCompleted();
        }

        return this.props.stream.index;
    }

    getNumberCardsCompleted() {
        return this.props.challenge_version ? this.props.challenge_version.index_complete : 0;
    }

    getCompletionRate() {
        if ('completion' === this.props.mode) {
            return this.getProgressIndex() + '/' + (this.getNumberCardsCompleted()) + '(' + Math.round(this.getProgressIndex() / this.getNumberCardsCompleted() * 100) + '%)';
        }

        return null;
    }

    prependZero(number) {
        if (number.toString().length === 1) {
            return '0' + number;
        }

        return number;
    }

    getChallengeTimeSpent() {
        if (this.props.times.challengeTimeSpent) {
            const duration = new Date(this.props.times.challengeTimeSpent * 1000);

            return [this.prependZero(duration.getUTCHours()), this.prependZero(duration.getUTCMinutes()), this.prependZero(duration.getUTCSeconds())].join(':');
        }

        return ' No data';
    }

    getBonusTimeSpent() {
        if (this.props.times.bonusTimeSpent) {
            const duration = new Date(this.props.times.bonusTimeSpent * 1000);

            return [this.prependZero(duration.getUTCHours()), this.prependZero(duration.getUTCMinutes()), this.prependZero(duration.getUTCSeconds())].join(':');
        }

        return ' No data';
    }

    getTotalTimeSpent() {
        if (this.props.times.totalTimeSpent) {
            const duration = new Date(this.props.times.totalTimeSpent * 1000);

            return [this.prependZero(duration.getUTCHours()), this.prependZero(duration.getUTCMinutes()), this.prependZero(duration.getUTCSeconds())].join(':');
        }

        return ' No data';
    }

    getAverageTimeSpent() {
        if (this.props.avgTimeSpent) {
            const duration = new Date(this.props.times.avgTimeSpent * 1000);

            return [this.prependZero(duration.getUTCHours()), this.prependZero(duration.getUTCMinutes()), this.prependZero(duration.getUTCMinutes())].join(':');
        }

        return ' No data';
    }

    renderCompletionRate() {
        if ('completion' === this.props.mode) {
            return (
                <td>
                    {this.getCompletionRate()}
                </td>
            );
        }

        return null;
    }

    renderCompletionProgress() {
        if ('completion' === this.props.mode) {
            return (
                <td>
                    <ProgressBar
                        index={this.props.stream.index}
                        no_bonus_cards={this.props.stream.no_cards - this.getNumberCardsCompleted()}
                        no_cards={this.props.stream.no_cards}
                    />
                </td>
            );
        }

        return null;
    }

    renderChallengeTimeSpent() {
        if ('time' === this.props.mode) {
            return (
                <td>
                    {this.getChallengeTimeSpent()}
                </td>
            );
        }

        return null;
    }

    renderBonusTimeSpent() {
        if ('time' === this.props.mode) {
            return (
                <td>
                    {this.getBonusTimeSpent()}
                </td>
            );
        }

        return null;
    }

    renderTotalTimeSpent() {
        if ('time' === this.props.mode) {
            return (
                <td>
                    {this.getTotalTimeSpent()}
                </td>
            );
        }

        return null;
    }

    renderAverageTimeSpent() {
        if ('time' === this.props.mode) {
            return (
                <td>
                    {this.getAverageTimeSpent()}
                </td>
            );
        }

        return null;
    }

    render() {
        return (
            <tr>
                <td>{this.props.stream.challenge_name} {this.props.stream.lesson_code}</td>
                <td>{this.props.stream.teacher_name}</td>
                <td>{new Date(this.props.stream.updated_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
                {this.renderCompletionRate()}
                {this.renderCompletionProgress()}
                {this.renderChallengeTimeSpent()}
                {this.renderBonusTimeSpent()}
                {this.renderTotalTimeSpent()}
                {this.renderAverageTimeSpent()}
            </tr>
        );
    }
}

Stream.propTypes = {
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lesson_id: PropTypes.number.isRequired,
        challenge_version_id: PropTypes.number.isRequired,
        challenge_name: PropTypes.string.isRequired,
        teacher_name: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        no_cards: PropTypes.number.isRequired,
        no_bonus_cards: PropTypes.number.isRequired,
        created_at: PropTypes.number.isRequired,
        updated_at: PropTypes.number.isRequired
    }).isRequired,
    challenge_version: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired,
        index_complete: PropTypes.number.isRequired,
        index_bonus_first: PropTypes.number.isRequired,
        index_final: PropTypes.number.isRequired,
        no_cards: PropTypes.number.isRequired
    }),
    mode: PropTypes.string.isRequired,
    times: PropTypes.shape({
        challengeTimeSpent: PropTypes.number,
        bonusTimeSpent: PropTypes.number,
        totalTimeSpent: PropTypes.number,
    }).isRequired,
    avgTimeSpent: PropTypes.number
};

export default Stream;
