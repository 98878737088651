import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AnswerOptions from './AnswerOptions';

class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'challenge-component-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100)
        this.state = {
            answer_option: _.get(this.props.existing_answer, 'data.answer.answer_option', '')
        }
    }

    isValid() {
        if (this.state.answer_option === 'paper') {
            return true
        } else if (this.state.answer_option === 'tool') {
            if (this.graphing_instance) {
                return !!this.graphing_instance.getObjectName(0)
            } else {
                return false
            }
        } else {
            return false
        }
    }

    isCorrect() {
        return true;
    }

    onSelectAnswerOption() {
        return (answer_option) => {
            this.setState({
                answer_option
            })
        }
    }

    renderOptionsContainer() {
        return (
            <AnswerOptions
                answer_option={this.state.answer_option}
                onSelect={this.onSelectAnswerOption()}
            />
        )
    }

    renderPaper() {
        return (
            <div style={{display: this.state.answer_option === 'paper' ? 'block' : 'none'}}>
                {`Great! Now start your graph on paper.`}
            </div>
        )
    }

    renderGraphingTool() {
        return (
            <div
                className="component-graphing-content-graph"
                style={{height: this.state.answer_option === 'tool' ? 'auto' : '0'}}
            >
                <div id={this.uniq_id}/>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-component-graphing" id={`component-${this.uniq_id}`}>
                {this.renderOptionsContainer()}

                <div className="component-graphing-content-container">
                    {this.renderPaper()}

                    {this.renderGraphingTool()}
                </div>
            </div>
        )
    }
}

Graphing.propTypes = {
    component_data: PropTypes.object.isRequired,
    existing_answer: PropTypes.object
}

export default Graphing
