import MindsetsJsSDK from "mindsets-js-sdk";

export const LOADED_ROLES = '[users] Loaded roles';
export const LOADED_CURRICULA = '[users] Loaded curricula';
export const LOADED_PERMISSIONS = '[users] Loaded permissions';
export const SET_USER_CURRICULA = '[user] Set user curricula';
export const SET_USER_ROLES = '[user] Set user roles';

export const LoadRolesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('roles', {limit: 0}))
        .then(response => dispatch(LoadedRolesAction(response.items)));
};
export const LoadedRolesAction = payload => ({
    type: LOADED_ROLES,
    payload
});

export const LoadCurriculaAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('curriculums', {limit: 0}))
        .then(response => dispatch(LoadedCurriculaAction(response.items)));
};
export const LoadedCurriculaAction = payload => ({
    type: LOADED_CURRICULA,
    payload
});

export const LoadPermissionsAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('permissions', {limit: 0}))
        .then(response => dispatch(LoadedPermissionsAction(response.items)));
};
export const LoadedPermissionsAction = payload => ({
    type: LOADED_PERMISSIONS,
    payload
});

export const LoadUserCurriculaAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('curriculums', {limit: 0}))
        .then(response => dispatch(SetUserCurriculaAction(response.items)));
};
export const SetUserCurriculaAction = payload => ({
    type: SET_USER_CURRICULA,
    payload
});

export const LoadUserRolesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('roles', {'selectors[0]': 'permissions'}))
        .then(response => dispatch(SetUserRolesAction(response.items)));
};
export const SetUserRolesAction = payload => ({
    type: SET_USER_ROLES,
    payload
});

export const InvalidateSessionAction = id => dispatch => {
    return Promise.resolve().then(() => MindsetsJsSDK().API.delete(`users/${id}`));
};
