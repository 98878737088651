import React from 'react';
import {ListPage, accessConstants, filterConstants, countriesConstants, LoadDistrictsAction} from "mindsets-js-sdk";

import Table from "./components/Table";
import CreateButton from "./components/CreateButton";

export default function () {
    return (
        <>
            <h1>School Districts</h1>
            <ListPage
                action={options => LoadDistrictsAction(Object.assign({
                    'selectors[0]': 'schools_count',
                    'selectors[1]': 'subscriptions',
                    'selectors[2]': 'teachers_count',
                    'selectors[3]': 'students_count'
                }, options))}
                filter_columns={[
                    {
                        key: 'id',
                        name: 'ID',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'name',
                        name: 'Name',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'country',
                        name: 'Country',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS],
                        values: Object.keys(countriesConstants.COUNTRIES).map(code => {
                            return {
                                label: countriesConstants.COUNTRIES[code],
                                value: code
                            }
                        })
                    },
                    {
                        key: 'city',
                        name: 'City',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'state',
                        name: 'State',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'number_schools',
                        name: 'No. of schools',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    },
                    {
                        key: 'has_subscription',
                        name: 'Has subscription',
                        expressions: [filterConstants.EXPRESSION_TRUE, filterConstants.EXPRESSION_FALSE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'name',
                        name: 'Name'
                    },
                    {
                        key: 'country',
                        name: 'Country'
                    },
                    {
                        key: 'city',
                        name: 'City'
                    },
                    {
                        key: 'state',
                        name: 'State'
                    },
                    {
                        key: 'schools_count',
                        name: 'No. schools'
                    },
                    {
                        key: 'teachers_count',
                        name: 'No. teachers'
                    }
                ]}
                entity={'districts'}
                create_button={<CreateButton/>}
                permissions={[accessConstants.PERMISSION_CAN_SEE_SCHOOLS]}
            >
                <Table/>
            </ListPage>
        </>
    );
}
