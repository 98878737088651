import {
    SET_LIMIT_POSTFIX,
    SET_LIMIT_PREFIX,
    SET_CURRENT_POSTFIX,
    SET_CURRENT_PREFIX
} from "mindsets-js-sdk";

import {
    LOADED_TIMESPENT,
    LOADED_TIMESPENT_TOTAL,
    LOADED_TIMESPENT_PAGES,
} from "../actions/timespent_actions";

const searchParams = new URLSearchParams(location.search);

const timespent = (payload = [], action) => {
    switch (action.type) {
        case LOADED_TIMESPENT:
            return action.payload;
        default:
            return payload;
    }
};
const timespent_current = (payload = searchParams.has('current') ? parseInt(searchParams.get('current')) : 1, action) => {
    switch (action.type) {
        case SET_CURRENT_PREFIX + 'timespent' + SET_CURRENT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
const timespent_total = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_TIMESPENT_TOTAL:
            return action.payload;
        default:
            return payload;
    }
};
const timespent_pages = (payload = 0, action) => {
    switch (action.type) {
        case LOADED_TIMESPENT_PAGES:
            return action.payload;
        default:
            return payload;
    }
};
const timespent_limit = (payload = searchParams.has('limit') ? parseInt(searchParams.get('limit')) : 25, action) => {
    switch (action.type) {
        case SET_LIMIT_PREFIX + 'timespent' + SET_LIMIT_POSTFIX:
            return action.payload;
        default:
            return payload;
    }
};
export default {
    timespent,
    timespent_current,
    timespent_total,
    timespent_pages,
    timespent_limit
}
