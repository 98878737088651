import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Xarrow from "react-xarrows"


class MatchingLine extends Component {
    render() {
        return (
            <div className="cc-matching-line">
                <Xarrow
                    start={this.props.start_id}
                    end={this.props.end_id}
                    startAnchor={['right', 'middle']}
                    endAnchor={['left', 'middle']}
                    curveness={0.1}
                    strokeWidth={2}
                    headSize={0}
                    tailSize={0}
                    color={this.props.match_color}
                />
            </div>
        )
    }
}

MatchingLine.defaultProps = {
    match_color: '#22a1d7'
}

MatchingLine.propTypes = {
    start_id: PropTypes.string.isRequired,
    end_id: PropTypes.string.isRequired,
    match_color: PropTypes.string
}

export default MatchingLine