import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {getCardClass} from '../utils';
import {
    SetChallengeBuilderChallengePagesAction, SetChallengeBuilderSelectedComponentIndexAction,
    SetChallengeBuilderSelectedRowIndexAction
} from "../../../../../actions/challenge_actions";

export default function () {
    const dispatch = useDispatch();

    const index = useSelector(state => state.challenge_builder_index);
    const challenge_code = useSelector(state => state.challenge_builder_challenge_code);
    const challenge_name = useSelector(state => state.challenge_builder_challenge_name);
    const challenge_color = useSelector(state => state.challenge_builder_challenge_color);
    const challenge_pages = useSelector(state => state.challenge_builder_challenge_pages);
    const selected_row_index = useSelector(state => state.challenge_builder_selected_row_index);
    const selected_component_index = useSelector(state => state.challenge_builder_selected_component_index);

    const page = challenge_pages[index];

    function renderContent() {
        if (!page.type) {
            return (
                <h4 className='text-center text-warning'>Please select the type of the page</h4>
            );
        }

        const CardClass = getCardClass(page);

        return (
            <CardClass
                challenge={{
                    code: challenge_code,
                    name: challenge_name,
                    color: challenge_color
                }}
                page={JSON.parse(JSON.stringify(page))}
                updatePage={page => {
                    let pages = JSON.parse(JSON.stringify(challenge_pages));
                    pages[index] = page;
                    dispatch(SetChallengeBuilderChallengePagesAction(pages));
                }}
                selectRow={selected_row_index => {
                    dispatch(SetChallengeBuilderSelectedRowIndexAction(selected_row_index));
                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(null));
                }}
                selectComponent={(selected_row_index, selected_component_index) => {
                    dispatch(SetChallengeBuilderSelectedRowIndexAction(selected_row_index));
                    dispatch(SetChallengeBuilderSelectedComponentIndexAction(selected_component_index));
                }}
            />
        );
    }

    return (
        <div
            className={"challenge-card" + (selected_row_index !== null || selected_component_index !== null ? ' limited' : '')}>
            {renderContent()}
        </div>
    )
}
