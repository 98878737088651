import React, {useState} from 'react';
import PropTypes from "prop-types";

import Items from './Items';
import Selections from './Selections';
import './selector.scss';

function Selector({component_data, existing_answer, is_preview, callback}) {
    const [answer, setAnswer] = useState(_.get(existing_answer, 'data.answer', defaultAnswer()));

    function defaultAnswer() {
        return {
            text: '',
            answer_selections: [],
            total_value: 0
        }
    }

    function getAnswerText(answer_selections) {
        if (_.isEmpty(answer_selections)) {
            return '';
        }

        const unit_prefix = _.get(component_data, 'data.unit_prefix', '');
        const unit_suffix = _.get(component_data, 'data.unit_suffix', '');
        const items = _.get(component_data, 'data.items', []);
        const selection_details = answer_selections.map((selection_data) => {
            const item = _.find(items, {id: selection_data.id});
            const selection_value_text = `${unit_prefix}${item.value}${unit_suffix}`;
            const selection_subtotal = item.value * selection_data.amount;
            const selection_subtotal_text = `${unit_prefix}${selection_subtotal}${unit_suffix}`;

            return `${item.title}: ${selection_value_text} x ${selection_data.amount} = ${selection_subtotal_text}`;
        })
        const total = getTotalValue(answer_selections);
        const total_text = `Total = ${unit_prefix}${total}${unit_suffix}`;

        return selection_details.join('\n') + '\n' + total_text;
    }

    function getTotalValue(answer_selections) {
        let total = 0
        const items = _.get(component_data, 'data.items', [])
        answer_selections.map((selection_data) => {
            const item = _.find(items, {id: selection_data.id})
            total += item.value * selection_data.amount
        })
        return total
    }

    function onClickItem(item_id, step = 1) {
        if (is_preview) {
            return false
        }

        // validation
        if (step > 0) {
            const value_maximum = _.get(component_data, 'data.value_maximum')
            if (value_maximum !== undefined) {
                if (answer.total_value >= value_maximum) {
                    return false
                }
            }
            const total_amount = _.sumBy(answer.answer_selections, 'amount')
            const amount_maximum = _.get(component_data, 'data.amount_maximum')
            if (amount_maximum !== undefined) {
                if (total_amount >= amount_maximum) {
                    return false
                }
            }
        }

        const answer_clone = _.cloneDeep(answer);
        const selected_item = _.find(answer_clone.answer_selections, {'id': item_id});
        if (selected_item) {
            selected_item.amount = selected_item.amount + step
        } else {
            answer_clone.answer_selections.push({
                'id': item_id,
                'amount': step
            })
        }
        _.remove(answer_clone.answer_selections, (item) => {
            return item.amount <= 0
        });
        answer_clone.answer_selections = _.orderBy(answer_clone.answer_selections, (item) => item.id);
        answer_clone.text = getAnswerText(answer_clone.answer_selections);
        answer_clone.total_value = getTotalValue(answer_clone.answer_selections);
        setAnswer(answer_clone);
        if (typeof callback === 'function') {
            callback(answer_clone);
        }
    }

    return (
        <div className="challenge-component-selector">
            <Items
                component_data={component_data}
                onClickItem={onClickItem}
            />
            <Selections
                component_data={component_data}
                answer={answer}
                onClickItem={onClickItem}
            />
        </div>
    );
}

Selector.propTypes = {
    component_data: PropTypes.object.isRequired,
    existing_answer: PropTypes.object,
    is_preview: PropTypes.bool,
    callback: PropTypes.func
}

export default Selector
