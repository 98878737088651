import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import EditButton from "./EditButton";

const Row = function ({item, row_number}) {
    const curricula = useSelector(state => state.standards_curricula);
    const getCurriculum = function () {
        const curriculum = curricula.find(curriculum => curriculum.id === item.curriculum_id);
        if (curriculum) {
            return curriculum.name;
        }

        return item.curriculum_id;
    }

    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{getCurriculum()}</td>
            <td>{item.code}</td>
            <td>{item.grade}</td>
            <td>{item.subject}</td>
            <td>{item.topic}</td>
            <td>{item.domain}</td>
            <td>{item.description}</td>
            <td><EditButton id={item.id}/></td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        grade: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        curriculum_id: PropTypes.number.isRequired,
        subject: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        topic: PropTypes.string.isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired,
};

export default Row;
