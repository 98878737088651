import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {ReloadListAction} from "mindsets-js-sdk";

import ReviewButton from "./ReviewButton";
import ApproveButton from "./ApproveButton";

const Row = function ({item, row_number}) {
    const dispatch = useDispatch();

    return (
        <tr key={item.id}>
            <td>{row_number}</td>
            <td>{item.name}</td>
            <td>{item.number_students}</td>
            <td>{item.status}</td>
            <td>
                <div className='d-flex flex-gap-5'>
                    <Link to={'/contracts/' + item.id} className="button">View</Link>
                    <ReviewButton id={item.id} status={item.status} callback={() => dispatch(ReloadListAction(true, 'contracts'))}/>
                    <ApproveButton id={item.id} status={item.status} callback={() => dispatch(ReloadListAction(true, 'contracts'))}/>
                </div>
            </td>
        </tr>
    );
};

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number_students: PropTypes.number,
        status: PropTypes.string.isRequired,
    }).isRequired,
    row_number: PropTypes.number.isRequired
};

export default Row;
