import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Checkbox,
    authUtils,
    accessConstants,
    VerifyGradesAction,
    AssignGradesAction,
    AddClassCodesToSelectionAction
} from "mindsets-js-sdk";

import NoAccess from "../../common/NoAccess";
import Results from "./components/Results";

export default function () {
    const me = useSelector(state => state.me);
    const dispatch = useDispatch();
    const selected_lesson_codes = useSelector(state => state.selected_lesson_codes);
    const [grades, setGrades] = useState([]);
    const [lessons, setLessons] = useState({});
    const [selected_lesson_codes_string, setSelectedLessonCodesString] = useState(selected_lesson_codes.join(', '));
    if (!me) {
        return null;
    }
    if (authUtils.iCant(me, [accessConstants.PERMISSION_CAN_MANAGE_ALL_LESSONS])) {
        return (
            <NoAccess/>
        );
    }

    return (
        <>
            <div className='section'>
                <div className='d-flex'>
                    {['3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'Elementary', 'Middle', 'High School', 'All'].map(grade_level => {
                        return (
                            <Checkbox
                                key={grade_level}
                                checked={grades.includes(grade_level)}
                                toggle={() => {
                                    let grades_new = JSON.parse(JSON.stringify(grades));
                                    if (grades_new.includes(grade_level)) {
                                        grades_new.splice(grades_new.indexOf(grade_level), 1);
                                    } else {
                                        grades_new.push(grade_level);
                                    }
                                    setGrades(grades_new);
                                }}
                                label={'Grade ' + grade_level}
                            />
                        );
                    })}
                </div>
            </div>
            <div className='section'>
                <div>
                    <label htmlFor='lesson-codes' className='d-block'>Class codes *</label>
                    <textarea
                        id='lesson-codes'
                        value={selected_lesson_codes_string}
                        onChange={event => {
                            const value = event.target.value;
                            setLessons({});
                            setSelectedLessonCodesString(value);
                            const array = value.split(',').map(item => item.trim()).filter(item => item !== '');
                            dispatch(AddClassCodesToSelectionAction(array));
                        }}/>
                </div>
            </div>
            <div className='section'>
                <h3>Selected {selected_lesson_codes.length} classes</h3>
            </div>
            <div className='section'>
                <button
                    onClick={event => {
                        event.preventDefault();
                        dispatch(VerifyGradesAction({
                            lesson_codes: selected_lesson_codes
                        })).then(response => setLessons(response.lesson_codes));
                    }}
                    disabled={!(grades.length && selected_lesson_codes.length)}
                >Verify
                </button>
            </div>
            <div className='section'>
                <Results
                    lessons={lessons}
                    setLessons={setLessons}
                    setSelectedLessonCodesString={setSelectedLessonCodesString}
                    assign={() => {
                        dispatch(AssignGradesAction({
                            lesson_codes: selected_lesson_codes,
                            grades
                        }));
                        setLessons({});
                        setGrades([]);
                        setSelectedLessonCodesString('');
                        dispatch(AddClassCodesToSelectionAction([]));
                    }}
                />
            </div>
        </>
    );
}
