import React from 'react';

import BaseComponentProperty from "../index";
import SpreadsheetCell from "./SpreadsheetCell";
import Style from "../Style";

export default class Spreadsheet extends BaseComponentProperty {
    hasQuestionNumber() {
        return true;
    }

    renderHeaderRow(index, row) {
        return (
            <div key={index} style={{position: 'relative'}} className='bordered'>
                <i
                    className='fas fa-trash text-danger clickable'
                    style={{position: 'absolute', right: '8px', top: '8px'}}
                    onClick={() => {
                        let data = this.props.component.data;
                        data.headers.splice(index, 1);
                        this.props.onChange('data', data);
                    }}
                />
                <button className='button mb-2' onClick={() => {
                    let data = JSON.parse(JSON.stringify(this.props.component.data));
                    const keys = Object.keys(data.headers[index]);
                    let next_key = 'a';
                    if (keys.length > 0) {
                        const last_key = keys[keys.length - 1];
                        next_key = String.fromCharCode(last_key.charCodeAt(0) + 1);
                    }
                    row[next_key] = {};
                    data.headers[index] = row;
                    this.props.onChange('data', data);
                }}>+ cell
                </button>
                <div className='d-flex flex-row flex-wrap'>
                    {Object.keys(row).map((cell_key, i) => {
                        let cell = JSON.parse(JSON.stringify(row[cell_key]));

                        return (
                            <div key={i} className='bordered'>
                                <Style onChange={value => {
                                    let data = JSON.parse(JSON.stringify(this.props.component.data));
                                    let cell = data.headers[index][cell_key];
                                    if (typeof cell !== 'object') {
                                        cell = {style: value, title: cell};
                                    } else {
                                        cell.style = value;
                                    }
                                    data.headers[index][cell_key] = cell;
                                    this.props.onChange('data', data);
                                }} initial={typeof cell === 'object' ? cell.style : null}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderContentRow(index, row) {
        return (
            <div key={index} style={{position: 'relative'}} className='bordered'>
                <i
                    className='fas fa-trash text-danger clickable'
                    style={{position: 'absolute', right: '8px', top: '8px'}}
                    onClick={() => {
                        let data = this.props.component.data;
                        data.data.splice(index, 1);
                        this.props.onChange('data', data);
                    }}
                />
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    const keys = Object.keys(data.data[index]);
                    let next_key = 'a';
                    if (keys.length > 0) {
                        const last_key = keys[keys.length - 1];
                        next_key = String.fromCharCode(last_key.charCodeAt(0) + 1);
                    }
                    row[next_key] = {
                        editable: false
                    };
                    data.data[index] = row;
                    this.props.onChange('data', data);
                }}>+ cell
                </button>
                <div className='d-flex flex-row flex-wrap'>
                    {Object.keys(row).map((cell_key, i) => {
                        return (
                            <SpreadsheetCell
                                key={i}
                                is_header={false}
                                cell={row[cell_key]}
                                updateCell={cell => {
                                    let data = this.props.component.data;
                                    data.data[index][cell_key] = cell;
                                    this.props.onChange('data', data);
                                }}
                                deleteCell={() => {
                                    let data = this.props.component.data;
                                    delete data.data[index][cell_key];
                                    let cells = data.data[index];
                                    Object.keys(cells).map((cell_key2, cell_key_index) => {
                                        if (cell_key2 !== String.fromCharCode(cell_key_index + 97)) {
                                            cells[String.fromCharCode(cell_key_index + 97)] = cells[cell_key2];
                                            delete cells[cell_key2];
                                        }
                                    });
                                    this.props.onChange('data', data);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    renderOwnProperties() {
        return (
            <div>
                <div>
                    <label htmlFor="class_name">Color</label>
                    <select
                        id="class_name"
                        className='form-control'
                        value={this.props.component.data.class_name ? this.props.component.data.class_name : ''}
                        onChange={event => {
                            let data = this.props.component.data;
                            data.class_name = event.target.value.toString();
                            this.props.onChange('data', data);
                        }}
                    >
                        <option value='' disabled={true}>Select color</option>
                        <option value='table-pink'>Pink</option>
                        <option value='table-purple'>Purple</option>
                        <option value='table-green'>Green</option>
                    </select>
                </div>
                <h4>Heading</h4>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let headers = data.headers;
                    headers.push({title: ''});
                    data.headers = headers;
                    this.props.onChange('data', data);
                }}>+ row
                </button>
                <div className='d-flex flex-column'>
                    {this.props.component.data.headers.map((row, index) => this.renderHeaderRow(index, row))}
                </div>
                <h4>Data</h4>
                <button className='button mb-2' onClick={() => {
                    let data = this.props.component.data;
                    let rows = data.data;
                    rows.push({});
                    data.data = rows;
                    this.props.onChange('data', data);
                }}>+ row
                </button>
                <div className='d-flex flex-column'>
                    {this.props.component.data.data.map((row, index) => this.renderContentRow(index, row))}
                </div>
            </div>
        );
    }
}
