import React, {useState} from "react";
import {useSelector} from "react-redux";
import {SchoolDropdownItem} from "mindsets-js-sdk";

export default function () {
    const contract = useSelector(state => state.contract);
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className='d-flex align-items-end flex-gap-5'>
                <h5>
                    <span>Schools in the scope of the contract</span>
                    <span>{contract.schools && contract.schools.length ? '(' + contract.schools.length + ' selected)' : ''}</span>
                </h5>
                {contract.schools && contract.schools.length ? (
                    <button onClick={event => {
                        event.preventDefault();
                        setOpen(!open);
                    }}>{open ? 'Hide' : 'Open'}
                    </button>
                ) : null}
            </div>
            {!contract.schools || !contract.schools.length ? (
                <p>All the schools within the selected School District have access to the Library</p>
            ) : null}
            {open ? (
                <div className='d-flex flex-wrap mx-n1'>
                    {contract.schools && contract.schools.length ? contract.schools.map(school => {
                        return (
                            <div key={school.id} className='bordered m-1 d-flex'>
                                <SchoolDropdownItem item={school}/>
                            </div>
                        );
                    }) : null}
                </div>
            ) : null}
        </>
    );
}
