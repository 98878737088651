import MindsetsJsSDK, {SuccessMessageAction} from "mindsets-js-sdk";

export const LOADED_PROJECT_PRACTICES = '[project] Loaded Practices';

export const LoadPracticesAction = () => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.get('practices', {order_by: 'name'}))
        .then(response => {
            dispatch(LoadedPracticesAction(response.items));

            return response.items;
        });
};
export const LoadedPracticesAction = payload => ({
    type: LOADED_PROJECT_PRACTICES,
    payload
});

export const DeleteProjectAction = (school_id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.delete(`projects/${school_id}`, options))
        .then(() => dispatch(SuccessMessageAction('Project deleted')));
};
export const InvalidateProjectCacheAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.post(`projects/${id}/invalidate`, null, options))
        .then(() => dispatch(SuccessMessageAction('Project cache has been invalidated.')));
};
export const InvalidateAllProjectsCacheAction = (id, options) => dispatch => {
    return Promise.resolve()
        .then(() => MindsetsJsSDK().API.post(`projects/invalidate`, null, options))
        .then(() => dispatch(SuccessMessageAction('All Projects cache has been invalidated.')));
};
