import React from 'react';

import Row from './Row';
import {useSelector} from "react-redux";

export default function () {
    const items = useSelector(state => state.timespent);

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="text-center">
                    <th scope="col">Card No</th>
                    <th scope="col">Challenge Code</th>
                    <th scope="col">Challenge ID</th>
                    <th scope="col">Lesson Code</th>
                    <th scope="col">Lesson ID</th>
                    <th scope="col">Stream Entity ID</th>
                    <th scope="col">Stream ID</th>
                    <th scope="col">User ID</th>
                    <th scope="col">Time Stamp</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item => <Row key={item.id} item={item}/>)}
                </tbody>
            </table>
        </div>
    );
};
