import React from 'react';
import PropTypes from "prop-types";
import {datesConstants} from "mindsets-js-sdk";

import ManageButton from "./ManageButton";

const Row = function ({item, row_number}) {
    return (
        <tr>
            <td>{row_number}</td>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{Array.isArray(item.users) ? item.users.length : 0}</td>
            <td>{new Date(item.created_at * 1000).toLocaleDateString('en-US', datesConstants.US_DATE_FORMAT)}</td>
            <td>
                <ManageButton id={item.id}/>
            </td>
        </tr>
    );
}

Row.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired
    }).isRequired,
    row_number: PropTypes.number.isRequired,
};

export default Row;
