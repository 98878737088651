import React, {Component} from 'react'
import PropTypes from 'prop-types'


class AnswerOptions extends Component {
    renderPaperOption() {
        const onClickPaper = (e) => {
            e.preventDefault()
            this.props.onSelect('paper')
        }

        const extra_class = this.props.answer_option === 'paper' ? ' selected ' : ''

        return (
            <a className={"link-new " + extra_class} href="#" onClick={onClickPaper}>
                {`I am using paper`}
            </a>
        )
    }

    renderToolOption() {
        const onClickTool = (e) => {
            e.preventDefault()
            this.props.onSelect('tool')
        }

        const extra_class = this.props.answer_option === 'tool' ? ' selected ' : ''

        return (
            <a className={"link-new " + extra_class} href="#" onClick={onClickTool}>
                {`I am using the online tool`}
            </a>
        )
    }

    render() {
        return (
            <div className="component-graphing-options-container">
                <div className="d-flex flex-box-10-20">
                    <div>
                        {this.renderPaperOption()}
                    </div>

                    <div>
                        {this.renderToolOption()}
                    </div>
                </div>
            </div>
        )
    }
}

AnswerOptions.propTypes = {
    answer_option: PropTypes.string,
    onSelect: PropTypes.func.isRequired
}

export default AnswerOptions
