import Validator from "./Validator";

export default class JsonEntitiesEntityDataCardContentRowsRowValidator extends Validator {
    constructor() {
        super();
        this.validationRules = {
            type: 'object',
            empty: false,
            mandatory: true,
            attributes: {
                components: {
                    type: 'array',
                    empty: false,
                    mandatory: true
                },
                config: {
                    type: 'object',
                    empty: false,
                    mandatory: false
                },
                container_style: {
                    type: 'object',
                    empty: false,
                    mandatory: false
                }
            }
        };
    }
}
