import React from 'react';
import {Link} from "react-router-dom";
import {ListPage, accessConstants, filterConstants, LoadAnnouncementsAction} from "mindsets-js-sdk";

import './announcements.scss';

import Table from "./components/Table";

export default function () {
    return (
        <>
            <h1>Announcements</h1>
            <ListPage
                action={LoadAnnouncementsAction}
                filter_columns={[
                    {
                        key: 'body',
                        name: 'Body',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'title',
                        name: 'Title',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_CONTAINS, filterConstants.EXPRESSION_NOT_CONTAINS]
                    },
                    {
                        key: 'order',
                        name: 'Order',
                        expressions: [filterConstants.EXPRESSION_EQUAL, filterConstants.EXPRESSION_NOT_EQUAL, filterConstants.EXPRESSION_GT, filterConstants.EXPRESSION_GTE, filterConstants.EXPRESSION_LT, filterConstants.EXPRESSION_LTE]
                    }
                ]}
                sort_columns={[
                    {
                        key: 'id',
                        name: 'ID'
                    },
                    {
                        key: 'body',
                        name: 'Body'
                    },
                    {
                        key: 'title',
                        name: 'Title'
                    },
                    {
                        key: 'order',
                        name: 'Order'
                    }
                ]}
                entity={'announcements'}
                create_button={(
                    <div className="d-flex justify-content-end">
                        <Link to="/announcements/new" className="button">+ New Announcement</Link>
                    </div>
                )}
                permissions={[accessConstants.PERMISSION_CAN_SEE_ANNOUNCEMENTS]}
                default_order_by='order'
            >
                <Table/>
            </ListPage>
        </>
    );
}
