import React, {Component} from 'react'
import PropTypes from 'prop-types'


class Selection extends Component {
    renderImage() {
        return (
            <div className="cc-selector-selections-item-img">
                <img
                    src={this.props.item.image_url}
                    alt={this.props.item.image_alt}
                />
            </div>
        )
    }

    renderTitle() {
        return (
            <div className="cc-selector-selections-item-title">
                {this.props.item.title}
            </div>
        )
    }

    renderValue() {
        const unit_prefix = _.get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = _.get(this.props.component_data, 'data.unit_suffix', '')
        const value_prefix = _.get(this.props.component_data, 'data.value_prefix', '')
        const value_suffix = _.get(this.props.component_data, 'data.value_suffix', '')
        const value = this.props.item.value
        const value_text = `${value_prefix}${unit_prefix}${value}${unit_suffix}${value_suffix}`
        return (
            <div className="cc-selector-selections-item-value">
                {value_text}
            </div>
        )
    }

    renderAmount() {
        const amount = _.get(this.props.selection_data, 'amount', 0)
        const onClickMinus = () => {
            this.props.onClickItem(this.props.item.id, -1)
        }
        const onClickPlus = () => {
            this.props.onClickItem(this.props.item.id, 1)
        }
        const total_value = this.props.answer.total_value
        const value_maximum = _.get(this.props.component_data, 'data.value_maximum', Number.MAX_SAFE_INTEGER)
        const total_amount = _.sumBy(this.props.answer.answer_selections, 'amount')
        const amount_maximum = _.get(this.props.component_data, 'data.amount_maximum', Number.MAX_SAFE_INTEGER)
        const plus_disabled = (total_value >= value_maximum) || (total_amount >= amount_maximum)
        return (
            <div className="d-flex cc-selector-selections-item-amount">
                <div className="cc-selector-selections-item-amount-minus">
                    <button className="btn btn-challenge btn-small" onClick={onClickMinus}>
                        <i className="fas fa-minus">
                        </i>
                    </button>
                </div>
                <div className="cc-selector-selections-item-amount-text">
                    {amount}
                </div>
                <div className="cc-selector-selections-item-amount-plus">
                    <button className="btn btn-challenge btn-small" onClick={onClickPlus} disabled={plus_disabled}>
                        <i className="fas fa-plus">
                        </i>
                    </button>
                </div>
            </div>
        )
    }

    renderSubtotal() {
        const value = _.get(this.props.item, 'value', 0)
        const amount = _.get(this.props.selection_data, 'amount', 0)
        const subtotal = value * amount
        const unit_prefix = _.get(this.props.component_data, 'data.unit_prefix', '')
        const unit_suffix = _.get(this.props.component_data, 'data.unit_suffix', '')
        const subtotal_text = `${unit_prefix}${subtotal}${unit_suffix}`
        return (
            <div className="cc-selector-selections-item-subtotal">
                {subtotal_text}
            </div>
        )
    }

    render() {
        return (
            <div className="cc-selector-selections-item">
                <div className="d-flex justify-content-center align-items-end flex-box-0-10 flex-wrap">
                    {this.renderImage()}
                    {this.renderTitle()}
                    {this.renderValue()}
                    {this.renderAmount()}
                    {this.renderSubtotal()}
                </div>
            </div>
        )
    }
}

Selection.propTypes = {
    component_data: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    selection_data: PropTypes.object.isRequired,
    onClickItem: PropTypes.func.isRequired,
    answer: PropTypes.object.isRequired
}

export default Selection
